<template>
    <div class="curated-set">
        <section class="gs-generic-header">
            <h1>Curated Set</h1>
            <router-link class="gs-standard-button"
                         :to="{ name: 'EditCuratedSet', params: { curatedSetId: $route.params.curatedSetId } }">
                Edit
            </router-link>
        </section>

        <LoadingSpinner v-if="isLoadingCuratedSet"
                        is-black="true" />
        <div v-else
             class="gs-standard-content">
            <section class="row curated-set-details">
                <h3 class="gs-standard-sub-header">
                    General Info
                </h3>

                <div class="col-md-4">
                    <div class="gs-standard-detail">
                        <label>Name:</label>
                        <span>{{ curatedSet.name }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Identifier:</label>
                        <span>{{ curatedSet.identifier }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Category:</label>
                        <span>
                            {{ _.get(curatedSet.productCategory, 'name', 'None') }}
                        </span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Is Active:</label>
                        <span>{{ curatedSet.isActive || 'false' }}</span>
                    </div>
                </div>
            </section>


            <section class="curated-set-images">
                <h3 class="gs-standard-sub-header">
                    Set Image
                </h3>

                <div v-if="curatedSet.heroImage"
                     class="row image-list">
                    <div class="image col-md-2">
                        <cld-image :public-id="curatedSet.heroImage"
                                   width="800"
                                   height="800"
                                   crop="limit" />
                    </div>
                </div>
                <div v-else>
                    <span class="no-items">No curated set image</span>
                </div>
            </section>

            <section class="gs-generic-header">
                <h3>Product Skus</h3>
            </section>

            <section v-if="isLoadingCuratedSetProductSkus">
                <LoadingSpinner is-black="true" />
            </section>
            <section v-else
                     class="curated-set-products-table gs-hide-filter">
                <v-client-table
                    v-if="curatedSetProductSkus.length > 0"
                    :data="curatedSetProductSkus"
                    :columns="columns"
                    :options="options">
                    <div slot="productLink"
                         slot-scope="data">
                        <router-link :to="{ name: 'ProductSku', params: { productSkuId: data.row['id'] } }">
                            {{ data.row.name }}
                        </router-link>
                    </div>

                    <div slot="productType"
                         slot-scope="data">
                        {{ data.row.productTypes[0].name }}
                    </div>
                </v-client-table>
                <div v-else
                     class="no-items">
                    There are no product skus for this curated set
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'CuratedSet',
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        isLoadingCuratedSet:            true,
        isLoadingCuratedSetProductSkus: false,
        curatedSetImages:               [],
        curatedSetProductSkus:          [],
        columns:                        ['id', 'productLink', 'productType', 'quantity'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'productLink': 'Product Sku',
            'productType': 'Product Type',
          },
        },
      }
    },
    mounted() {
      this.getCuratedSet()
    },
    methods: {
      getCuratedSet() {
        this.isLoadingCuratedSet = true
        this.axios.get('/layout_instances/' + this.$route.params.curatedSetId)
          .then(response => { this.onCuratedSetRetrieved(response.data) })
      },
      getCuratedSetProductSkus() {
        const layoutSkus = this.curatedSet.data.pieces.map((p) => p.productSku)
        if (_.isEmpty(layoutSkus)) return

        this.isLoadingCuratedSetProductSkus = true
        this.axios.get('/product_skus', { params: { skus: layoutSkus } })
          .then(response => this.onCuratedSetProductSkusRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////

      //////////// CALLBACKS ////////////
      onCuratedSetRetrieved(data) {
        this.curatedSet = data.layoutInstance
        this.isLoadingCuratedSet = false

        this.getCuratedSetProductSkus()
      },
      onCuratedSetProductSkusRetrieved(data) {
        this.curatedSetProductSkus = _.map(data.productSkus, sku => {
          const existingSku = _.find(this.curatedSet.data.pieces, piece => {
            return piece.productSku === sku.sku
          })
          return _.merge(sku, { quantity: (existingSku || {}).quantity })
        })
        this.isLoadingCuratedSetProductSkus = false
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss">

.curated-set {

  .gs-standard-content {

    .curated-set-images {

      margin-bottom:            3em;

      .image-list {

        .image {

          text-align:           center;
          margin-bottom:        1em;

          img {
            display:            block;
            min-height:         1.5em;
            width:              100%;
            margin-top:         0.5em;
          }
        }
      }
    }
  }
}

</style>
