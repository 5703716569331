<template>
    <div class="edit-product">
        <section class="gs-generic-header">
            <h1>Edit: {{ product.name }}</h1>

            <button :disabled="isSavingProduct"
                    class="gs-standard-button"
                    @click="backToProductPage()">
                Go Back
            </button>
        </section>

        <LoadingSpinner v-if="isLoadingProduct"
                        is-black="true" />
        <div v-else
             class="content gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />

            <section class="product-details gs-special-content row">
                <EnableDisableButton
                    v-if="productBrandIsSet"
                    :object-to-watch="product"
                    :fields-to-observe="['name', 'identifier', 'brand', 'manufacturer', 'description', 'internalNotes', 'secondaryInfo', 'materialsString']"
                    :disable-button-computed="isSavingProduct"
                    button-text="Save"
                    @buttonClick="updateCurrentProduct()" />
                <button v-else
                        class="gs-standard-button">
                    <LoadingSpinner />
                </button>

                <div class="col-md-6">
                    <h2>Product Details</h2>

                    <div class="detail">
                        <label>Name:</label>
                        <input v-model="product.name"
                               placeholder="Name" />
                    </div>

                    <div class="detail">
                        <label>Identifier:</label>
                        <input v-model="product.identifier"
                               placeholder="Identifier" />
                    </div>

                    <div class="detail">
                        <label>Brand:</label>
                        <div class="input-group mb-3 brand-select">
                            <select class="custom-select"
                                    @change="selectBrand($event)">
                                <option>
                                    Please select a brand..
                                </option>
                                <option v-for="option in brands"
                                        :key="option.id"
                                        :selected="product.brand.id == option.id">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="detail">
                        <label>Manufacturer:</label>
                        <div class="input-group mb-3 manufacturer-select">
                            <select class="custom-select"
                                    @change="selectManufacturer($event)">
                                <option>
                                    Please select a manufacturer..
                                </option>
                                <option v-for="option in manufacturers"
                                        :key="option.id"
                                        :selected="product.manufacturer.id == option.id">
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="detail">
                        <label>Materials Description:</label>
                        <input v-model="product.materialsString"
                               placeholder="Materials" />
                    </div>

                    <div class="detail">
                        <label>Internal Notes:</label>
                        <textarea v-model="product.internalNotes"
                                  placeholder="Internal Notes" />
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="detail">
                        <label>Description:</label>
                        <textarea v-model="product.description"
                                  placeholder="Description" />
                    </div>

                    <div class="detail">
                        <label>Behind the Design:</label>
                        <textarea v-model="product.secondaryInfo"
                                  placeholder="Behind the Design" />
                    </div>
                </div>
            </section>


            <section class="product-associations">
                <h3>Associations</h3>

                <ProductAssociationManager
                    :selected-categories="currentProductCategories"
                    :selected-styles="currentProductStyles"
                    :selected-types="currentProductTypes"
                    :selected-materials="currentProductMaterials"
                    @addCategory="addCategory"
                    @removeCategory="removeCategory"
                    @addStyle="addStyle"
                    @removeStyle="removeStyle"
                    @addType="addType"
                    @removeType="removeType"
                    @addMaterial="addMaterial"
                    @removeMaterial="removeMaterial" />
            </section>

            <section class="attributes">
                <h3>Defined Attributes</h3>

                <ProductAttributeManager
                    :product-id="product.id" />
            </section>

            <section class="additional-properties">
                <h3>Additional Properties</h3>

                <ProductAdditionalPropertiesManager
                    :additional-properties="product.additionalProperties"
                    @addAdditionalProperty="addProductAdditionalProperty"
                    @removeAdditionalProperty="removeAdditionalProperty" />
            </section>

            <section class="product-images">
                <h3>Images</h3>

                <FormErrors v-if="imagePrimaryErrorMessage || imageErrorMessages.length > 0"
                            :primary-message="imagePrimaryErrorMessage"
                            :error-messages="imageErrorMessages" />

                <ProductImageManager
                    :product-images="currentProductImages"
                    :is-saving-image="isAddingProductImage"
                    :removing-image-index="removingProductIndex"
                    @addProductImage="addProductImage"
                    @removeProductImage="removeProductImage" />
            </section>

            <section class="recommendations">
                <h3>Recommendations</h3>

                <div class="gs-toggle-container">
                    <label class="tag">Recommend?</label>
                    <toggle-button
                        :value="recommendation.recommend"
                        :sync="true"
                        color="#82C7EB"
                        @change="recommendation.recommend = !recommendation.recommend" />
                </div>
                <div v-if="recommendation.recommend">
                    <input v-model="recommendation.ranking"
                           placeholder="Recommendation rank (optional)" />
                    <div class="gs-toggle-container">
                        <label class="tag">Recommendation exclusive to product types?</label>
                        <toggle-button
                            :value="recommendation.exclusiveForType"
                            color="#82C7EB"
                            @change="recommendation.exclusiveForType = !recommendation.exclusiveForType" />
                    </div>
                </div>

                <button class="gs-standard-button"
                        @click="uploadRecommendation">
                    Save (or delete) recommendation
                </button>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'
  import EnableDisableButton from '@/components/forms/EnableDisableButton'
  import ProductAssociationManager from '@/components/resources/products/ProductAssociationManager'
  import ProductImageManager from '@/components/resources/products/ProductImageManager'
  import ProductAdditionalPropertiesManager from '@/components/resources/products/ProductAdditionalPropertiesManager'
  import { arrayHelper } from '@petra-living/petra-vue-common'
  import apiHelper from '@/helpers/apiHelper'
  import ProductAttributeManager from '../../components/resources/products/ProductAttributeManager'
  import * as Sentry from '@sentry/browser'

  export default {
    name: 'EditProduct',
    components: {
      ProductAttributeManager,
      LoadingSpinner,
      FormErrors,
      EnableDisableButton,
      ProductAssociationManager,
      ProductImageManager,
      ProductAdditionalPropertiesManager,
    },
    data() {
      return {
        product:                          {},
        primaryErrorMessage:              '',
        errorMessages:                    [],
        currentProductCategories:         [],
        currentProductMaterials:          [],
        currentProductStyles:             [],
        currentProductTypes:              [],
        currentProductImages:             [],
        brands:                           [],
        manufacturers:                    [],
        recommend:                        false,
        recommendation:                   { recommend: false, id: null, ranking: null, exclusiveForType: null },
        productBrandIsSet:                false,
        isLoadingProduct:                 true,
        isSavingProduct:                  false,
        isAddingProductImage:             false,
        removingProductIndex:             null,
        imagePrimaryErrorMessage:         '',
        imageErrorMessages:               [],
      }
    },
    async mounted() {
      try {
        const response = await this.axios.get('/products/' + this.$route.params.productId)
        this.onCurrentProductRetrieved(response.data)
      } catch(error) {
        Sentry.captureException(error)
      }
    },
    methods: {
      async getCurrentProductCategories() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_categories')
          this.onCurrentProductCategoriesRetrieved(response.data)
        } catch(error) {
          Sentry.captureException(error)
        }
      },
      async getCurrentProductStyles() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_styles')
          this.onCurrentProductStylesRetrieved(response.data)
        } catch(error) {
          Sentry.captureException(error)
        }
      },
      async getCurrentProductMaterials() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_materials')
          this.onCurrentProductMaterialsRetrieved(response.data)
        } catch(error) {
          Sentry.captureException(error)
        }
      },
      async getCurrentProductTypes() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_types')
          this.onCurrentProductTypesRetrieved(response.data)
        } catch(error) {
          Sentry.captureException(error)
        }
      },
      async getCurrentProductImages() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_images')
          this.onCurrentProductImagesRetrieved(response.data)
        } catch(error) {
          Sentry.captureException(error)
        }
      },
      async getBrands() {
        try {
          const response = await this.axios.get('/brands')
          this.onBrandsRetrieved(response.data)
        } catch(error) {
          Sentry.captureException(error)
        }
      },
      async getManufacturers() {
        try {
          const response = await this.axios.get('/manufacturers')
          this.onManufacturersRetrieved(response.data)
        } catch(error) {
          Sentry.captureException(error)
        }
      },
      async getAdditionalProperties() {
        try {
          const response = await this.axios.get('/products_additional_properties')
          this.onAdditionalPropertiesRetrieved(response.data)
        } catch(error) {
          Sentry.captureException(error)
        }
      },
      async getRecommendations() {
        try {
          const response = await this.axios.get('/recommendations')
          this.onRecommendationsRetrieved(response.data)
        } catch(error) {
          Sentry.captureException(error)
        }
      },
      //////////// TEMPLATE ACTIONS ////////////
      backToProductPage() {
        this.$router.push({ name: 'Product', params: { productId: this.$route.params.productId } })
      },
      updateCurrentProduct() {
        this.isSavingProduct = true
        this.axios.patch('/products/' + this.product.id, { product: this.buildUpdateProduct() })
          .then(response => this.onCurrentProductUpdated(response.data))
          .catch(error => this.onCurrentProductUpdatedFailure(error.response))
      },
      selectBrand(event) {
        const brandName = event.target.value
        this.product.brand = _.find(this.brands, (b) => b.name == brandName)
      },
      selectManufacturer(event) {
        const manufacturerName = event.target.value
        this.product.manufacturer = _.find(this.manufacturers, (m) => m.name == manufacturerName)
      },
      async addCategory(category) {
        try {
          const response = await this.axios.post('/products/' + this.product.id + '/product_categories', { productCategory: category })
          this.currentProductCategories.push(response.data.productCategory)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async removeCategory(category) {
        try {
          const response = await this.axios.delete('/products/' + this.product.id + '/product_categories/' + category.id)
          arrayHelper.removeObjectByValue(this.currentProductCategories, response.data.productCategory.id, 'id')
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async addStyle(style) {
        try {
          const response = await this.axios.post('/products/' + this.product.id + '/product_styles', { productStyle: style })
          this.currentProductStyles.push(response.data.productStyle)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async removeStyle(style) {
        try {
          const response = await this.axios.delete('/products/' + this.product.id + '/product_styles/' + style.id)
          arrayHelper.removeObjectByValue(this.currentProductStyles, response.data.productStyle.id, 'id')
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async addType(type) {
        try {
          const response = await this.axios.post('/products/' + this.product.id + '/product_types', { productType: type })
          this.currentProductTypes.push(response.data.productType)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async removeType(type) {
        try {
          const response = await this.axios.delete('/products/' + this.product.id + '/product_types/' + type.id)
          arrayHelper.removeObjectByValue(this.currentProductTypes, response.data.productType.id, 'id')
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async addMaterial(material) {
        try {
          const response = await this.axios.post('/products/' + this.product.id + '/product_materials', { productMaterial: material })
          this.currentProductMaterials.push(response.data.productMaterial)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async removeMaterial(material) {
        try {
          const response = await this.axios.delete('/products/' + this.product.id + '/product_materials/' + material.id)
          arrayHelper.removeObjectByValue(this.currentProductMaterials, response.data.productMaterial.id, 'id')
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      addProductAdditionalProperty(key, value) {
        let additionalProperty = {}
        additionalProperty[key] = value
        this.axios.post('/products/' + this.product.id + '/additional_properties', {
          additionalProperties: additionalProperty,
        }).then(response => { this.product.additionalProperties = response.data.additionalProperties })
      },
      removeAdditionalProperty(key) {
        this.axios.delete('/products/' + this.product.id + '/additional_properties/' + key)
          .then(response => { this.product.additionalProperties = response.data.additionalProperties || {} })
      },
      addProductImage(imageType, file) {
        this.isAddingProductImage = true
        this.imagePrimaryErrorMessage = ''
        this.imageErrorMessages = []

        const imageObj = {
          imageType: imageType,
          file: file,
        }
        apiHelper.submitMultipartForm(
          imageObj, // params
          `/products/${this.product.id}/product_images`, // endpoint
          this.onProductImageAdded, // onSuccess
          this.onProductImageAddedFailure, // onFailure
        )
      },
      removeProductImage(index) {
        let productImage = this.currentProductImages[index]
        this.removingProductIndex = index
        this.axios.delete('/products/' + this.product.id + '/product_images/' + productImage.id)
          .then(response => this.onProductImageRemoved(index))
      },
      //////////// CALLBACKS ////////////
      onCurrentProductRetrieved(data) {
        this.product = data.product
        this.isLoadingProduct = false

        this.getCurrentProductCategories()
        this.getCurrentProductStyles()
        this.getCurrentProductMaterials()
        this.getCurrentProductTypes()
        this.getCurrentProductImages()

        this.getBrands()
        this.getManufacturers()
        this.getAdditionalProperties()
        this.getRecommendations()
      },
      onCurrentProductCategoriesRetrieved(data) {
        this.currentProductCategories = data.productCategories
      },
      onCurrentProductStylesRetrieved(data) {
        this.currentProductStyles = data.productStyles
      },
      onCurrentProductMaterialsRetrieved(data) {
        this.currentProductMaterials = data.productMaterials
      },
      onCurrentProductTypesRetrieved(data) {
        this.currentProductTypes = data.productTypes
      },
      onCurrentProductImagesRetrieved(data) {
        this.currentProductImages = data.productImages
      },
      onBrandsRetrieved(data) {
        this.brands = data.brands

        // NOTE
        // This is a bit hacky :( The brand object that is on the product does not match the brand objects
        // that are returned by getBrands endpoint. So we need to re-assign the product brand so they will match
        // Only once this is done do we render the save button
        let matchingBrand = arrayHelper.findObjectByValue(this.brands, this.product.brand.id, 'id')
        this.product.brand = matchingBrand
        this.productBrandIsSet = true
      },
      onManufacturersRetrieved(data) {
        this.manufacturers = data.manufacturers
      },
      onAdditionalPropertiesRetrieved(data) {
        this.additionalProperties = data.productAdditionalProperties
      },
      onCurrentProductUpdated(data) {
        this.isSavingProduct = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully updated product!',
          statusCode: 1,
        })
        this.backToProductPage()
      },
      onCurrentProductUpdatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error updating product sku'
          this.errorMessages = JSON.stringify(response)
        }

        this.isSavingProduct = false
      },
      onProductImageAdded(data) {
        let productImage = data.productImage
        if (!_.includes(['OTHER_IMAGE', 'LIFESTYLE_IMAGE'], data.productImage.imageType)) {
          arrayHelper.removeObjectByValue(this.currentProductImages, productImage.imageType, 'imageType')
        }
        this.currentProductImages.push(productImage)
        this.isAddingProductImage = false
      },
      onProductImageAddedFailure(error) {
        this.imagePrimaryErrorMessage = 'An error occurred and the image was not added.'
        this.imageErrorMessages = error.messages
        this.isAddingProductImage = false
      },
      onProductImageRemoved(index) {
        this.currentProductImages.splice(index, 1)
        this.removingProductIndex = null
      },
      onRecommendationRetrieved(data) {
        // this is a simplification (that we're only expecting a single
        // recommendation per product)
        if (!data.recommendation.id) {
          this.recommendation.recommend = false
          return
        }
        data.recommendation.recommend = true
        this.recommendation = data.recommendation
      },
      onRecommendationsRetrieved(data) {
        // this is a simplification (that we're only expecting a single
        // recommendation per product)
        const rec = data.recommendations.find(r => r.productId === this.product.id)
        if (!rec) {
          this.recommendation = { recommend: false }
          return
        }
        rec.recommend = true
        this.recommendation = rec
      },
      uploadRecommendation() {
        if (!this.recommendation.recommend && this.recommendation.id) {
          this.axios.delete('/recommendations/' + this.recommendation.id)
        } else {
          this.axios.post('/recommendations', {
            recommendation: {
              productId:        this.product.id,
              ranking:          this.recommendation.ranking,
              exclusiveForType: !!this.recommendation.exclusiveForType,
            },
          }).then(response => this.onRecommendationRetrieved(response.data))
        }
      },
      //////////// INTERNAL METHODS ////////////
      buildUpdateProduct() {
        return {
          brandId:          this.product.brand.id,
          manufacturerId:   this.product.manufacturer.id,
          description:      this.product.description,
          identifier:       this.product.identifier,
          secondaryInfo:    this.product.secondaryInfo,
          internalNotes:    this.product.internalNotes,
          name:             this.product.name,
          materialsString:  this.product.materialsString,
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

.edit-product {

  margin-bottom:                  5em;

  .content {

    > section {
      padding:                    1em;

      > h3 {
        margin:                   0.5em 0;
        width:                    100%;
      }
    }

    .product-details {

      position:                   relative;

      > button {
        position:                 absolute;
        bottom:                   2em;
        right:                    2em;
      }

      .detail {

        margin-top:               0.5em;

        > label {
          min-width:              8em;
        }
      }

      textarea {
        width:                    30em;
        height:                   8em;
        display:                  block;
      }
    }
  }
}

</style>
