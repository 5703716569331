export const imageMixin = {
  methods: {
    // expecting an array of product image objects with 'imageType' keys
    getProductImage(productImageArray) {
      const frontView = _.find(productImageArray, (pi) => pi.imageType == 'FRONT_VIEW')
      const angle = _.find(productImageArray, (pi) => pi.imageType == 'ANGLE')
      const thumbnail = _.find(productImageArray, (pi) => pi.imageType == 'THUMBNAIL_IMAGE')
      const firstImage = _.first(productImageArray)

      return frontView || angle || thumbnail || firstImage
    },
  },
}

export default imageMixin