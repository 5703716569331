var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v(_vm._s(_vm.product.name))]), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        return _vm.goToEditProductPage();
      }
    }
  }, [_vm._v(" Edit ")])]), _vm.isLoading ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "gs-standard-content"
  }, [_c('section', {
    staticClass: "row product-details"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" General Info ")]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Name:")]), _c('span', [_vm._v(_vm._s(_vm.product.name))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Identifier:")]), _c('a', {
    attrs: {
      "href": _vm.productPageLink(_vm.product.identifier),
      "target": "_blank"
    }
  }, [_vm._v(_vm._s(_vm.product.identifier))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Brand:")]), _c('span', [_vm._v(_vm._s(_vm.product.brand.name))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Manufacturer:")]), _c('span', [_vm._v(_vm._s(_vm.product.manufacturer.name))])])]), _c('div', {
    staticClass: "col-md-6 gs-standard-info-block"
  }, [_c('label', [_vm._v("Description:")]), _vm.product.description ? _c('span', [_vm._v(_vm._s(_vm.product.description))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("No Description")])]), _c('div', {
    staticClass: "col-md-6 gs-standard-info-block"
  }, [_c('label', [_vm._v("Materials Description:")]), _vm.product.materialsString ? _c('span', [_vm._v(_vm._s(_vm.product.materialsString))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("No Materials Description")])]), _c('div', {
    staticClass: "col-md-6 gs-standard-info-block"
  }, [_c('label', [_vm._v("Internal Notes:")]), _vm.product.internalNotes ? _c('span', [_vm._v(_vm._s(_vm.product.internalNotes))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("No internal notes")])]), _c('div', {
    staticClass: "col-md-6 gs-standard-info-block"
  }, [_c('label', [_vm._v("Behind the Design:")]), _vm.product.secondaryInfo ? _c('span', [_vm._v(_vm._s(_vm.product.secondaryInfo))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("No behind the design")])]), _vm.isLoadingRecommendations ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "col-md-6 gs-standard-info-block"
  }, [_c('label', [_vm._v("Recommendation:")]), _vm.recommendation ? _c('span', [_vm._v(" Rank: " + _vm._s(_vm.recommendation.ranking || 'unranked') + " | Exclusive for type? " + _vm._s(_vm.recommendation.exclusiveForType) + " ")]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("No recommendation")])])], 1), _c('section', {
    staticClass: "row product-associations"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Associations ")]), _c('div', {
    staticClass: "col-md-3 gs-standard-info-block"
  }, [_c('label', [_vm._v("Categories:")]), _vm.productCategories.length ? _c('span', [_vm._v(_vm._s(_vm.listArrayOfObjects(_vm.productCategories, 'name')))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("No categories")])]), _c('div', {
    staticClass: "col-md-3 gs-standard-info-block"
  }, [_c('label', [_vm._v("Styles:")]), _vm.productStyles.length ? _c('span', [_vm._v(_vm._s(_vm.listArrayOfObjects(_vm.productStyles, 'name')))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("No styles")])]), _c('div', {
    staticClass: "col-md-3 gs-standard-info-block"
  }, [_c('label', [_vm._v("Materials:")]), _vm.productMaterials.length ? _c('span', [_vm._v(_vm._s(_vm.listArrayOfObjects(_vm.productMaterials, 'name')))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("No materials")])]), _c('div', {
    staticClass: "col-md-3 gs-standard-info-block"
  }, [_c('label', [_vm._v("Types:")]), _vm.productTypes.length ? _c('span', [_vm._v(_vm._s(_vm.listArrayOfObjects(_vm.productTypes, 'name')))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("No types")])])]), _c('section', {
    staticClass: "row attributes"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Defined Attributes ")]), _c('ApolloQuery', {
    attrs: {
      "query": require('@/graphql/queries/ProductAttributeInstancesByProduct.gql'),
      "variables": {
        productId: _vm.product.id
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _ref$result = _ref.result,
            loading = _ref$result.loading,
            error = _ref$result.error,
            data = _ref$result.data;
        return [loading ? _c('div', {
          staticClass: "loading apollo"
        }, [_vm._v(" Loading... ")]) : error ? _c('div', {
          staticClass: "error apollo"
        }, [_vm._v(" An error occurred: " + _vm._s(data.error) + " ")]) : data ? _c('div', {
          staticClass: "result apollo property-list"
        }, _vm._l(data.productAttributes, function (attr) {
          return _c('div', {
            key: attr.name
          }, _vm._l(attr.instances, function (instance) {
            return _c('div', {
              key: instance.id,
              staticClass: "property"
            }, [_vm._v(" " + _vm._s('- ' + attr.name + ': ' + instance.value) + " "), attr.description ? _c('p', {
              staticClass: "attr-description"
            }, [_vm._v(" " + _vm._s(attr.description) + " ")]) : _vm._e()]);
          }), 0);
        }), 0) : _vm._e()];
      }
    }])
  })], 1), _c('section', {
    staticClass: "row additional-properties"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Additional Properties ")]), _vm.productAdditionalPropertiesExist ? _c('div', {
    staticClass: "col-md-6 property-list"
  }, _vm._l(_vm.product.additionalProperties, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "property"
    }, [_vm._v(" " + _vm._s(key) + ": "), _c('b', [_vm._v(_vm._s(value))])]);
  }), 0) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" No Additional Properties ")])]), _c('section', {
    staticClass: "product-images"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Images ")]), _vm.isLoadingProductImages ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _vm.productImages.length ? _c('div', {
    staticClass: "row image-list"
  }, _vm._l(_vm.productImages, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "image col-md-2"
    }, [_c('b', [_vm._v(_vm._s(item.imageType))]), item.publicId ? _c('div', [_vm.isImageTypeVideo(item.imageType) ? _c('cld-video', {
      attrs: {
        "public-id": item.publicId,
        "fallback-content": "Your browser does not support HTML5 video tags.",
        "controls": "true",
        "width": "200",
        "height": "200",
        "loop": "true"
      }
    }) : _c('cld-image', {
      attrs: {
        "public-id": item.publicId,
        "width": "200",
        "height": "200",
        "crop": "limit"
      }
    })], 1) : _c('div', [_vm.isImageTypeVideo(item.imageType) ? _c('video', {
      attrs: {
        "src": item.imageUrl,
        "controls": ""
      }
    }) : _c('img', {
      attrs: {
        "src": item.imageUrl
      }
    })])]);
  }), 0) : _c('div', [_c('span', {
    staticClass: "no-items"
  }, [_vm._v("No Product Images")])])], 1)]), _c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Product SKUs")]), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        return _vm.goToCreateProductSkuPage();
      }
    }
  }, [_vm._v(" Add New SKU ")])]), _vm.isLoadingProductSkus ? _c('section', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('section', {
    staticClass: "product-skus-table gs-hide-filter"
  }, [_vm.productSkus.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.productSkus,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "skuLink",
      fn: function fn(data) {
        return _c('div', {}, [_c('router-link', {
          attrs: {
            "to": {
              name: 'ProductSku',
              params: {
                productSkuId: data.row['id']
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.row.sku) + " ")])], 1);
      }
    }, {
      key: "active",
      fn: function fn(data) {
        return _c('div', {}, [_vm._v(" " + _vm._s(data.row.isActive) + " ")]);
      }
    }], null, false, 3910745111)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no product SKUs for this product :( ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }