<template>
    <div class="table-header">
        <div class="table-column">
            Type
        </div>
        <div class="table-column">
            Name
        </div>
        <div class="table-column">
            Requested Dates
        </div>
        <div class="table-column">
            Confirmed Date
        </div>
        <div class="table-column">
            Time
        </div>
        <div class="table-column">
            Contact
        </div>
        <div class="table-column">
            Items
        </div>
        <div class="table-column">
            Assembly
        </div>
        <div class="table-column">
            Subregion
        </div>
    </div>
</template>

<script>
  export default {
    name: 'JourneyTableHeader',
  }
</script>

<style lang="scss" scoped>
  .table-header {
    display: flex;
    width: 98%;
    margin-left: 1%;
    flex-direction: row;
    margin-bottom: 16px;
    justify-content: space-between;

    .table-column {
      display: flex;
      width: 11%;
      color: #6b6b6b;
      font-size: 13px;
      font-size: bold;
      justify-content: space-between;
    }
  }
</style>