<template>
    <div class="region-configuration">
        <transition name="overlay">
            <div v-show="showRegionConfiguration"
                 class="cart-opacity full-page-overlay"
                 @click="hideRegionConfiguration" />
        </transition>
        <div class="region-configuration-visible"
             :class="{ visible : showRegionConfiguration }">
            <section :class="['menu-top', { shadow: isScrolling }]">
                <div class="menu-top-header">
                    <h2 class="bold">
                        Region Configuration
                    </h2>
                    <h2 class="title">
                        {{ regionObject.name }}
                    </h2>
                </div>
                <configuration :selected-region="regionObject" />
                <configuration-form :selected-region="regionObject" />
            </section>

            <div class="close-container">
                <img class="close-right"
                     src="@/assets/images/icons/close-button.svg"
                     @click="hideRegionConfiguration" />
            </div>
        </div>
    </div>
</template>

<script>
  import EventBus from '@/components/utilities/EventBus.vue'
  import Configuration from './Configuration.vue'
  import ConfigurationForm from './ConfigurationForm.vue'
  export default {
    name: 'RegionConfiguraion',
    components: { Configuration, ConfigurationForm },
    props: {
      showRegionConfiguration: Boolean,
      regionObject: {
        default: () => {},
        type: Object,
      },
    },
    apollo: {
      regions: {
        query: require('@/graphql/queries/Regions.gql'),
        result(response) {
          this.isLoadingRegions = false
          const regions = response.data.regions
          const urlRegionId = this.$route.query.regionId
          this.selectedRegion = regions.find(r => r.id === urlRegionId) || {}
        },
      },
    },
    data() {
      return {
        isScrolling: false,
        isLoadingRegions: false,

        selectedRegion: {},
      }
    },
    methods: {
      onScroll(e) {
        if (e.target.scrollTop > 0) this.isScrolling = true
        else this.isScrolling = false
      },
      hideRegionConfiguration() {
        EventBus.$emit('hideRegionConfiguration')
      },
    },
  }
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
  .region-configuration {
    .full-page-overlay {
      z-index: 82;
    }
    .region-configuration-visible {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      margin: 0;
      overflow-x: hidden;
      overflow-y: auto;
      display: inline-block;
      background-color: #fff;
      width: 20%;
      min-width: 500px;
      box-shadow: -5px 0px 6px 1px rgba(151, 151, 151, 0.2);
      z-index: 83;
      transform: translateX(103%);
      transition: transform 1s;

      &.visible {
        transform: translateX(0%);
      }

      .close-container {
        position: absolute;
        right: 21px;
        top: 24px;
        z-index: 3;

        > img {
          width: 16px;
          height: 16px;
          cursor: pointer;
        }
      }

      .menu-top {
        margin-top: 16px;
        padding: 16px 21px;
        position: relative;
        z-index: 2;
        &.shadow {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
        }

        &-header {
          display: flex;
          flex-direction: column;
          .bold {
            font-weight: 700;
            font-size: 21px;
          }
          .title {
            font-weight: 700;
            font-size: 16px;
            padding-top: 16px;
          }
        }

        > span {
          display: block;
        }
      }
    }
  }
</style>
