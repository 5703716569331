var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isEditingThisItem ? _c('span', [_c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.showLoadingState
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('saveButtonClick');
      }
    }
  }, [_vm.showLoadingState ? _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.5"
    }
  }) : _vm.showSaveButton ? _c('span', [_vm._v("Save")]) : _vm._e()], 1)]) : _c('button', {
    staticClass: "gs-standard-button",
    on: {
      "click": function click($event) {
        return _vm.$emit('editButtonClick');
      }
    }
  }, [_vm._v(" Edit ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }