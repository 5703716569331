var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "simple-typeahead",
    style: {
      width: _vm.typeaheadWidth
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchQuery,
      expression: "searchQuery"
    }],
    attrs: {
      "disabled": !_vm.items.length,
      "placeholder": _vm.inputPlaceholder || 'Search items...'
    },
    domProps: {
      "value": _vm.searchQuery
    },
    on: {
      "focus": _vm.onFocus,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.searchQuery = $event.target.value;
      }
    }
  }), _vm.showResults ? _c('div', {
    staticClass: "result-list"
  }, [_vm.filteredItems.length === 0 ? _c('span', {
    staticClass: "result"
  }, [_vm._v("No results")]) : _vm._l(_vm.filteredItems, function (item) {
    return _c('span', {
      key: item[_vm.displayKey],
      staticClass: "result",
      on: {
        "blur": _vm.onBlur,
        "click": function click($event) {
          return _vm.onItemClicked(item);
        }
      }
    }, [_vm._v(_vm._s(_vm.displayKey ? item[_vm.displayKey] : item))]);
  })], 2) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }