var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customer-notes"
  }, [_vm._m(0), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "internal-notes"
  }, [_c('label', [_vm._v("Quick Notes:")]), _vm._v(" " + _vm._s(_vm.internalNotes) + " ")]), _c('div', {
    staticClass: "internal-notes"
  }, [_c('label', [_vm._v("Journey Notes:")]), _vm._v(" " + _vm._s(_vm.journeyNote) + " ")]), _vm.journeyIssues.length ? _c('div', {
    staticClass: "past-journey-issues"
  }, [_c('label', [_vm._v("Past Journey Issues:")]), _vm._l(_vm.journeyIssues, function (issue) {
    return _c('div', {
      key: issue.id
    }, [_vm._v(" " + _vm._s(_vm.getIssueText(issue)) + " ")]);
  })], 2) : _vm._e()])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('h3', [_vm._v("Customer Notes")])]);
}]

export { render, staticRenderFns }