<template>
    <div class="product-additional-properties-manager">
        <div v-if="additionalPropertiesExist"
             class="property-list">
            <div v-for="(value, key) in additionalProperties"
                 :key="key"
                 class="property">
                {{ key }}: <b>{{ value }}</b>
                <i class="fa fa-close remove-property"
                   @click="removeAdditionalProperty(key)" />
            </div>
        </div>
        <div v-else
             class="no-items">
            No Additional Properties
        </div>

        <div class="new-additional-property">
            <SimpleArrayDropdown
                :items="productAdditionalProperties"
                :selected-item="currentProductAdditionalProperty"
                prompt="Please select a property"
                form-width="20em"
                @itemSelected="additionalPropertySelected" />
            <input v-model="currentAdditionalPropertyValue"
                   class="value"
                   placeholder="Value"
                   @keyup.enter="addAdditionalProperty()" />
        </div>

        <button :disabled="isAddPropertyDisabled"
                class="gs-standard-button"
                @click="addAdditionalProperty()">
            Add
        </button>
    </div>
</template>

<script>

  import SimpleArrayDropdown from '@/components/forms/SimpleArrayDropdown'

  export default {
    name: 'ProductAdditionalPropertiesManager',
    components: {
      SimpleArrayDropdown,
    },
    props: {
      additionalProperties: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        productAdditionalProperties:        [],
        currentAdditionalPropertyValue:     '',
        currentProductAdditionalProperty:   '',
      }
    },
    computed: {
      isAddPropertyDisabled() {
        return !this.currentAdditionalPropertyValue || !this.currentProductAdditionalProperty
      },
      additionalPropertiesExist() {
        return Object.keys(this.additionalProperties).length > 0
      },
    },
    mounted() {
      this.getAdditionalProperties()
    },
    methods: {
      //////////// CREATED/MOUNTED METHODS ////////////
      getAdditionalProperties() {
        this.axios.get('/products_additional_properties')
          .then(response => this.onAdditionalPropertiesRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      addAdditionalProperty() {
        if (!this.currentAdditionalPropertyValue || !this.currentProductAdditionalProperty) return

        this.$emit('addAdditionalProperty', this.currentProductAdditionalProperty, this.currentAdditionalPropertyValue)

        this.currentAdditionalPropertyValue = ''
        this.currentProductAdditionalProperty = ''
      },
      additionalPropertySelected(type) {
        this.currentProductAdditionalProperty = type
      },
      removeAdditionalProperty(key) {
        this.$emit('removeAdditionalProperty', key)
      },
      //////////// CALLBACKS ////////////
      onAdditionalPropertiesRetrieved(data) {
        this.productAdditionalProperties = data.productAdditionalProperties
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

.product-additional-properties-manager {

  .property-list {
    margin:                  1em;

    .property {
      padding:                0.5em;
      border:                 1px solid lightgray;
      border-radius:          0.5em;
      width:                  50%;
      margin-top:             0.5em;

      .remove-property {
        float:                right;
        cursor:               pointer;
        padding:              0.25em;
      }
    }
  }

  .new-additional-property {

    .value {
      margin-left:            1em;
      padding:                0.5em;
    }
  }

  .gs-standard-button {
    margin-top:               1em;
    display:                  inline-block;
  }
}

</style>
