var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SimpleImageManager', {
    attrs: {
      "images": _vm.productImages,
      "image-types": _vm.productImageTypes,
      "items-to-exclude": _vm.existingProductImageTypes,
      "show-loading-state": _vm.isLoadingImageTypes,
      "is-saving-image": _vm.isSavingImage,
      "removing-image-index": _vm.removingImageIndex
    },
    on: {
      "itemAdded": _vm.addProductImage,
      "removeImage": _vm.removeProductImage
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }