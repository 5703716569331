<template>
    <div class="best-seller gs-simple-crud">
        <h1>Best Sellers</h1>

        <div class="product-add-container">
            <LoadingSpinner v-if="isLoadingProducts"
                            is-black="true" />
            <SimpleAddRemoveList
                v-else
                :items="products"
                :selected-items="selectedProducts"
                display-key="name"
                match-key="id"
                item-type="product"
                @itemAdded="addProduct"
                @itemRemoved="removeProduct" />
            <button
                @click="createBestSellers">
                Add First Product
            </button>
        </div>

        <div v-if="isLoading">
            <LoadingSpinner is-black="true" />
        </div>

        <div v-else
             class="content">
            <section class="best-seller-table gs-standard-table">
                <v-client-table
                    v-if="bestSellers.length > 0"
                    :data="formattedBestSellers"
                    :columns="columns"
                    :options="options">
                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteBestSeller(data.row)" />
                    </div>

                    <div slot="reorder"
                         slot-scope="data"
                         class="gs-standard-table-reorder">
                        <button :disabled="data.row.rank == null || topRanked(data.row)"
                                @click="promoteRank(data.row)">
                            ⇩
                        </button>
                        <button :disabled="data.row.rank === 1"
                                @click="demoteRank(data.row)">
                            ⇧
                        </button>
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no Best Sellers to display
                </div>
            </section>

            <ConfirmModal
                v-if="showConfirmDeleteModal"
                :show-loading-state="isDeletingBestSeller"
                @cancel="showConfirmDeleteModal = false"
                @confirm="deleteBestSeller()">
                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingBestSeller.productName }}</b> as a best seller?</span>
            </ConfirmModal>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import SimpleAddRemoveList from '@/components/forms/SimpleAddRemoveList'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'BestSellers',
    components: {
      LoadingSpinner,
      SimpleAddRemoveList,
      DeleteIcon,
      ConfirmModal,
    },
    data() {
      return {
        isLoading: false,
        isLoadingProducts: false,
        errorMessages: [],
        primaryErrorMessage: '',
        bestSellers: [],
        products: [],
        selectedProducts: [],
        currentDeletingBestSeller: null,
        isDeletingBestSeller: false,
        showConfirmDeleteModal: false,
        columns: ['rank', 'productName', 'reorder', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
        },
      }
    },
    computed: {
      formattedBestSellers() {
        const bestSellers = this.bestSellers.map((bestSeller) => {
          return {
            id: bestSeller.id,
            productName: bestSeller.product.name,
            rank: bestSeller.rank,
          }
        })
        return _.sortBy(bestSellers, (b) => b.rank || 100)
      },
    },
    mounted() {
      this.getBestSellers()
      this.getProducts()
    },
    methods: {
      getBestSellers() {
        this.isLoading = true
        this.axios.get('/best_sellers')
          .then(response => this.onBestSellersReceived(response.data))
      },
      getProducts() {
        this.isLoadingProducts = true
        this.axios.get('/products')
          .then(response => this.onProductsReceived(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      createBestSellers() {
        if (this.selectedProducts.length === 0) return

        const params = {
          product_id: this.selectedProducts[0].id,
        }

        this.axios.post('/best_sellers', params)
          .then(response => this.onBestSellerCreated(response.data))
          .catch(error => this.onBestSellerCreatedFailure(error.response))
      },
      updateBestSellerRank(bestSeller) {
        this.axios.patch('/best_sellers/' + bestSeller.id, {
          rank: bestSeller.rank,
        })
          .then(response => this.onBestSellerUpdated())
          .catch(error => this.onBestSellerUpdateFailure(error.response))
      },
      setCurrentDeleteBestSeller(bestSeller) {
        this.showConfirmDeleteModal = true
        this.currentDeletingBestSeller = bestSeller
      },
      addProduct(product) {
        this.selectedProducts.push(product)
      },
      removeProduct(product) {
        arrayHelper.removeObjectByValue(this.selectedProducts, product.id, 'id')
      },
      //////////// CALLBACKS ////////////
      onBestSellersReceived(data) {
        this.isLoading = false
        this.bestSellers = data.bestSellers
      },
      onProductsReceived(data) {
        this.isLoadingProducts = false
        this.products = data.products
      },
      onBestSellerCreated(data) {
        this.bestSellers.push(data.bestSeller)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created best seller!',
          statusCode: 1,
        })
        this.selectedProducts.splice(0, 1)
      },
      onBestSellerCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating best seller'
          this.errorMessages = JSON.stringify(response)
        }
      },
      onBestSellerUpdated() {
        this.getBestSellers()
      },
      onBestSellerUpdateFailure(response) {
        EventBus.$emit('globalAlert', {
          message: response.data.messages[0],
          statusCode: 3,
        })
      },
      onBestSellerDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingBestSeller = null

        arrayHelper.removeObjectByValue(this.bestSellers, data.bestSeller.id, 'id')
        EventBus.$emit('globalAlert', {
          message: 'Best seller successfully deleted!',
          statusCode: 1,
        })
      },
      onBestSellerDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error deleting best seller'
          this.errorMessages = JSON.stringify(response)
        }

        this.showConfirmDeleteModal = false
        this.currentDeletingBestSeller = null
      },
      //////////// INTERNAL METHODS ////////////
      deleteBestSeller() {
        this.isDeletingBestSeller = true
        this.axios.delete('/best_sellers/' + this.currentDeletingBestSeller.id)
          .then(response => this.onBestSellerDeleted(response.data))
          .catch(error => this.onBestSellerDeletedFailure(error.response))
          .finally(() => {
            this.isDeletingBestSeller = false
          })
      },
      promoteRank(bestSeller) {
        const newRank = (bestSeller.rank || 0) + 1
        if (!bestSeller.rank) {
          let rankToPromote = newRank
          this.bestSellers.reverse().forEach(c => {
            if (c.id === bestSeller.id || !c.rank || c.rank !== rankToPromote) return
            rankToPromote = ++c.rank
            this.updateBestSellerRank(c)
          })
        } else {
          const bestSellerToSwap = this.bestSellers.find(b => b.rank === newRank)
          if (bestSellerToSwap) {
            bestSellerToSwap.rank--
            this.updateBestSellerRank(bestSellerToSwap)
          }
        }
        bestSeller.rank = newRank
        this.updateBestSellerRank(bestSeller)
      },
      demoteRank(bestSeller) {
        bestSeller.rank = Math.max((bestSeller.rank || 0) - 1, 1)
        if (bestSeller.rank > 0) {
          const bestSellerToSwap = this.bestSellers.find(b => b !== bestSeller && b.rank === bestSeller.rank)
          if (bestSellerToSwap) {
            bestSellerToSwap.rank++
            this.updateBestSellerRank(bestSellerToSwap)
          }
        }
        this.updateBestSellerRank(bestSeller)
      },
      topRanked(bestSeller) {
        return !!bestSeller.rank && bestSeller.rank === _.max(this.bestSellers.map(b => b.rank))
      },
    },
  }
</script>

<style lang="scss" scoped>
.product-add-container {
  width: 50%;
  margin-bottom: 50px;

  button {
    margin-top: 20px;
  }
}
</style>
