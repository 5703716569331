import Vue from 'vue'
import $ from 'jquery'
import App from './App.vue'
import router from './router'
import store from './store'

// Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.min.css'
import 'font-awesome/css/font-awesome.css'
import 'vue-select/dist/vue-select.css'
import 'vue2-timepicker/dist/VueTimepicker.css'


// Plugins
import { ClientTable } from 'vue-tables-2'
import ToggleButton from 'vue-js-toggle-button'
import _ from 'lodash'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import Cloudinary from 'cloudinary-vue'
import vSelect from 'vue-select'
import VueTimepicker from 'vue2-timepicker'
import VueCompositionAPI from '@vue/composition-api'
import { BootstrapVue, CollapsePlugin } from 'bootstrap-vue'

// Mixins
import { utilityMixin, stringMixin, numberMixin, dateMixin } from '@petra-living/petra-vue-common'

// Axios config
import axios from './api/axios'
import VueAxios from 'vue-axios'
import { createProvider } from './vue-apollo'

try {
  Object.defineProperty(Vue.prototype, '_', { value: _ })
} catch (e) {
  Sentry.captureException(`Lodash Error: ${e}`)
}

window.jQuery = $
window.$ = $

// Use stuff!
Vue.use(VueAxios, axios)
Vue.use(VueCompositionAPI)
Vue.use(ClientTable)
Vue.use(ToggleButton)
Vue.use(VueMoment, {
  moment,
})
Vue.use(Cloudinary, {
  configuration: {
    cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME || 'oliver-dev-api',
  },
})
Vue.use(BootstrapVue)
Vue.use(CollapsePlugin)
Vue.component('VSelect', vSelect)
Vue.component('VueTimepicker', VueTimepicker)


// UI Mixins
Vue.mixin(utilityMixin)
Vue.mixin(dateMixin)
Vue.mixin(numberMixin)
Vue.mixin(stringMixin)

Vue.config.productionTip = false

if (['staging', 'production'].includes(process.env.VUE_APP_NOODL_ENV)) {
  window.Sentry = Sentry

  Sentry.init({
    release: process.env.VUE_APP_REVISION || 'dev',
    dsn: 'https://a52d3fcfef454e2ba9ea3dc43bc57ecd@o254926.ingest.sentry.io/1446900',
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
    environment: process.env.VUE_APP_NOODL_ENV,
  })
}

new Vue({
  router,
  store,
  axios,
  apolloProvider: createProvider(),
  render: h => h(App),
}).$mount('#app')
