var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-additional-properties-manager"
  }, [_vm.additionalPropertiesExist ? _c('div', {
    staticClass: "property-list"
  }, _vm._l(_vm.additionalProperties, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "property"
    }, [_vm._v(" " + _vm._s(key) + ": "), _c('b', [_vm._v(_vm._s(value))]), _c('i', {
      staticClass: "fa fa-close remove-property",
      on: {
        "click": function click($event) {
          return _vm.removeAdditionalProperty(key);
        }
      }
    })]);
  }), 0) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" No Additional Properties ")]), _c('div', {
    staticClass: "new-additional-property"
  }, [_c('SimpleArrayDropdown', {
    attrs: {
      "items": _vm.productAdditionalProperties,
      "selected-item": _vm.currentProductAdditionalProperty,
      "prompt": "Please select a property",
      "form-width": "20em"
    },
    on: {
      "itemSelected": _vm.additionalPropertySelected
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentAdditionalPropertyValue,
      expression: "currentAdditionalPropertyValue"
    }],
    staticClass: "value",
    attrs: {
      "placeholder": "Value"
    },
    domProps: {
      "value": _vm.currentAdditionalPropertyValue
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addAdditionalProperty();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.currentAdditionalPropertyValue = $event.target.value;
      }
    }
  })], 1), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isAddPropertyDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.addAdditionalProperty();
      }
    }
  }, [_vm._v(" Add ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }