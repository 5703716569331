var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isHidden ? _c('b-navbar', {
    staticClass: "top-nav",
    class: {
      'prod': _vm.isProduction
    }
  }, [_c('b-navbar-brand', [_c('router-link', {
    attrs: {
      "to": {
        name: 'Home'
      }
    }
  }, [_vm._v(" Noodl ")])], 1), _c('b-navbar-toggle', {
    attrs: {
      "target": "nav-collapse"
    }
  }), _c('b-collapse', {
    attrs: {
      "id": "nav-collapse",
      "is-nav": ""
    }
  }, [_c('b-navbar-nav', [_vm._l(_vm.majorLinks, function (link) {
    return _c('b-nav-item', {
      key: link.name
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: link.routeName
        }
      }
    }, [_vm._v(" " + _vm._s(link.name) + " ")])], 1);
  }), _c('b-nav-item-dropdown', {
    attrs: {
      "text": "Settings",
      "right": ""
    }
  }, _vm._l(_vm.settingsLinks, function (link) {
    return _c('b-dropdown-item', {
      key: link.name
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: link.routeName
        }
      }
    }, [_vm._v(" " + _vm._s(link.name) + " ")])], 1);
  }), 1), _c('b-nav-item-dropdown', {
    attrs: {
      "text": "Product Attributes",
      "right": ""
    }
  }, _vm._l(_vm.attributeLinks, function (link) {
    return _c('b-dropdown-item', {
      key: link.name
    }, [_c('router-link', {
      attrs: {
        "to": {
          name: link.routeName
        }
      }
    }, [_vm._v(" " + _vm._s(link.name) + " ")])], 1);
  }), 1), _c('b-nav-item-dropdown', {
    attrs: {
      "text": "New",
      "right": ""
    }
  }, [_c('b-dropdown-item', [_c('router-link', {
    attrs: {
      "to": {
        name: 'JourneyList'
      }
    }
  }, [_vm._v(" Driver App ")])], 1), _c('b-dropdown-item', [_c('router-link', {
    attrs: {
      "to": {
        name: 'JourneyScheduling'
      }
    }
  }, [_vm._v(" Scheduling App ")])], 1)], 1), _c('b-nav-item-dropdown', {
    attrs: {
      "text": "Tools",
      "right": ""
    }
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "".concat(_vm.apiRoot, "/sidekiq")
    }
  }, [_vm._v(" Sidekiq ")]), _c('b-dropdown-item', {
    attrs: {
      "href": "".concat(_vm.apiRoot, "/flipper")
    }
  }, [_vm._v(" Flipper ")])], 1)], 2), _c('b-navbar-nav', {
    staticClass: "ml-auto"
  }, [_c('b-nav-item-dropdown', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm._v(" Logged in as " + _vm._s(_vm.currentUser.emailAddress) + " ")];
      },
      proxy: true
    }], null, false, 1810347449)
  }, [_c('b-dropdown-item', [_c('router-link', {
    attrs: {
      "to": {
        name: 'UserAccount'
      }
    }
  }, [_vm._v(" Account ")])], 1), _c('b-dropdown-item', {
    on: {
      "click": _vm.logout
    }
  }, [_vm._v(" Sign Out ")])], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }