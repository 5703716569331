var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customer-trips"
  }, [_c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.trips, function (trip) {
    return _c('tr', {
      key: trip.id,
      ref: trip.id,
      refInFor: true
    }, [_c('td', [_vm.onfleetLink(trip) ? _c('a', {
      attrs: {
        "href": _vm.onfleetLink(trip),
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(_vm.tripPurpose(trip)) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.tripPurpose(trip)) + " ")])]), _c('td', [_vm._v(" " + _vm._s(trip.id) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.driverName(trip)) + " ")]), _c('td', [_vm.googleMapsLink(trip) ? _c('a', {
      attrs: {
        "href": _vm.googleMapsLink(trip),
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(_vm.address(trip).join(', ')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.address(trip).join(', ')) + " ")])]), _c('td', {
      staticClass: "clickable",
      on: {
        "click": function click($event) {
          return _vm.showItemInfo(trip);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.inventoryItemsLength(trip)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.capacity(trip)) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.assemblyTime(trip)) + " ")])]);
  }), 0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Type ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" ID ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Driver ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Location ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Items ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Capacity ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Assembly ")])])]);
}]

export { render, staticRenderFns }