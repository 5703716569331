<template>
    <div class="product-types gs-simple-crud">
        <h1>Product Types</h1>

        <SimpleCreateForm
            :primary-error-message="primaryErrorMessage"
            :error-messages="errorMessages"
            :show-loading-state="isCreatingProductType"
            :new-model-instance="newProductType"
            @formSubmit="createProductType">
            <SimpleArrayDropdown
                :items="['initial', 'term_payback']"
                :selected-item="newProductType.pricingModel"
                :object="newProductType"
                class="dropdown"
                @itemSelected="selectPricingModel" />
        </SimpleCreateForm>

        <div v-if="isLoadingProductTypes">
            <LoadingSpinner is-black="true" />
        </div>

        <div v-else
             class="content">
            <section class="product-types-table gs-standard-table">
                <v-client-table
                    v-if="productTypes.length > 0"
                    :data="productTypes"
                    :columns="columns"
                    :options="options">
                    <div slot="editName"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row.name" />
                        <span v-else>{{ data.row.name }}</span>
                    </div>

                    <div slot="saleType"
                         slot-scope="data">
                        <div v-if="isEditingThisItem(data.row.id)">
                            <input id="leaseOnly"
                                   v-model="data.row.saleType"
                                   type="radio"
                                   name="saleType"
                                   value="lease_only" />
                            <label for="leaseOnly">Lease</label>
                            <br />
                            <input id="leaseOrPurchase"
                                   v-model="data.row.saleType"
                                   type="radio"
                                   name="saleType"
                                   value="lease_or_purchase" />
                            <label for="leaseOrPurchase">Either</label>
                            <br />
                            <input id="purchaseOnly"
                                   v-model="data.row.saleType"
                                   type="radio"
                                   name="saleType"
                                   value="purchase_only" />
                            <label for="purchaseOnly">Purchase</label>
                        </div>
                        <span v-else>{{ data.row.saleType }}</span>
                    </div>

                    <div slot="purchaseScalePercentage"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row.purchaseScalePercentage" />
                        <span v-else-if="data.row.saleType.includes('purchase')">{{ data.row.purchaseScalePercentage }}</span>
                    </div>

                    <div slot="pricingModel"
                         slot-scope="data">
                        <select v-if="isEditingThisItem(data.row.id)"
                                v-model="data.row.pricingModel">
                            <option v-for="pm in ['initial', 'term_payback']"
                                    :key="pm"
                                    :value="pm">
                                {{ pm }}
                            </option>
                        </select>
                        <span v-else>{{ data.row.pricingModel || 'initial' }}</span>
                    </div>

                    <div slot="parentTypeId"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row.parentTypeId" />
                        <span v-else>{{ data.row.parentTypeId }}</span>
                    </div>

                    <div slot="edit"
                         slot-scope="data"
                         class="gs-standard-table-edit">
                        <TableEditButton
                            :is-editing-this-item="isEditingThisItem(data.row.id)"
                            :show-loading-state="isEditingThisItem(data.row.id) && isSavingThisItem(data.row.id)"
                            :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                            @saveButtonClick="updateProductType(data.row)"
                            @editButtonClick="currentEditProductTypeId = data.row.id" />
                    </div>

                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteProductType(data.row)" />
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no Product Types to display
                </div>
            </section>

            <ConfirmModal
                v-if="showConfirmDeleteModal"
                :show-loading-state="isDeletingProductType"
                @cancel="showConfirmDeleteModal = false"
                @confirm="deleteProductType()">
                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingProductType.name }}</b>? This will also destroy any associations with this product type!</span>
            </ConfirmModal>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleArrayDropdown from '@/components/forms/SimpleArrayDropdown'
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import EventBus from '@/components/utilities/EventBus'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'ProductTypes',
    components: {
      LoadingSpinner,
      SimpleArrayDropdown,
      SimpleCreateForm,
      ConfirmModal,
      DeleteIcon,
      TableEditButton,
    },
    data() {
      return {
        isLoadingProductTypes:          false,
        errorMessages:                  [],
        primaryErrorMessage:            '',
        productTypes:                   [],
        newProductType:                 this.initializeNewProductType(),
        isCreatingProductType:          false,
        currentEditProductTypeId:       null,
        currentSavingProductTypeId:     null,
        currentDeletingProductType:     null,
        isDeletingProductType:          false,
        showConfirmDeleteModal:         false,
        columns:                        ['id', 'editName', 'identifier', 'saleType', 'purchaseScalePercentage', 'productCount', 'pricingModel', 'parentTypeId', 'edit', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'editName': 'Name',
            'productCount': 'Product Count',
            'saleType': 'Sale Type',
            'purchaseScalePercentage': 'Purchase Scale',
            'parentTypeId': 'Parent Type ID',
            'pricingModel': 'Pricing Model',
          },
        },
      }
    },
    mounted() {
      this.getProductTypes()
    },
    methods: {
      getProductTypes() {
        this.isLoadingProductTypes = true
        this.axios.get('/product_types')
          .then(response => this.onProductTypesRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      createProductType() {
        this.isCreatingProductType = true
        if (this.newProductType.saleType === 'lease_only') {
          this.newProductType.purchaseScalePercentage = null
        }
        this.axios.post('/product_types', { productType: this.newProductType })
          .then(response => this.onProductTypeCreated(response.data))
          .catch(error => this.onProductTypeCreatedFailure(error.response))
      },
      updateProductType(productType) {
        this.currentSavingProductTypeId = productType.id
        this.isCreatingProductType = true
        if (productType.saleType === 'lease_only') {
          productType.purchaseScalePercentage = null
        }
        this.axios.patch('/product_types/' + productType.id, { productType: productType })
          .then(response => this.onProductTypeUpdated(response.data))
          .catch(error => this.onProductTypeUpdatedFailure(error.response))
      },
      setCurrentDeleteProductType(productType) {
        this.showConfirmDeleteModal = true
        this.currentDeletingProductType = productType
      },
      //////////// CALLBACKS ////////////
      onProductTypesRetrieved(data) {
        this.isLoadingProductTypes = false
        this.productTypes = data.productTypes
      },
      onProductTypeCreated(data) {
        this.isCreatingProductType = false
        this.productTypes.push(data.productType)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created product type!',
          statusCode: 1,
        })
        this.newProductType = this.initializeNewProductType()
      },
      onProductTypeCreatedFailure(response) {
        this.primaryErrorMessage = 'Unknown error creating productType'
        this.errorMessages = JSON.stringify(response)

        this.isCreatingProductType = false
      },
      onProductTypeUpdated(data) {
        arrayHelper.replaceObjectByValue(this.productTypes, data.productType, 'id')
        this.currentEditProductTypeId = null
        this.currentSavingProductTypeId = null
      },
      onProductTypeUpdatedFailure(response) {
        EventBus.$emit('globalAlert', {
          message: response.data.messages[0],
          statusCode: 3,
        })

        this.currentSavingProductTypeId = null
      },
      onProductTypeDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingProductType = null
        arrayHelper.removeObjectByValue(this.productTypes, data.productType.id, 'id')
        EventBus.$emit('globalAlert', {
          message: 'Product type successfully deleted!',
          statusCode: 1,
        })
      },
      onProductTypeDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating productType'
          this.errorMessages = JSON.stringify(response)
        }

        this.showConfirmDeleteModal = false
        this.currentDeletingProductType = false
      },
      onProductTypeDeleteDone() {
        this.isDeletingProductType = false
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewProductType() {
        return {
          name:         '',
          saleType:     'lease_only',
          purchaseScalePercentage: 100.0,
          pricingModel: 'initial',
        }
      },
      isSavingThisItem(id) {
        return this.currentSavingProductTypeId === id
      },
      isEditingThisItem(id) {
        return this.currentEditProductTypeId === id
      },
      deleteProductType() {
        this.isDeletingProductType = true
        this.axios.delete('/product_types/' + this.currentDeletingProductType.id)
          .then(response => this.onProductTypeDeleted(response.data))
          .catch(error => this.onProductTypeDeletedFailure(error.response))
          .finally(() => this.onProductTypeDeleteDone())
      },
      selectPricingModel(value, instance) {
        instance.pricingModel = value
      },
    },
  }
</script>

<style lang="scss" scoped>

.product-types {

  .content {
    margin-top:                   1em;
  }

  input {
    width: 7em;
  }

  .dropdown {
    min-width: 10em;
  }
}

</style>
