var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "simple-create-form"
  }, [_c('form', {
    staticClass: "add-new gs-standard-form col-md-6",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.$emit('formSubmit');
      }
    }
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _vm._l(_vm.newModelInstance, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "gs-standard-detail"
    }, [_c('label', {
      attrs: {
        "for": key
      }
    }, [_vm._v(_vm._s(_vm.makeCamelCaseReadable(key)) + ":")]), _vm._t(key, function () {
      return [_vm.inputType(key, value) === 'checkbox' ? _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.newModelInstance[key],
          expression: "newModelInstance[key]"
        }],
        key: key,
        attrs: {
          "name": key,
          "placeholder": _vm.makeCamelCaseReadable(key),
          "type": "checkbox"
        },
        domProps: {
          "checked": Array.isArray(_vm.newModelInstance[key]) ? _vm._i(_vm.newModelInstance[key], null) > -1 : _vm.newModelInstance[key]
        },
        on: {
          "change": function change($event) {
            var $$a = _vm.newModelInstance[key],
                $$el = $event.target,
                $$c = $$el.checked ? true : false;

            if (Array.isArray($$a)) {
              var $$v = null,
                  $$i = _vm._i($$a, $$v);

              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.newModelInstance, key, $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(_vm.newModelInstance, key, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(_vm.newModelInstance, key, $$c);
            }
          }
        }
      }) : _vm.inputType(key, value) === 'radio' ? _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.newModelInstance[key],
          expression: "newModelInstance[key]"
        }],
        key: key,
        attrs: {
          "name": key,
          "placeholder": _vm.makeCamelCaseReadable(key),
          "type": "radio"
        },
        domProps: {
          "checked": _vm._q(_vm.newModelInstance[key], null)
        },
        on: {
          "change": function change($event) {
            return _vm.$set(_vm.newModelInstance, key, null);
          }
        }
      }) : _c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.newModelInstance[key],
          expression: "newModelInstance[key]"
        }],
        key: key,
        attrs: {
          "name": key,
          "placeholder": _vm.makeCamelCaseReadable(key),
          "type": _vm.inputType(key, value)
        },
        domProps: {
          "value": _vm.newModelInstance[key]
        },
        on: {
          "input": function input($event) {
            if ($event.target.composing) { return; }

            _vm.$set(_vm.newModelInstance, key, $event.target.value);
          }
        }
      })];
    }, {
      "value": value,
      "model": _vm.newModelInstance[key]
    })], 2);
  }), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "type": "submit",
      "disabled": _vm.showLoadingState || !_vm.isValid
    }
  }, [!_vm.showLoadingState ? _c('span', [_vm._v("Create")]) : _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.5"
    }
  })], 1)], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }