var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-skuless-skus"
  }, [_vm.isLoadingProducts ? _c('section', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('section', {
    staticClass: "product-skuless-skus-table gs-standard-table gs-hide-filter"
  }, [_vm.filteredProducts.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.filteredProducts,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "productName",
      fn: function fn(data) {
        return _c('div', {}, [_c('router-link', {
          attrs: {
            "to": {
              name: 'Product',
              params: {
                productId: data.row['id']
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.row['name']) + " ")])], 1);
      }
    }], null, false, 3303512593)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no products to display. Please refine your search. ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }