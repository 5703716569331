<template>
    <div class="create-layout">
        <section class="gs-generic-header">
            <h1>Create Layout</h1>
            <button :disabled="isSavingLayout"
                    class="gs-standard-button"
                    @click="createLayout()">
                <span v-if="!isSavingLayout">Create</span>
                <LoadingSpinner v-else
                                max-height="1.5" />
            </button>
        </section>

        <div class="create-form gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />


            <section class="layout-details">
                <h2>Layout Basics</h2>

                <div class="gs-standard-detail">
                    <label>Name:</label>
                    <input v-model="newLayout.name" />
                </div>

                <div class="gs-standard-detail">
                    <label>Category:</label>
                    <div class="input-group mb-3 category-select">
                        <select class="custom-select"
                                @change="selectProductCategory($event)">
                            <option selected>
                                Please select a category...
                            </option>
                            <option v-for="option in allProductCategories"
                                    :key="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="gs-standard-detail">
                    <label>Description:</label>
                    <textarea v-model="newLayout.description" />
                </div>

                <div class="gs-toggle-container">
                    <label class="tag">Is Active?</label>
                    <toggle-button :value="newLayout.isActive"
                                   color="#82C7EB"
                                   @change="newLayout.isActive = !newLayout.isActive" />
                </div>

                <div class="gs-standard-detail">
                    <label>Supported views (comma seperated):</label>
                    <input v-model="newLayout.supportedViews" />
                </div>
            </section>


            <section class="row select-products">
                <h2>Product Types</h2>

                <div class="col-md-4">
                    <LoadingSpinner v-if="isLoadingProductTypes"
                                    is-black="true" />
                    <SimpleAddRemoveList
                        v-else
                        :items="allProductTypes"
                        :selected-items="newLayoutProductTypes"
                        :allow-duplicates="true"
                        display-key="name"
                        item-type="product type"
                        @itemAdded="addProductType"
                        @itemRemoved="removeProductType" />
                </div>
            </section>

            <section class="product-images">
                <h2>Layout Image</h2>

                <input
                    ref="hidden-uploader"
                    type="file"
                    name="layout-image"
                    accept="image/*"
                    class="input-file"
                    @change="onImageSelect($event.target.name, $event.target.files)" />
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'
  import SimpleAddRemoveList from '@/components/forms/SimpleAddRemoveList'
  import apiHelper from '@/helpers/apiHelper'

  export default {
    name: 'CreateLayout',
    components: {
      LoadingSpinner,
      FormErrors,
      SimpleAddRemoveList,
    },
    data() {
      return {
        newLayout:                    this.initializeNewLayout(),
        newLayoutProductTypes:        [],
        primaryErrorMessage:          '',
        errorMessages:                [],
        allProductTypes:              [],
        heroImage:                    null,
        isSavingLayout:               false,
        allProductCategories:         [],
        selectedProductCategory:      null,
        isLoadingProductTypes:        true,
      }
    },
    mounted() {
      this.getProductTypes()
      this.getAllProductCategories()
    },
    methods: {
      getProductTypes() {
        this.isLoadingProductTypes = true
        this.axios.get('/product_types')
          .then(response => this.onProductTypesRetrieved(response.data))
      },
      getAllProductCategories() {
        this.axios.get('/product_categories')
          .then(response => { this.allProductCategories = response.data.productCategories })
      },
      //////////// TEMPLATE ACTIONS ////////////
      selectProductCategory(event) {
        const categoryName = event.target.value
        this.selectedProductCategory = _.find(this.allProductCategories, (pc) => pc.name == categoryName)
        this.newLayout.productCategoryId = _.get(this.selectedProductCategory, 'id')
      },
      createLayout() {
        this.isSavingLayout = true
        this.newLayout.heroImage = this.heroImage
        this.heroImage = null
        const supportedViews = this.newLayout.supportedViews.split(', ')

        // TODO: Figure out submitting json object via submitMultipartForm

        this.axios.post('/layouts', { data: this.newLayout.data, supportedViews })
          .then(response => {
            apiHelper.submitMultipartForm(
              _.omit(this.newLayout, ['data', 'supportedViews']),
              '/layouts/' + response.data.layout.id,
              this.onLayoutCreated,
              this.onLayoutCreatedFailure,
              'patch',
            )
          })
          .catch(error => this.onLayoutCreatedFailure(error.response))
      },
      addProductType(productType) {
        const existingType = _.find(this.newLayout.data.pieces, (piece) => {
          return piece.productType === productType.identifier
        })

        this.newLayoutProductTypes.push(productType)

        if (existingType) {
          existingType.quantity = parseInt(existingType.quantity) + 1
          return
        }

        this.newLayout.data.pieces.push({
          productType: productType.identifier,
          quantity: 1,
        })
      },
      removeProductType(productType, types, index) {
        const removedType = _.find(this.newLayout.data.pieces, piece => {
          return piece.productType === productType.identifier
        })
        if (removedType.quantity > 1) {
          removedType.quantity -= 1
        } else {
          _.pull(this.newLayout.data.pieces, removedType)
        }

        this.newLayoutProductTypes.splice(index, 1)
      },
      onImageSelect(name, files) {
        if (files.length === 0) return
        this.heroImage = files[0]
      },
      removeLayoutImage(index) {
        this.heroImage = null
      },
      //////////// CALLBACKS ////////////
      onProductTypesRetrieved(data) {
        this.allProductTypes = data.productTypes
        this.isLoadingProductTypes = false
      },
      onLayoutCreated(data) {
        this.isSavingLayout = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully created Layout!',
          statusCode: 1,
        })
        this.$router.push({ name: 'Layout', params: { layoutId: data.layout.id } })
      },
      onLayoutCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating Layout'
          this.errorMessages = JSON.stringify(response)
        }

        this.isSavingLayout = false
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewLayout() {
        return {
          name:                 '',
          productCategoryId:    '',
          data:                 {
            pieces:             [],
          },
          heroImage:            null,
          isActive:             false,
          supportedViews:       '',
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

.create-layout {

  margin-bottom:                  5em;

  .create-form {

    .category-select {
      width: 30em;
    }

    > section {
      padding:                    1em;

      > h2 {
        margin:                   0.5em 0;
        width:                    100%;
      }
    }

    .layout-details {

      input, textarea {
        display:                  block;
        width:                    30em;
      }

      textarea {
        height:                   10em;
      }

      .gs-toggle-container {
        margin-top:               2em;
      }
    }

  }
}

</style>
