var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home"
  }, [_c('h1', [_vm._v(_vm._s(_vm.homepageCopy))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }