var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "customer-journey",
    class: {
      'change': _vm.pendingChange
    }
  }, [_c('div', {
    staticClass: "main-row"
  }, [_c('div', {
    staticClass: "table-row"
  }, [_c('div', {
    staticClass: "table-column"
  }, [_c('p', {
    staticClass: "info"
  }, [_vm._v(" " + _vm._s(_vm.journeyPurpose) + " ")])]), _c('div', {
    staticClass: "table-column"
  }, [_c('p', {
    staticClass: "info"
  }, [_vm._v(" " + _vm._s(_vm.customerName) + " ")])]), _c('div', {
    staticClass: "table-column"
  }, [_c('div', {
    staticClass: "info"
  }, _vm._l(_vm.requestedDates, function (date, index) {
    return _c('div', {
      key: index
    }, [_vm._v(" " + _vm._s(date) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "table-column"
  }, [!_vm.isSavingJourney ? _c('div', {
    staticClass: "journey-date-time"
  }, [_c('div', [_c('datepicker', {
    attrs: {
      "format": _vm.dateFormat,
      "placeholder": "Schedule",
      "disabled-dates": _vm.disabledDates
    },
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  }), !_vm.selectedDate || !_vm.pendingDateChange ? _c('i', {
    staticClass: "fa fa-calendar",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _c('i', {
    staticClass: "fa fa-times-circle",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": _vm.clearSelectedDate
    }
  })], 1)]) : _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1), _c('div', {
    staticClass: "table-column"
  }, [_c('div', {
    staticClass: "time-picker-parent"
  }, [_c('vue-timepicker', {
    attrs: {
      "format": "hh:mm a",
      "placeholder": "Schedule",
      "hide-disabled-items": "",
      "hide-clear-button": "",
      "minute-interval": 30,
      "auto-scroll": ""
    },
    model: {
      value: _vm.selectedTime,
      callback: function callback($$v) {
        _vm.selectedTime = $$v;
      },
      expression: "selectedTime"
    }
  }), !_vm.selectedTimeString || !_vm.pendingTimeChange ? _c('i', {
    staticClass: "fa fa-clock-o",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _c('i', {
    staticClass: "fa fa-times-circle",
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": _vm.clearSelectedTime
    }
  })], 1)]), _c('div', {
    staticClass: "table-column"
  }, [_c('div', {
    staticClass: "info clickable",
    on: {
      "click": _vm.showContactInfo
    }
  }, [_vm._v(" Show ")])]), _c('div', {
    staticClass: "table-column"
  }, [_c('div', {
    staticClass: "info clickable",
    on: {
      "click": _vm.showItemInfo
    }
  }, [_vm._v(" " + _vm._s(_vm.itemCount) + " ")])]), _c('div', {
    staticClass: "table-column"
  }, [_c('p', {
    staticClass: "info"
  }, [_vm._v(" " + _vm._s(_vm.assemblyTime) + " ")])]), _c('div', {
    staticClass: "table-column last-column"
  }, [_c('div', {
    staticClass: "half"
  }, [_c('p', {
    staticClass: "info"
  }, [_vm._v(" " + _vm._s(_vm.journeySubregion) + " ")])]), _c('div', {
    staticClass: "half clickable"
  }, [_vm.trips.length ? _c('div', {
    staticClass: "chevron",
    on: {
      "click": _vm.toggleRow
    }
  }, [!_vm.rowExpaneded ? _c('i', {
    staticClass: "fa fa-chevron-down",
    attrs: {
      "aria-hidden": "true"
    }
  }) : _c('i', {
    staticClass: "fa fa-chevron-up",
    attrs: {
      "aria-hidden": "true"
    }
  })]) : _vm._e()])])]), _vm.rowExpaneded ? _c('div', {
    staticClass: "hidden-row"
  }, [_c('CustomerTrips', {
    attrs: {
      "trips": _vm.trips
    }
  })], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }