<template>
    <div class="curated-set-list">
        <section class="gs-generic-header">
            <h1>Curated Sets</h1>
            <button class="gs-standard-button gs-add-button">
                <router-link :to="{ name: 'CreateCuratedSet' }">
                    Add new curated set
                </router-link>
                <i class="fa fa-plus-circle" />
            </button>
        </section>

        <section class="row gs-standard-form gs-special-content">
            <h3 class="gs-standard-sub-header">
                Filter Sets:
            </h3>

            <div class="col-md-6">
                <div class="gs-standard-detail">
                    <label>Set Name:</label>
                    <input v-model="curatedSetNameSearch" />
                </div>

                <div class="gs-standard-detail">
                    <input v-model="inactiveOnly"
                           type="checkbox" />
                    <label>Inactive only</label>
                </div>
            </div>
        </section>

        <section v-if="isLoadingCuratedSets">
            <LoadingSpinner is-black="true" />
        </section>
        <section v-else
                 class="curated-sets-table gs-standard-table gs-hide-filter">
            <h3 class="gs-standard-sub-header">
                Curated Sets
            </h3>

            <v-client-table
                v-if="filteredSets.length > 0"
                :data="filteredSets"
                :columns="setColumns"
                :options="options">
                <span slot="name"
                      slot-scope="data">
                    <router-link :to="{ name: 'CuratedSet', params: { curatedSetId: data.row.id } }">
                        {{ data.row.name }}
                    </router-link>
                </span>
                <span slot="active"
                      slot-scope="data">
                    {{ data.row.isActive }}
                </span>
                <span slot="productCategory"
                      slot-scope="data">
                    {{ _.get(data.row.productCategory, "name") }}
                </span>
                <span slot="updated"
                      slot-scope="data">
                    {{ formatLongDateString(data.row.updatedAt) }}
                </span>
            </v-client-table>

            <div v-else
                 class="no-items">
                There are no curated sets to display. Please refine your search.
            </div>
        </section>

        <section class="gs-generic-header">
            <h1>Layouts</h1>
            <button class="gs-standard-button gs-add-button">
                <router-link :to="{ name: 'CreateLayout' }">
                    Add new layout
                </router-link>
                <i class="fa fa-plus-circle" />
            </button>
        </section>

        <section v-if="isLoadingLayouts">
            <LoadingSpinner is-black="true" />
        </section>
        <section v-else
                 class="layouts-table gs-standard-table gs-hide-filter">
            <v-client-table
                v-if="layouts.length > 0"
                :data="layouts"
                :columns="layoutColumns"
                :options="options">
                <span slot="id"
                      slot-scope="data">
                    <router-link :to="{ name: 'Layout', params: { layoutId: data.row.id } }">
                        {{ data.row.id }}
                    </router-link>
                </span>
                <span slot="name"
                      slot-scope="data">
                    <router-link :to="{ name: 'Layout', params: { layoutId: data.row.id } }">
                        {{ data.row.name }}
                    </router-link>
                </span>
                <span slot="active"
                      slot-scope="data">
                    {{ data.row.isActive }}
                </span>
                <span slot="productCategory"
                      slot-scope="data">
                    {{ _.get(data.row.productCategory, "name") }}
                </span>
                <span slot="updated"
                      slot-scope="data">
                    {{ formatLongDateString(data.row.updatedAt) }}
                </span>
                <span slot="supportedViews"
                      slot-scope="data">
                    {{ JSON.stringify(data.row.supportedViews) }}
                </span>
            </v-client-table>

            <div v-else
                 class="no-items">
                There are no layouts.
            </div>
        </section>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'CuratedSetList',
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        curatedSets:              [],
        layouts:                  [],
        curatedSetNameSearch:     '',
        inactiveOnly:             false,
        isLoadingCuratedSets:     false,
        isLoadingLayouts:         false,
        setColumns:               ['id', 'name', 'active', 'productCategory', 'updated'],
        layoutColumns:            ['id', 'name', 'active', 'productCategory', 'updated', 'supportedViews'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'name': 'Name',
            'active': 'Active?',
            'productCategory': 'Category',
            'updated': 'Updated At',
            'supportedViews': 'Supported Views',
          },
        },
      }
    },
    computed: {
      filteredSets() {
        return this.curatedSets.filter((curatedSet) => {
          return _.toString(curatedSet.name).toLowerCase().includes(this.curatedSetNameSearch.toLowerCase()) &&
            (!this.inactiveOnly || !curatedSet.isActive)
        }, this)
      },
    },
    mounted() {
      this.isLoadingCuratedSets = true
      this.axios.get('/layout_instances', { params: { is_admin: true } })
        .then(response => this.onCuratedSetsRetreived(response.data))

      this.isLoadingLayouts = true
      this.axios.get('/layouts')
        .then(response => this.onLayoutsRetreived(response.data))
    },
    methods: {
      onCuratedSetsRetreived(data) {
        this.curatedSets = data.layoutInstances
        this.isLoadingCuratedSets = false
      },
      onLayoutsRetreived(data) {
        this.layouts = data.layouts
        this.isLoadingLayouts = false
      },
    },
  }
</script>

<style lang="scss" scoped>

.curated-set-list {

  .curated-sets-table {
    margin-top:                   2em;
  }
}

</style>
