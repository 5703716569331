var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "jits-table"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Name | Item ID ")]), _vm.isDelivery ? _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Packets ")]) : _vm._e(), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Ref ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Picked Up ")]), _vm.isDelivery ? _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Delivered ")]) : _vm._e(), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Issues? ")])])]), _vm._l(_vm.groupedJits, function (jitsObject, idx) {
    return _c('tbody', {
      key: idx
    }, [jitsObject.location ? _c('div', {
      staticClass: "location"
    }, [_vm._v(" " + _vm._s(jitsObject.location) + " ")]) : _vm._e(), _vm._l(jitsObject.jits, function (jit) {
      return _c('tr', {
        key: jit.id,
        ref: jit.id,
        refInFor: true
      }, [_c('td', [_vm.getPublicID(jit) ? _c('cld-image', {
        staticClass: "jit-photo",
        attrs: {
          "public-id": _vm.getPublicID(jit),
          "width": "75",
          "height": "75",
          "crop": "limit"
        }
      }) : _vm._e()], 1), _c('td', [_c('div', [_vm._v(" " + _vm._s(jit.name) + " | " + _vm._s(jit.id) + " ")]), _c('div', [_vm._v(" Condition: " + _vm._s(jit.itemState) + " ")])]), _vm.isDelivery ? _c('td', [_vm._v(" " + _vm._s(_vm.getWarehousePackets(jit)) + " ")]) : _vm._e(), _c('td', [_vm._v(" " + _vm._s(jit.tripId) + " ")]), _vm.isDelivery ? _c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: jit.warehouseFulfilled,
          expression: "jit.warehouseFulfilled"
        }],
        attrs: {
          "type": "checkbox",
          "name": "warehouseFulfilled",
          "disabled": "disabled"
        },
        domProps: {
          "checked": Array.isArray(jit.warehouseFulfilled) ? _vm._i(jit.warehouseFulfilled, null) > -1 : jit.warehouseFulfilled
        },
        on: {
          "change": [function ($event) {
            var $$a = jit.warehouseFulfilled,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;

            if (Array.isArray($$a)) {
              var $$v = null,
                  $$i = _vm._i($$a, $$v);

              if ($$el.checked) {
                $$i < 0 && _vm.$set(jit, "warehouseFulfilled", $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(jit, "warehouseFulfilled", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(jit, "warehouseFulfilled", $$c);
            }
          }, function ($event) {
            return _vm.postCheckmarks(jit);
          }]
        }
      })]) : _vm._e(), _c('td', [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: jit.customerFulfilled,
          expression: "jit.customerFulfilled"
        }],
        attrs: {
          "type": "checkbox",
          "name": "customerFulfilled",
          "disabled": "disabled"
        },
        domProps: {
          "checked": Array.isArray(jit.customerFulfilled) ? _vm._i(jit.customerFulfilled, null) > -1 : jit.customerFulfilled
        },
        on: {
          "change": [function ($event) {
            var $$a = jit.customerFulfilled,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;

            if (Array.isArray($$a)) {
              var $$v = null,
                  $$i = _vm._i($$a, $$v);

              if ($$el.checked) {
                $$i < 0 && _vm.$set(jit, "customerFulfilled", $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(jit, "customerFulfilled", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(jit, "customerFulfilled", $$c);
            }
          }, function ($event) {
            return _vm.postCheckmarks(jit);
          }]
        }
      })]), _c('td', {
        staticClass: "issue-ellipse",
        on: {
          "click": function click($event) {
            return _vm.showIssueModal(jit);
          }
        }
      }, [jit.issue ? _c('img', {
        attrs: {
          "src": require("@/assets/images/icons/warning-ellipses.svg")
        }
      }) : _c('img', {
        attrs: {
          "src": require("@/assets/images/icons/grey-ellipses.svg")
        }
      })])]);
    })], 2);
  })], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }