<template>
    <div class="edit-layout">
        <section class="gs-generic-header">
            <h1>Edit Layout</h1>
            <button class="gs-standard-button">
                <router-link :to="{ name: 'Layout', params: { layoutId: $route.params.layoutId } }">
                    Go Back
                </router-link>
            </button>
        </section>

        <LoadingSpinner v-if="isLoadingLayout"
                        is-black="true" />
        <div v-else
             class="content gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />

            <section class="layout-details gs-special-content">
                <div class="gs-generic-header">
                    <h2>Layout Details</h2>

                    <button
                        v-if="layout"
                        class="gs-standard-button"
                        :disabled="isUpdatingLayout"
                        @click="updateLayout()">
                        Update
                    </button>
                    <button v-else
                            class="gs-standard-button">
                        <LoadingSpinner />
                    </button>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="gs-standard-detail">
                            <label>Name</label>
                            <input v-model="layout.name" />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Category</label>
                            <div class="input-group mb-3 category-select">
                                <select class="custom-select"
                                        @change="selectProductCategory($event)">
                                    <option>
                                        Please select a category...
                                    </option>
                                    <option v-for="option in allProductCategories"
                                            :key="option.id"
                                            :selected="layout.productCategory.id == option.id">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="gs-toggle-container">
                            <label class="tag">Is Active?</label>
                            <toggle-button :value="layout.isActive"
                                           color="#82C7EB"
                                           @change="layout.isActive = !layout.isActive" />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Supported views (comma seperated)</label>
                            <input v-model="supportedViews" />
                        </div>
                    </div>
                </div>
            </section>

            <section class="row products">
                <h3>Product Types</h3>

                <div class="col-md-6">
                    <FormErrors v-if="productTypeErrorMessage || productTypeErrorMessages.length > 0"
                                :primary-message="productTypeErrorMessage"
                                :error-messages="productTypeErrorMessages" />

                    <LoadingSpinner v-if="isLoadingProductTypes"
                                    is-black="true" />
                    <SimpleAddRemoveList
                        v-else
                        :items="allProductTypes"
                        :selected-items="layoutProductTypes"
                        :allow-duplicates="true"
                        display-key="name"
                        item-type="product type"
                        @itemAdded="addProductType"
                        @itemRemoved="removeProductType" />
                </div>
            </section>

            <section class="product-images">
                <h3>Layout Image</h3>

                <FormErrors v-if="imagePrimaryErrorMessage || imageErrorMessages.length > 0"
                            :primary-message="imagePrimaryErrorMessage"
                            :error-messages="imageErrorMessages" />

                <div v-if="layout.heroImage">
                    <cld-image
                        :public-id="layout.heroImage"
                        height="200" />
                    <button
                        class="gs-standard-button gs-delete-button"
                        @click="removeLayoutImage">
                        Remove
                    </button>
                </div>

                <input
                    v-else-if="!isUpdatingLayout"
                    ref="hidden-uploader"
                    type="file"
                    name="layout-image"
                    accept="image/*"
                    class="input-file"
                    @change="onImageSelect($event.target.name, $event.target.files)" />
                <h5 v-else>
                    Uploading...
                </h5>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'
  import SimpleAddRemoveList from '@/components/forms/SimpleAddRemoveList'
  import apiHelper from '@/helpers/apiHelper'

  export default {
    name: 'EditLayout',
    components: {
      LoadingSpinner,
      FormErrors,
      SimpleAddRemoveList,
    },
    data() {
      return {
        layout:                         {},
        primaryErrorMessage:            '',
        errorMessages:                  [],
        allProductTypes:                [],
        allProductCategories:           [],
        layoutProductTypes:             [],
        isLoadingLayout:                true,
        isUpdatingLayout:           false,
        isLoadingProductTypes:          true,
        imagePrimaryErrorMessage:       '',
        imageErrorMessages:             [],
        productTypeErrorMessage:        '',
        productTypeErrorMessages:       [],
        heroImage: null,
      }
    },
    mounted() {
      this.getLayout()
      this.getAllProductCategories()
    },
    methods: {
      getLayout() {
        this.isLoadingLayout = true
        this.axios.get('/layouts/' + this.$route.params.layoutId)
          .then(response => { this.onLayoutRetrieved(response.data) })
      },
      getAllProductCategories() {
        this.axios.get('/product_categories')
          .then(response => { this.allProductCategories = response.data.productCategories })
      },
      getProductTypes() {
        this.axios.get('/product_types')
          .then(response => { this.onProductTypesRetrieved(response.data) })
      },
      getLayoutProductTypes() {
        this.layoutProductTypes = _.flatMap(this.layout.data.pieces, piece => {
          var type = _.find(this.allProductTypes, (t) => piece.productType === t.identifier)
          if (!type) return null // This shouldn't happen
          return _.times(piece.quantity || 1, _.constant(type))
        })
        this.isLoadingLayoutProductTypes = false
      },
      //////////// TEMPLATE ACTIONS ////////////
      selectProductCategory(event) {
        const categoryName = event.target.value
        this.layout.productCategory = _.find(this.allProductCategories, (pc) => pc.name == categoryName)
        this.layout.productCategoryId = _.get(this.layout.productCategory, 'id')
      },
      updateLayout() {
        this.isUpdatingLayout = true
        this.layout.heroImage = this.heroImage
        this.heroImage = null

        const supportedViews = this.supportedViews.split(', ')

        // TODO: Figure out submitting json object via submitMultipartForm

        this.axios.patch('/layouts/' + this.$route.params.layoutId, { data: this.layout.data, supportedViews })
          .then(response => {
            apiHelper.submitMultipartForm(
              _.omit(this.layout, ['data', supportedViews]),
              '/layouts/' + this.$route.params.layoutId,
              this.onLayoutUpdated,
              this.onLayoutUpdatedFailure,
              'patch',
            )
          })
          .catch(response => this.onLayoutUpdatedFailure(response))
      },
      addProductType(productType) {
        const existingPiece = _.find(this.layout.data.pieces, (piece) => {
          return piece.productType === productType.identifier
        })

        this.layoutProductTypes.push(productType)

        if (existingPiece) {
          existingPiece.quantity = parseInt(existingPiece.quantity) + 1
          return
        }

        this.layout.data.pieces.push({
          productType: productType.identifier,
          quantity: 1,
        })
      },
      removeProductType(productType, types, index) {
        const removedType = _.find(this.layout.data.pieces, piece => {
          return piece.productType === productType.identifier
        })
        if (removedType.quantity > 1) {
          removedType.quantity -= 1
        } else {
          _.pull(this.layout.data.pieces, removedType)
        }

        this.layoutProductTypes.splice(index, 1)
      },
      onImageSelect(name, files) {
        if (files.length === 0) return
        this.heroImage = files[0]
      },
      removeLayoutImage() {
        this.layout.heroImage = null
        this.heroImage = null
      },
      //////////// CALLBACKS ////////////
      onLayoutRetrieved(data) {
        this.layout = data.layout
        this.layout.productCategoryId = _.get(data.layout.productCategory, 'id')
        this.supportedViews = data.layout.supportedViews.join(', ')
        this.isLoadingLayout = false

        this.getProductTypes()
      },
      onProductTypesRetrieved(data) {
        this.isLoadingProductTypes = false
        this.allProductTypes = data.productTypes
        this.getLayoutProductTypes()
      },
      onLayoutUpdated(data) {
        this.isUpdatingLayout = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully updated Layout!',
          statusCode: 1,
        })
        this.$router.push({ name: 'Layout', params: { layoutId: data.layout.id } })
      },
      onLayoutUpdatedFailure(response) {
        if (_.get(response.data, 'error')) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error updating Layout'
          this.errorMessages = JSON.stringify(response)
        }

        this.isUpdatingLayout = false
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

.edit-layout {

  margin-bottom:                    5em;

  .content {

    > section {
      margin-top:                   2em;

      > h3 {
        width:                      100%;
        margin-bottom:              1em;
      }
    }

    .layout-details {

      .gs-standard-detail {
        margin-top:                 1.5em;
      }

      input, textarea {
        display:                    block;
        width:                      95%;
      }

      textarea {
        height:                     12em;
      }

      .gs-toggle-container {
        margin-top:                 2em;
      }
    }
  }
}

</style>
