var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "suggestion-override"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('div', {
    staticClass: "content gs-standard-form"
  }, [_c('span', {
    staticClass: "h1"
  }, [_vm._v("Suggestion Override")]), _vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _vm.suggestionOverride ? _c('EnableDisableButton', {
    staticClass: "delete-button",
    attrs: {
      "object-to-watch": _vm.suggestionOverride,
      "fields-to-observe": ['sourceProductSku', 'targetProductSku', 'priority', 'block'],
      "disable-button-computed": _vm.isDeletingSuggestionOverride,
      "button-text": "Delete"
    },
    on: {
      "buttonClick": function buttonClick($event) {
        return _vm.deleteSuggestionOverride();
      }
    }
  }) : _vm._e(), _c('router-link', {
    staticClass: "gs-standard-button",
    attrs: {
      "to": {
        name: 'EditSuggestionOverride',
        params: {
          suggestionOverrideId: _vm.$route.params.suggestionOverrideId
        }
      }
    }
  }, [_vm._v(" Edit ")])], 1)]), _vm.isLoadingSuggestionOverride ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "gs-standard-content"
  }, [_c('section', {
    staticClass: "row suggestion-override-details"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" General Info ")]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Source Product Sku Id:")]), _c('span', [_vm._v(_vm._s(_vm.suggestionOverride.sourceProductSku.id))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Target Product Sku Id:")]), _c('span', [_vm._v(_vm._s(_vm.suggestionOverride.targetProductSku.id))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Priority:")]), _c('span', [_vm._v(_vm._s(_vm.suggestionOverride.priority))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Block?:")]), _c('span', [_vm._v(_vm._s(_vm.suggestionOverride.block))])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }