<template>
    <div class="jit-issue">
        <div class="body">
            <div class="title">
                <h4 class="h4-emphasized">
                    {{ title }}
                </h4>
                <hr />
            </div>
            <div class="item-info">
                <table class="table">
                    <tbody>
                        <tr>
                            <td>
                                <cld-image :public-id="jitObject.publicId"
                                           class="jit-photo"
                                           width="75"
                                           height="75"
                                           crop="limit" />
                            </td>
                            <td>
                                {{ jitObject.name }} – Condition: {{ jitObject.itemState }}
                            </td>
                            <td>
                                {{ jitObject.location }}
                            </td>
                            <td>
                                {{ `Ref #: ${jitObject.tripId}` }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <form @submit.prevent="attemptSubmit">
                <div>
                    <div class="issue-select">
                        <span>Item Issue Type</span>
                        <div class="input-group mb-3 issue-select">
                            <select class="custom-select"
                                    @change="selectIssue($event)">
                                <option>Select issue</option>
                                <option v-for="option in _.keys(questionSet)"
                                        :key="option"
                                        :selected="primaryIssue == option">
                                    {{ option }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="typeof questionSet[primaryIssue] !== 'string'"
                         class="issue-select">
                        <span>More specific</span>
                        <div class="input-group mb-3 issue-select">
                            <select class="custom-select"
                                    @change="selectSecondIssue($event)">
                                <option>Select issue</option>
                                <option v-for="option in _.keys(questionSet[primaryIssue])"
                                        :key="option"
                                        :selected="secondaryIssue == option">
                                    {{ option }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div v-if="existingIssueString"
                         class="issue-select">
                        <span>Existing Issue Type:</span>

                        {{ existingIssueString }}
                    </div>
                </div>

                <div class="issue-notes">
                    <span>Notes</span>
                    <textarea v-model="issueNote"
                              type="text"
                              name="issue-notes"
                              placeholder="(extra details)" />
                </div>
                <div class="submit">
                    <button type="submit"
                            :disabled="isSubmitting">
                        {{ existingIssueString ? 'Edit' : 'Submit' }}
                    </button>
                </div>
            </form>
            <div class="delete">
                <button type="submit"
                        :disabled="!existingIssue"
                        @click="deleteIssue">
                    Delete issue
                </button>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'JITIssueReporter',
    props: {
      jitObject: {
        type: Object,
        default: () => {},
      },
      successCallback: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        deliveryIssuesObject: {
          'Missing/Incorrect Item': {
            'Forgot at warehouse': 'item_forgot_at_warehouse',
            'Forgot one or more packets': 'forgot_packets_at_warehouse',
            'Incorrect item from warehouse': 'incorrect_item_from_warehouse',
            'Missing from warehouse': 'item_missing_from_warehouse',
            'Other (add a comment)': 'item_missing_other',
          },
          'New Item Issue': {
            'Manufacturer Defect': 'new_item_manufacturer_defect',
            'Missing parts/hardware': 'new_item_missing_parts',
            'Damaged in box': 'new_item_damaged_in_box',
            'Assembled improperly': 'new_item_assembled_improperly',
          },
          'Customer not satisfied with used condition of item': 'used_item_customer_condition_satisfaction' ,
          'Customer Issue': {
            'Item did not fit': 'customer_item_did_not_fit',
            'Customer ordered incorrect item': 'customer_ordered_incorrect_item',
            'Customer did not like item': 'customer_rejected_item',
          },
        },
        pickupIssuesObject: {
          'Customer Kept Item': 'pickup_item_kept',
          'Significant Damage to Item': 'pickup_item_significant_damage',
          'Unable to pick up item (add a comment)': 'pickup_item_other',
          'Did not have space on the truck': 'pickup_item_no_space_in_truck',
          'Wanted a different item picked up': 'pickup_item_wrong_item',
        },
        primaryIssue: '',
        secondaryIssue: '',
        issueNote: '',
        isSubmitting: false,
      }
    },
    computed: {
      title() {
        const itemName = _.get(this.jitObject, 'name')
        const title = `${itemName} - Issue`

        if (this.existingIssue) return title
        return `${title} Report`
      },
      existingIssue() {
        return _.get(this.jitObject, 'issue')
      },
      existingIssueString() {
        if (!this.existingIssue) return

        return _.get(this.existingIssue, 'issue', '').split('_').join(' ')
      },
      isDropoffTrip() {
        return _.get(this.jitObject, 'isDelivery')
      },
      questionSet() {
        return this.isDropoffTrip ? this.deliveryIssuesObject : this.pickupIssuesObject
      },
      selectedIssue() {
        const issueValue = this.questionSet[this.primaryIssue]
        if (typeof issueValue !== 'string') {
          return issueValue ? issueValue[this.secondaryIssue] : null
        }

        return issueValue
      },
    },
    mounted() {
      this.$nextTick(() => this.issueNote = _.get(this.existingIssue, 'note'))
    },
    methods: {
      selectIssue(event) {
        const issueValue = event.target.value
        this.primaryIssue = issueValue == 'Select issue' ? '' : issueValue
        this.secondaryIssue = ''
      },
      selectSecondIssue(event) {
        const issueValue = event.target.value
        this.secondaryIssue = issueValue == 'Select issue' ? '' : issueValue
      },
      submitIssue() {
        const issueVariables = {
          journeyId: this.jitObject.journeyId,
          inventoryItemId: this.jitObject.id,
          issue: this.selectedIssue || this.existingIssue?.issue,
          note: this.issueNote || this.existingIssue?.note,
          id: this.existingIssue?.id,
        }

        this.isSubmitting = true

        this.$apollo.mutate({
          mutation: this.existingIssue ?
            require('@/graphql/mutations/UpdateJourneyIssue.gql') :
            require('@/graphql/mutations/CreateJourneyIssue.gql'),
          variables: issueVariables,
        }).then((data) => {
          const new_id = data?.data?.createJourneyIssue?.id
          if (new_id) issueVariables.id = new_id
          this.successCallback(issueVariables)
          this.$emit('close')
        }).catch((error) => {
          alert(error)
        }).finally(() => this.isSubmitting = false)
      },
      attemptSubmit() {
        if (!this.existingIssue) {
          if (!this.selectedIssue) {
            alert(`Please select ${this.isDropoffTrip ? 'both' : 'an'} issue option${this.isDropoffTrip ? 's' : ''}`)
            return
          }

          if (this.selectedIssue.indexOf('_other') !== -1 && !this.issueNote) {
            alert('Please provide an issue note')
            return
          }
        }

        this.submitIssue()
      },
      deleteIssue() {
        if (confirm("Really delete issue? This cannot be undone.")) {
          const issueVariables = {
            id: this.existingIssue.id,
          }
          this.$apollo.mutate({
            mutation: require('@/graphql/mutations/DeleteJourneyIssue.gql'),
            variables: issueVariables,
          }).then((_data) => {
            issueVariables.deleted = true
            this.successCallback(issueVariables)
            this.$emit('close')
          }).catch((error) => {
            alert(error)
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .jit-issue {

    .title {
      h4 {
        font-weight: bold;
        text-align: left;
      }

      hr {
        border-bottom: 1px solid;
        margin: 2px 0 0 0;
      }
    }

    span {
      font-weight: bold;
    }

    .item-info {
      .table {
        tr, td {
          height: 40px;
        }

        td {
          vertical-align: inherit;
          padding: 0.5rem 0;
        }
      }
    }

    .issue-select {
      margin-bottom: 1rem;
    }

    .issue-notes {
      margin-bottom: 1rem;

      textarea {
        width: 100%;
        height: 10em;
        padding: 0.5rem;
        border: none;
        background: rgba(151, 151, 151, 0.1);
        box-shadow: inset 0px 1px 4px 2px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
      }

      textarea.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .submit {
      button {
        background-color: #FFE82F;
        color: black;
        border: none;
        width: 180px;
        font-weight: bold;
        height: 40px;
        border-radius: 2px;
        float: right;
      }
      button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .delete {
      button {
        background-color: #FFE82F;
        color: black;
        border: none;
        width: 180px;
        font-weight: bold;
        height: 40px;
        border-radius: 2px;
        float: left;
      }
      button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
</style>

<style lang="scss">
  .jit-issue {
    .simple-dropdown {
      width: 50%;
      margin-left: 1rem;
      padding: 0 2.5rem 0 1rem;
      height: 30px;
      line-height: 30px;

      .select-area > i {
        top: 0.3rem;
      }
    }

    .simple-dropdown.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
</style>
