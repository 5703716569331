var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tags gs-simple-crud"
  }, [_vm._m(0), _c('SimpleCreateForm', {
    attrs: {
      "primary-error-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages,
      "show-loading-state": _vm.isCreatingTag,
      "new-model-instance": _vm.newTag
    },
    on: {
      "formSubmit": _vm.createTag
    }
  }), _vm.isLoadingTags ? _c('div', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_c('section', {
    staticClass: "tags-table gs-standard-table"
  }, [_vm.tags.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.tags,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "delete",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-delete"
        }, [_c('DeleteIcon', {
          on: {
            "delete": function _delete($event) {
              return _vm.setCurrentDeleteTag(data.row);
            }
          }
        })], 1);
      }
    }], null, false, 497149133)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no tags to display ")])], 1), _vm.showConfirmDeleteModal ? _c('ConfirmModal', {
    attrs: {
      "show-loading-state": _vm.isDeletingTag
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showConfirmDeleteModal = false;
      },
      "confirm": function confirm($event) {
        return _vm.deleteTag();
      }
    }
  }, [_c('span', {
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_vm._v("Are you sure you want to remove "), _c('b', [_vm._v(_vm._s(_vm.currentDeletingTag.name))]), _vm._v("? This will also destroy any associations with this tag!")])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Tags")])]);
}]

export { render, staticRenderFns }