<template>
    <div class="product-attribute gs-simple-crud">
        <h1>Product Attributes</h1>

        <ApolloMutation
            :mutation="require('@/graphql/mutations/CreateProductAttribute.gql')"
            :variables="newProductAttribute"
            @done="onProductAttributeCreated">
            <template #default="{ mutate, loading, error }">
                <SimpleCreateForm
                    :primary-error-message="primaryErrorMessage"
                    :error-messages="errorMessages"
                    :show-loading-state="loading"
                    :new-model-instance="newProductAttribute"
                    @formSubmit="mutate">
                    <template #:features="slotProps">
                        <SimpleAddRemoveList
                            :items="allowedFeatures"
                            :selected-items="slotProps.value"
                            :allow-duplicates="false"
                            :show-on-empty="true"
                            item-type="feature"
                            @itemAdded="addFeature"
                            @itemRemoved="removeFeature" />
                    </template>
                    <template #:allowedValues="slotProps">
                        <SimpleCreateRemoveList
                            :values="slotProps.value"
                            :allow-duplicates="true"
                            @valueAdded="addAllowedValue"
                            @valueRemoved="removeAllowedValue" />
                    </template>
                </SimpleCreateForm>
                <p v-if="error">
                    An error occurred: {{ error }}
                </p>
            </template>
        </ApolloMutation>

        <ApolloQuery
            :query="require('@/graphql/queries/ProductAttributes.gql')"
            @result="onProductAttributesRetrieved">
            <template #default="{ result: { loading, error, data } }">
                <!-- Loading -->
                <div v-if="loading"
                     class="loading apollo">
                    Loading...
                </div>

                <!-- Error -->
                <div v-else-if="error"
                     class="error apollo">
                    An error occurred: {{ data.error }}
                </div>

                <!-- Result -->
                <div v-else-if="data"
                     class="result apollo">
                    <section class="product-attribute-table gs-standard-table">
                        <!-- eslint-disable vue/no-template-shadow -->
                        <v-client-table
                            v-if="data.productAttributes.length > 0"
                            :data="data.productAttributes"
                            :columns="columns"
                            :options="options">
                            <div slot="data"
                                 slot-scope="data">
                                <button class="gs-standard-button">
                                    <router-link :to="{ name: 'ProductAttributeInstances', params: { productAttributeId: data.row['id'] } }">
                                        Data
                                    </router-link>
                                </button>
                            </div>

                            <div slot="editName"
                                 slot-scope="data">
                                <input v-if="isEditingThisItem(data.row.id)"
                                       v-model="data.row['name']" />
                                <span v-else>{{ data.row['name'] }}</span>
                            </div>

                            <div slot="editDescription"
                                 slot-scope="data">
                                <textarea v-if="isEditingThisItem(data.row.id)"
                                          v-model="data.row['description']" />
                                <span v-else>{{ data.row['description'] }}</span>
                            </div>

                            <div slot="editFeatures"
                                 slot-scope="data">
                                <!-- XXX: Removing items in this list works, but doesn't refresh the UX - why? -->
                                <SimpleAddRemoveList
                                    v-if="isEditingThisItem(data.row.id)"
                                    :items="allowedFeatures"
                                    :selected-items="data.row.features"
                                    :allow-duplicates="false"
                                    :show-on-empty="true"
                                    item-type="feature"
                                    @itemAdded="addFeature"
                                    @itemRemoved="removeFeature" />
                                <pre v-else>{{ _.join(_.map(data.row['features'], v => "- " + v), "\n") }}</pre>
                            </div>

                            <div slot="editAllowedValues"
                                 slot-scope="data">
                                <!-- XXX: Removing items in this list works, but doesn't refresh the UX - why? -->
                                <SimpleCreateRemoveList
                                    v-if="isEditingThisItem(data.row.id)"
                                    :values="data.row.allowedValues"
                                    :allow-duplicates="false"
                                    @valueAdded="addAllowedValue"
                                    @valueRemoved="removeAllowedValue" />
                                <pre v-else>{{ _.join(_.map(data.row['allowedValues'], v => "- " + v), "\n") }}</pre>
                            </div>

                            <div slot="editMultipleAllowed"
                                 slot-scope="data">
                                <input v-if="isEditingThisItem(data.row.id)"
                                       v-model="data.row['multipleAllowed']"
                                       type="checkbox" />
                                <span v-else>{{ (data.row['multipleAllowed'] ? '✔' : '✘') }}</span>
                            </div>

                            <div slot="editInternal"
                                 slot-scope="data">
                                <input v-if="isEditingThisItem(data.row.id)"
                                       v-model="data.row['internal']"
                                       type="checkbox" />
                                <span v-else>{{ (data.row['internal'] ? '✔' : '✘') }}</span>
                            </div>

                            <div slot="edit"
                                 slot-scope="data"
                                 class="gs-standard-table-edit">
                                <ApolloMutation
                                    :mutation="require('@/graphql/mutations/UpdateProductAttribute.gql')"
                                    :variables="data.row"
                                    @done="onProductAttributeUpdated">
                                    <!-- eslint-disable vue/no-unused-vars -->
                                    <template #default="{ mutate, _loading, updateError }">
                                        <TableEditButton
                                            :is-editing-this-item="isEditingThisItem(data.row.id)"
                                            :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                                            @saveButtonClick="mutate"
                                            @editButtonClick="currentEditProductAttributeId = data.row.id" />
                                        <p v-if="updateError">
                                            An error occurred: {{ updateError }}
                                        </p>
                                    </template>
                                </ApolloMutation>
                            </div>

                            <div slot="delete"
                                 slot-scope="data"
                                 class="gs-standard-table-delete">
                                <DeleteIcon @delete="setCurrentDeleteProductAttribute(data.row)" />
                            </div>
                        </v-client-table>

                        <div v-else
                             class="no-items">
                            There are no product attributes to display
                        </div>
                    </section>

                    <ApolloMutation
                        :mutation="require('@/graphql/mutations/DeleteProductAttribute.gql')"
                        :variables="currentDeletingProductAttribute"
                        @done="onProductAttributeDeleted">
                        <template #default="{ mutate, _loading, deleteError }">
                            <!-- eslint-disable vue/no-unused-vars -->
                            <ConfirmModal
                                v-if="showConfirmDeleteModal"
                                :show-loading-state="isDeletingProductAttribute"
                                @cancel="showConfirmDeleteModal = false"
                                @confirm="mutate">
                                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingProductAttribute.name }}</b>? This will also any delete data for this attribute!</span>
                            </ConfirmModal>
                            <p v-if="deleteError">
                                An error occurred: {{ deleteError }}
                            </p>
                        </template>
                    </ApolloMutation>
                </div>

                <!-- No result -->
                <div v-else
                     class="no-result apollo">
                    No result :(
                </div>
            </template>
        </ApolloQuery>
    </div>
</template>

<script>

  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import EventBus from '@/components/utilities/EventBus'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'
  import SimpleAddRemoveList from '../../components/forms/SimpleAddRemoveList'
  import SimpleCreateRemoveList from '../../components/forms/SimpleCreateRemoveList'

  export default {
    name: 'ProductAttributes',
    components: {
      SimpleAddRemoveList,
      SimpleCreateRemoveList,
      SimpleCreateForm,
      ConfirmModal,
      DeleteIcon,
      TableEditButton,
    },
    data() {
      return {
        isLoadingProductAttributes:         false,
        errorMessages:                      [],
        primaryErrorMessage:                '',
        productAttributes:                  [],
        newProductAttribute:                this.initializeProductAttribute(),
        isCreatingProductAttribute:         false,
        currentEditProductAttributeId:      null,
        currentSavingProductAttributeId:    null,
        currentDeletingProductAttribute:    null,
        isDeletingProductAttribute:         false,
        showConfirmDeleteModal:             false,
        allowedFeatures:                    ['filter', 'grid_bubble', 'pdp_bubble', 'detail', 'tooltip', 'grid_detail'],
        columns:                            ['id', 'editName', 'editDescription', 'editFeatures',
                                             'editAllowedValues', 'editMultipleAllowed', 'editInternal', 'data', 'edit', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [10, 20, 50, 100, 250],
          headings: {
            'editName': 'Name',
            'editDescription': 'Description',
            'editFeatures': 'Features',
            'editAllowedValues': 'Allowed Values',
            'editMultipleAllowed': 'Multiple Allowed?',
            'editInternal': 'Internal?',
          },
        },
      }
    },
    methods: {
      //////////// TEMPLATE ACTIONS ////////////
      setCurrentDeleteProductAttribute(productAttribute) {
        this.showConfirmDeleteModal = true
        this.currentDeletingProductAttribute = productAttribute
      },
      addFeature(value, features) {
        if (features.includes(value)) {
          return
        }

        features.push(String(value))
      },
      removeFeature(value, features, index) {
        features.splice(index, 1)
      },
      addAllowedValue(value, allowedValues) {
        if (allowedValues.includes(value)) {
          return
        }

        allowedValues.push(String(value))
      },
      removeAllowedValue(value, allowedValues, index) {
        allowedValues.splice(index, 1)
      },
      //////////// CALLBACKS ////////////
      onProductAttributesRetrieved(data) {
        this.isLoadingProductAttributes = false
        this.productAttributes = data.data.productAttributes
      },
      onProductAttributeCreated(data) {
        this.isCreatingProductAttribute = false
        this.productAttributes.push(data.data.createProductAttribute)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created productAttribute!',
          statusCode: 1,
        })
        this.newProductAttribute = this.initializeProductAttribute()
      },
      onProductAttributeCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating productAttribute'
          this.errorMessages = JSON.stringify(response)
        }

        this.isCreatingProductAttribute = false
      },
      onProductAttributeUpdated(data) {
        arrayHelper.replaceObjectByValue(this.productAttributes, data.data.updateProductAttribute, 'id')
        this.currentEditProductAttributeId = null
        this.currentSavingProductAttributeId = null
      },
      onProductAttributeDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingProductAttribute = null

        arrayHelper.removeObjectByValue(this.productAttributes, data.data.deleteProductAttribute.id, 'id')
        EventBus.$emit('globalAlert', {
          message: 'Product attribute successfully deleted!',
          statusCode: 1,
        })
      },
      initializeProductAttribute() {
        return {
          name            : '',
          description     : '',
          allowedValues   : [],
          features        : [],
          multipleAllowed : false,
          internal        : false,
        }
      },
      isSavingThisItem(id) {
        return this.currentSavingProductAttributeId === id
      },
      isEditingThisItem(id) {
        return this.currentEditProductAttributeId === id
      },
    },
  }
</script>

<style lang="scss" scoped>

.product-attribute {

  .content {
    margin-top:                   1em;
  }
}

</style>

<style lang="scss">
  .product-attribute {
    .table td, .table th {
      max-width: 5rem;
    }
  }
</style>
