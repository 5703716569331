<template>
    <div class="create-suggestion-override">
        <section class="gs-generic-header">
            <h1>Create Curated Set</h1>
            <button :disabled="isSavingSuggestionOverride"
                    class="gs-standard-button"
                    @click="createSuggestionOverride()">
                <span v-if="!isSavingSuggestionOverride">Create</span>
                <LoadingSpinner v-else
                                max-height="1.5" />
            </button>
        </section>

        <div class="create-form gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />


            <section class="suggestion-override-details">
                <h2>Override Basics</h2>

                <div class="gs-standard-detail">
                    <label>Source Product Sku Id:</label>
                    <input v-model="newSuggestionOverride.sourceProductSkuId" />
                    <div v-if="sourceProductSkuExists"
                         class="edit-source-product-sku">
                        {{ selectedSourceProductSku.name }}
                        <i class="fa fa-close remove-item"
                           @click="removeSourceProductSku()" />
                    </div>
                    <SimpleTypeahead
                        v-else
                        :items="allProductSkus"
                        display-key="name"
                        input-placeholder="Search product skus..."
                        typeahead-width="20em"
                        @itemClicked="selectSourceProductSku" />
                </div>

                <div class="gs-standard-detail">
                    <label>Target Product Sku Id:</label>
                    <input v-model="newSuggestionOverride.targetProductSkuId" />
                    <div v-if="targetProductSkuExists"
                         class="edit-target-product-sku">
                        {{ selectedTargetProductSku.name }}
                        <i class="fa fa-close remove-item"
                           @click="removeTargetProductSku()" />
                    </div>
                    <SimpleTypeahead
                        v-else
                        :items="allProductSkus"
                        display-key="name"
                        input-placeholder="Search product skus..."
                        typeahead-width="20em"
                        @itemClicked="selectTargetProductSku" />
                </div>

                <div class="gs-standard-detail">
                    <label>Priority:</label>
                    <input v-model="newSuggestionOverride.priority" />
                </div>

                <div class="gs-toggle-container">
                    <label class="tag">Block?:</label>
                    <toggle-button :value="newSuggestionOverride.block"
                                   color="#82C7EB"
                                   @change="newSuggestionOverride.block = !newSuggestionOverride.block" />
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'
  import SimpleTypeahead from '@/components/forms/SimpleTypeahead'

  export default {
    name: 'CreateSuggestionOverride',
    components: {
      LoadingSpinner,
      FormErrors,
      SimpleTypeahead,
    },
    data() {
      return {
        newSuggestionOverride: this.initializeNewSuggestionOverride(),
        primaryErrorMessage: '',
        errorMessages: [],
        allProductSkus: [],
        selectedSourceProductSku: {},
        selectedTargetProductSku: {},
        isSavingSuggestionOverride: false,
        isLoadingProductSkus: true,
      }
    },
    computed: {
      sourceProductSkuExists() {
        return Object.keys(this.selectedSourceProductSku).length > 0
      },
      targetProductSkuExists() {
        return Object.keys(this.selectedTargetProductSku).length > 0
      },
    },
    watch: {
      'newSuggestionOverride.name' : function(val) {
        this.newSuggestionOverride.identifier = _.kebabCase(val)
      },
    },
    mounted() {
      this.getProductSkus()
    },
    methods: {
      getProductSkus() {
        this.isLoadingProductSkus = true
        this.axios.get('/product_skus')
          .then(response => this.onProductSkusRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      createSuggestionOverride() {
        this.isSavingSuggestionOverride = true
        this.axios.post('/suggestion_overrides', {
          suggestionOverride: this.newSuggestionOverride,
        }).then(response => this.onSuggestionOverrideCreated(response.data))
          .catch(error => this.onSuggestionOverrideCreatedFailure(error.response))
      },
      //////////// CALLBACKS ////////////
      onProductSkusRetrieved(data) {
        this.allProductSkus = data.productSkus.filter(sku => sku.isActive)
        this.isLoadingProductSkus = false
      },
      selectSourceProductSku(productSku) {
        this.selectedSourceProductSku = productSku
        this.newSuggestionOverride.sourceProductSkuId = productSku.id
      },
      removeSourceProductSku() {
        this.selectedSourceProductSku = {}
        this.newSuggestionOverride.sourceProductSkuId = null
      },
      selectTargetProductSku(productSku) {
        this.selectedTargetProductSku = productSku
        this.newSuggestionOverride.targetProductSkuId = productSku.id
      },
      removeTargetProductSku() {
        this.selectedTargetProductSku = {}
        this.newSuggestionOverride.targetProductSkuId = null
      },
      onSuggestionOverrideCreated(data) {
        this.isSavingSuggestionOverride = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully created Curated Set!',
          statusCode: 1,
        })
        this.$router.push({ name: 'SuggestionOverride', params: { suggestionOverrideId: data.suggestionOverride.id } })
      },
      onSuggestionOverrideCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating curated set'
          this.errorMessages = JSON.stringify(response)
        }

        this.isSavingSuggestionOverride = false
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewSuggestionOverride() {
        return {
          sourceProductSkuId: '',
          targetProductSkuId: '',
          priority:           0,
          block:              false,
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

  .create-suggestion-override {

    margin-bottom:                  5em;

    .create-form {

      > section {
        padding:                    1em;

        > h2 {
          margin:                   0.5em 0;
          width:                    100%;
        }
      }

      .suggestion-override-details {

        input, textarea {
          display:                  block;
          width:                    30em;
        }

        textarea {
          height:                   10em;
        }

        .gs-toggle-container {
          margin-top:               2em;
        }
      }

    }
  }

</style>
