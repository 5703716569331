<template>
    <div class="row login-container text-center">
        <div class="login-wrapper col-md-6 col-sm-8 col-xs-10">
            <form class="form-signin"
                  @submit.prevent="login">
                <h2 class="form-signin-heading">
                    Sign in
                </h2>

                <InlineMessage v-if="error"
                               :message="error"
                               status-code="3" />

                <label for="input-email"
                       class="sr-only">Email address</label>
                <input id="input-email"
                       v-model="email_address"
                       type="email"
                       class="form-control"
                       placeholder="Email address"
                       required
                       autofocus />
                <label for="input-password"
                       class="sr-only">Password</label>
                <input id="input-password"
                       v-model="password"
                       type="password"
                       class="form-control"
                       placeholder="Password"
                       required />
                <button class="gs-standard-button sign-in-button"
                        type="submit">
                    <span v-if="!isLoading">Sign in</span>
                    <LoadingSpinner v-else
                                    max-height="1.25" />
                </button>
            </form>
        </div>
    </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import InlineMessage from '@/components/forms/InlineMessage'
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'Login',
    components: {
      InlineMessage,
      LoadingSpinner,
    },
    data() {
      return {
        email_address:  '',
        password:       '',
        error:          null,
        isLoading:      false,
      }
    },
    computed: {
      ...mapGetters({ currentUser: 'currentUser' }),
    },
    created() {
      this.checkCurrentLogin()
    },
    updated() {
      this.checkCurrentLogin()
    },
    methods: {
      login() {
        if (this.isLoading) return
        this.isLoading = true
        this.axios.post('/auth', { email_address: this.email_address, password: this.password })
          .then(response => this.loginSuccessful(response))
          .catch(error => this.loginFailed(error))
          .finally(() => this.isLoading = false)
      },
      loginFailed(error) {
        if (_.get(error, 'response.status') === 401) {
          this.error = 'Incorrect Email or Password'
        } else {
          this.error = 'Login failed! Please try again.'
        }

        this.$store.dispatch('logout')
      },
      loginSuccessful(_response) {
        this.error = false

        localStorage.setItem('loggedIn', true)

        this.$store.dispatch('setCurrentUser')
          .then(() => this.$router.replace(this.$route.query.redirect || '/home'))
          .catch((error) => this.setCurrentUserFailed(error))
      },
      setCurrentUserFailed(_error) {
        this.error = 'Login failed! Please try again.'
        this.$store.dispatch('logout')
      },
      checkCurrentLogin() {
        if (this.currentUser) {
          this.$router.replace(this.$route.query.redirect || '/home')
        }
      },
    },
  }
</script>

<style scoped lang="scss">

.login-container {

  padding:                      3em 1em;

  .login-wrapper {
    margin:                     0 auto;

    .form-signin {

      > h2 {
        margin-bottom:          1em;
        display:                inline-block;
      }

      > input {
        border: none;
        border-bottom:          1px solid black;
        margin-bottom:          0.5em;
        display:                block;
        border-radius:          0;
        color:                  black;
        text-align:             center;
      }

      .sign-in-button {
        margin-top:             1em;
      }
    }
  }
}

</style>
