<template>
    <div class="create-product">
        <section class="gs-generic-header">
            <h1>Create Product</h1>
            <button type="submit"
                    :disabled="isSavingProduct"
                    class="gs-standard-button"
                    @click="submitForm()">
                <span v-if="!isSavingProduct">Create</span>
                <LoadingSpinner v-else
                                max-height="1.5" />
            </button>
        </section>

        <div class="create-form gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />


            <section class="product-details">
                <h2>Product Details</h2>

                <input v-model="newProduct.name"
                       placeholder="Name" />
                <input v-model="newProduct.identifier"
                       placeholder="Identifier" />
                <div class="input-group mb-3 brand-select">
                    <select class="custom-select"
                            @change="selectBrand($event)">
                        <option>
                            Please select a brand..
                        </option>
                        <option v-for="option in brands"
                                :key="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <div class="input-group mb-3 manufacturer-select">
                    <select class="custom-select"
                            @change="selectManufacturer($event)">
                        <option>
                            Please select a manufacturer..
                        </option>
                        <option v-for="option in manufacturers"
                                :key="option.id">
                            {{ option.name }}
                        </option>
                    </select>
                </div>
                <textarea v-model="newProduct.description"
                          placeholder="Description" />
                <textarea v-model="newProduct.secondaryInfo"
                          placeholder="Behind The Design" />
                <textarea v-model="newProduct.internalNotes"
                          placeholder="Internal Notes" />
                <input v-model="newProduct.materialsString"
                       placeholder="Materials Description" />
                <div class="gs-toggle-container">
                    <label class="tag">Recommend?</label>
                    <toggle-button
                        :value="recommendation.recommend"
                        color="#82C7EB"
                        @change="recommendation.recommend = !recommendation.recommend" />
                </div>
                <div v-if="recommendation.recommend">
                    <input v-model="recommendation.ranking"
                           placeholder="Recommendation rank (optional)" />
                    <div class="gs-toggle-container">
                        <label class="tag">Recommendation exclusive to product types?</label>
                        <toggle-button
                            :value="recommendation.exclusiveForType"
                            color="#82C7EB"
                            @change="recommendation.exclusiveForType = !recommendation.exclusiveForType" />
                    </div>
                </div>
            </section>


            <section class="associations">
                <h2>Associations</h2>

                <ProductAssociationManager
                    :selected-categories="newProductCategories"
                    :selected-styles="newProductStyles"
                    :selected-types="newProductTypes"
                    :selected-materials="newProductMaterials"
                    @addCategory="addCategory"
                    @removeCategory="removeCategory"
                    @addStyle="addStyle"
                    @removeStyle="removeStyle"
                    @addType="addType"
                    @removeType="removeType"
                    @addMaterial="addMaterial"
                    @removeMaterial="removeMaterial" />
            </section>


            <section class="additional-properties">
                <h2>Additional Properties</h2>

                <ProductAdditionalPropertiesManager
                    :additional-properties="newProduct.additionalProperties"
                    @addAdditionalProperty="appendAdditionalProperty"
                    @removeAdditionalProperty="removeAdditionalProperty" />
            </section>


            <section class="product-images">
                <h2>Product Images</h2>

                <ProductImageManager
                    :product-images="newProductImages"
                    @addProductImage="addProductImage"
                    @removeProductImage="removeProductImage" />
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'
  import ProductAssociationManager from '@/components/resources/products/ProductAssociationManager'
  import ProductImageManager from '@/components/resources/products/ProductImageManager'
  import ProductAdditionalPropertiesManager from '@/components/resources/products/ProductAdditionalPropertiesManager'
  import { arrayHelper } from '@petra-living/petra-vue-common'
  import apiHelper from '@/helpers/apiHelper'

  export default {
    name: 'CreateProduct',
    components: {
      LoadingSpinner,
      FormErrors,
      ProductAssociationManager,
      ProductImageManager,
      ProductAdditionalPropertiesManager,
    },
    data() {
      return {
        newProduct:                   this.initializeNewProduct(),
        primaryErrorMessage:          '',
        errorMessages:                [],
        brands:                       [],
        manufacturers:                [],
        newProductImages:             [],
        newProductTypes:              [],
        newProductMaterials:          [],
        newProductCategories:         [],
        newProductStyles:             [],
        recommendation:               { recommend: false, id: null, ranking: null, exclusiveForType: null },
        selectedBrand:                null,
        selectedManufacturer:         null,
        isSavingProduct:              false,
        productImageUploadError:      false,
      }
    },
    computed: {
      newProductCategoryIds() {
        return _.map(this.newProductCategories, (category) => category.id)
      },
      newProductStyleIds() {
        return _.map(this.newProductStyles, (style) => style.id)
      },
      newProductTypeIds() {
        return _.map(this.newProductTypes, (type) => type.id)
      },
      newProductMaterialIds() {
        return _.map(this.newProductMaterials, (material) => material.id)
      },
    },
    watch: {
      'newProduct.name' : function(val) {
        this.newProduct.identifier = _.kebabCase(val)
      },
    },
    mounted() {
      this.getBrands()
      this.getManufacturers()
    },
    methods: {
      getBrands() {
        this.axios.get('/brands')
          .then(response => this.onBrandsRetrieved(response.data))
      },
      getManufacturers() {
        this.axios.get('/manufacturers')
          .then(response => this.onManufacturersRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      selectBrand(event) {
        const brandName = event.target.value
        this.selectedBrand = _.find(this.brands, (b) => b.name == brandName)
        this.newProduct.brandId = _.get(this.selectedBrand, 'id')
      },
      selectManufacturer(event) {
        const manufacturerName = event.target.value
        this.selectedManufacturer = _.find(this.manufacturers, (m) => m.name == manufacturerName)
        this.newProduct.manufacturerId = _.get(this.selectedManufacturer, 'id')
      },
      addCategory(category) {
        this.newProductCategories.push(category)
      },
      removeCategory(category) {
        arrayHelper.removeObjectByValue(this.newProductCategories, category.id, 'id')
      },
      addStyle(style) {
        this.newProductStyles.push(style)
      },
      removeStyle(style) {
        arrayHelper.removeObjectByValue(this.newProductStyles, style.id, 'id')
      },
      addType(type) {
        this.newProductTypes.push(type)
      },
      removeType(type) {
        arrayHelper.removeObjectByValue(this.newProductTypes, type.id, 'id')
      },
      addMaterial(material) {
        this.newProductMaterials.push(material)
      },
      removeMaterial(type) {
        arrayHelper.removeObjectByValue(this.newProductMaterials, type.id, 'id')
      },
      appendAdditionalProperty(key, value) {
        this.$set(this.newProduct.additionalProperties, key, value)
      },
      removeAdditionalProperty(key) {
        // THIS MIGHT BE A HACK
        delete this.newProduct.additionalProperties[key]
        let copiedObject = Object.assign({}, this.newProduct.additionalProperties)
        this.$set(this.newProduct, 'additionalProperties', copiedObject)
      },
      addProductImage(imageType, file) {
        let newImage = {
          imageType:  imageType,
          file:  file,
          imageName:  file.name,
        }
        this.newProductImages.push(newImage)
      },
      removeProductImage(index) {
        arrayHelper.removeIndex(this.newProductImages, index)
      },
      submitForm() {
        this.isSavingProduct = true
        this.axios.post('/products', {
          product:            this.newProduct,
          productCategories:  this.newProductCategoryIds,
          productTypes:       this.newProductTypeIds,
          productStyles:      this.newProductStyleIds,
          productMaterials:   this.newProductMaterialIds,
        }).then(response => this.onProductCreated(response.data))
          .catch(error => this.onProductCreatedFailure(error.response))
      },
      //////////// CALLBACKS ////////////
      onBrandsRetrieved(data) {
        this.brands = data.brands
      },
      onManufacturersRetrieved(data) {
        this.manufacturers = data.manufacturers
      },
      onProductCreated(data) {
        if (this.newProductImages.length > 0) {
          this.newProduct.id = data.product.id
          this.uploadNewProductImages(data)
        }
        this.uploadRecommendation(data)

        EventBus.$emit('globalAlert', {
          message: 'Successfully created Product!',
          statusCode: 1,
        })
        this.$router.push({ name: 'Product', params: { productId: data.product.id } })
      },
      onProductCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating product'
          this.errorMessages = JSON.stringify(response)
        }

        this.isSavingProduct = false
      },
      onRecommendationRetrieved(data) {
        // this is a simplification (that we're only expecting a single
        // recommendation per product)
        this.recommendation = data.recommendation
      },
      uploadNewProductImages(data) {
        _.each(this.newProductImages, (image) => {
          apiHelper.submitMultipartForm(
            image, // params
            `/products/${data.product.id}/product_images`, // endpoint
            this.onProductImageAdded, // onSuccess
            this.onProductImageAddedFailure, // onFailure
          )
        })
      },
      uploadRecommendation(data) {
        if (!this.recommendation.recommend) {
          return
        }
        this.axios.post('/recommendations', {
          recommendation: {
            productId:          data.product.id,
            ranking:            this.recommendation.ranking,
            exclusiveForType:   !!this.recommendation.exclusiveForType,
          },
        }).then(response => this.onRecommendationRetrieved(response.data))
      },
      onProductImageAdded(_data, newImage) {
        arrayHelper.removeObjectByValue(this.newProductImages, newImage.imageName, 'imageName')
        this.isSavingProduct = false

        if (this.newProductImages.length === 0) {
          EventBus.$emit('globalAlert', {
            message: 'Successfully created product!',
            statusCode: 1,
          })
          this.$router.push({ name: 'Product', params: { productId: this.newProduct.id } })
        }
      },
      onProductImageAddedFailure(_error) {
        this.productImageUploadError = true
        this.isSavingProduct = false

        EventBus.$emit('globalAlert', {
          message: 'Product was created, but some images may have failed to upload!',
          statusCode: 2,
          linger: true,
        })

        this.$router.push({ name: 'Product', params: { productId: this.newProduct.id } })
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewProduct() {
        return {
          name:                 '',
          identifier:           '',
          brandId:              '',
          manufacturerId:       '',
          description:          '',
          internalNotes:        '',
          secondaryInfo:        '',
          materialsString:      '',
          additionalProperties: {},
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

.create-product {

  margin-bottom:                  5em;

  .create-form {

    .manufacturer-select, .brand-select {
      width: 20em;
    }

    > section {
      padding:                    1em;

      > h2 {
        margin:                   0.5em 0;
        width:                    100%;
      }
    }

    .product-details {

      input, textarea {
        display:                  block;
      }

      textarea {
        width:                    20em;
      }

      .simple-dropdown {
        display:                  block;
        margin:                   0.5em 0;
      }
    }

  }
}

</style>
