var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-product"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Edit: " + _vm._s(_vm.product.name))]), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isSavingProduct
    },
    on: {
      "click": function click($event) {
        return _vm.backToProductPage();
      }
    }
  }, [_vm._v(" Go Back ")])]), _vm.isLoadingProduct ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "content gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "product-details gs-special-content row"
  }, [_vm.productBrandIsSet ? _c('EnableDisableButton', {
    attrs: {
      "object-to-watch": _vm.product,
      "fields-to-observe": ['name', 'identifier', 'brand', 'manufacturer', 'description', 'internalNotes', 'secondaryInfo', 'materialsString'],
      "disable-button-computed": _vm.isSavingProduct,
      "button-text": "Save"
    },
    on: {
      "buttonClick": function buttonClick($event) {
        return _vm.updateCurrentProduct();
      }
    }
  }) : _c('button', {
    staticClass: "gs-standard-button"
  }, [_c('LoadingSpinner')], 1), _c('div', {
    staticClass: "col-md-6"
  }, [_c('h2', [_vm._v("Product Details")]), _c('div', {
    staticClass: "detail"
  }, [_c('label', [_vm._v("Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.product.name,
      expression: "product.name"
    }],
    attrs: {
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.product.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.product, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "detail"
  }, [_c('label', [_vm._v("Identifier:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.product.identifier,
      expression: "product.identifier"
    }],
    attrs: {
      "placeholder": "Identifier"
    },
    domProps: {
      "value": _vm.product.identifier
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.product, "identifier", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "detail"
  }, [_c('label', [_vm._v("Brand:")]), _c('div', {
    staticClass: "input-group mb-3 brand-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectBrand($event);
      }
    }
  }, [_c('option', [_vm._v(" Please select a brand.. ")]), _vm._l(_vm.brands, function (option) {
    return _c('option', {
      key: option.id,
      domProps: {
        "selected": _vm.product.brand.id == option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "detail"
  }, [_c('label', [_vm._v("Manufacturer:")]), _c('div', {
    staticClass: "input-group mb-3 manufacturer-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectManufacturer($event);
      }
    }
  }, [_c('option', [_vm._v(" Please select a manufacturer.. ")]), _vm._l(_vm.manufacturers, function (option) {
    return _c('option', {
      key: option.id,
      domProps: {
        "selected": _vm.product.manufacturer.id == option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "detail"
  }, [_c('label', [_vm._v("Materials Description:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.product.materialsString,
      expression: "product.materialsString"
    }],
    attrs: {
      "placeholder": "Materials"
    },
    domProps: {
      "value": _vm.product.materialsString
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.product, "materialsString", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "detail"
  }, [_c('label', [_vm._v("Internal Notes:")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.product.internalNotes,
      expression: "product.internalNotes"
    }],
    attrs: {
      "placeholder": "Internal Notes"
    },
    domProps: {
      "value": _vm.product.internalNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.product, "internalNotes", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "detail"
  }, [_c('label', [_vm._v("Description:")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.product.description,
      expression: "product.description"
    }],
    attrs: {
      "placeholder": "Description"
    },
    domProps: {
      "value": _vm.product.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.product, "description", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "detail"
  }, [_c('label', [_vm._v("Behind the Design:")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.product.secondaryInfo,
      expression: "product.secondaryInfo"
    }],
    attrs: {
      "placeholder": "Behind the Design"
    },
    domProps: {
      "value": _vm.product.secondaryInfo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.product, "secondaryInfo", $event.target.value);
      }
    }
  })])])], 1), _c('section', {
    staticClass: "product-associations"
  }, [_c('h3', [_vm._v("Associations")]), _c('ProductAssociationManager', {
    attrs: {
      "selected-categories": _vm.currentProductCategories,
      "selected-styles": _vm.currentProductStyles,
      "selected-types": _vm.currentProductTypes,
      "selected-materials": _vm.currentProductMaterials
    },
    on: {
      "addCategory": _vm.addCategory,
      "removeCategory": _vm.removeCategory,
      "addStyle": _vm.addStyle,
      "removeStyle": _vm.removeStyle,
      "addType": _vm.addType,
      "removeType": _vm.removeType,
      "addMaterial": _vm.addMaterial,
      "removeMaterial": _vm.removeMaterial
    }
  })], 1), _c('section', {
    staticClass: "attributes"
  }, [_c('h3', [_vm._v("Defined Attributes")]), _c('ProductAttributeManager', {
    attrs: {
      "product-id": _vm.product.id
    }
  })], 1), _c('section', {
    staticClass: "additional-properties"
  }, [_c('h3', [_vm._v("Additional Properties")]), _c('ProductAdditionalPropertiesManager', {
    attrs: {
      "additional-properties": _vm.product.additionalProperties
    },
    on: {
      "addAdditionalProperty": _vm.addProductAdditionalProperty,
      "removeAdditionalProperty": _vm.removeAdditionalProperty
    }
  })], 1), _c('section', {
    staticClass: "product-images"
  }, [_c('h3', [_vm._v("Images")]), _vm.imagePrimaryErrorMessage || _vm.imageErrorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.imagePrimaryErrorMessage,
      "error-messages": _vm.imageErrorMessages
    }
  }) : _vm._e(), _c('ProductImageManager', {
    attrs: {
      "product-images": _vm.currentProductImages,
      "is-saving-image": _vm.isAddingProductImage,
      "removing-image-index": _vm.removingProductIndex
    },
    on: {
      "addProductImage": _vm.addProductImage,
      "removeProductImage": _vm.removeProductImage
    }
  })], 1), _c('section', {
    staticClass: "recommendations"
  }, [_c('h3', [_vm._v("Recommendations")]), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Recommend?")]), _c('toggle-button', {
    attrs: {
      "value": _vm.recommendation.recommend,
      "sync": true,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.recommendation.recommend = !_vm.recommendation.recommend;
      }
    }
  })], 1), _vm.recommendation.recommend ? _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.recommendation.ranking,
      expression: "recommendation.ranking"
    }],
    attrs: {
      "placeholder": "Recommendation rank (optional)"
    },
    domProps: {
      "value": _vm.recommendation.ranking
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.recommendation, "ranking", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Recommendation exclusive to product types?")]), _c('toggle-button', {
    attrs: {
      "value": _vm.recommendation.exclusiveForType,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.recommendation.exclusiveForType = !_vm.recommendation.exclusiveForType;
      }
    }
  })], 1)]) : _vm._e(), _c('button', {
    staticClass: "gs-standard-button",
    on: {
      "click": _vm.uploadRecommendation
    }
  }, [_vm._v(" Save (or delete) recommendation ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }