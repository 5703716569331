<template>
    <div class="current-configuration">
        <div class="configuration">
            <div class="configuration-description">
                Max hours per truck
            </div>
            <div class="configuration-value">
                {{ getFormattedTime(selectedRegion.availableMinutesPerTruck) }}
            </div>
        </div>
        <div class="configuration">
            <div class="configuration-description">
                Number of trucks
            </div>
            <div class="configuration-value">
                {{ selectedRegion.numberOfTrucks }}
            </div>
        </div>
        <div class="configuration">
            <div class="configuration-description">
                Max cubic feet per truck
            </div>
            <div class="configuration-value">
                {{ selectedRegion.totalTruckCuft }} cuft
            </div>
        </div>
        <div class="configuration">
            <div class="configuration-description">
                Avg. driving time b/w stops
            </div>
            <div class="configuration-value">
                {{ getFormattedTime(selectedRegion.drivingTimeMinutes) }}
            </div>
        </div>
        <div class="configuration">
            <div class="configuration-description">
                Buffer Time Per Truck
            </div>
            <div class="configuration-value">
                {{ getFormattedTime(selectedRegion.bufferTimeMinutes) }}
            </div>
        </div>
    </div>
</template>

<script>
  import { timeInHours, timeInMinutes } from '@/helpers/utility'
  export default {
    name: 'Configuration',
    props: {
      selectedRegion: {
        type: Object,
        default: null,
      },
    },
    methods: {
      getFormattedTime(time) {
        const hours = timeInHours(time)
        const minutes = timeInMinutes(time)

        return `${hours} ${hours > 1 ? 'hours' : 'hour'} ${
          minutes > 0 ? `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}` : ''
        }`
      },
    },
  }
</script>

<style lang="scss" scoped>
  .current-configuration {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    background: #f8f8f8;
    padding-left: 16px;
    padding-top: 16px;
    padding-right: 16px;

    .configuration {
      display: flex;
      font-size: 16px;
      margin-bottom: 16px;
      justify-content: space-between;
      .configuration-description {
        font-weight: bold;
      }
    }
  }
</style>
