var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "create-product"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Create Product")]), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "type": "submit",
      "disabled": _vm.isSavingProduct
    },
    on: {
      "click": function click($event) {
        return _vm.submitForm();
      }
    }
  }, [!_vm.isSavingProduct ? _c('span', [_vm._v("Create")]) : _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.5"
    }
  })], 1)]), _c('div', {
    staticClass: "create-form gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "product-details"
  }, [_c('h2', [_vm._v("Product Details")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProduct.name,
      expression: "newProduct.name"
    }],
    attrs: {
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.newProduct.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProduct, "name", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProduct.identifier,
      expression: "newProduct.identifier"
    }],
    attrs: {
      "placeholder": "Identifier"
    },
    domProps: {
      "value": _vm.newProduct.identifier
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProduct, "identifier", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "input-group mb-3 brand-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectBrand($event);
      }
    }
  }, [_c('option', [_vm._v(" Please select a brand.. ")]), _vm._l(_vm.brands, function (option) {
    return _c('option', {
      key: option.id
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)]), _c('div', {
    staticClass: "input-group mb-3 manufacturer-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectManufacturer($event);
      }
    }
  }, [_c('option', [_vm._v(" Please select a manufacturer.. ")]), _vm._l(_vm.manufacturers, function (option) {
    return _c('option', {
      key: option.id
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProduct.description,
      expression: "newProduct.description"
    }],
    attrs: {
      "placeholder": "Description"
    },
    domProps: {
      "value": _vm.newProduct.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProduct, "description", $event.target.value);
      }
    }
  }), _vm._v(" "), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProduct.secondaryInfo,
      expression: "newProduct.secondaryInfo"
    }],
    attrs: {
      "placeholder": "Behind The Design"
    },
    domProps: {
      "value": _vm.newProduct.secondaryInfo
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProduct, "secondaryInfo", $event.target.value);
      }
    }
  }), _vm._v(" "), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProduct.internalNotes,
      expression: "newProduct.internalNotes"
    }],
    attrs: {
      "placeholder": "Internal Notes"
    },
    domProps: {
      "value": _vm.newProduct.internalNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProduct, "internalNotes", $event.target.value);
      }
    }
  }), _vm._v(" "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProduct.materialsString,
      expression: "newProduct.materialsString"
    }],
    attrs: {
      "placeholder": "Materials Description"
    },
    domProps: {
      "value": _vm.newProduct.materialsString
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProduct, "materialsString", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Recommend?")]), _c('toggle-button', {
    attrs: {
      "value": _vm.recommendation.recommend,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.recommendation.recommend = !_vm.recommendation.recommend;
      }
    }
  })], 1), _vm.recommendation.recommend ? _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.recommendation.ranking,
      expression: "recommendation.ranking"
    }],
    attrs: {
      "placeholder": "Recommendation rank (optional)"
    },
    domProps: {
      "value": _vm.recommendation.ranking
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.recommendation, "ranking", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Recommendation exclusive to product types?")]), _c('toggle-button', {
    attrs: {
      "value": _vm.recommendation.exclusiveForType,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.recommendation.exclusiveForType = !_vm.recommendation.exclusiveForType;
      }
    }
  })], 1)]) : _vm._e()]), _c('section', {
    staticClass: "associations"
  }, [_c('h2', [_vm._v("Associations")]), _c('ProductAssociationManager', {
    attrs: {
      "selected-categories": _vm.newProductCategories,
      "selected-styles": _vm.newProductStyles,
      "selected-types": _vm.newProductTypes,
      "selected-materials": _vm.newProductMaterials
    },
    on: {
      "addCategory": _vm.addCategory,
      "removeCategory": _vm.removeCategory,
      "addStyle": _vm.addStyle,
      "removeStyle": _vm.removeStyle,
      "addType": _vm.addType,
      "removeType": _vm.removeType,
      "addMaterial": _vm.addMaterial,
      "removeMaterial": _vm.removeMaterial
    }
  })], 1), _c('section', {
    staticClass: "additional-properties"
  }, [_c('h2', [_vm._v("Additional Properties")]), _c('ProductAdditionalPropertiesManager', {
    attrs: {
      "additional-properties": _vm.newProduct.additionalProperties
    },
    on: {
      "addAdditionalProperty": _vm.appendAdditionalProperty,
      "removeAdditionalProperty": _vm.removeAdditionalProperty
    }
  })], 1), _c('section', {
    staticClass: "product-images"
  }, [_c('h2', [_vm._v("Product Images")]), _c('ProductImageManager', {
    attrs: {
      "product-images": _vm.newProductImages
    },
    on: {
      "addProductImage": _vm.addProductImage,
      "removeProductImage": _vm.removeProductImage
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }