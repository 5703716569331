<template>
    <div class="customer-notes">
        <div class="header">
            <h3>Customer Notes</h3>
        </div>
        <div class="body">
            <div class="internal-notes">
                <label>Quick Notes:</label>
                {{ internalNotes }}
            </div>

            <div class="internal-notes">
                <label>Journey Notes:</label>
                {{ journeyNote }}
            </div>

            <div v-if="journeyIssues.length"
                 class="past-journey-issues">
                <label>Past Journey Issues:</label>

                <div v-for="issue in journeyIssues"
                     :key="issue.id">
                    {{ getIssueText(issue) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

  export default {
    name: 'CustomerNotesModal',
    props: {
      journeyId: {
        type: String,
        default: '',
      },
      journeyNote: {
        type: String,
        default: '',
      },
      customerNotes: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      internalNotes() {
        return _.get(this.customerNotes, 'internalNotes')
      },
      journeyIssues() {
        const allIssues = _.get(this.customerNotes, 'journeyIssues')
        return _.filter(allIssues, (issue) => _.get(issue, 'journey.id') != this.journeyId)
      },
    },
    methods: {
      getIssueText(jitIssueObject) {
        const issueName = _.get(jitIssueObject, 'issue', '').split('_').join(' ')
        const extraNote = _.get(jitIssueObject, 'note')

        let text = issueName
        if(extraNote) {
          text = `${text} (${extraNote})`
        }
        return text
      },
    },
  }
</script>

<style lang="scss" scoped>
  .customer-notes {
    .body {
      padding: 1em 0;

      label {
        font-weight: bold;
      }

      > div {
        margin-bottom: 2em;
      }

      .past-journey-issues div {
        margin-bottom: 1em;
      }
    }
  }
</style>
