var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "jit-issue"
  }, [_c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('h4', {
    staticClass: "h4-emphasized"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('hr')]), _c('div', {
    staticClass: "item-info"
  }, [_c('table', {
    staticClass: "table"
  }, [_c('tbody', [_c('tr', [_c('td', [_c('cld-image', {
    staticClass: "jit-photo",
    attrs: {
      "public-id": _vm.jitObject.publicId,
      "width": "75",
      "height": "75",
      "crop": "limit"
    }
  })], 1), _c('td', [_vm._v(" " + _vm._s(_vm.jitObject.name) + " – Condition: " + _vm._s(_vm.jitObject.itemState) + " ")]), _c('td', [_vm._v(" " + _vm._s(_vm.jitObject.location) + " ")]), _c('td', [_vm._v(" " + _vm._s("Ref #: ".concat(_vm.jitObject.tripId)) + " ")])])])])]), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.attemptSubmit.apply(null, arguments);
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "issue-select"
  }, [_c('span', [_vm._v("Item Issue Type")]), _c('div', {
    staticClass: "input-group mb-3 issue-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectIssue($event);
      }
    }
  }, [_c('option', [_vm._v("Select issue")]), _vm._l(_vm._.keys(_vm.questionSet), function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "selected": _vm.primaryIssue == option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  })], 2)])]), typeof _vm.questionSet[_vm.primaryIssue] !== 'string' ? _c('div', {
    staticClass: "issue-select"
  }, [_c('span', [_vm._v("More specific")]), _c('div', {
    staticClass: "input-group mb-3 issue-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectSecondIssue($event);
      }
    }
  }, [_c('option', [_vm._v("Select issue")]), _vm._l(_vm._.keys(_vm.questionSet[_vm.primaryIssue]), function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "selected": _vm.secondaryIssue == option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  })], 2)])]) : _vm._e(), _vm.existingIssueString ? _c('div', {
    staticClass: "issue-select"
  }, [_c('span', [_vm._v("Existing Issue Type:")]), _vm._v(" " + _vm._s(_vm.existingIssueString) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "issue-notes"
  }, [_c('span', [_vm._v("Notes")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.issueNote,
      expression: "issueNote"
    }],
    attrs: {
      "type": "text",
      "name": "issue-notes",
      "placeholder": "(extra details)"
    },
    domProps: {
      "value": _vm.issueNote
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.issueNote = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "submit"
  }, [_c('button', {
    attrs: {
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm._v(" " + _vm._s(_vm.existingIssueString ? 'Edit' : 'Submit') + " ")])])]), _c('div', {
    staticClass: "delete"
  }, [_c('button', {
    attrs: {
      "type": "submit",
      "disabled": !_vm.existingIssue
    },
    on: {
      "click": _vm.deleteIssue
    }
  }, [_vm._v(" Delete issue ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }