<template>
    <div class="inline-message"
         :class="['status-' + statusCode]">
        <div>{{ message }}</div>
    </div>
</template>

<script>
  export default {
    name: 'InlineMessage',
    props: {
      message: {
        type: String,
        default: '',
      },
      statusCode: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style lang="scss" scoped>

.inline-message {

  position:                   relative;
  padding:                    .75rem 1.25rem;
  margin-bottom:              1rem;
  border-radius:              .25rem;
  background-color:           white;
  color:                      black;
  text-align:                 center;

  &.status-3 {
    color:                    $danger-primary;
    background-color:         $danger-background;
    border:                   1px solid $danger-border;
  }

  // .status-icon {
  //   display:                  block;
  //
  //   > img {
  //     width:                  2em;
  //     height:                 2em;
  //     margin-bottom:          1em;
  //   }
  // }

}

</style>
