<template>
    <div class="simple-create-remove-list">
        <div class="value-zone">
            <span v-for="(value, index) in values"
                  :key="index"
                  class="value">
                <span class="value-name">
                    {{ value }}
                </span>
                <i class="fa fa-close change-value"
                   @click="valueRemoved(value, index)" />
            </span>
        </div>

        <div class="add-value">
            <input v-model="newValue" />
            <i class="fa fa-plus change-value"
               @click="addValue(newValue)" />
        </div>
    </div>
</template>

<script>
  export default {
    name: 'SimpleCreateRemoveList',
    props: {
      values: {
        type: Array,
        default: () => [],
      },
      allowDuplicates: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        newValue:         '',
      }
    },
    methods: {
      valueRemoved(value, index) {
        this.$emit('valueRemoved', value, this.values, index)
      },
      addValue(value) {
        this.$emit('valueAdded', String(value), this.values)
        this.newValue = ''
      },
    },
  }

</script>

<style lang="scss" scoped>

  .simple-add-remove-list {

    .value-zone {
      border:                           1px solid lightgray;
      padding:                          1em;
      min-height:                       5em;
      background-color:                 #fff;
      -moz-box-shadow:                  inset 0 0 4px lightgray;
      -webkit-box-shadow:               inset 0 0 4px lightgray;
      box-shadow:                       inset 0 0 4px lightgray;

      .value {
        padding:                        0.25em 0.4em;
        border:                         1px solid lightgray;
        margin-right:                   0.5em;
        margin-bottom:                  0.5em;
        display:                        inline-block;
        font-size:                      0.9em;

        &:hover {
          opacity:                      0.8;
        }

        .value-name {
          margin-right:                 0.25em;
        }

        .change-value {
          cursor:                       pointer;
        }
      }
    }

    .no-selected-values {
      color:                            gray;
    }

  }

</style>
