<template>
    <div class="brand gs-simple-crud">
        <h1>Brands</h1>

        <SimpleCreateForm
            :primary-error-message="primaryErrorMessage"
            :error-messages="errorMessages"
            :show-loading-state="isCreatingBrand"
            :new-model-instance="newBrand"
            @formSubmit="createBrand" />

        <div v-if="isLoadingBrands">
            <LoadingSpinner is-black="true" />
        </div>


        <div v-else
             class="content">
            <section class="brand-table gs-standard-table">
                <v-client-table
                    v-if="brands.length > 0"
                    :data="brands"
                    :columns="columns"
                    :options="options">
                    <div slot="editName"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row['name']" />
                        <span v-else>{{ data.row['name'] }}</span>
                    </div>

                    <div slot="edit"
                         slot-scope="data"
                         class="gs-standard-table-edit">
                        <TableEditButton
                            :is-editing-this-item="isEditingThisItem(data.row.id)"
                            :show-loading-state="isEditingThisItem(data.row.id) && isSavingThisItem(data.row.id)"
                            :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                            @saveButtonClick="updateBrand(data.row)"
                            @editButtonClick="currentEditBrandId = data.row.id" />
                    </div>

                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteBrand(data.row)" />
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no brands to display
                </div>
            </section>

            <ConfirmModal
                v-if="showConfirmDeleteModal"
                :show-loading-state="isDeletingBrand"
                @cancel="showConfirmDeleteModal = false"
                @confirm="deleteBrand()">
                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingBrand.name }}</b>? This will also destroy any associations with this brand!</span>
            </ConfirmModal>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import EventBus from '@/components/utilities/EventBus'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'Brands',
    components: {
      LoadingSpinner,
      SimpleCreateForm,
      ConfirmModal,
      DeleteIcon,
      TableEditButton,
    },
    data() {
      return {
        isLoadingBrands:          false,
        errorMessages:            [],
        primaryErrorMessage:      '',
        brands:                   [],
        newBrand:                 this.initializeBrand(),
        isCreatingBrand:          false,
        currentEditBrandId:       null,
        currentSavingBrandId:     null,
        currentDeletingBrand:     null,
        isDeletingBrand:          false,
        showConfirmDeleteModal:   false,
        columns:                  ['id', 'editName', 'identifier', 'productCount', 'edit', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'editName': 'Name',
            'productCount': 'Product Count',
          },
        },
      }
    },
    mounted() {
      this.getBrands()
    },
    methods: {
      //////////// TEMPLATE ACTIONS ////////////
      createBrand() {
        this.isCreatingBrand = true
        this.axios.post('/brands', { brand: this.newBrand })
          .then(response => this.onBrandCreated(response.data))
          .catch(error => this.onBrandCreatedFailure(error.response))
      },
      updateBrand(brand) {
        this.currentSavingBrandId = brand.id
        this.axios.patch('/brands/' + brand.id, { brand: brand })
          .then(response => this.onBrandUpdated(response.data))
          .catch(error => this.onBrandUpdatedFailure(error.response))
      },
      setCurrentDeleteBrand(brand) {
        this.showConfirmDeleteModal = true
        this.currentDeletingBrand = brand
      },
      //////////// CALLBACKS ////////////
      onBrandsRetrieved(data) {
        this.isLoadingBrands = false
        this.brands = data.brands
      },
      onBrandCreated(data) {
        this.isCreatingBrand = false
        this.brands.push(data.brand)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created brand!',
          statusCode: 1,
        })
        this.newBrand = this.initializeBrand()
      },
      onBrandCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating brand'
          this.errorMessages = JSON.stringify(response)
        }

        this.isCreatingBrand = false
      },
      onBrandUpdated(data) {
        arrayHelper.replaceObjectByValue(this.brands, data.brand, 'id')
        this.currentEditBrandId = null
        this.currentSavingBrandId = null
      },
      onBrandUpdatedFailure(response) {
        if (response.data.error) {
          EventBus.$emit('globalAlert', {
            message: response.data.messages[0],
            statusCode: 3,
          })
        } else {
          this.primaryErrorMessage = 'Unknown error updating brand'
          this.errorMessages = JSON.stringify(response)
        }

        this.currentSavingBrandId = null
      },
      onBrandDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingBrand = null

        arrayHelper.removeObjectByValue(this.brands, data.brand.id, 'id')
        EventBus.$emit('globalAlert', {
          message: 'Brand successfully deleted!',
          statusCode: 1,
        })
      },
      onBrandDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error deleting brand'
          this.errorMessages = JSON.stringify(response)
        }

        this.isDeletingBrand = false
      },
      onBrandDeleteDone() {
        this.isDeletingBrand = false
      },
      //////////// INTERNAL METHODS ////////////
      getBrands() {
        this.isLoadingBrands = true
        this.axios.get('/brands')
          .then(response => this.onBrandsRetrieved(response.data))
      },
      initializeBrand() {
        return {
          name:       '',
        }
      },
      isSavingThisItem(id) {
        return this.currentSavingBrandId === id
      },
      isEditingThisItem(id) {
        return this.currentEditBrandId === id
      },
      deleteBrand() {
        this.isDeletingBrand = true
        this.axios.delete('/brands/' + this.currentDeletingBrand.id)
          .then(response => this.onBrandDeleted(response.data))
          .catch(error => this.onBrandDeletedFailure(error.response))
          .finally(() => this.onBrandDeleteDone())
      },
    },
  }
</script>

<style lang="scss" scoped>

.brand {

  .content {
    margin-top:                   1em;
  }
}

</style>
