var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "curated-set"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Curated Set")]), _c('router-link', {
    staticClass: "gs-standard-button",
    attrs: {
      "to": {
        name: 'EditCuratedSet',
        params: {
          curatedSetId: _vm.$route.params.curatedSetId
        }
      }
    }
  }, [_vm._v(" Edit ")])], 1), _vm.isLoadingCuratedSet ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "gs-standard-content"
  }, [_c('section', {
    staticClass: "row curated-set-details"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" General Info ")]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Name:")]), _c('span', [_vm._v(_vm._s(_vm.curatedSet.name))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Identifier:")]), _c('span', [_vm._v(_vm._s(_vm.curatedSet.identifier))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Category:")]), _c('span', [_vm._v(" " + _vm._s(_vm._.get(_vm.curatedSet.productCategory, 'name', 'None')) + " ")])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Is Active:")]), _c('span', [_vm._v(_vm._s(_vm.curatedSet.isActive || 'false'))])])])]), _c('section', {
    staticClass: "curated-set-images"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Set Image ")]), _vm.curatedSet.heroImage ? _c('div', {
    staticClass: "row image-list"
  }, [_c('div', {
    staticClass: "image col-md-2"
  }, [_c('cld-image', {
    attrs: {
      "public-id": _vm.curatedSet.heroImage,
      "width": "800",
      "height": "800",
      "crop": "limit"
    }
  })], 1)]) : _c('div', [_c('span', {
    staticClass: "no-items"
  }, [_vm._v("No curated set image")])])]), _c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h3', [_vm._v("Product Skus")])]), _vm.isLoadingCuratedSetProductSkus ? _c('section', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('section', {
    staticClass: "curated-set-products-table gs-hide-filter"
  }, [_vm.curatedSetProductSkus.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.curatedSetProductSkus,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "productLink",
      fn: function fn(data) {
        return _c('div', {}, [_c('router-link', {
          attrs: {
            "to": {
              name: 'ProductSku',
              params: {
                productSkuId: data.row['id']
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.row.name) + " ")])], 1);
      }
    }, {
      key: "productType",
      fn: function fn(data) {
        return _c('div', {}, [_vm._v(" " + _vm._s(data.row.productTypes[0].name) + " ")]);
      }
    }], null, false, 3814741069)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no product skus for this curated set ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }