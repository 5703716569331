var render = function () {
  var _vm$existingIssues;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "journey-issue"
  }, [_c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "title"
  }, [_c('h4', {
    staticClass: "h4-emphasized capitalized"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('hr')]), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.attemptSubmit.apply(null, arguments);
      }
    }
  }, [(_vm$existingIssues = _vm.existingIssues) !== null && _vm$existingIssues !== void 0 && _vm$existingIssues.length ? _c('div', {
    staticClass: "issue-select"
  }, [_c('span', {
    staticClass: "capitalized"
  }, [_vm._v("Existing " + _vm._s(_vm.tripPurpose) + " Trip Issues:")]), _c('ul', {
    attrs: {
      "id": "existingIssueList"
    }
  }, _vm._l(_vm.existingIssues, function (issue) {
    var _issue$issue, _issue$issue$split, _issue$issue$split$jo;

    return _c('li', {
      key: issue.id
    }, [_c('form', {
      on: {
        "submit": function submit($event) {
          $event.preventDefault();
        }
      }
    }, [_c('div', [_c('DeleteIcon', {
      on: {
        "delete": function _delete($event) {
          return _vm.deleteIssue(issue);
        }
      }
    }), _vm._v(" " + _vm._s((_issue$issue = issue.issue) === null || _issue$issue === void 0 ? void 0 : (_issue$issue$split = _issue$issue.split('_')) === null || _issue$issue$split === void 0 ? void 0 : (_issue$issue$split$jo = _issue$issue$split.join(' ')) === null || _issue$issue$split$jo === void 0 ? void 0 : _issue$issue$split$jo.trim()) + " "), issue.note ? _c('span', [_vm._v(" (" + _vm._s(issue.note.trim()) + ") ")]) : _vm._e()], 1)])]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "issue-select"
  }, [_c('span', {
    staticClass: "capitalized"
  }, [_vm._v(_vm._s(_vm.tripPurpose) + " Trip Issue Type")]), _c('div', {
    staticClass: "input-group mb-3 issue-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectIssue($event);
      }
    }
  }, [_c('option', [_vm._v("Select issue")]), _vm._l(_vm._.keys(_vm.issueOptions), function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "selected": _vm.selectedIssue == option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "issue-notes"
  }, [_c('span', [_vm._v("Notes")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.issueNote,
      expression: "issueNote"
    }],
    attrs: {
      "type": "text",
      "name": "issue-notes",
      "placeholder": "(extra details)"
    },
    domProps: {
      "value": _vm.issueNote
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.issueNote = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "submit"
  }, [_c('button', {
    attrs: {
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm._v(" Submit ")])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }