var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "banner gs-simple-crud"
  }, [_c('h1', [_vm._v("Marketing Banner")]), _c('SimpleCreateForm', {
    attrs: {
      "primary-error-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages,
      "show-loading-state": _vm.isCreatingBanner,
      "is-valid": _vm.isValid,
      "new-model-instance": _vm.newBanner
    },
    on: {
      "formSubmit": _vm.createBanner
    },
    scopedSlots: _vm._u([{
      key: "bannerEndDate",
      fn: function fn() {
        return [_c('datepicker', {
          attrs: {
            "format": _vm.formatDate
          },
          model: {
            value: _vm.newBanner.bannerEndDate,
            callback: function callback($$v) {
              _vm.$set(_vm.newBanner, "bannerEndDate", $$v);
            },
            expression: "newBanner.bannerEndDate"
          }
        })];
      },
      proxy: true
    }, {
      key: "regionName",
      fn: function fn() {
        return [_c('SimpleArrayDropdown', {
          attrs: {
            "items": _vm.regionNames,
            "selected-item": _vm.newBanner.regionName,
            "prompt": "All regions"
          },
          on: {
            "itemSelected": _vm.regionSelected
          }
        })];
      },
      proxy: true
    }, {
      key: "categoryName",
      fn: function fn() {
        return [_c('SimpleArrayDropdown', {
          attrs: {
            "items": _vm.categoryNames,
            "selected-item": _vm.newBanner.categoryName,
            "prompt": "Choose Category"
          },
          on: {
            "itemSelected": _vm.categorySelected
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "content"
  }, [_c('section', {
    staticClass: "banner-table gs-standard-table"
  }, [_vm.banners.length > 0 ? _c('v-client-table', {
    staticClass: "client-table",
    attrs: {
      "data": _vm.banners,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "categoryName",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('SimpleArrayDropdown', {
          attrs: {
            "items": _vm.categoryNames,
            "selected-item": data.row.bannerCategory ? data.row.bannerCategory.name : '',
            "prompt": "Choose Category"
          },
          on: {
            "itemSelected": _vm.categorySelectedUpdate
          }
        }) : data.row.bannerCategory ? _c('span', [_vm._v(_vm._s(data.row.bannerCategory.name))]) : _c('span', [_vm._v("No Category")])], 1);
      }
    }, {
      key: "text",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.text,
            expression: "data.row.text"
          }],
          domProps: {
            "value": data.row.text
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, "text", $event.target.value);
            }
          }
        }) : _c('span', [_vm._v(_vm._s(data.row.text))])]);
      }
    }, {
      key: "mobileText",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.mobileText,
            expression: "data.row.mobileText"
          }],
          domProps: {
            "value": data.row.mobileText
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, "mobileText", $event.target.value);
            }
          }
        }) : _c('span', [_vm._v(_vm._s(data.row.mobileText))])]);
      }
    }, {
      key: "link",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.link,
            expression: "data.row.link"
          }],
          domProps: {
            "value": data.row.link
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, "link", $event.target.value);
            }
          }
        }) : _c('span', [_c('a', {
          attrs: {
            "href": _vm.getBannerLink(data.row),
            "target": "_blank"
          }
        }, [_vm._v(" " + _vm._s(data.row.link) + " ")])])]);
      }
    }, {
      key: "cta",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.cta,
            expression: "data.row.cta"
          }],
          domProps: {
            "value": data.row.cta
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, "cta", $event.target.value);
            }
          }
        }) : _c('span', [_vm._v(_vm._s(data.row.cta))])]);
      }
    }, {
      key: "bannerEndDate",
      fn: function fn(data) {
        return _c('div', {
          class: {
            'edit': _vm.isEditingThisItem(data.row.id)
          }
        }, [_vm.isEditingThisItem(data.row.id) ? _c('datepicker', {
          attrs: {
            "format": _vm.formatDate
          },
          model: {
            value: data.row.bannerEndDate,
            callback: function callback($$v) {
              _vm.$set(data.row, "bannerEndDate", $$v);
            },
            expression: "data.row.bannerEndDate"
          }
        }) : _c('span', [_vm._v(_vm._s(_vm.formatDate(data.row.bannerEndDate)))])], 1);
      }
    }, {
      key: "active",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.active,
            expression: "data.row.active"
          }],
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(data.row.active) ? _vm._i(data.row.active, null) > -1 : data.row.active
          },
          on: {
            "change": function change($event) {
              var $$a = data.row.active,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false;

              if (Array.isArray($$a)) {
                var $$v = null,
                    $$i = _vm._i($$a, $$v);

                if ($$el.checked) {
                  $$i < 0 && _vm.$set(data.row, "active", $$a.concat([$$v]));
                } else {
                  $$i > -1 && _vm.$set(data.row, "active", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.$set(data.row, "active", $$c);
              }
            }
          }
        }) : _c('span', [_vm._v(_vm._s(data.row.active))])]);
      }
    }, {
      key: "regionName",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('SimpleArrayDropdown', {
          attrs: {
            "items": _vm.regionNames,
            "selected-item": data.row.region ? data.row.region.name : '',
            "prompt": "All regions"
          },
          on: {
            "itemSelected": _vm.regionSelectedUpdate
          }
        }) : data.row.region ? _c('span', [_vm._v(_vm._s(data.row.region.name))]) : _c('span', [_vm._v("All Regions")])], 1);
      }
    }, {
      key: "edit",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-edit"
        }, [_c('TableEditButton', {
          attrs: {
            "is-editing-this-item": _vm.isEditingThisItem(data.row.id),
            "show-loading-state": _vm.isEditingThisItem(data.row.id) && _vm.isSavingThisItem(data.row.id),
            "show-save-button": _vm.isEditingThisItem(data.row.id) && !_vm.isSavingThisItem(data.row.id)
          },
          on: {
            "saveButtonClick": function saveButtonClick($event) {
              return _vm.updateBanner(data.row);
            },
            "editButtonClick": function editButtonClick($event) {
              _vm.currentEditBannerId = data.row.id;
            }
          }
        })], 1);
      }
    }, {
      key: "delete",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-delete"
        }, [_c('DeleteIcon', {
          on: {
            "delete": function _delete($event) {
              return _vm.setCurrentDeleteBanner(data.row);
            }
          }
        })], 1);
      }
    }], null, false, 2960496782)
  }) : _c('div', [_c('h2', [_vm._v("No Banners Exist")])])], 1), _vm.showConfirmDeleteModal ? _c('ConfirmModal', {
    attrs: {
      "show-loading-state": _vm.isDeletingBanner
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showConfirmDeleteModal = false;
      },
      "confirm": function confirm($event) {
        return _vm.deleteBanner();
      }
    }
  }, [_c('span', {
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_vm._v("Are you sure you want to delete this banner?")])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }