<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper"
                 @click="closeModal">
                <div class="modal-container">
                    <div class="body">
                        <div class="type-select-container">
                            <h3 style="margin-bottom:10px;">
                                {{ categoryName }}
                            </h3>
                            <h4 style="margin-bottom:10px;">
                                Product Types
                            </h4>
                            <v-select :value="selected"
                                      label="name"
                                      :options="allProductTypesWithAccent"
                                      multiple
                                      placeholder="Select Type"
                                      @input="setSelected" />
                            <button style="margin-top:20px;"
                                    class="gs-standard-button"
                                    @click="onAdd">
                                Add
                            </button>
                            <button class="gs-standard-button clear"
                                    @click="onClear()">
                                Clear Selection
                            </button>
                        </div>
                        <div class="gs-toggle-container"
                             style="margin-top:20px;">
                            <label class="tag"
                                   style="margin-right:20px;">Show Accents: </label>
                            <toggle-button :value="accent"
                                           color="#82C7EB"
                                           @change="accent = !accent" />
                        </div>
                        <div class="type-container">
                            <div class="core-type">
                                <h4 style="margin-bottom:10px;">
                                    Core Types
                                </h4>
                                <draggable v-model="currentCoreTypes"
                                           @update="onUpdate">
                                    <transition-group>
                                        <div v-for="element in currentCoreTypes"
                                             :key="element.id">
                                            <div class="product-type-boxes">
                                                <div class="product-type-name">
                                                    {{ element.name }}
                                                </div>
                                                <button class="product-delete-button gs-standard-button"
                                                        @click="onDelete('core',element.id)">
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </transition-group>
                                </draggable>
                            </div>
                            <div class="accent-type">
                                <h4 style="margin-bottom:10px;">
                                    Accent Types
                                </h4>
                                <draggable v-model="currentAccentTypes"
                                           @update="onUpdate">
                                    <transition-group>
                                        <div v-for="element in currentAccentTypes"
                                             :key="element.id">
                                            <div class="product-type-boxes">
                                                <div class="product-type-name">
                                                    {{ element.name }}
                                                </div>
                                                <button class="product-delete-button gs-standard-button"
                                                        @click="onDelete('accent',element.id)">
                                                    Delete
                                                </button>
                                            </div>
                                        </div>
                                    </transition-group>
                                </draggable>
                            </div>
                        </div>
                    </div>

                    <div class="footer tablet-hide">
                        <slot name="footer">
                            <button :disabled="showLoadingState"
                                    class="gs-standard-button cancel"
                                    @click="onCancelClick()">
                                Cancel
                            </button>
                            <button :disabled="showLoadingState"
                                    class="gs-standard-button confirm"
                                    @click="onConfirm()">
                                <LoadingSpinner v-if="showLoadingState"
                                                max-height="1" />
                                <span v-else-if="showSuccess">Success</span>
                                <span v-else-if="showFailure">Failed</span>
                                <span v-else>Confirm</span>
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import draggable from 'vuedraggable'


  export default {
    name: 'EditTypesModal',
    components: {
      LoadingSpinner,
      draggable,
    },
    props: {
      allProductTypes: {
        type: Array,
        default: () => [],
      },
      currentProductTypes: {
        type: Array,
        default: () => [],
      },
      hideEditTypesModal: {
        type: Function,
        default: () => {},
      },
      accentTypeId: {
        type: Number,
        default: null,
      },
      categoryName: {
        type: String,
        default: '',
      },
      categoryId: {
        type: Number,
        default: null,
      },
      hasAccent: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        showLoadingState: false,
        showSuccess: false,
        showFailure: false,
        selected: null,
        candidateTypes: this.currentProductTypes,
        drag: false,
        currentCoreTypes: this.currentProductTypes.filter(productType => Number(productType.parentTypeId) !== this.accentTypeId),
        currentAccentTypes: this.currentProductTypes.filter(productType => productType.parentTypeId === this.accentTypeId),
        accent: this.hasAccent,
      }
    },
    computed: {
      allProductTypesWithAccent() {
        return this.allProductTypes.map(productType => ({
          ...productType,
          name: `${productType.name}${productType.parentTypeId === this.accentTypeId ? ' (Accent)' : ''}`,
        }))
      },
    },
    methods: {
      closeModal(event) {
        // Checks to see if the user has clicked outside of the modal
        if (!event.target.closest('.modal-container')) this.$emit('cancel')
      },
      onCancelClick() {
        this.hideEditTypesModal()
      },
      setSelected(value) {
        this.selected = value
      },
      onClear() {
        this.selected = null
      },
      onAdd() {
        const selectedTypes = this.selected || []
        const selectedCoreTypes = selectedTypes.filter(productType => productType.parentTypeId !== this.accentTypeId)
        const selectedAccentTypes = selectedTypes.filter(productType => productType.parentTypeId === this.accentTypeId)
        this.currentCoreTypes = [...this.currentCoreTypes, ...selectedCoreTypes]
        this.currentAccentTypes = [...this.currentAccentTypes, ...selectedAccentTypes]
        this.candidateTypes = [...this.currentCoreTypes, ...this.currentAccentTypes]
        this.selected = null
      },
      onDelete(type, id) {
        if (type === 'core') {
          this.currentCoreTypes = this.currentCoreTypes.filter(type => type.id !== id)
        } else {
          this.currentAccentTypes = this.currentAccentTypes.filter(type => type.id !== id)
        }
        this.candidateTypes = [...this.currentCoreTypes, ...this.currentAccentTypes]
      },
      onConfirm() {
        this.showLoadingState = true
        this.axios.patch('/product_categories/' + this.categoryId, { productCategory: {
          relevantProductTypes: this.candidateTypes.map(candidateType => candidateType.id),
          hasAccent: this.accent,
        } }).then(() => {
          this.showLoadingState = false
          this.showSuccess = true
          setTimeout(() => {
            this.showSuccess = false
          }, 2000)
        })
          .catch(() => {
            this.showLoadingState = false
            this.showFailure = false
          })
      },
      onUpdate() {
        this.candidateTypes = [...this.currentCoreTypes, ...this.currentAccentTypes]
      },
    },
  }
</script>

<style lang="scss" scoped>


.modal-mask {
  position:                           fixed;
  z-index:                            999999;
  top:                                0;
  left:                               0;
  width:                              100%;
  height:                             100%;
  background-color:                   rgba(0, 0, 0, .5);
  display:                            table;
  transition:                         opacity .3s ease;

  .v-select {
      width: 40vw;
  }

  .clear {
            background:               #fff;
            border:                   1px solid #000000;
            color:                    #000000;
            margin-left: 20px;
  }

  .add {
      height: 30px;
  }

  .modal-wrapper {
    display:                          table-cell;
    vertical-align:                   middle;

    .modal-container {
      width:                          50%;
      margin:                         0 auto;
      padding:                        2em;
      background-color:               #fff;
      border-radius:                  1em;
      box-shadow:                     0 2px 8px rgba(0, 0, 0, .33);
      transition:                     all .3s ease;
      position:                       relative;

      .body {
        margin:                       20px 0;
        display: flex;
        height: 80vh;
        overflow: scroll;
        flex-direction: column;
        .type-container {
            margin-top: 20px;
            display: flex;
            direction: row;
            width: 100%;
            justify-content: space-between;
            .product-type-boxes {
                border: 1px solid  #000000;
                padding: 8px;
                margin: 8px 8px 8px 0px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            .core-type {
                display: flex;
                flex-direction: column;
                width: 45%;
            }
            .accent-type {
                display: flex;
                flex-direction: column;
                width: 45%;
            }
        }
      }

      .footer {
        display:                      block;

        .gs-standard-button {

          &.cancel {
            background:               #fff;
            border:                   1px solid #000000;
            color:                    #000000;
          }

          &.confirm {
            float:                    right;
          }
        }
      }
    }

  }
}


</style>
