<template>
    <div class="contact">
        <div class="header">
            <h3 class="emphasized">
                {{ customerName }}
            </h3>
        </div>
        <div class="body">
            <div class="contact-info">
                <div class="header">
                    Address
                </div>
                <div>
                    {{ customerAddress }}
                </div>
            </div>
            <div class="contact-info">
                <div class="header">
                    Email
                </div>
                <div>
                    {{ customerEmail }}
                </div>
            </div>
            <div class="contact-info">
                <div class="header">
                    Phone
                </div>
                <div>
                    {{ customerPhone }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'ContactModal',
    props: {
      customerName: {
        type: String,
        required: true,
      },
      customerAddress: {
        type: String,
        required: true,
      },
      customerEmail: {
        type: String,
        required: true,
      },
      customerPhone: {
        type: String,
        required: true,
      },
    },
  }
</script>

<style lang="scss" scoped>
  .contact {
    .body {
      padding: 1em 0;

      label {
        font-weight: bold;
      }
      .contact-info {
        margin-bottom: 2em;
        .header {
          font-weight: bold;
        }
      }

      > div {
        margin-bottom: 2em;
      }
    }
  }
</style>
