var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-product-sku"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Edit Product Sku: " + _vm._s(_vm.productSku.sku))]), _c('router-link', {
    attrs: {
      "to": {
        name: 'ProductSku',
        params: {
          productSkuId: _vm.$route.params.productSkuId
        }
      }
    }
  }, [_c('button', {
    staticClass: "gs-standard-button"
  }, [_vm._v(" Go Back ")])])], 1), _vm.isLoadingProductSku ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "content gs-standard-form"
  }, [_vm.isLoadingProduct ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('section', {
    staticClass: "parent-product-section"
  }, [_c('h3', {
    staticClass: "row"
  }, [_vm._v(" Parent Product ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Name:")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Product',
        params: {
          productId: _vm.product.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " ")])], 1), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Identifier:")]), _c('span', [_vm._v(_vm._s(_vm.product.identifier))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Brand:")]), _vm.product.brand ? _c('span', [_vm._v(_vm._s(_vm.product.brand.name))]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-6 additional-properties"
  }, [_c('h5', [_vm._v("Additional Properties:")]), _vm.productAdditionalPropertiesExist ? _c('div', {
    staticClass: "property-list"
  }, _vm._l(_vm.product.additionalProperties, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "property"
    }, [_vm._v(" " + _vm._s(key) + ": "), _c('b', [_vm._v(_vm._s(value))])]);
  }), 0) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" No Additional Properties ")])])])]), _c('div', {
    staticClass: "create-form gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "product-sku-details gs-standard-form"
  }, [_c('div', {
    staticClass: "gs-generic-header row"
  }, [_c('h2', [_vm._v("SKU Details")]), _c('EnableDisableButton', {
    attrs: {
      "object-to-watch": _vm.productSku,
      "observe-entire-object": true,
      "disable-button-computed": _vm.isUpdatingProductSku,
      "button-text": "Update"
    },
    on: {
      "buttonClick": _vm.updateProductSku
    }
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("SKU")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.sku,
      expression: "productSku.sku"
    }],
    domProps: {
      "value": _vm.productSku.sku
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "sku", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("UPC")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.upc,
      expression: "productSku.upc"
    }],
    domProps: {
      "value": _vm.productSku.upc
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "upc", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Sunset")]), _c('toggle-button', {
    attrs: {
      "value": _vm.productSku.opsDetails.sunset,
      "color": "#82C7EB"
    },
    model: {
      value: _vm.productSku.opsDetails.sunset,
      callback: function callback($$v) {
        _vm.$set(_vm.productSku.opsDetails, "sunset", $$v);
      },
      expression: "productSku.opsDetails.sunset"
    }
  })], 1)]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Retail Price ($)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.retailPriceDollars,
      expression: "productSku.retailPriceDollars"
    }],
    domProps: {
      "value": _vm.productSku.retailPriceDollars
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "retailPriceDollars", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Base Price ($)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.basePriceDollars,
      expression: "productSku.basePriceDollars"
    }],
    domProps: {
      "value": _vm.productSku.basePriceDollars
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "basePriceDollars", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Lease Override Multiplier")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.leasePercentageOverride,
      expression: "productSku.leasePercentageOverride"
    }],
    domProps: {
      "value": _vm.productSku.leasePercentageOverride
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "leasePercentageOverride", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Weight (lbs)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.weightInPounds,
      expression: "productSku.weightInPounds"
    }],
    domProps: {
      "value": _vm.productSku.weightInPounds
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "weightInPounds", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Length (in.)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.lengthInInches,
      expression: "productSku.lengthInInches"
    }],
    domProps: {
      "value": _vm.productSku.lengthInInches
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "lengthInInches", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Height (in.)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.heightInInches,
      expression: "productSku.heightInInches"
    }],
    domProps: {
      "value": _vm.productSku.heightInInches
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "heightInInches", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Width (in.)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.widthInInches,
      expression: "productSku.widthInInches"
    }],
    domProps: {
      "value": _vm.productSku.widthInInches
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "widthInInches", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Internal Notes")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productSku.internalNotes,
      expression: "productSku.internalNotes"
    }],
    domProps: {
      "value": _vm.productSku.internalNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.productSku, "internalNotes", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Is Active?")]), _c('toggle-button', {
    attrs: {
      "value": _vm.productSku.isActive,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.productSku.isActive = !_vm.productSku.isActive;
      }
    }
  })], 1)])])]), _c('section', {
    staticClass: "row warhouse-packets"
  }, [_c('h2', [_vm._v("Warehouse Packets")]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "new-packet"
  }, [_c('h3', [_vm._v("Create")]), _c('form', {
    staticClass: "gs-standard-form",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.createWarehousePacket.apply(null, arguments);
      }
    }
  }, [_c('label', [_vm._v("New Packet Sku:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newPacketSku,
      expression: "newPacketSku"
    }],
    attrs: {
      "type": "text",
      "name": "packetSku"
    },
    domProps: {
      "value": _vm.newPacketSku
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.newPacketSku = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "type": "submit",
      "disabled": _vm.isCreatingWarehousePacket
    }
  }, [_c('span', [_vm._v("Create")])])])]), _vm.productSku.warehousePackets ? _c('v-client-table', {
    attrs: {
      "data": _vm.productSku.warehousePackets,
      "columns": ['packetSku', 'sunsetDate', 'action'],
      "options": {
        filterable: false
      }
    },
    scopedSlots: _vm._u([{
      key: "editName",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row['name'],
            expression: "data.row['name']"
          }],
          domProps: {
            "value": data.row['name']
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, 'name', $event.target.value);
            }
          }
        }) : _c('span', [_vm._v(_vm._s(data.row['name']))])]);
      }
    }, {
      key: "action",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-edit"
        }, [!data.row.sunsetDate ? _c('button', {
          staticClass: "gs-standard-button",
          on: {
            "click": function click($event) {
              return _vm.sunsetWarhousePacket(data.row.id);
            }
          }
        }, [_vm._v(" Mark as sunset ")]) : _vm._e()]);
      }
    }], null, false, 2214480271)
  }) : _vm._e()], 1)]), _c('section', {
    staticClass: "row variations"
  }, [_c('h2', [_vm._v("Variations")]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('ProductSkuVariationsManager', {
    attrs: {
      "product-sku-variations": _vm.productSkuVariations
    },
    on: {
      "addVariation": _vm.addProductSkuVariation,
      "removeVariation": _vm.removeProductSkuVariation
    }
  })], 1)]), _vm.isLoadingProductSkuImages ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('section', {
    staticClass: "product-images"
  }, [_c('h2', [_vm._v("Product Images")]), _vm.imagePrimaryErrorMessage || _vm.imageErrorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.imagePrimaryErrorMessage,
      "error-messages": _vm.imageErrorMessages
    }
  }) : _vm._e(), _c('ProductImageManager', {
    attrs: {
      "product-images": _vm.productSkuImages,
      "is-saving-image": _vm.isAddingProductSkuImage,
      "removing-image-index": _vm.removingProductSkuImageIndex
    },
    on: {
      "addProductImage": _vm.addProductSkuImage,
      "removeProductImage": _vm.removeProductSkuImage
    }
  })], 1), _c('section', {
    staticClass: "ops-details"
  }, [_c('h2', [_vm._v("Ops Details")]), _c('vue-json-editor', {
    attrs: {
      "expanded-on-start": true
    },
    model: {
      value: _vm.productSku.opsDetails,
      callback: function callback($$v) {
        _vm.$set(_vm.productSku, "opsDetails", $$v);
      },
      expression: "productSku.opsDetails"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }