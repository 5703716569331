import axios from 'axios'
import { stringHelper } from '@petra-living/petra-vue-common'
import EventBus from '@/components/utilities/EventBus'

// Set in .env.production and .env.staging
const API_URL = `${process.env.VUE_APP_API_HOST}/admin/api`

axios.defaults.xsrfCookieName = 'CSRF-TOKEN'
axios.defaults.xsrfHeaderName = 'X-CSRF-Token'

let axiosObj = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  crossDomain: true,
  withCredentials: true,
})

axiosObj.interceptors.request.use((request) => {
  if (request.headers['Content-Type'] === 'multipart/form-data') return request

  if (request.data) request.data = stringHelper.unCamelizeObject(request.data)
  if (request.params) request.params = stringHelper.unCamelizeObject(request.params)

  return request
})

axiosObj.interceptors.response.use(response => {
  if (response.data) response.data = stringHelper.camelizeObject(response.data)
  return response
}, error => {
  // clear any open modals
  EventBus.$emit('clearModals')

  let statusCode
  let statusMessage = 'Unknown error'
  let redirectUser = false

  // If there is a response, determine how to act
  if (error.response) {
    // We want to first handle errors for expired tokens. Let the user know and redirect them to login.
    if (error.response.data && error.response.data.error === 'INVALID_TOKEN') {
      statusMessage = 'Your session has expired. Please log in again'
      redirectUser = true
      this.$store.dispatch('logout')
    } else if (error.response.status === 401) {
      // Then we want to bypass showing error message for incorrect login. Login component will do this.
      return Promise.reject(error)
    } else if (error.response.data && error.response.data.error) {
      // Relay information from the API
      statusCode = `(${error.response.status}) ${error.response.data.error}`
      statusMessage = error.response.data.message
    } else {
      // Otherwise who knows....
      statusCode = error.response.status
      statusMessage = error.response.statusText
    }
  } else if (error.message) {
    // If there is no response it is likely a network connection issue.
    statusMessage = error.message
    redirectUser = true
  }

  EventBus.$emit('globalError', {
    statusMessage:  statusMessage,
    statusCode:     statusCode,
    redirectUser:   redirectUser,
  })

  return Promise.reject(error)
})

export default axiosObj
