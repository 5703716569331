var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-curated-set"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Edit Curated Set")]), _c('button', {
    staticClass: "gs-standard-button"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'CuratedSet',
        params: {
          curatedSetId: _vm.$route.params.curatedSetId
        }
      }
    }
  }, [_vm._v(" Go Back ")])], 1)]), _vm.isLoadingCuratedSet ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "content gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "curated-set-details gs-special-content"
  }, [_c('div', {
    staticClass: "gs-generic-header"
  }, [_c('h2', [_vm._v("Curated Set Details")]), _vm.curatedSet ? _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isUpdatingCuratedSet
    },
    on: {
      "click": function click($event) {
        return _vm.updateCuratedSet();
      }
    }
  }, [_vm._v(" Update ")]) : _c('button', {
    staticClass: "gs-standard-button"
  }, [_c('LoadingSpinner')], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.curatedSet.name,
      expression: "curatedSet.name"
    }],
    domProps: {
      "value": _vm.curatedSet.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.curatedSet, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Identifier")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.curatedSet.identifier,
      expression: "curatedSet.identifier"
    }],
    domProps: {
      "value": _vm.curatedSet.identifier
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.curatedSet, "identifier", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Category")]), _c('div', {
    staticClass: "input-group mb-3 category-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectProductCategory($event);
      }
    }
  }, [_c('option', [_vm._v(" Please select a category... ")]), _vm._l(_vm.allProductCategories, function (option) {
    return _c('option', {
      key: option.id,
      domProps: {
        "selected": _vm.curatedSet.productCategory.id == option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Is Active?")]), _c('toggle-button', {
    attrs: {
      "value": _vm.curatedSet.isActive,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.curatedSet.isActive = !_vm.curatedSet.isActive;
      }
    }
  })], 1)])])]), _c('section', {
    staticClass: "row products"
  }, [_c('h3', [_vm._v("Product Skus")]), _c('div', {
    staticClass: "col-md-6"
  }, [_vm.isLoadingCuratedSetProductSkus ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('SimpleAddRemoveList', {
    attrs: {
      "items": _vm.allProductSkus,
      "selected-items": _vm.curatedSetProductSkus,
      "allow-duplicates": true,
      "display-key": "name",
      "match-key": "id",
      "item-type": "product sku"
    },
    on: {
      "itemAdded": _vm.addProductSku,
      "itemRemoved": _vm.removeProductSku
    }
  })], 1)]), _c('section', {
    staticClass: "product-images"
  }, [_c('h3', [_vm._v("Curated Set Image")]), _vm.curatedSet.heroImage && !_vm.isUpdatingCuratedSet ? _c('div', [_c('cld-image', {
    attrs: {
      "public-id": _vm.curatedSet.heroImage,
      "height": "200"
    }
  }), _c('button', {
    staticClass: "gs-standard-button gs-delete-button",
    on: {
      "click": _vm.removeCuratedSetImage
    }
  }, [_vm._v(" Remove ")])], 1) : !_vm.isUpdatingCuratedSet ? _c('input', {
    ref: "hidden-uploader",
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "name": "curated-set-image",
      "accept": "image/*"
    },
    on: {
      "change": function change($event) {
        return _vm.onImageSelect($event.target.name, $event.target.files);
      }
    }
  }) : _c('h5', [_vm._v(" Uploading... ")])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }