<template>
    <div class="create-product-sku">
        <section class="gs-generic-header">
            <h1>Create Product SKU</h1>
            <button type="submit"
                    :disabled="isSavingProductSku"
                    class="gs-standard-button"
                    @click="createProductSku">
                <span v-if="!isSavingProductSku">Create</span>
                <LoadingSpinner v-else
                                max-height="1.5" />
            </button>
        </section>

        <section class="parent-product gs-standard-form">
            <label>Parent Product:</label>
            <div v-if="parentProductExists"
                 class="edit-parent-product">
                {{ parentProduct.name }}
                <i class="fa fa-close remove-item"
                   @click="removeParentProduct" />
            </div>
            <SimpleTypeahead
                v-else
                :items="products"
                display-key="name"
                input-placeholder="Search products..."
                typeahead-width="20em"
                @itemClicked="selectParentProduct" />
        </section>

        <div class="create-form gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />

            <section class="row product-sku-details">
                <h2>SKU Details</h2>

                <div class="col-md-3">
                    <label>SKU</label>
                    <input v-model="newProductSku.sku" />

                    <label>UPC</label>
                    <input v-model="newProductSku.upc" />

                    <label>Sunset</label>
                    <toggle-button
                        :value="newProductSku.opsDetails.sunset"
                        color="#82C7EB"
                        @change="newProductSku.opsDetails.sunset = !newProductSku.opsDetails.sunset" />
                </div>
                <div class="col-md-3">
                    <label>Retail Price ($)</label>
                    <input v-model="newProductSku.retailPriceDollars" />

                    <label>Base Price ($)</label>
                    <input v-model="newProductSku.basePriceDollars" />

                    <label>Lease Override Multiplier</label>
                    <input v-model="newProductSku.leasePercentageOverride" />
                </div>
                <div class="col-md-3">
                    <label>Weight (lbs)</label>
                    <input v-model="newProductSku.weightInPounds" />

                    <label>Length (in.)</label>
                    <input v-model="newProductSku.lengthInInches" />

                    <label>Height (in.)</label>
                    <input v-model="newProductSku.heightInInches" />

                    <label>Width (in.)</label>
                    <input v-model="newProductSku.widthInInches" />
                </div>
                <div class="col-md-3">
                    <label>Internal Notes</label>
                    <textarea v-model="newProductSku.internalNotes" />

                    <div class="gs-toggle-container">
                        <label class="tag">Is Active?</label>
                        <toggle-button :value="newProductSku.isActive"
                                       color="#82C7EB"
                                       @change="newProductSku.isActive = !newProductSku.isActive" />
                    </div>
                </div>
            </section>

            <section class="row variations">
                <h2>Variations</h2>

                <div class="col-md-4">
                    <ProductSkuVariationsManager
                        :product-sku-variations="newProductSkuVariations"
                        @addVariation="addProductSkuVariation"
                        @removeVariation="removeProductSkuVariation" />
                </div>
            </section>

            <section class="product-images">
                <h2>Product SKU Images</h2>

                <ProductImageManager
                    :product-images="newProductSkuImages"
                    @addProductImage="addProductSkuImage"
                    @removeProductImage="removeProductSkuImage" />
            </section>

            <section class="ops-details">
                <h2>Ops Details</h2>

                <vue-json-editor v-model="newProductSku.opsDetails"
                                 :expanded-on-start="true" />
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'
  import SimpleTypeahead from '@/components/forms/SimpleTypeahead'
  import ProductSkuVariationsManager from '@/components/resources/product-skus/ProductSkuVariationsManager'
  import ProductImageManager from '@/components/resources/products/ProductImageManager'
  import { arrayHelper } from '@petra-living/petra-vue-common'
  import apiHelper from '@/helpers/apiHelper'
  import VueJsonEditor from 'vue-json-editor'

  export default {
    name: 'CreateProductSku',
    components: {
      VueJsonEditor,
      LoadingSpinner,
      SimpleTypeahead,
      ProductSkuVariationsManager,
      ProductImageManager,
      FormErrors,
    },
    data() {
      return {
        primaryErrorMessage:          '',
        errorMessages:                [],
        products:                     [],
        parentProduct:                {},
        isSavingProductSku:           false,
        newProductSku:                this.initializeNewProductSku(),
        newProductSkuVariations:      [],
        newProductSkuImages:          [],
        productSkuImageUploadError:   false,
      }
    },
    computed: {
      parentProductExists() {
        return Object.keys(this.parentProduct).length > 0
      },
    },
    mounted() {
      this.getProducts()
    },
    methods: {
      getProducts() {
        this.axios.get('/products')
          .then(response => this.onProductsRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      createProductSku() {
        this.primaryErrorMessage = ''
        this.errorMessages = ''
        this.isSavingProductSku = true
        this.axios.post('/product_skus', {
          productSku:           this.newProductSku,
          productSkuVariations: this.newProductSkuVariations,
        })
          .then(response => this.onProductSkuCreated(response.data))
          .catch(error => this.onProductSkuCreatedFailure(error.response))
      },
      selectParentProduct(product) {
        this.parentProduct = product
        this.newProductSku.productId = product.id
      },
      removeParentProduct() {
        this.parentProduct = {}
        this.newProductSku.productId = null
      },
      addProductSkuVariation(variationType, value, sortOrder) {
        const productSkuVariation = { variationType: variationType, value: value, sortOrder: sortOrder }
        this.newProductSkuVariations.push(productSkuVariation)
      },
      removeProductSkuVariation(variation) {
        arrayHelper.removeObjectByValue(this.newProductSkuVariations, variation.variationType, 'variationType')
      },
      addProductSkuImage(imageType, file) {
        let newImage = {
          imageType: imageType,
          file: file,
          imageName: file.name,
        }
        this.newProductSkuImages.push(newImage)
      },
      removeProductSkuImage(index) {
        arrayHelper.removeIndex(this.newProductSkuImages, index)
      },
      //////////// CALLBACKS ////////////
      onProductsRetrieved(data) {
        this.products = data.products
        this.setParentProductFromParams()
      },
      onProductSkuCreated(data) {
        if (this.newProductSkuImages.length > 0) {
          this.newProductSku.id = data.productSku.id
          this.uploadNewProductSkuImages(data)
        } else {
          EventBus.$emit('globalAlert', {
            message: 'Successfully created Product Sku!',
            statusCode: 1,
          })
          this.$router.push({ name: 'ProductSku', params: { productSkuId: data.productSku.id } })
        }
      },
      onProductSkuCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating product sku'
          this.errorMessages = JSON.stringify(response)
        }

        this.isSavingProductSku = false
      },
      uploadNewProductSkuImages(data) {
        _.each(this.newProductSkuImages, (image) => {
          apiHelper.submitMultipartForm(
            image, // params
            `/product_skus/${data.productSku.id}/product_images`, // endpoint
            this.onProductSkuImageAdded, // onSuccess
            this.onProductSkuImageAddedFailure, // onFailure
          )
        })
      },
      onProductSkuImageAdded(_data, newImage) {
        arrayHelper.removeObjectByValue(this.newProductSkuImages, newImage.imageName, 'imageName')

        if (this.newProductSkuImages.length === 0) {
          this.isSavingProductSku = false
          EventBus.$emit('globalAlert', {
            message: 'Successfully created product sku!',
            statusCode: 1,
          })
          this.$router.push({ name: 'ProductSku', params: { productSkuId: this.newProductSku.id } })
        }
      },
      onProductSkuImageAddedFailure(_error) {
        this.isSavingProductSku = false
        this.productSkuImageUploadError = true

        EventBus.$emit('globalAlert', {
          message: 'Product sku was created, but some images may have failed to upload!',
          statusCode: 2,
          linger: true,
        })

        this.$router.push({ name: 'ProductSku', params: { productSkuId: this.newProductSku.id } })
      },
      customFormatter(date) {
        return this.$moment(date).format('YYYY-MM-DD')
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewProductSku() {
        return {
          productId:                null,
          sku:                      '',
          upc:                      '',
          retailPriceDollars:       0,
          basePriceDollars:         0,
          weightInPounds:           0,
          lengthInInches:           0,
          widthInInches:            0,
          heightInInches:           0,
          isActive:                 false,
          internalNotes:            '',
          currentInventory:         0,
          leasePercentageOverride:  1,
          opsDetails:                   {
            sunset: false,
            numberOfPackets: 1,
            unitsPerPacket: 1,
            packetDimensions: [{ length: 0, width: 0, height: 0 }],
          },
        }
      },
      setParentProductFromParams() {
        let productId = this.$route.query.productId
        if (!productId) return

        let product = arrayHelper.findObjectByValue(this.products, parseInt(productId), 'id')
        this.selectParentProduct(product)
      },
    },
  }
</script>

<style lang="scss" scoped>

.create-product-sku {

  margin-bottom:                      5em;

  .parent-product {

    padding:                          1em;

    > label {
      display:                        inline-block;
      margin-right:                   1em;
      font-weight:                    bold;
    }

    .edit-parent-product {
      position:                       relative;
      padding:                        0.5em 4em 0.5em 1em;
      display:                        inline-block;
      border:                         1px solid lightgray;
      border-radius:                  0.5em;

      > i {
        position:                     absolute;
        cursor:                       pointer;
        padding:                      0.5em;
        right:                        0.5em;
        top:                          0.25em;
      }
    }
  }

  .create-form {

    > section {
      padding:                        1em;

      > h2 {
        margin:                       0.5em 0;
        width:                        100%;
      }
    }

    .product-sku-details {

      label {
        margin-bottom:                0;
      }

      input {
        margin-top:                   0;
        display:                      block;
      }

      textarea {
        height:                       9em;
      }
    }
  }
}

</style>
