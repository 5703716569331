import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store.js'


import Home from '@/views/Home'
import Login from '@/views/Login'
import UserAccount from '@/views/UserAccount'

import Banners from '@/views/Banners'
import BlackoutDates from '@/views/BlackoutDates'

import Brands from '@/views/product-attributes/Brands'
import DiscountsList from '@/views/discounts/DiscountsList'
import Discount from '@/views/discounts/Discount'
import CreateDiscount from '@/views/discounts/CreateDiscount'
import EditDiscount from '@/views/discounts/EditDiscount'
import ProductAttributeInstances from '@/views/product-attributes/ProductAttributeInstances'
import ProductAttributes from '@/views/product-attributes/ProductAttributes'
import ProductMaterials from '@/views/product-attributes/ProductMaterials'
import ProductStyles from '@/views/product-attributes/ProductStyles'
import ProductCategories from '@/views/product-attributes/ProductCategories'
import ProductTypes from '@/views/product-attributes/ProductTypes'
import ProductColors from '@/views/product-attributes/ProductColors'
import Manufacturers from '@/views/manufacturers/Manufacturers'
import Tags from '@/views/tags/Tags'

import ProductSkus from '@/views/product-skus/ProductSkus'
import ProductSku from '@/views/product-skus/ProductSku'
import CreateProductSku from '@/views/product-skus/CreateProductSku'
import EditProductSku from '@/views/product-skus/EditProductSku'
import ProductSkusList from '@/views/product-skus/product-skus/ProductSkusList'
import ProductSkusSkuless from '@/views/product-skus/product-skus/ProductSkusSkuless'

import BestSellers from '@/views/products/BestSellers'
import CreateProduct from '@/views/products/CreateProduct'
import Product from '@/views/products/Product'
import EditProduct from '@/views/products/EditProduct'

import UserList from '@/views/users/UserList'
import User from '@/views/users/User'
import EditUser from '@/views/users/EditUser'

import CuratedSetList from '@/views/curated-sets/CuratedSetList'
import CreateCuratedSet from '@/views/curated-sets/CreateCuratedSet'
import CuratedSet from '@/views/curated-sets/CuratedSet'
import EditCuratedSet from '@/views/curated-sets/EditCuratedSet'

import CreateLayout from '@/views/layouts/CreateLayout'
import Layout from '@/views/layouts/Layout'
import EditLayout from '@/views/layouts/EditLayout'

import SuggestionOverrideList from '@/views/suggestion-overrides/SuggestionOverrideList'
import CreateSuggestionOverride from './views/suggestion-overrides/CreateSuggestionOverride'
import SuggestionOverride from './views/suggestion-overrides/SuggestionOverride'
import EditSuggestionOverride from './views/suggestion-overrides/EditSuggestionOverride'

import JourneyList from '@/views/journeys/JourneyList'
import JourneyScheduling from '@/views/journeys/JourneyScheduling'
import Partnerships from '@/views/partnerships'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login,
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
    },
    {
      path: '/banners',
      name: 'Banners',
      component: Banners,
    },
    {
      path: '/blackout_dates',
      name: 'BlackoutDates',
      component: BlackoutDates,
    },
    {
      path: '/brands',
      name: 'Brands',
      component: Brands,
    },
    {
      path: '/discounts',
      name: 'DiscountsList',
      component: DiscountsList,
    },
    {
      path: '/discounts/new',
      name: 'CreateDiscount',
      component: CreateDiscount,
    },
    {
      path: '/discounts/:discountId',
      name: 'Discount',
      component: Discount,
    },
    {
      path: '/discounts/:discountId/edit',
      name: 'EditDiscount',
      component: EditDiscount,
    },
    {
      path: '/manufacturers',
      name: 'Manufacturers',
      component: Manufacturers,
    },
    {
      path: '/product_attribute_instances/:productAttributeId',
      name: 'ProductAttributeInstances',
      component: ProductAttributeInstances,
    },
    {
      path: '/product_attributes',
      name: 'ProductAttributes',
      component: ProductAttributes,
    },
    {
      path: '/product_materials',
      name: 'ProductMaterials',
      component: ProductMaterials,
    },
    {
      path: '/product_styles',
      name: 'ProductStyles',
      component: ProductStyles,
    },
    {
      path: '/product_categories',
      name: 'ProductCategories',
      component: ProductCategories,
    },
    {
      path: '/product_types',
      name: 'ProductTypes',
      component: ProductTypes,
    },
    {
      path: '/product_colors',
      name: 'ProductColors',
      component: ProductColors,
    },
    {
      path: '/tags',
      name: 'Tags',
      component: Tags,
    },
    {
      path: '/user_account',
      name: 'UserAccount',
      component: UserAccount,
    },
    {
      path: '/product_skus',
      name: 'ProductSkus',
      component: ProductSkus,
      children: [
        {
          path: 'list',
          name: 'ProductSkusList',
          component: ProductSkusList,
          meta: { skipScroll: true },
        },
        {
          path: 'skuless',
          name: 'ProductSkusSkuless',
          component: ProductSkusSkuless,
          meta: { skipScroll: true },
        },
      ],
    },
    {
      path: '/product_skus/new',
      name: 'CreateProductSku',
      component: CreateProductSku,
    },
    // NOTE
    // Paths to unique ids must come after /new if they are going to work
    // This design might need some re-thinking
    {
      path: '/product_skus/:productSkuId',
      name: 'ProductSku',
      component: ProductSku,
    },
    {
      path: '/product_skus/:productSkuId/edit',
      name: 'EditProductSku',
      component: EditProductSku,
    },
    {
      path: '/best_sellers',
      name: 'BestSellers',
      component: BestSellers,
    },
    {
      path: '/products/new',
      name: 'CreateProduct',
      component: CreateProduct,
    },
    {
      path: '/products/:productId',
      name: 'Product',
      component: Product,
    },
    {
      path: '/products/:productId/edit',
      name: 'EditProduct',
      component: EditProduct,
    },
    {
      path: '/users',
      name: 'UserList',
      component: UserList,
    },
    {
      path: '/users/:userId',
      name: 'User',
      component: User,
    },
    {
      path: '/users/:userId/edit',
      name: 'EditUser',
      component: EditUser,
    },
    {
      path: '/curated_sets',
      name: 'CuratedSetList',
      component: CuratedSetList,
    },
    {
      path: '/curated_sets/new',
      name: 'CreateCuratedSet',
      component: CreateCuratedSet,
    },
    {
      path: '/curated_sets/:curatedSetId',
      name: 'CuratedSet',
      component: CuratedSet,
    },
    {
      path: '/curated_sets/:curatedSetId/edit',
      name: 'EditCuratedSet',
      component: EditCuratedSet,
    },
    {
      path: '/layouts/new',
      name: 'CreateLayout',
      component: CreateLayout,
    },
    {
      path: '/layouts/:layoutId',
      name: 'Layout',
      component: Layout,
    },
    {
      path: '/layouts/:layoutId/edit',
      name: 'EditLayout',
      component: EditLayout,
    },
    {
      path: '/suggestion_overrides',
      name: 'SuggestionOverrideList',
      component: SuggestionOverrideList,
    },
    {
      path: '/suggestion_overrides/new',
      name: 'CreateSuggestionOverride',
      component: CreateSuggestionOverride,
    },
    {
      path: '/suggestion_overrides/:suggestionOverrideId',
      name: 'SuggestionOverride',
      component: SuggestionOverride,
    },
    {
      path: '/suggestion_overrides/:suggestionOverrideId/edit',
      name: 'EditSuggestionOverride',
      component: EditSuggestionOverride,
    },
    {
      path: '/journeys',
      name: 'JourneyList',
      component: JourneyList,
    },
    {
      path: '/scheduling',
      name: 'JourneyScheduling',
      component: JourneyScheduling,
    },
    {
      path: '/partnerships',
      name: 'Partnerships',
      component: Partnerships,
    },
  ],
  scrollBehavior(to, _from, _savedPosition) {
    if (to.meta && to.meta.skipScroll) return
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, _from, next) => {
  const currentUser = _.get(store.state, 'auth.user')
  const loggedIn = localStorage.getItem('loggedIn')

  if (loggedIn && !currentUser) {
    store.dispatch('setCurrentUser')
      .then(() => next())
      .catch(() => {
      // If there's an error retrieving/setting the user, redirect to login
      // #setCurrentUser will handle setting the currentUser to null :)
      if (to.path !== '/') {
        router.push({ name: 'Login' })
      }
    })
  } else {
    next()
  }
})

export default router
