import { render, staticRenderFns } from "./RegionConfiguration.vue?vue&type=template&id=0be49a34&scoped=true&"
import script from "./RegionConfiguration.vue?vue&type=script&lang=js&"
export * from "./RegionConfiguration.vue?vue&type=script&lang=js&"
import style1 from "./RegionConfiguration.vue?vue&type=style&index=1&id=0be49a34&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be49a34",
  null
  
)

export default component.exports