<template>
    <div class="configuration-form">
        <div class="configuration">
            <div class="configuration-field-description">
                Max hours per truck
            </div>
            <div class="configuration-select">
                <select v-model="availableTimePerTruckHours"
                        class="custom-select">
                    <option v-for="option in hours"
                            :key="option"
                            :selected="availableTimePerTruckHours == option">
                        {{ option }}
                    </option>
                </select>
                <span class="labels">
                    Hours
                </span>
                <select v-model="availableTimePerTruckMinutes"
                        class="custom-select">
                    <option v-for="option in minutes"
                            :key="option"
                            :selected="availableTimePerTruckMinutes == option">
                        {{ option }}
                    </option>
                </select>
                <span class="labels">
                    Minutes
                </span>
            </div>
        </div>
        <div class="configuration">
            <div class="configuration-field-description">
                Number of trucks
            </div>
            <div class="configuration-input">
                <input v-model="numberOfTrucks"
                       type="number" />
            </div>
        </div>
        <div class="configuration">
            <div class="configuration-field-description">
                Max cubic feet per truck
            </div>
            <div class="configuration-input">
                <input v-model="totalTruckCuft"
                       type="number" />
            </div>
        </div>
        <div class="configuration">
            <div class="configuration-field-description">
                Avg. driving time b/w stops
            </div>
            <div class="configuration-select">
                <select v-model="drivingTimeHours"
                        class="custom-select">
                    <option v-for="option in hours"
                            :key="option"
                            :selected="drivingTimeHours == option">
                        {{ option }}
                    </option>
                </select>
                <span class="labels">
                    Hours
                </span>
                <select v-model="drivingTimeMinutes"
                        class="custom-select">
                    <option v-for="option in minutes"
                            :key="option"
                            :selected="drivingTimeMinutes == option">
                        {{ option }}
                    </option>
                </select>
                <span class="labels">
                    Minutes
                </span>
            </div>
        </div>
        <div class="configuration">
            <div class="configuration-field-description">
                Buffer Time Per Truck
            </div>
            <div class="configuration-select">
                <select v-model="bufferTimeHours"
                        class="custom-select">
                    <option v-for="option in hours"
                            :key="option"
                            :selected="bufferTimeHours == option">
                        {{ option }}
                    </option>
                </select>
                <span class="labels">
                    Hours
                </span>
                <select v-model="bufferTimeMinutes"
                        class="custom-select">
                    <option v-for="option in minutes"
                            :key="option"
                            :selected="bufferTimeMinutes == option">
                        {{ option }}
                    </option>
                </select>
                <span class="labels">
                    Minutes
                </span>
            </div>
        </div>
        <span v-if="error">Update failed</span>
        <div class="actions">
            <button class="save-button"
                    @click="updateRegion">
                {{ saveButtonCopy }}
            </button>
            <button class="cancel-button"
                    @click="hideRegionConfiguration">
                CANCEL
            </button>
        </div>
    </div>
</template>

<script>
  import EventBus from '@/components/utilities/EventBus.vue'
  import { timeInHours, timeInMinutes, totalTimeInMinutes } from '@/helpers/utility'
  export default {
    name: 'ConfigurationForm',
    props: {
      selectedRegion: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        availableTimePerTruckHours: timeInHours(this.selectedRegion.availableMinutesPerTruck),
        availableTimePerTruckMinutes: timeInMinutes(this.selectedRegion.availableMinutesPerTruck),
        numberOfTrucks: this.selectedRegion.numberOfTrucks,
        totalTruckCuft: this.selectedRegion.totalTruckCuft,
        bufferTimeMinutes: timeInMinutes(this.selectedRegion.bufferTimeMinutes),
        bufferTimeHours: timeInHours(this.selectedRegion.bufferTimeMinutes),
        drivingTimeHours: timeInHours(this.selectedRegion.drivingTimeMinutes),
        drivingTimeMinutes: timeInMinutes(this.selectedRegion.drivingTimeMinutes),
        hours: Array.from(Array(10).keys()),
        minutes: Array.from(Array(12).keys()).map(x => x * 5),
        isSaving: false,
        error: false,
        success: false,
      }
    },
    computed: {
      saveButtonCopy() {
        if (this.isSaving) {
          return 'SAVING'
        }

        if (this.success) {
          return 'SAVED'
        }
        return 'SAVE'
      },
    },
    watch: {
      selectedRegion: function(val) {
        this.availableTimePerTruckHours = timeInHours(val.availableMinutesPerTruck)
        this.availableTimePerTruckMinutes = timeInMinutes(val.availableMinutesPerTruck)
        this.numberOfTrucks = val.numberOfTrucks
        this.totalTruckCuft = val.totalTruckCuft
        this.bufferTimeMinutes = timeInMinutes(val.bufferTimeMinutes)
        this.bufferTimeHours = timeInHours(val.bufferTimeMinutes)
        this.drivingTimeHours = timeInHours(val.drivingTimeMinutes)
        this.drivingTimeMinutes = timeInMinutes(val.drivingTimeMinutes)
      },
    },
    methods: {
      hideRegionConfiguration() {
        EventBus.$emit('hideRegionConfiguration')
      },
      async updateRegion() {
        this.isSaving = true
        this.error = false
        this.success = false
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/UpdateRegion.gql'),
            variables: {
              availableMinutesPerTruck: totalTimeInMinutes(
                this.availableTimePerTruckHours,
                this.availableTimePerTruckMinutes,
              ),
              bufferTimeMinutes: totalTimeInMinutes(this.bufferTimeHours, this.bufferTimeMinutes),
              drivingTimeMinutes: totalTimeInMinutes(this.drivingTimeHours, this.drivingTimeMinutes),
              numberOfTrucks: Number(this.numberOfTrucks),
              totalTruckCuft: Number(this.totalTruckCuft),
              id: this.selectedRegion.id,
            },
          })
          this.onRegionUpdated()
        } catch (error) {
          this.onRegionUpdateFailed(error)
        }
      },
      onRegionUpdated() {
        this.isSaving = false
        this.success = true
        setTimeout(() => {
          location.reload()
        }, 500)
      },
      onRegionUpdateFailed(error) {
        this.isSaving = false
        this.error = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .configuration-form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .actions {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 42px;
      .save-button {
        background: #ffe82f;
        color: #262223;
        width: 160px;
        height: 40px;
        margin-top: 16px;
        margin-bottom: 16px;
      }
      .cancel-button {
        background: white;
        color: #262223;
        width: 160px;
        height: 40px;
        border: 1px solid #262223;
      }
    }

    .configuration {
      display: flex;
      font-size: 16px;
      margin-bottom: 8px;
      flex-direction: column;

      .configuration-field-description {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      .configuration-select {
        display: flex;
        flex-direction: row;
        align-items: center;

        .custom-select {
          width: 20%;
        }
        .labels {
          padding-left: 8px;
          margin-right: 16px;
        }
      }

      .configuration-input {
        input {
          width: 90%;
        }
      }
    }
  }
</style>

