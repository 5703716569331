<template>
    <div class="product">
        <section class="gs-generic-header">
            <h1>{{ product.name }}</h1>
            <button type="submit"
                    class="gs-standard-button"
                    @click="goToEditProductPage()">
                Edit
            </button>
        </section>

        <LoadingSpinner v-if="isLoading"
                        is-black="true" />
        <div v-else
             class="gs-standard-content">
            <section class="row product-details">
                <h3 class="gs-standard-sub-header">
                    General Info
                </h3>

                <div class="col-md-6">
                    <div class="gs-standard-detail">
                        <label>Name:</label>
                        <span>{{ product.name }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Identifier:</label>
                        <a :href="productPageLink(product.identifier)"
                           target="_blank">{{ product.identifier }}</a>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Brand:</label>
                        <span>{{ product.brand.name }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Manufacturer:</label>
                        <span>{{ product.manufacturer.name }}</span>
                    </div>
                </div>

                <div class="col-md-6 gs-standard-info-block">
                    <label>Description:</label>
                    <span v-if="product.description">{{ product.description }}</span>
                    <span v-else
                          class="no-info">No Description</span>
                </div>

                <div class="col-md-6 gs-standard-info-block">
                    <label>Materials Description:</label>
                    <span v-if="product.materialsString">{{ product.materialsString }}</span>
                    <span v-else
                          class="no-info">No Materials Description</span>
                </div>

                <div class="col-md-6 gs-standard-info-block">
                    <label>Internal Notes:</label>
                    <span v-if="product.internalNotes">{{ product.internalNotes }}</span>
                    <span v-else
                          class="no-info">No internal notes</span>
                </div>

                <div class="col-md-6 gs-standard-info-block">
                    <label>Behind the Design:</label>
                    <span v-if="product.secondaryInfo">{{ product.secondaryInfo }}</span>
                    <span v-else
                          class="no-info">No behind the design</span>
                </div>

                <LoadingSpinner v-if="isLoadingRecommendations"
                                is-black="true" />
                <div v-else
                     class="col-md-6 gs-standard-info-block">
                    <label>Recommendation:</label>
                    <span v-if="recommendation">
                        Rank: {{ recommendation.ranking || 'unranked' }} | Exclusive for type? {{ recommendation.exclusiveForType }}
                    </span>
                    <span v-else
                          class="no-info">No recommendation</span>
                </div>
            </section>

            <section class="row product-associations">
                <h3 class="gs-standard-sub-header">
                    Associations
                </h3>

                <div class="col-md-3 gs-standard-info-block">
                    <label>Categories:</label>
                    <span v-if="productCategories.length">{{ listArrayOfObjects(productCategories, 'name') }}</span>
                    <span v-else
                          class="no-info">No categories</span>
                </div>

                <div class="col-md-3 gs-standard-info-block">
                    <label>Styles:</label>
                    <span v-if="productStyles.length">{{ listArrayOfObjects(productStyles, 'name') }}</span>
                    <span v-else
                          class="no-info">No styles</span>
                </div>

                <div class="col-md-3 gs-standard-info-block">
                    <label>Materials:</label>
                    <span v-if="productMaterials.length">{{ listArrayOfObjects(productMaterials, 'name') }}</span>
                    <span v-else
                          class="no-info">No materials</span>
                </div>

                <div class="col-md-3 gs-standard-info-block">
                    <label>Types:</label>
                    <span v-if="productTypes.length">{{ listArrayOfObjects(productTypes, 'name') }}</span>
                    <span v-else
                          class="no-info">No types</span>
                </div>
            </section>

            <section class="row attributes">
                <h3 class="gs-standard-sub-header">
                    Defined Attributes
                </h3>

                <ApolloQuery
                    :query="require('@/graphql/queries/ProductAttributeInstancesByProduct.gql')"
                    :variables="{ productId: product.id }">
                    <template #default="{ result: { loading, error, data } }">
                        <!-- Loading -->
                        <div v-if="loading"
                             class="loading apollo">
                            Loading...
                        </div>

                        <!-- Error -->
                        <div v-else-if="error"
                             class="error apollo">
                            An error occurred: {{ data.error }}
                        </div>

                        <!-- Result -->
                        <div v-else-if="data"
                             class="result apollo property-list">
                            <div v-for="attr in data.productAttributes"
                                 :key="attr.name">
                                <div v-for="instance in attr.instances"
                                     :key="instance.id"
                                     class="property">
                                    {{ '- ' + attr.name + ': ' + instance.value }}
                                    <p v-if="attr.description"
                                       class="attr-description">
                                        {{ attr.description }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </template>
                </ApolloQuery>
            </section>

            <section class="row additional-properties">
                <h3 class="gs-standard-sub-header">
                    Additional Properties
                </h3>

                <div v-if="productAdditionalPropertiesExist"
                     class="col-md-6 property-list">
                    <div v-for="(value, key) in product.additionalProperties"
                         :key="key"
                         class="property">
                        {{ key }}: <b>{{ value }}</b>
                    </div>
                </div>
                <div v-else
                     class="no-items">
                    No Additional Properties
                </div>
            </section>

            <section class="product-images">
                <h3 class="gs-standard-sub-header">
                    Images
                </h3>

                <LoadingSpinner v-if="isLoadingProductImages"
                                is-black="true" />
                <div v-else-if="productImages.length"
                     class="row image-list">
                    <div v-for="(item, index) in productImages"
                         :key="index"
                         class="image col-md-2">
                        <b>{{ item.imageType }}</b>
                        <div v-if="item.publicId">
                            <cld-video v-if="isImageTypeVideo(item.imageType)"
                                       :public-id="item.publicId"
                                       fallback-content="Your browser does not support HTML5 video tags."
                                       controls="true"
                                       width="200"
                                       height="200"
                                       loop="true" />
                            <cld-image v-else
                                       :public-id="item.publicId"
                                       width="200"
                                       height="200"
                                       crop="limit" />
                        </div>
                        <div v-else>
                            <video v-if="isImageTypeVideo(item.imageType)"
                                   :src="item.imageUrl"
                                   controls />
                            <img v-else
                                 :src="item.imageUrl" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <span class="no-items">No Product Images</span>
                </div>
            </section>
        </div>


        <section class="gs-generic-header">
            <h1>Product SKUs</h1>
            <button type="submit"
                    class="gs-standard-button"
                    @click="goToCreateProductSkuPage()">
                Add New SKU
            </button>
        </section>

        <section v-if="isLoadingProductSkus">
            <LoadingSpinner is-black="true" />
        </section>
        <section v-else
                 class="product-skus-table gs-hide-filter">
            <v-client-table
                v-if="productSkus.length > 0"
                :data="productSkus"
                :columns="columns"
                :options="options">
                <div slot="skuLink"
                     slot-scope="data">
                    <router-link :to="{ name: 'ProductSku', params: { productSkuId: data.row['id'] } }">
                        {{ data.row.sku }}
                    </router-link>
                </div>
                <div slot="active"
                     slot-scope="data">
                    {{ data.row.isActive }}
                </div>
            </v-client-table>
            <div v-else
                 class="no-items">
                There are no product SKUs for this product :(
            </div>
        </section>
    </div>
</template>

<script>
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import * as Sentry from '@sentry/browser'

  export default {
    name: 'Product',
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        product:                  {},
        productCategories:        [],
        productStyles:            [],
        productMaterials:         [],
        productTypes:             [],
        productImages:            [],
        productSkus:              [],
        recommendation:           null,
        isLoading:                true,
        isLoadingProductSkus:     true,
        isLoadingProductImages:   true,
        isLoadingRecommendations:     true,
        columns:                  ['skuLink', 'currentInventory', 'active'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'skuLink': 'SKU',
            'currentInventory': 'Current Inventory',
            'active': 'Currently Active?',
          },
        },
      }
    },
    computed: {
      productAdditionalPropertiesExist() {
        return this.product && !_.isEmpty(this.product.additionalProperties)
      },
    },
    mounted() {
      this.getProduct()
    },
    methods: {
      //////////// MOUNTED METHODS /////////////
      async getProduct() {
        try {
          const response = await this.axios.get('/products/' + this.$route.params.productId)
          this.onProductRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async getProductCategories() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_categories')
          this.onProductCategoriesRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async getProductStyles() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_styles')
          this.onProductStylesRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async getProductMaterials() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_materials')
          this.onProductMaterialsRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async getProductTypes() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_types')
          this.onProductTypesRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async getProductImages() {
        this.isLoadingProductImages = true
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_images')
          this.onProductImagesRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async getProductSkus() {
        try {
          const response = await this.axios.get('/products/' + this.product.id + '/product_skus')
          this.onProductSkusRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      async getRecommendations() {
        this.isLoadingRecommendations = true
        try {
          const response = await this.axios.get('/recommendations')
          this.onRecommendationsRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      //////////// TEMPLATE ACTIONS ////////////
      goToEditProductPage() {
        this.$router.push({ name: 'EditProduct', params: { productId: this.$route.params.productId } })
      },
      //////////// CALLBACKS ////////////
      onProductRetrieved(data) {
        this.product = data.product
        this.isLoading = false

        this.getProductCategories()
        this.getProductStyles()
        this.getProductMaterials()
        this.getProductTypes()
        this.getProductImages()
        this.getProductSkus()
        this.getRecommendations()
      },
      onProductCategoriesRetrieved(data) {
        this.productCategories = data.productCategories
      },
      onProductStylesRetrieved(data) {
        this.productStyles = data.productStyles
      },
      onProductMaterialsRetrieved(data) {
        this.productMaterials = data.productMaterials
      },
      onProductTypesRetrieved(data) {
        this.productTypes = data.productTypes
      },
      onProductImagesRetrieved(data) {
        this.productImages = data.productImages
        this.isLoadingProductImages = false
      },
      onProductSkusRetrieved(data) {
        this.productSkus = data.productSkus
        this.isLoadingProductSkus = false
      },
      onRecommendationsRetrieved(data) {
        // this is a simplification (that we're only expecting a single
        // recommendation per product)
        this.recommendation = data.recommendations.find(r => r.productId === this.product.id)
        this.isLoadingRecommendations = false
      },
      goToCreateProductSkuPage() {
        this.$router.push({ name: 'CreateProductSku', query: { productId: this.$route.params.productId } })
      },
      productPageLink(identifier) {
        if (!identifier) {
          return '#'
        }

        const domain = process.env.NODE_ENV === 'production' ? 'oliver' : 'staging.oliver'
        return `https://${domain}.space/products/${identifier}`
      },
      isImageTypeVideo(imageType) {
        return imageType === 'VIDEO'
      },
    },
  }
</script>

<style lang="scss" scoped>

.product {

  .gs-standard-content {

    > section {

      .no-items {
        padding:                  1em;
      }

      .attr-description {
        padding-left: 30px;
      }
      .attr-description::before {
        content: "- "
      }

      &.additional-properties {

        .property-list {

          .property {
            padding:              0.5em;
            border:               1px solid lightgray;
            border-radius:        0.5em;
            width:                auto;
            margin-top:           0.5em;
          }
        }
      }

      &.product-images {

        margin-bottom:            3em;

        .image-list {

          .image {

            text-align:           center;
            margin-bottom:        1em;

            > img {
              display:            block;
              min-height:         1.5em;
              width:              100%;
              margin-top:         0.5em;
            }
          }
        }
      }
    }
  }

  .product-skus-table {
    padding:                      2em 1em;

    .no-items {
      text-align:                 center;
    }
  }
}

</style>
