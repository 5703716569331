<template>
    <div class="edit-suggestion-override">
        <section class="gs-generic-header">
            <h1>Edit Suggestion Override</h1>
            <button class="gs-standard-button">
                <router-link :to="{ name: 'SuggestionOverride', params: { suggestionOverrideId: $route.params.suggestionOverrideId } }">
                    Go Back
                </router-link>
            </button>
        </section>

        <LoadingSpinner v-if="isLoadingSuggestionOverride"
                        is-black="true" />
        <div v-else
             class="content gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />

            <section class="suggestion-override-details gs-special-content">
                <div class="gs-generic-header">
                    <h2>Suggestion Override Details</h2>

                    <EnableDisableButton
                        v-if="suggestionOverride"
                        :object-to-watch="suggestionOverride"
                        :fields-to-observe="['sourceProductSkuId', 'targetProductSkuId', 'priority', 'block']"
                        :disable-button-computed="isUpdatingSuggestionOverride"
                        button-text="Update"
                        @buttonClick="updateSuggestionOverride()" />
                    <button v-else
                            class="gs-standard-button">
                        <LoadingSpinner />
                    </button>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="gs-standard-detail">
                            <label>Source Product Sku Id:</label>
                            <input v-model="suggestionOverride.sourceProductSku.id"
                                   disabled />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Target Product Sku Id:</label>
                            <input v-model="suggestionOverride.targetProductSku.id"
                                   disabled />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Priority:</label>
                            <input v-model="suggestionOverride.priority" />
                        </div>

                        <div class="gs-toggle-container">
                            <label class="tag">Block?:</label>
                            <toggle-button :value="suggestionOverride.block"
                                           color="#82C7EB"
                                           @change="suggestionOverride.block = !suggestionOverride.block" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'
  import EnableDisableButton from '@/components/forms/EnableDisableButton'

  export default {
    name: 'EditSuggestionOverride',
    components: {
      LoadingSpinner,
      FormErrors,
      EnableDisableButton,
    },
    data() {
      return {
        suggestionOverride:                     {},
        primaryErrorMessage:            '',
        errorMessages:                  [],
        isLoadingSuggestionOverride:            true,
        isUpdatingSuggestionOverride:           false,
      }
    },
    mounted() {
      this.getSuggestionOverride()
    },
    methods: {
      getSuggestionOverride() {
        this.isLoadingSuggestionOverride = true
        this.axios.get('/suggestion_overrides/' + this.$route.params.suggestionOverrideId)
          .then(response => { this.onSuggestionOverrideRetrieved(response.data) })
      },
      //////////// TEMPLATE ACTIONS ////////////
      updateSuggestionOverride() {
        this.isUpdatingSuggestionOverride = true
        this.axios.patch('/suggestion_overrides/' + this.$route.params.suggestionOverrideId, { suggestionOverride: this.suggestionOverride })
          .then(response => this.onSuggestionOverrideUpdated(response.data))
          .catch(error => this.onSuggestionOverrideUpdatedFailure(error.response))
      },
      //////////// CALLBACKS ////////////
      onSuggestionOverrideRetrieved(data) {
        this.suggestionOverride = data.suggestionOverride
        this.suggestionOverride.productStyleId = _.get(data.suggestionOverride.productStyle, 'id')
        this.suggestionOverride.productCategoryId = _.get(data.suggestionOverride.productCategory, 'id')
        this.isLoadingSuggestionOverride = false
      },
      onSuggestionOverrideUpdated(data) {
        this.isUpdatingSuggestionOverride = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully updated curated set!',
          statusCode: 1,
        })
        this.$router.push({ name: 'SuggestionOverride', params: { suggestionOverrideId: data.suggestionOverride.id } })
      },
      onSuggestionOverrideUpdatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error updating curated set'
          this.errorMessages = JSON.stringify(response)
        }

        this.isUpdatingSuggestionOverride = false
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

.edit-suggestion-override {

  margin-bottom:                    5em;

  .content {

    > section {
      margin-top:                   2em;

      > h3 {
        width:                      100%;
        margin-bottom:              1em;
      }
    }

    input[disabled] {
      background-color:#D3D3D3;
    }

    .suggestion-override-details {

      .gs-standard-detail {
        margin-top:                 1.5em;
      }

      input, textarea {
        display:                    block;
        width:                      95%;
      }

      textarea {
        height:                     12em;
      }

      .gs-toggle-container {
        margin-top:                 2em;
      }
    }
  }
}

</style>
