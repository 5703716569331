export const params = {
  methods: {
    updateQueryParams(params) {
      if (!_.isObject(params)) return

      const stringifiedKeys = _.map(_.keys(params), (key) => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      }).join('&')

      // using history state to avoid reloading the DOM
      history.pushState({}, null, `${this.$route.path}?${stringifiedKeys}`)
    },
    deleteParamsFromVueRouteQuery(params) {
      const query = Object.assign({}, this.$route.query)
      let queryUpdated = false
      params.forEach( param => {
        if (param in query) {
          queryUpdated = true
        }
        delete query[param]
      },
      )

      if (queryUpdated) {
        return this.$router.replace({ query })
      }
    },
    updateVueRouteQueryWithNewParams(newQueryParams) {
      if (!_.isMatch(this.$route.query, newQueryParams)) {
        // create a copy of the exiting query object, otherwise we modify that one in place
        // and vue gets upset
        const query = Object.assign({}, this.$route.query)
        Object.assign(query, newQueryParams)
        return this.$router.replace({ query })
      }
    },
  },
}

export default params
