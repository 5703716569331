var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grouped-journey-count"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" Number of Trips ")]), _vm._l(_vm.journeyCountGroupedBySubregion, function (value, name) {
    return _c('div', {
      key: name,
      staticClass: "data-column"
    }, [_c('span', {
      staticClass: "field"
    }, [_vm._v(_vm._s(name))]), _vm._v(" : "), _c('span', {
      staticClass: "value"
    }, [_vm._v(_vm._s(value) + " ")])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }