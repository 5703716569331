<template>
    <div class="simple-typeahead"
         :style="{ width: typeaheadWidth }">
        <input v-model="searchQuery"
               :disabled="!items.length"
               :placeholder="inputPlaceholder || 'Search items...'"
               @focus="onFocus" />
        <div v-if="showResults"
             class="result-list">
            <span v-if="filteredItems.length === 0"
                  class="result">No results</span>
            <span v-for="item in filteredItems"
                  v-else
                  :key="item[displayKey]"
                  class="result"
                  @blur="onBlur"
                  @click="onItemClicked(item)">{{ displayKey ? item[displayKey] : item }}</span>
        </div>
    </div>
</template>

<script>

// TODO hide items that are already in selected list

  export default {
    name: 'SimpleTypeahead',
    props: {
      items:  {
        type: Array,
        default: () => [],
      },
      inputPlaceholder: {
        type: String,
        default: '',
      },
      displayKey:  {
        type: String,
        default: 'name',
      },
      showOnEmpty: {
        type: Boolean,
        default: false,
      },
      typeaheadWidth: {
        type: String,
        default: '20em',
      },
    },
    data() {
      return {
        searchQuery:        '',
        focused:            false,
      }
    },
    computed: {
      filteredItems() {
        return this.items.filter((item) => {
          if (!_.isEmpty(this.searchQuery)) {
            return (this.displayKey ? item[this.displayKey] : item).toLowerCase().includes(this.searchQuery.toLowerCase())
          }

          return this.showOnEmpty
        }, this)
      },
      showResults() {
        return this.focused && (this.searchQuery.length > 0 || this.showOnEmpty)
      },
    },
    methods: {
      onItemClicked(item) {
        this.searchQuery = ''
        this.focused = false
        this.$emit('itemClicked', item)
      },
      onFocus() {
        this.focused = true
      },
      onBlur() {
        this.focused = false
      },
    },
  }

</script>

<style lang="scss" scoped>

.simple-typeahead {

  position:                       relative;
  display:                        inline-block;

  > input {
    width:                        100%;

    &:disabled {
      cursor:                     not-allowed;
    }
  }

  .result-list {
    position:                     absolute;
    width:                        100%;
    background-color:             #fff;
    border:                       1px solid lightgray;
    border-top:                   none;
    top:                          2.5em;
    z-index:                      10;

    .result {
      display:                    block;
      padding:                    0.2em;

      &:hover {
        background-color:         #5AA8FB;
        color:                    white;
        cursor:                   pointer;
      }

      &:first-of-type {
        padding-top:              0.5em;
      }
    }
  }

}

</style>
