import * as dayjs from 'dayjs'

export const get = (obj, path, defaultValue = undefined) => {
  const travel = regexp =>
    String.prototype.split
      .call(path, regexp)
      .filter(Boolean)
      .reduce((res, key) => (res !== null && res !== undefined ? res[key] : res), obj)
  const result = travel(/[,[\]]+?/) || travel(/[,[\].]+?/)
  return result === undefined || result === obj ? defaultValue : result
}

export const uniq = arr => {
  return [...new Set(arr)]
}

export const compact = arr => {
  return arr.filter(Boolean)
}

export const groupBy = (arr, groupByKeyFn) => {
  return arr.reduce((acc, c) => {
    var key = groupByKeyFn(c)
    acc[key] = acc[key] || []
    acc[key].push(c)
    return acc
  }, {})
}

export const getUnixValue = scheduledTime => {
  const currentDate = new Date()
  let hour = scheduledTime ? Number(scheduledTime.slice(0, 2)) : 23
  let minute = scheduledTime ? Number(scheduledTime.slice(3, 5)) : 59
  if (scheduledTime && scheduledTime.includes('pm') && hour < 12) {
    hour += 24
  }

  const currentDay = currentDate.getDate()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()
  const date = new Date(currentYear, currentMonth, currentDay, hour, minute)
  return dayjs(date).unix()
}

export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries(obj || {}).length

export const sumBy = (arr, fn) =>
  arr.map(typeof fn === 'function' ? fn : val => val[fn]).reduce((acc, val) => acc + val, 0)

export const capitalize = s => {
  return s && s[0].toUpperCase() + s.slice(1)
}

export const deepEqual = (x, y) => {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key]))
    : x === y
}

export const titleCase = s => s.replace(/^_*(.)|_+(.)/g, (s, c, d) => (c ? c.toUpperCase() : ' ' + d.toUpperCase()))

export const totalTimeInMinutes = (hours, minutes) => Number(hours) * 60 + Number(minutes)

export const timeInHours = time => Math.floor(time / 60)

export const timeInMinutes = time => time % 60
