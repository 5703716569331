<template>
    <div class="capacity-vizualizer">
        <div class="expanded"
             :class="{ 'flex-end' : !selectedDateObject }">
            <div v-if="selectedDateObject"
                 class="active-day">
                <div class="date-info">
                    {{ selectedDateObject.readableFullDate }}
                </div>
                <div class="capacity-metrics">
                    <div class="assembly-container">
                        <div class="header">
                            Remaining Assembly Time
                            <span>
                                ({{ formattedTime(selectedDateObject.remainingAssembly) }})
                            </span>
                        </div>
                        <div class="progress-bar-container row mb-1">
                            <div class="col-sm-12">
                                <div v-if="selectedDateObject.assemblyDelta !== 0"
                                     class="delta-text">
                                    {{ assemblyChangeText(selectedDateObject) }}
                                </div>
                                <b-progress class="mt-2"
                                            :max="maxAssembly">
                                    <b-progress-bar :class="selectedDateObject.assemblyProgressClass"
                                                    :value="selectedDateObject.assemblyTime" />
                                    <b-progress-bar class="delta"
                                                    :class="selectedDateObject.assemblyProgressClass"
                                                    :value="selectedDateObject.assemblyDelta" />
                                </b-progress>
                            </div>
                        </div>
                    </div>
                    <div class="capacity-container">
                        <div class="header">
                            Remaining Capacity
                            <span>
                                ({{ selectedDateObject.remainingCapacity }} cubic ft)
                            </span>
                        </div>
                        <div class="progress-bar-container row mb-1">
                            <div class="col-sm-12">
                                <div v-if="selectedDateObject.capacityDelta !== 0"
                                     class="delta-text">
                                    {{ capacityChangeText(selectedDateObject) }}
                                </div>
                                <b-progress class="mt-2"
                                            :max="maxCapacity">
                                    <b-progress-bar :class="selectedDateObject.capacityProgressClass"
                                                    :value="selectedDateObject.capacity" />
                                    <b-progress-bar class="delta"
                                                    :class="selectedDateObject.capacityProgressClass"
                                                    :value="selectedDateObject.capacityDelta" />
                                </b-progress>
                            </div>
                        </div>
                    </div>
                    <grouped-journey-count :journey-count-grouped-by-subregion="journeyCountGroupedBySubregion" />
                </div>
            </div>
            <div class="month-view">
                <div v-for="day in upcomingDays"
                     :key="day.date"
                     class="day-of-month"
                     :class="[{ 'active' : _.get(selectedDateObject, 'date') == day.date }, day.assemblyProgressClass]"
                     @click="updateSelectedDate(day)">
                    {{ day.date }}
                </div>
            </div>
        </div>
        <div class="collapsed">
            <div class="days-container">
                <!-- just get the first 5 days in condensed view -->
                <div v-for="day in upcomingDays.slice(0, 5)"
                     :key="day.date"
                     class="delivery-day">
                    <div class="date-square"
                         :class="day.assemblyProgressClass">
                        <div class="header">
                            {{ day.date }}
                        </div>
                        <div class="body">
                            {{ day.day }}
                        </div>
                    </div>
                    <div class="assembly-container">
                        <div class="header">
                            Remaining Assembly Time
                        </div>
                        <div class="progress-bar-container row mb-1">
                            <div class="col-sm-2 value">
                                {{ day.remainingAssembly }}
                            </div>
                            <div class="col-sm-10 pt-1">
                                <div v-if="day.assemblyDelta !== 0"
                                     class="delta-text">
                                    {{ assemblyChangeText(day) }}
                                </div>
                                <b-progress class="mt-2"
                                            :max="maxAssembly">
                                    <b-progress-bar :class="day.assemblyProgressClass"
                                                    :value="day.assemblyTime" />
                                    <b-progress-bar class="delta"
                                                    :class="day.assemblyProgressClass"
                                                    :value="day.assemblyDelta" />
                                </b-progress>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import * as dayjs from 'dayjs'
  import { get } from '@/helpers/utility'
  import GroupedJourneyCount from '@/components/resources/scheduling/GroupedJourneyCount.vue'

  export default {
    name: 'CapacityVisualizer',
    components: {
      GroupedJourneyCount,
    },
    props: {
      // this is expected to be an object with keys being scheduled dates
      // and values being a sum of capacity metrics
      capacityObject: {
        type: Object,
        default: () => {},
      },
      // similar to "capacityObject" this has date keys but values
      // represent some delta (+/-) in capacity metrics
      softScheduleChanges: {
        type: Object,
        default: () => {},
      },
      regionObject: {
        type: Object,
        default: () => {},
      },
      journeyCountGroupedBySubregion: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        originalOffsetTop: 0,
        upcomingDays: [],
        selectedDate: '',
      }
    },
    computed: {
      selectedDateObject() {
        return this.upcomingDays.find(day => day.fullDate === this.selectedDate)
      },
      maxAssembly() {
        return this.regionObject.maxServiceTimeMinutes
      },
      maxCapacity() {
        return this.regionObject.totalCubicFeetCapacity
      },
    },
    watch: {
      // because capacityObject is some property object, this call will
      // only happen on mount or DOM refresh
      capacityObject() {
        this.refreshDayCapacityMetrics()
      },
      regionObject() {
        // clear selected date on region switch
        this.selectedDate = ''
      },
    },
    mounted() {
      this.upcomingDays = this.getDaysForNextTwoWeeks()
      this.addScrollListener()
      this.setFirstDateMargin()
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.stickyScrollHandler)
    },
    methods: {
      formattedTime(minutes) {
        const hours = Math.floor(minutes / 60)
        let str = `${minutes % 60} ${this.pluralizeOrNot(minutes % 60, 'minute')}`
        if (hours) {
          str = `${hours} ${this.pluralizeOrNot(hours, 'hour')} ${str}`
        }

        return str
      },
      updateSelectedDate(day) {
        this.selectedDate = this.selectedDate == day.fullDate ? '' : day.fullDate
        this.$emit('selectedDateChange', this.selectedDate)
      },
      getDaysForNextTwoWeeks() {
        let dates = []
        let upcomingDate = dayjs().startOf('day')
        let daysOut = 18

        // finish out this week, then have 2 left
        daysOut -= Math.max(upcomingDate.day() - 1, 0)

        while (daysOut > 0) {
          // moment day 0-6 (sunday to saturday)
          const isDeliveryDay = upcomingDate.day() > 0
          const dateString = upcomingDate.format('YYYY-MM-DD')

          if (isDeliveryDay) {
            // on mount lets assign this info, but will update
            // on soft change events, see refresh day capacity metrics
            dates.push({
              capacity: 0,
              capacityDelta: 0,
              assemblyTime: 0,
              assemblyDelta: 0,
              assemblySoftTotal: 0,
              capacitySoftTotal: 0,
              remainingAssembly: 0,
              remainingCapacity: 0,
              assemblyProgressClass: 'very-low',
              capacityProgressClass: 'very-low',
              day: upcomingDate.format('ddd'),
              date: upcomingDate.format('DD'),
              fullDate: dateString,
              readableFullDate: upcomingDate.format('ddd, MMM D, YYYY'),
            })
            daysOut -= 1
          }

          upcomingDate = upcomingDate.add(1, 'day')
        }

        return dates
      },
      getProgressClass(ratio) {
        if (ratio == 0) return 'very-low'
        if (ratio < 0.33) return 'low'
        if (ratio < 0.66) return 'medium'
        if (ratio < 1) return 'high'

        return 'very-high'
      },
      getAssemblyProgessClass(assemblyTime) {
        const ratio = assemblyTime / this.maxAssembly
        return this.getProgressClass(ratio)
      },
      getCapacityProgessClass(capacity) {
        const ratio = capacity / this.maxCapacity
        return this.getProgressClass(ratio)
      },
      assemblyChangeText(dateObject) {
        const negativeChange = dateObject.assemblySoftTotal !== dateObject.assemblyTime + dateObject.assemblyDelta
        return `${negativeChange ? '-' : '+'}${dateObject.assemblyDelta}`
      },
      capacityChangeText(dateObject) {
        const negativeChange = dateObject.capacitySoftTotal !== dateObject.capacity + dateObject.capacityDelta
        return `${negativeChange ? '-' : '+'}${dateObject.capacityDelta}`
      },
      // this function adds a scroll listener to handle the transition
      // between a condensed capacity visualizer
      addScrollListener() {
        this.originalOffsetTop = $('.capacity-vizualizer').offset().top
        window.addEventListener('scroll', this.stickyScrollHandler)
      },
      stickyScrollHandler() {
        const isStuck = window.pageYOffset > this.originalOffsetTop
        const journeysPaddingTop = isStuck ? '175px' : '0'

        // need some top padding because the condensed component is shorter
        $('.journeys-wrapper').css({ paddingTop: journeysPaddingTop, paddingBottom: journeysPaddingTop })
        if (isStuck) {
          $('.expanded').hide()
          $('.collapsed').show()
        } else {
          $('.expanded').show()
          $('.collapsed').hide()
        }
      },
      setFirstDateMargin() {
        // this is pretty janky, but what's it's doing is taking the first
        // day of the week in the mini calendar and applying enough margin
        // to put it in its rightful alignment

        // leftMargin : 27px
        // width 40
        const dayOfTheWeek = dayjs()
          .startOf('day')
          .day()
        const offsetPosition = Math.max(dayOfTheWeek - 1, 0)
        const margin = `${offsetPosition * 66 + 27}px`

        this.$nextTick(() =>
          $('.day-of-month')
            .eq(0)
            .css('margin-left', margin),
        )
      },
      refreshDayCapacityMetrics() {
        this.upcomingDays.forEach(day => {
          const originalAssmemblyTime = get(this.capacityObject[day.fullDate], 'assemblyTime', 0)
          const originalCapacity = get(this.capacityObject[day.fullDate], 'capacity', 0)

          const assemblyDelta = get(this.softScheduleChanges[day.fullDate], 'assemblyChange', 0)
          const capacityDelta = get(this.softScheduleChanges[day.fullDate], 'capacityChange', 0)

          day.assemblyTime = Math.round(originalAssmemblyTime)
          day.assemblyDelta = Math.round(assemblyDelta)
          day.assemblySoftTotal = day.assemblyTime + day.assemblyDelta
          day.capacity = Math.round(originalCapacity)
          day.capacityDelta = Math.round(capacityDelta)
          day.capacitySoftTotal = day.capacity + day.capacityDelta

          day.remainingAssembly = Math.max(this.maxAssembly - day.assemblySoftTotal, 0)
          day.remainingCapacity = Math.max(this.maxCapacity - day.capacitySoftTotal, 0)

          // The progress bar component does not handle negatives well, so
          // show original as total and show delta as a positive
          if (assemblyDelta < 0) {
            day.assemblyTime = day.assemblySoftTotal
            day.assemblyDelta = -day.assemblyDelta
          }
          if (capacityDelta < 0) {
            day.capacity = day.capacitySoftTotal
            day.capacityDelta = -day.capacityDelta
          }

          // make the progress class include the deltas
          day.assemblyProgressClass = this.getAssemblyProgessClass(day.assemblySoftTotal)
          day.capacityProgressClass = this.getCapacityProgessClass(day.capacitySoftTotal)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  $very-low-capacity: #00b929;
  $low-capacity: #a5ff99;
  $moderate-capacity: #ffe82f;
  $high-capacity: #ff708b;
  $very-high-capacity: red;

  .capacity-vizualizer {
    .very-low {
      background-color: $very-low-capacity;
    }
    .low {
      background-color: $low-capacity;
    }
    .medium {
      background-color: $moderate-capacity;
    }
    .high {
      background-color: $high-capacity;
    }
    .very-high {
      background-color: $very-high-capacity;
    }

    .date-square {
      padding: 0.5rem 0.5rem;
      border-radius: 5px;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);

      .header {
        font-weight: 600;
      }

      .body {
        font-weight: 400;
      }
    }

    .progress-bar-container {
      padding: 0.5rem 0 0 0;

      .delta-text {
        text-align: right;
      }

      .value {
        font-weight: bold;
        padding: 0;
      }

      .delta {
        opacity: 0.3;
      }
      .col-sm-12 {
        padding-left: 0px;
      }
    }

    .progress {
      height: 8px;
    }

    .expanded {
      width: 100%;
      margin-bottom: 2rem;
      border-radius: 4px;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;

      .active-day {
        display: flex;
        width: 400px;
        background: #f8f8f8;
        border-radius: 2px;
        padding: 24px;
        flex-direction: column;

        .date-info {
          font-weight: bold;
          font-size: 16px;
        }
        .delta-text {
          font-size: 12px;
          margin-top: -24px;
        }

        .capacity-metrics {
          display: flex;
          flex-direction: column;
          min-width: 300px;

          .header {
            font-weight: bold;
            font-size: 13px;
            margin-top: 16px;
            color: #6b6b6b;
          }

          .value {
            font-size: 1.2rem;
          }
        }
      }

      .month-view {
        width: 400px;

        .day-of-month {
          padding: 0.5rem 0.5rem;
          border-radius: 5px;
          box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3);

          font-size: 1.2rem;
          font-weight: 600;
          display: inline-block;
          margin-left: 27px;
          margin-bottom: 1rem;
        }

        .day-of-month:hover {
          cursor: pointer;
        }

        .day-of-month.active {
          box-shadow: 0 0 3px 2px black;
        }
      }
    }

    .expanded.flex-end {
      justify-content: flex-end;
    }

    .collapsed {
      display: none;

      background-color: #f8f8f8;
      position: fixed;
      width: 100%;
      top: 0px;
      left: 0px;
      z-index: 1;
      padding: 0;

      .days-container {
        display: flex;
        justify-content: space-evenly;
        padding: 0.25rem 0;

        .delivery-day {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .date-square {
            display: inline-block;
            width: 55px;
            height: 55px;
            padding: 0.5rem 0.5rem;

            .header {
              font-size: 1rem;
            }

            .body {
              font-size: 0.9rem;
            }
          }

          .assembly-container {
            margin-left: 1rem;
            min-width: 150px;

            .delta-text {
              font-size: 10px;
              margin-top: -13px;
              margin-bottom: -8px;
            }

            .header {
              font-weight: bold;
              font-size: 13px;
              margin-top: 16px;
              margin-bottom: 16px;
            }

            .value {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
</style>
