var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "journey-issue"
  }, [_c('div', {
    staticClass: "body"
  }, [_vm._m(0), !_vm.inventoryItemIdsFetched ? _c('div', [_c('LoadingSpinner')], 1) : _vm.orderItemsWithoutPickup.length ? _c('div', {
    staticClass: "order-items-list"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("Adding one of the following items? ")]), _c('table', {
    staticClass: "table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.orderItemsWithoutPickup, function (orderItem) {
    var _orderItem$journeyInv;

    return _c('tr', {
      key: orderItem.id,
      staticClass: "order-item"
    }, [_c('td', {
      staticClass: "item-checkbox"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: orderItem.added,
        expression: "orderItem.added"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(orderItem.added) ? _vm._i(orderItem.added, null) > -1 : orderItem.added
      },
      on: {
        "change": function change($event) {
          var $$a = orderItem.added,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = null,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(orderItem, "added", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(orderItem, "added", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(orderItem, "added", $$c);
          }
        }
      }
    })]), _c('td', {
      staticClass: "item-image"
    }, [_c('cld-image', {
      staticClass: "inventory-item-photo",
      attrs: {
        "public-id": _vm.getPublicID(orderItem.productSku.productImages),
        "width": "36",
        "height": "36",
        "crop": "limit"
      }
    })], 1), _c('td', [_vm._v(" " + _vm._s(orderItem.productSku.name) + " - " + _vm._s(orderItem.productSku.sku) + " ")]), _c('td', [_vm._v(" " + _vm._s(((_orderItem$journeyInv = orderItem.journeyInventoryItems[0].inventoryItem.conditionGrade) === null || _orderItem$journeyInv === void 0 ? void 0 : _orderItem$journeyInv.name) || '??') + " ")]), _c('td', {
      staticClass: "early-return"
    }, [orderItem.earlyReturn ? [_vm._v(" $" + _vm._s(Number(orderItem.earlyReturnCharge / 100).toFixed(2)) + " ")] : _vm._e()], 2)]);
  }), 0)])]) : _vm._e(), _c('span', {
    staticClass: "total-fee"
  }, [_vm._v(" Total Fee: $" + _vm._s(_vm.totalEarlyReturnCost))]), _c('form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "issue-notes"
  }, [_c('span', [_vm._v("If not from the list above, can you describe the item?")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.issueNote,
      expression: "issueNote"
    }],
    attrs: {
      "type": "text",
      "name": "issue-notes",
      "placeholder": "(Details of the item)"
    },
    domProps: {
      "value": _vm.issueNote
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.issueNote = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "submit"
  }, [_c('button', {
    attrs: {
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm._v(" " + _vm._s(_vm.isSubmitting ? "Submitting" : "Submit") + " ")])])]), _c('div')])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title"
  }, [_c('h4', {
    staticClass: "h4-emphasized"
  }, [_vm._v(" Add to Pickup ")]), _c('hr')]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }), _c('th', {
    attrs: {
      "scope": "col"
    }
  }), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Item ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Condition ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Early Return Fee ")])])]);
}]

export { render, staticRenderFns }