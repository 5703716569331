<template>
    <div class="product-skuless-skus">
        <section v-if="isLoadingProducts">
            <LoadingSpinner is-black="true" />
        </section>
        <section v-else
                 class="product-skuless-skus-table gs-standard-table gs-hide-filter">
            <v-client-table
                v-if="filteredProducts.length > 0"
                :data="filteredProducts"
                :columns="columns"
                :options="options">
                <div slot="productName"
                     slot-scope="data">
                    <router-link :to="{ name: 'Product', params: { productId: data.row['id'] } }">
                        {{ data.row['name'] }}
                    </router-link>
                </div>
            </v-client-table>

            <div v-else
                 class="no-items">
                There are no products to display. Please refine your search.
            </div>
        </section>
    </div>
</template>

<script>
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import * as Sentry from '@sentry/browser'

  export default {
    name: 'ProductSkus',
    components: {
      LoadingSpinner,
    },
    props: {
      productNameSearch: {
        type: String,
        default: '',
      },
      productBrandSearch: {
        type: String,
        default: '',
      },
      selectedCategories: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        products:               [],
        isLoadingProducts:      true,
        columns:                ['id', 'productName', 'brand.name'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'id': 'ID',
            'productName': 'Product Name',
            'brand.name': 'Brand',
          },
        },
      }
    },
    computed: {
      selectedCategoryIds() {
        return _.map(this.selectedCategories, (category) => category.id)
      },
      productsFilteredByCategory() {
        if (!this.selectedCategories.length) return this.products

        // If the product sku belongs to ANY of the selected categories, it will be included in result set
        return this.products.filter((product) => {
          let productCategoryIds = product.categories.map((category) => { return category.id })
          let sharedCategories = _.intersection(this.selectedCategoryIds, productCategoryIds)
          return sharedCategories.length > 0
        }, this)
      },
      filteredProducts() {
        return this.productsFilteredByCategory.filter((product) => {
          return product.name.toLowerCase().includes(this.productNameSearch.toLowerCase()) &&
            product.brand.name.toLowerCase().includes(this.productBrandSearch.toLowerCase())
        }, this)
      },
    },
    mounted() {
      this.getSkulessProducts()
    },
    methods: {
      async getSkulessProducts() {
        try {
          const response = await this.axios.get('/products_no_skus')
          this.onProductsRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      //////////// TEMPLATE ACTIONS ////////////
      onProductsRetrieved(data) {
        this.isLoadingProducts = false
        this.products = data.products
      },
    },
  }
</script>

<style lang="scss" scoped>
  .product-skuless-skus {
    min-height: 10em;
  }
</style>
