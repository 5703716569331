<template>
    <div class="inventory-item-summary">
        <div class="header">
            <h3 class="emphasized">
                Journey Items
            </h3>
        </div>
        <div class="body">
            <div v-if="isLoadingItems">
                <loading-spinner />
            </div>
            <div v-else
                 class="item-details">
                <div class="item-table-header">
                    <div class="item-column" />
                    <div class="item-column name">
                        Name
                    </div>
                    <div class="item-column item-id">
                        Item ID
                    </div>
                    <div class="item-column">
                        Assembly
                    </div>
                    <div class="item-column">
                        Capacity
                    </div>
                </div>
                <div v-for="item in inventoryItems"
                     :key="item.key">
                    <div class="item-table-row">
                        <div class="item-column">
                            <cld-image :public-id="getPublicID(item.productSku.productImages)"
                                       class="inventory-item-photo"
                                       width="75"
                                       height="75"
                                       crop="limit" />
                        </div>
                        <div class="item-column name">
                            {{ item.productSku.name }} - {{ item.productSku.sku }}
                        </div>
                        <div class="item-column item-id">
                            {{ item.id }}
                        </div>
                        <div class="item-column">
                            {{ getAssemblyTime(item) }}
                        </div>
                        <div class="item-column">
                            {{ getPacketDimensions(item) }}
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { imageMixin } from '@/mixins/imageMixin'
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import { get } from '@/helpers/utility'
  export default {
    name: 'InventoryItemSummary',
    components: { LoadingSpinner },
    mixins: [imageMixin],
    props: {
      inventoryItemIds: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        inventoryItems: [],
        isLoadingItems: false,
        skipQuery: true,
      }
    },
    mounted() {
      this.$nextTick(() => {
        this.triggerItemsQuery()
      })
    },
    apollo: {
      inventoryItems: {
        query: require('@/graphql/queries/InventoryItemSummary.gql'),
        variables() {
          return {
            ids: this.inventoryItemIds,
          }
        },
        result(_response, _key) {
          this.isLoadingItems = false
        },
        skip() {
          return this.skipQuery
        },
      },
    },
    methods: {
      getPublicID(productImages) {
        const productImage = this.getProductImage(productImages)
        if (productImage) {
          return productImage.body.split('upload/')[1]
        }
        return ''
      },
      triggerItemsQuery() {
        this.isLoadingItems = true
        this.$apollo.queries.inventoryItems.skip = false
        this.$apollo.queries.inventoryItems.refetch()
      },
      getAssemblyTime(item) {
        return Math.round(get(item, 'productSku.newOpsDetails.assemblyTimeInMinutes')) || '10'
      },
      getPacketDimensions(item) {
        const packetDimensions = get(item, 'productSku.packetDimensions') || []
        const capacity = packetDimensions.reduce((currentValue, packetDimension) => {
          return (
            (currentValue +
              packetDimension.heightInInches * packetDimension.widthInInches * packetDimension.lengthInInches) /
            (12 * 12 * 12)
          )
        }, 0)

        return capacity ? `${Math.round(capacity)} cubic ft` : 'N/A'
      },
    },
  }
</script>

<style lang="scss" scoped>
  .inventory-item-summary {
    padding: 16px 16px;
    .header {
      font-weight: bold;
      margin-bottom: 16px;
    }
    .body {
      height: 500px;
      max-height: 90%;
      overflow-y: scroll;

      label {
        font-weight: bold;
      }
      .item-details {
        display: flex;
        flex-direction: column;

        .item-table-header {
          display: flex;
          flex-direction: row;
          .item-column {
            width: 20%;
            color: #6b6b6b;
            font-size: 13px;
            font-weight: bold;
            &.name {
              width: 25%;
            }

            &.item-id {
              width: 15%;
            }
          }
          margin-bottom: 16px;
        }

        .item-table-row {
          display: flex;
          flex-direction: row;
          .item-column {
            width: 20%;
            color: #262223;
            font-weight: initial;
            font-size: 13px;
            padding-right: 16px;

            &.name {
              width: 25%;
            }

            &.item-id {
              width: 15%;
            }
          }
        }
      }

      > div {
        margin-bottom: 2em;
      }
    }

    .body::-webkit-scrollbar {
      display: none;
    }
  }
</style>
