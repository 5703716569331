<template>
    <div class="user">
        <section class="gs-generic-header">
            <h1>{{ fullName }}</h1>
            <router-link :to="{ name: 'EditUser', params: { userId: $route.params.userId } }"
                         class="gs-standard-button">
                Edit User
            </router-link>
        </section>

        <LoadingSpinner v-if="isLoadingUser"
                        is-black="true" />
        <div v-else
             class="gs-standard-content">
            <section class="row user-details">
                <div class="col-md-4">
                    <div class="gs-standard-detail">
                        <label>User Id:</label>
                        <span>{{ user.id }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>First Name:</label>
                        <span v-if="user.firstName">{{ user.firstName }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Last Name:</label>
                        <span v-if="user.lastName">{{ user.lastName }}</span>
                        <span v-else
                              class="no-info">None</span>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="gs-standard-detail">
                        <label>Email:</label>
                        <span>{{ user.emailAddress }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Phone:</label>
                        <span v-if="user.phone">{{ user.phone }}</span>
                        <span v-else
                              class="no-info">None</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Joined:</label>
                        <span>{{ user.createdAt }}</span>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'User',
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        isLoadingUser:    false,
        user:             {},
      }
    },
    computed: {
      fullName() {
        if (!this.user) return ''
        return this.user.firstName + ' ' + this.user.lastName
      },
    },
    mounted() {
      this.getUser()
    },
    methods: {
      //////////// CREATED/MOUNTED METHODS ////////////
      getUser() {
        this.isLoadingUser = true
        this.axios.get('/users/' + this.$route.params.userId)
          .then(response => { this.onUserRetrieved(response.data) })
      },

      //////////// CALLBACKS ////////////
      onUserRetrieved(data) {
        this.user = data.user
        this.isLoadingUser = false
      },
    },
  }
</script>

<style lang="scss" scoped>


.user {

  .gs-standard-content {
    margin-top: 2em;
  }

  .no-info {
    color: lightgray;
  }

}

</style>
