<template>
    <div class="user-account">
        <section class="header">
            <h1>{{ currentUser.fullName }}</h1>
            <span class="email">{{ currentUser.emailAddress }}</span>
        </section>

        <section class="row change-password">
            <div class="col-md-6">
                <h2>Change Password</h2>

                <div class="password-form gs-standard-form">
                    <FormErrors v-if="primaryPasswordErrorMessage || passwordErrorMessages.length > 0"
                                :primary-message="primaryPasswordErrorMessage"
                                :error-messages="passwordErrorMessages" />

                    <input v-model="oldPassword"
                           class="current-password"
                           placeholder="Old Password"
                           type="password" />
                    <input v-model="newPassword"
                           class="new-password"
                           placeholder="New Password"
                           type="password" />
                    <input v-model="confirmNewPassword"
                           class="confirm-new-password"
                           placeholder="Confirm New Password"
                           type="password" />

                    <button :disabled="isSavingPassword"
                            class="gs-standard-button"
                            @click="updatePassword">
                        <span v-if="!isSavingPassword">Update</span>
                        <LoadingSpinner v-else
                                        max-height="1.5" />
                    </button>
                </div>
            </div>
        </section>

        <section class="row add-user">
            <div class="col-md-6">
                <h2>Add New Admin User</h2>

                <div class="add-user-form gs-standard-form">
                    <FormErrors v-if="primaryUserErrorMessage || userErrorMessages.length > 0"
                                :primary-message="primaryUserErrorMessage"
                                :error-messages="userErrorMessages" />

                    <input v-model="newUser.firstName"
                           class="first-name"
                           placeholder="First name" />
                    <input v-model="newUser.lastName"
                           class="last-name"
                           placeholder="Last name" />
                    <input v-model="newUser.emailAddress"
                           class="current-password"
                           placeholder="Company email address" />
                    <input v-model="newUser.password"
                           class="new-user-password"
                           placeholder="Password"
                           type="password" />
                    <input v-model="newUser.confirmPassword"
                           class="new-user-confirm-password"
                           placeholder="Confirm Password"
                           type="password" />

                    <button :disabled="isSavingUser"
                            class="gs-standard-button"
                            @click="addNewUser">
                        <span v-if="!isSavingUser">Create</span>
                        <LoadingSpinner v-else
                                        max-height="1.5" />
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

  import { mapGetters } from 'vuex'
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'

  export default {
    name: 'UserAccount',
    components: {
      LoadingSpinner,
      FormErrors,
    },
    data() {
      return {
        oldPassword:                  '',
        newPassword:                  '',
        confirmNewPassword:           '',
        primaryPasswordErrorMessage:  '',
        passwordErrorMessages:        [],
        newUser:                      this.initializeNewUser(),
        primaryUserErrorMessage:      '',
        userErrorMessages:            [],
        isSavingUser:                 false,
        isSavingPassword:             false,
      }
    },
    computed: {
      ...mapGetters({ currentUser: 'currentUser' }),
      passwordIsValid() {
        return this.passwordIsLongEnough(this.newPassword) && this.passwordsMatch(this.newPassword, this.confirmNewPassword)
      },
      newUserIsValid() {
        return this.newUserEmailAddressIsValid && this.newUserPasswordIsValid
      },
      newUserEmailAddressIsValid() {
        return this.newUser.emailAddress.indexOf('@oliver.space') !== -1
      },
      newUserPasswordIsValid() {
        return this.passwordIsLongEnough(this.newUser.password) && this.passwordsMatch(this.newUser.password, this.newUser.confirmPassword)
      },
    },
    methods: {
      //////////// TEMPLATE ACTIONS ////////////
      updatePassword() {
        this.primaryPasswordErrorMessage = ''
        this.passwordErrorMessages = []

        if (!this.passwordIsValid) {
          this.addPasswordErrors()
          return
        }

        this.isSavingPassword = true

        this.axios
          .patch(`/admin_users/${this.currentUser.id}/change_password`, {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          })
          .then(response => this.onUpdatePassword(response.data))
          .finally(() => this.isSavingPassword = false)
      },
      addNewUser() {
        this.primaryUserErrorMessage = ''
        this.userErrorMessages = []

        if (!this.newUserIsValid) {
          this.addNewUserErrors()
          return
        }

        this.isSavingUser = true

        this.axios
          .post('/admin_users', { ...this.newUser })
          .then(response => this.onNewUserCreated(response.data))
          .catch(() => this.onNewUserCreateFailure())
          .finally(() => this.isSavingUser = false)
      },
      //////////// CALLBACKS ////////////
      onUpdatePassword(data) {
        if (data.error) {
          this.primaryPasswordErrorMessage = data.error
          this.passwordErrorMessages = data.messages
        } else {
          EventBus.$emit('globalAlert', {
            message: 'Successfully updated password!',
            statusCode: 1,
          })
          this.oldPassword = ''
          this.newPassword = ''
          this.confirmNewPassword = ''
        }
      },
      onNewUserCreated(data) {
        if (data.error) {
          this.primaryUserErrorMessage = data.error
          this.userErrorMessages = data.messages
        } else {
          EventBus.$emit('globalAlert', {
            message: 'Successfully created user!',
            statusCode: 1,
          })
          this.newUser = this.initializeNewUser()
        }
      },
      onNewUserCreateFailure() {
        EventBus.$emit('globalError', {
          statusMessage: 'An error prevented a new admin from being created',
          redirectUser: false,
        })
      },
      //////////// INTERNAL METHODS ////////////
      addPasswordErrors() {
        if (!this.oldPassword) {
          this.passwordErrorMessages.push('Old password must exist')
        }
        if (!this.newPassword) {
          this.passwordErrorMessages.push('New password must exist')
        }
        if (!this.confirmNewPassword) {
          this.passwordErrorMessages.push('Confirm new password must exist')
        }
        if (!this.passwordIsLongEnough(this.newPassword)) {
          this.passwordErrorMessages.push('Password must be at least 8 characters long')
        }
        if (!this.passwordsMatch(this.newPassword, this.confirmNewPassword)) {
          this.passwordErrorMessages.push('Passwords do not match')
        }
      },
      addNewUserErrors() {
        if (!this.newUser.emailAddress) {
          this.userErrorMessages.push('Email address must exist')
        }
        if (!this.newUserEmailAddressIsValid) {
          this.userErrorMessages.push('Email address must be an @oliver.space address')
        }
        if (!this.newUser.password) {
          this.userErrorMessages.push('Password must exist')
        }
        if (!this.newUser.confirmPassword) {
          this.userErrorMessages.push('Confirm password must exist')
        }
        if (!this.passwordIsLongEnough(this.newUser.password)) {
          this.userErrorMessages.push('Password must be at least 8 characters long')
        }
        if (!this.passwordsMatch(this.newUser.password, this.newUser.confirmPassword)) {
          this.userErrorMessages.push('Passwords do not match')
        }
      },
      passwordIsLongEnough(password) {
        return password.length >= 8
      },
      // Could be re-factored into more generic string method
      passwordsMatch(firstPassword, secondPassword) {
        return firstPassword === secondPassword
      },
      initializeNewUser() {
        return {
          firstName:          '',
          lastName:           '',
          emailAddress:       '',
          password:           '',
          confirmPassword:    '',
        }
      },
    },
  }
</script>


<style lang="scss" scoped>

.user-account {
  padding-bottom: 2em;

  > section {
    margin:                         2em 0;

    h2 {
      text-align:                   left;
    }

    &.header {
      text-align:                   center;
      margin:                       0em 5em;
    }

    .gs-standard-form {

      > input {
        display:                    block;
      }
    }
  }
}

</style>
