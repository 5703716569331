var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "configuration-form"
  }, [_c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-field-description"
  }, [_vm._v(" Max hours per truck ")]), _c('div', {
    staticClass: "configuration-select"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.availableTimePerTruckHours,
      expression: "availableTimePerTruckHours"
    }],
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.availableTimePerTruckHours = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.hours, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "selected": _vm.availableTimePerTruckHours == option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0), _c('span', {
    staticClass: "labels"
  }, [_vm._v(" Hours ")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.availableTimePerTruckMinutes,
      expression: "availableTimePerTruckMinutes"
    }],
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.availableTimePerTruckMinutes = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.minutes, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "selected": _vm.availableTimePerTruckMinutes == option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0), _c('span', {
    staticClass: "labels"
  }, [_vm._v(" Minutes ")])])]), _c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-field-description"
  }, [_vm._v(" Number of trucks ")]), _c('div', {
    staticClass: "configuration-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.numberOfTrucks,
      expression: "numberOfTrucks"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.numberOfTrucks
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.numberOfTrucks = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-field-description"
  }, [_vm._v(" Max cubic feet per truck ")]), _c('div', {
    staticClass: "configuration-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.totalTruckCuft,
      expression: "totalTruckCuft"
    }],
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.totalTruckCuft
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.totalTruckCuft = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-field-description"
  }, [_vm._v(" Avg. driving time b/w stops ")]), _c('div', {
    staticClass: "configuration-select"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.drivingTimeHours,
      expression: "drivingTimeHours"
    }],
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.drivingTimeHours = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.hours, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "selected": _vm.drivingTimeHours == option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0), _c('span', {
    staticClass: "labels"
  }, [_vm._v(" Hours ")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.drivingTimeMinutes,
      expression: "drivingTimeMinutes"
    }],
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.drivingTimeMinutes = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.minutes, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "selected": _vm.drivingTimeMinutes == option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0), _c('span', {
    staticClass: "labels"
  }, [_vm._v(" Minutes ")])])]), _c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-field-description"
  }, [_vm._v(" Buffer Time Per Truck ")]), _c('div', {
    staticClass: "configuration-select"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.bufferTimeHours,
      expression: "bufferTimeHours"
    }],
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.bufferTimeHours = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.hours, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "selected": _vm.bufferTimeHours == option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0), _c('span', {
    staticClass: "labels"
  }, [_vm._v(" Hours ")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.bufferTimeMinutes,
      expression: "bufferTimeMinutes"
    }],
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.bufferTimeMinutes = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.minutes, function (option) {
    return _c('option', {
      key: option,
      domProps: {
        "selected": _vm.bufferTimeMinutes == option
      }
    }, [_vm._v(" " + _vm._s(option) + " ")]);
  }), 0), _c('span', {
    staticClass: "labels"
  }, [_vm._v(" Minutes ")])])]), _vm.error ? _c('span', [_vm._v("Update failed")]) : _vm._e(), _c('div', {
    staticClass: "actions"
  }, [_c('button', {
    staticClass: "save-button",
    on: {
      "click": _vm.updateRegion
    }
  }, [_vm._v(" " + _vm._s(_vm.saveButtonCopy) + " ")]), _c('button', {
    staticClass: "cancel-button",
    on: {
      "click": _vm.hideRegionConfiguration
    }
  }, [_vm._v(" CANCEL ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }