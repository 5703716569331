var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-layout"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Edit Layout")]), _c('button', {
    staticClass: "gs-standard-button"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Layout',
        params: {
          layoutId: _vm.$route.params.layoutId
        }
      }
    }
  }, [_vm._v(" Go Back ")])], 1)]), _vm.isLoadingLayout ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "content gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "layout-details gs-special-content"
  }, [_c('div', {
    staticClass: "gs-generic-header"
  }, [_c('h2', [_vm._v("Layout Details")]), _vm.layout ? _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isUpdatingLayout
    },
    on: {
      "click": function click($event) {
        return _vm.updateLayout();
      }
    }
  }, [_vm._v(" Update ")]) : _c('button', {
    staticClass: "gs-standard-button"
  }, [_c('LoadingSpinner')], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.layout.name,
      expression: "layout.name"
    }],
    domProps: {
      "value": _vm.layout.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.layout, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Category")]), _c('div', {
    staticClass: "input-group mb-3 category-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectProductCategory($event);
      }
    }
  }, [_c('option', [_vm._v(" Please select a category... ")]), _vm._l(_vm.allProductCategories, function (option) {
    return _c('option', {
      key: option.id,
      domProps: {
        "selected": _vm.layout.productCategory.id == option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Is Active?")]), _c('toggle-button', {
    attrs: {
      "value": _vm.layout.isActive,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.layout.isActive = !_vm.layout.isActive;
      }
    }
  })], 1), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Supported views (comma seperated)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.supportedViews,
      expression: "supportedViews"
    }],
    domProps: {
      "value": _vm.supportedViews
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.supportedViews = $event.target.value;
      }
    }
  })])])])]), _c('section', {
    staticClass: "row products"
  }, [_c('h3', [_vm._v("Product Types")]), _c('div', {
    staticClass: "col-md-6"
  }, [_vm.productTypeErrorMessage || _vm.productTypeErrorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.productTypeErrorMessage,
      "error-messages": _vm.productTypeErrorMessages
    }
  }) : _vm._e(), _vm.isLoadingProductTypes ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('SimpleAddRemoveList', {
    attrs: {
      "items": _vm.allProductTypes,
      "selected-items": _vm.layoutProductTypes,
      "allow-duplicates": true,
      "display-key": "name",
      "item-type": "product type"
    },
    on: {
      "itemAdded": _vm.addProductType,
      "itemRemoved": _vm.removeProductType
    }
  })], 1)]), _c('section', {
    staticClass: "product-images"
  }, [_c('h3', [_vm._v("Layout Image")]), _vm.imagePrimaryErrorMessage || _vm.imageErrorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.imagePrimaryErrorMessage,
      "error-messages": _vm.imageErrorMessages
    }
  }) : _vm._e(), _vm.layout.heroImage ? _c('div', [_c('cld-image', {
    attrs: {
      "public-id": _vm.layout.heroImage,
      "height": "200"
    }
  }), _c('button', {
    staticClass: "gs-standard-button gs-delete-button",
    on: {
      "click": _vm.removeLayoutImage
    }
  }, [_vm._v(" Remove ")])], 1) : !_vm.isUpdatingLayout ? _c('input', {
    ref: "hidden-uploader",
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "name": "layout-image",
      "accept": "image/*"
    },
    on: {
      "change": function change($event) {
        return _vm.onImageSelect($event.target.name, $event.target.files);
      }
    }
  }) : _c('h5', [_vm._v(" Uploading... ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }