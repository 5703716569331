<template>
    <div class="banner gs-simple-crud">
        <h1>Marketing Banner</h1>

        <SimpleCreateForm :primary-error-message="primaryErrorMessage"
                          :error-messages="errorMessages"
                          :show-loading-state="isCreatingBanner"
                          :is-valid="isValid"
                          :new-model-instance="newBanner"
                          @formSubmit="createBanner">
            <template #bannerEndDate>
                <datepicker v-model="newBanner.bannerEndDate"
                            :format="formatDate" />
            </template>
            <template #regionName>
                <SimpleArrayDropdown :items="regionNames"
                                     :selected-item="newBanner.regionName"
                                     prompt="All regions"
                                     @itemSelected="regionSelected" />
            </template>
            <template #categoryName>
                <SimpleArrayDropdown :items="categoryNames"
                                     :selected-item="newBanner.categoryName"
                                     prompt="Choose Category"
                                     @itemSelected="categorySelected" />
            </template>
        </SimpleCreateForm>

        <div class="content">
            <section class="banner-table gs-standard-table">
                <v-client-table v-if="banners.length > 0"
                                :data="banners"
                                :columns="columns"
                                :options="options"
                                class="client-table">
                    <div slot="categoryName"
                         slot-scope="data">
                        <SimpleArrayDropdown v-if="isEditingThisItem(data.row.id)"
                                             :items="categoryNames"
                                             :selected-item="data.row.bannerCategory ? data.row.bannerCategory.name : ''"
                                             prompt="Choose Category"
                                             @itemSelected="categorySelectedUpdate" />
                        <span v-else-if="data.row.bannerCategory">{{ data.row.bannerCategory.name }}</span>
                        <span v-else>No Category</span>
                    </div>

                    <div slot="text"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row.text" />
                        <span v-else>{{ data.row.text }}</span>
                    </div>

                    <div slot="mobileText"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row.mobileText" />
                        <span v-else>{{ data.row.mobileText }}</span>
                    </div>

                    <div slot="link"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row.link" />
                        <span v-else>
                            <a :href="getBannerLink(data.row)"
                               target="_blank">
                                {{ data.row.link }}
                            </a>
                        </span>
                    </div>

                    <div slot="cta"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row.cta" />
                        <span v-else>{{ data.row.cta }}</span>
                    </div>

                    <div slot="bannerEndDate"
                         slot-scope="data"
                         :class="{'edit': isEditingThisItem(data.row.id)}">
                        <datepicker v-if="isEditingThisItem(data.row.id)"
                                    v-model="data.row.bannerEndDate"
                                    :format="formatDate" />
                        <span v-else>{{ formatDate(data.row.bannerEndDate) }}</span>
                    </div>

                    <div slot="active"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row.active"
                               type="checkbox" />
                        <span v-else>{{ data.row.active }}</span>
                    </div>

                    <div slot="regionName"
                         slot-scope="data">
                        <SimpleArrayDropdown v-if="isEditingThisItem(data.row.id)"
                                             :items="regionNames"
                                             :selected-item="data.row.region ? data.row.region.name : ''"
                                             prompt="All regions"
                                             @itemSelected="regionSelectedUpdate" />
                        <span v-else-if="data.row.region">{{ data.row.region.name }}</span>
                        <span v-else>All Regions</span>
                    </div>

                    <div slot="edit"
                         slot-scope="data"
                         class="gs-standard-table-edit">
                        <TableEditButton :is-editing-this-item="isEditingThisItem(data.row.id)"
                                         :show-loading-state="isEditingThisItem(data.row.id) && isSavingThisItem(data.row.id)"
                                         :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                                         @saveButtonClick="updateBanner(data.row)"
                                         @editButtonClick="currentEditBannerId = data.row.id" />
                    </div>

                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteBanner(data.row)" />
                    </div>
                </v-client-table>
                <div v-else>
                    <h2>No Banners Exist</h2>
                </div>
            </section>

            <ConfirmModal v-if="showConfirmDeleteModal"
                          :show-loading-state="isDeletingBanner"
                          @cancel="showConfirmDeleteModal = false"
                          @confirm="deleteBanner()">
                <span slot="body">Are you sure you want to delete this banner?</span>
            </ConfirmModal>
        </div>
    </div>
</template>

<script>
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import EventBus from '@/components/utilities/EventBus'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import gql from 'graphql-tag'
  import SimpleArrayDropdown from '../components/forms/SimpleArrayDropdown.vue'
  import Datepicker from 'vuejs-datepicker'
  import dayjs from "dayjs"
  import utc from 'dayjs/plugin/utc'
  dayjs.extend(utc)

  export default {
    name: 'Banners',
    components: {
      SimpleCreateForm,
      ConfirmModal,
      DeleteIcon,
      TableEditButton,
      SimpleArrayDropdown,
      Datepicker,
    },
    data() {
      return {
        isLoadingBanners: false,
        errorMessages: [],
        primaryErrorMessage: '',
        banners: [],
        newBanner: this.initializeBanner(),
        isCreatingBanner: false,
        currentEditBannerId: null,
        currentSavingBannerId: null,
        currentDeletingBanner: null,
        isDeletingBanner: false,
        showConfirmDeleteModal: false,
        regionNames: [],
        promoFields: {
          cta: '',
          bannerEndDate: '',
        },
        categoryNames: ['Value Prop Banner', 'Promotion Banner'],
        columns: ['categoryName', 'text', 'mobileText', 'link', 'cta', 'bannerEndDate', 'active', 'regionName', 'edit', 'delete'],
        isValid: false,
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            text: 'Banner Text',
            cta: 'CTA',
            categoryName: 'Category',
            bannerEndDate: 'Banner End Date',
          },
        },
      }
    },
    apollo: {
      regions: {
        query: gql`
          query {
            regions {
              id
              name
            }
          }
        `,
        update(data) {
          this.regionNames = data.regions.map(item => item.name)
        },
      },
      categories: {
        query: gql`
          query {
            bannerCategories {
              id
              name
            }
          }
        `,
        update(data) {
          this.categoryNames = data.bannerCategories.map(item => item.name)
        },
      },
      banners: {
        query: gql`
          query {
            banners {
              id
              text
              mobileText
              link
              active
              cta
              bannerCategory {
                name
              }
              bannerEndDate
              region {
                name
              }
            }
          }
        `,
      },
    },
    methods: {
      async createBanner() {
        this.isCreatingBanner = true
        this.primaryErrorMessage = ''
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/banner/CreateBanner.gql'),
            variables: this.newBanner,
          })
          this.onBannerCreated()
        } catch (e) {
          this.onBannerCreatedFailure(e)
        }
      },
      regionSelected(val) {
        this.newBanner.regionName = val
      },
      categorySelected(val) {
        this.isValid = val !== 'Choose Category'
        if (val === 'Promotion Banner') {
          this.newBanner = {...this.newBanner, ...this.promoFields}
        } else {
          delete this.newBanner.cta
          delete this.newBanner.bannerEndDate
        }
        this.newBanner.categoryName = val
      },
      async updateBanner(banner) {
        const variables = {
          ...banner,
        }

        if (banner.bannerCategory) {
          variables.categoryName = banner.bannerCategory.name
          delete variables.bannerCategory
          delete variables["__typename"]
        }
        if (banner.region) {
          variables.regionName = banner.region.name
          if(variables.regionName === 'All regions') {
            variables.regionName = null
          }
          delete variables.region
          delete variables["__typename"]
        } else {
          variables.regionName = null
        }
        this.primaryErrorMessage = ''
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/banner/UpdateBanner.gql'),
            variables,
          })
          this.onBannerUpdated()
        } catch (e) {
          this.onBannerUpdatedFailure(e)
        }
      },
      getBannerLink(row) {
        const link = _.get(row, 'link', '?#')
        return `${process.env.VUE_APP_WEB_HOST || 'https://staging.oliver.space'}${link}`
      },
      setCurrentDeleteBanner(banner) {
        this.showConfirmDeleteModal = true
        this.currentDeletingBanner = banner
      },
      onBannerCreated() {
        this.isCreatingBanner = false
        this.$apollo.queries.banners.refetch()
        EventBus.$emit('globalAlert', {
          message: 'Successfully created banner!',
          statusCode: 1,
        })
        this.newBanner = this.initializeBanner()
      },
      onBannerCreatedFailure(err) {
        if (err) {
          this.primaryErrorMessage = err
        } else {
          this.primaryErrorMessage = 'Unknown error creating banner'
        }
        this.isCreatingBanner = false
      },
      onBannerUpdated() {
        this.$apollo.queries.banners.refetch()
        this.currentEditBannerId = null
        this.currentSavingBannerId = null
      },
      onBannerUpdatedFailure(err) {
        if (err) {
          EventBus.$emit('globalAlert', {
            message: err,
            statusCode: 3,
          })
        } else {
          this.primaryErrorMessage = 'Unknown error updating banner'
        }

        this.currentSavingBannerId = null
      },
      onBannerDeleted() {
        this.showConfirmDeleteModal = false
        this.$apollo.queries.banners.refetch()
        this.currentDeletingBanner = null

        EventBus.$emit('globalAlert', {
          message: 'Banner successfully deleted!',
          statusCode: 1,
        })
        this.isDeletingBanner = false
      },
      onBannerDeletedFailure(err) {
        if (err) {
          this.primaryErrorMessage = err
        } else {
          this.primaryErrorMessage = 'Unknown error deleting banner'
        }

        this.isDeletingBanner = false
      },
      initializeBanner() {
        return {
          text: '',
          mobileText: '',
          link: '',
          regionName: '',
          active: false,
          categoryName: 'Choose Category',
        }
      },
      isSavingThisItem(id) {
        return this.currentSavingBannerId === id
      },
      isEditingThisItem(id) {
        return this.currentEditBannerId === id
      },
      regionSelectedUpdate(val) {
        const updatingBanner = this.banners.find(item => item.id === this.currentEditBannerId)
        updatingBanner.region = updatingBanner.region || {}
        updatingBanner.region.name = val
      },
      categorySelectedUpdate(val) {
        const updatingBanner = this.banners.find(item => item.id === this.currentEditBannerId)
        updatingBanner.bannerCategory = updatingBanner.bannerCategory || {}
        updatingBanner.bannerCategory.name = val
      },
      formatDate(date) {
        if(!date) return 'No Date Set'
        return dayjs(date).utc().format('YYYY-MM-DD')
      },
      async deleteBanner() {
        this.isDeletingBanner = true
        try {
          await this.$apollo.mutate({
            mutation: require('@/graphql/mutations/banner/DeleteBanner.gql'),
            variables: {
              id: this.currentDeletingBanner.id,
            },
          })
          this.onBannerDeleted()
        } catch (e) {
          this.onBannerDeletedFailure(e)
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .content {
    margin-top: 1em;
  }
  .edit {
    height: 300px;
  }
</style>
