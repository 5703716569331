var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "capacity-vizualizer"
  }, [_c('div', {
    staticClass: "expanded",
    class: {
      'flex-end': !_vm.selectedDateObject
    }
  }, [_vm.selectedDateObject ? _c('div', {
    staticClass: "active-day"
  }, [_c('div', {
    staticClass: "date-info"
  }, [_vm._v(" " + _vm._s(_vm.selectedDateObject.readableFullDate) + " ")]), _c('div', {
    staticClass: "capacity-metrics"
  }, [_c('div', {
    staticClass: "assembly-container"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" Remaining Assembly Time "), _c('span', [_vm._v(" (" + _vm._s(_vm.formattedTime(_vm.selectedDateObject.remainingAssembly)) + ") ")])]), _c('div', {
    staticClass: "progress-bar-container row mb-1"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_vm.selectedDateObject.assemblyDelta !== 0 ? _c('div', {
    staticClass: "delta-text"
  }, [_vm._v(" " + _vm._s(_vm.assemblyChangeText(_vm.selectedDateObject)) + " ")]) : _vm._e(), _c('b-progress', {
    staticClass: "mt-2",
    attrs: {
      "max": _vm.maxAssembly
    }
  }, [_c('b-progress-bar', {
    class: _vm.selectedDateObject.assemblyProgressClass,
    attrs: {
      "value": _vm.selectedDateObject.assemblyTime
    }
  }), _c('b-progress-bar', {
    staticClass: "delta",
    class: _vm.selectedDateObject.assemblyProgressClass,
    attrs: {
      "value": _vm.selectedDateObject.assemblyDelta
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "capacity-container"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" Remaining Capacity "), _c('span', [_vm._v(" (" + _vm._s(_vm.selectedDateObject.remainingCapacity) + " cubic ft) ")])]), _c('div', {
    staticClass: "progress-bar-container row mb-1"
  }, [_c('div', {
    staticClass: "col-sm-12"
  }, [_vm.selectedDateObject.capacityDelta !== 0 ? _c('div', {
    staticClass: "delta-text"
  }, [_vm._v(" " + _vm._s(_vm.capacityChangeText(_vm.selectedDateObject)) + " ")]) : _vm._e(), _c('b-progress', {
    staticClass: "mt-2",
    attrs: {
      "max": _vm.maxCapacity
    }
  }, [_c('b-progress-bar', {
    class: _vm.selectedDateObject.capacityProgressClass,
    attrs: {
      "value": _vm.selectedDateObject.capacity
    }
  }), _c('b-progress-bar', {
    staticClass: "delta",
    class: _vm.selectedDateObject.capacityProgressClass,
    attrs: {
      "value": _vm.selectedDateObject.capacityDelta
    }
  })], 1)], 1)])]), _c('grouped-journey-count', {
    attrs: {
      "journey-count-grouped-by-subregion": _vm.journeyCountGroupedBySubregion
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "month-view"
  }, _vm._l(_vm.upcomingDays, function (day) {
    return _c('div', {
      key: day.date,
      staticClass: "day-of-month",
      class: [{
        'active': _vm._.get(_vm.selectedDateObject, 'date') == day.date
      }, day.assemblyProgressClass],
      on: {
        "click": function click($event) {
          return _vm.updateSelectedDate(day);
        }
      }
    }, [_vm._v(" " + _vm._s(day.date) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "collapsed"
  }, [_c('div', {
    staticClass: "days-container"
  }, _vm._l(_vm.upcomingDays.slice(0, 5), function (day) {
    return _c('div', {
      key: day.date,
      staticClass: "delivery-day"
    }, [_c('div', {
      staticClass: "date-square",
      class: day.assemblyProgressClass
    }, [_c('div', {
      staticClass: "header"
    }, [_vm._v(" " + _vm._s(day.date) + " ")]), _c('div', {
      staticClass: "body"
    }, [_vm._v(" " + _vm._s(day.day) + " ")])]), _c('div', {
      staticClass: "assembly-container"
    }, [_c('div', {
      staticClass: "header"
    }, [_vm._v(" Remaining Assembly Time ")]), _c('div', {
      staticClass: "progress-bar-container row mb-1"
    }, [_c('div', {
      staticClass: "col-sm-2 value"
    }, [_vm._v(" " + _vm._s(day.remainingAssembly) + " ")]), _c('div', {
      staticClass: "col-sm-10 pt-1"
    }, [day.assemblyDelta !== 0 ? _c('div', {
      staticClass: "delta-text"
    }, [_vm._v(" " + _vm._s(_vm.assemblyChangeText(day)) + " ")]) : _vm._e(), _c('b-progress', {
      staticClass: "mt-2",
      attrs: {
        "max": _vm.maxAssembly
      }
    }, [_c('b-progress-bar', {
      class: day.assemblyProgressClass,
      attrs: {
        "value": day.assemblyTime
      }
    }), _c('b-progress-bar', {
      staticClass: "delta",
      class: day.assemblyProgressClass,
      attrs: {
        "value": day.assemblyDelta
      }
    })], 1)], 1)])])]);
  }), 0)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }