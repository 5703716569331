var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "suggestion-override-list"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Suggestion Overrides")]), _c('button', {
    staticClass: "gs-standard-button gs-add-button"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'CreateSuggestionOverride'
      }
    }
  }, [_vm._v(" Add new suggestion override ")]), _c('i', {
    staticClass: "fa fa-plus-circle"
  })], 1)]), _c('section', {
    staticClass: "row gs-standard-form gs-special-content"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Filter Overrides: ")]), _c('div', [_c('span', {
    staticClass: "name-filter"
  }, [_c('span', {
    staticClass: "gs-standard-detail"
  }, [_c('label', {
    staticClass: "filter-label"
  }, [_vm._v("Source Product Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.sourceNameSearch,
      expression: "sourceNameSearch"
    }],
    domProps: {
      "value": _vm.sourceNameSearch
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.sourceNameSearch = $event.target.value;
      }
    }
  })]), _c('span', {
    staticClass: "gs-standard-detail"
  }, [_c('label', {
    staticClass: "filter-label"
  }, [_vm._v("Target Product Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.targetNameSearch,
      expression: "targetNameSearch"
    }],
    domProps: {
      "value": _vm.targetNameSearch
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.targetNameSearch = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.blockedOnly,
      expression: "blockedOnly"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.blockedOnly) ? _vm._i(_vm.blockedOnly, null) > -1 : _vm.blockedOnly
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.blockedOnly,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.blockedOnly = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.blockedOnly = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.blockedOnly = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "checkbox-label"
  }, [_vm._v("Blocked Only")])])])]), _vm.isLoadingSuggestionOverrides ? _c('section', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('section', {
    staticClass: "suggestion-overrides-table gs-standard-table gs-hide-filter"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Suggestion Overrides ")]), _vm.filteredSets.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.filteredSets,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "id",
      fn: function fn(data) {
        return _c('span', {}, [_c('router-link', {
          attrs: {
            "to": {
              name: 'SuggestionOverride',
              params: {
                suggestionOverrideId: data.row.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.row.id) + " ")])], 1);
      }
    }, {
      key: "sourceProductSku",
      fn: function fn(data) {
        return _c('span', {}, [_vm._v(" " + _vm._s(data.row.sourceProductSku) + " ")]);
      }
    }, {
      key: "targetProductSku",
      fn: function fn(data) {
        return _c('span', {}, [_vm._v(" " + _vm._s(data.row.targetProductSku) + " ")]);
      }
    }, {
      key: "block",
      fn: function fn(data) {
        return _c('span', {}, [_vm._v(" " + _vm._s(data.row.block) + " ")]);
      }
    }, {
      key: "priority",
      fn: function fn(data) {
        return _c('span', {}, [_vm._v(" " + _vm._s(data.row.priority) + " ")]);
      }
    }, {
      key: "created",
      fn: function fn(data) {
        return _c('span', {}, [_vm._v(" " + _vm._s(_vm.formatLongDateString(data.row.createdAt)) + " ")]);
      }
    }], null, false, 1506976089)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no curated sets to display. Please refine your search. ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }