var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-sku"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v(_vm._s(_vm.productSku.sku))]), _c('router-link', {
    attrs: {
      "to": {
        name: 'EditProductSku',
        params: {
          productSkuId: _vm.$route.params.productSkuId
        }
      }
    }
  }, [_c('button', {
    staticClass: "gs-standard-button"
  }, [_vm._v(" Edit ")])])], 1), _vm.isLoadingProductSku ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "gs-standard-content"
  }, [_vm.isLoadingProduct ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('section', {
    staticClass: "row gs-standard-details product-section"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Parent Product ")]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Name:")]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Product',
        params: {
          productId: _vm.product.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.product.name) + " ")])], 1), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Identifier:")]), _c('span', [_vm._v(_vm._s(_vm.product.identifier))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Brand:")]), _c('span', [_vm._v(_vm._s(_vm.product.brand.name))])])]), _c('div', {
    staticClass: "col-md-6 additional-properties"
  }, [_c('h5', [_vm._v("Additional Properties:")]), _vm.productAdditionalPropertiesExist ? _c('div', {
    staticClass: "property-list"
  }, _vm._l(_vm.product.additionalProperties, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "property"
    }, [_vm._v(" " + _vm._s(key) + ": "), _c('b', [_vm._v(_vm._s(value))])]);
  }), 0) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" No Additional Properties ")])])]), _c('section', {
    staticClass: "row product-sku-details"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" SKU Details ")]), _c('div', {
    staticClass: "col-md-4 detail-column"
  }, [_c('div', {
    staticClass: "column-item"
  }, [_c('h4', [_vm._v("Base Info")]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("SKU:")]), _c('span', [_vm._v(_vm._s(_vm.productSku.sku))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("UPC:")]), _c('span', [_vm._v(_vm._s(_vm.productSku.upc))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Sunset:")]), _c('span', [_vm._v(_vm._s(_vm.productSku.opsDetails.sunset ? 'yes (don\'t purchase)' : 'no (continue purchasing)'))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Base Price:")]), _c('span', [_vm._v(_vm._s(_vm.numberToCurrency(_vm.productSku.basePriceDollars)))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Retail Price:")]), _c('span', [_vm._v(_vm._s(_vm.numberToCurrency(_vm.productSku.retailPriceDollars)))])]), _vm.indefiniteLongPricingPlan ? _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Long term $ / mo:")]), _c('span', [_vm._v(_vm._s(_vm.numberToCurrency(_vm.indefiniteLongPricingPlan.monthlyPrice)))])]) : _vm._e(), _vm.indefiniteShortPricingPlan ? _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Short term $ / mo:")]), _c('span', [_vm._v(_vm._s(_vm.numberToCurrency(_vm.indefiniteShortPricingPlan.monthlyPrice)))])]) : _vm._e(), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Current Inventory:")]), _c('span', [_vm._v(_vm._s(_vm.productSku.currentInventory))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Is Active:")]), _c('span', [_vm._v(_vm._s(_vm.productSku.isActive || 'false') + " "), !_vm.productSku.isActive ? _c('span', {
    class: _vm.readyClass
  }, [_vm._v(_vm._s(_vm.ready))]) : _vm._e()])])]), _c('div', {
    staticClass: "column-item"
  }, [_c('h4', [_vm._v("Variations")]), _vm.productSkuVariations.length > 0 ? _c('div', _vm._l(_vm.productSkuVariations, function (variation) {
    return _c('div', {
      key: variation.id
    }, [_c('span', {
      staticClass: "variation-type"
    }, [_c('b', [_vm._v(_vm._s(variation.variationType))]), _vm._v(" - ")]), _c('span', {
      staticClass: "value"
    }, [_vm._v(_vm._s(variation.value))]), variation.sortOrder ? _c('span', [_vm._v(" (" + _vm._s(variation.sortOrder) + ")")]) : _vm._e()]);
  }), 0) : _c('span', {
    staticClass: "no-items"
  }, [_vm._v(" No variations ")])]), _c('div', {
    staticClass: "column-item"
  }, [_c('h4', [_vm._v("Warehouse Packets")]), _vm._.get(_vm.productSku, 'warehousePackets', []).length ? _c('div', _vm._l(_vm.productSku.warehousePackets, function (packet) {
    return _c('div', {
      key: packet.packetSku
    }, [_c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v(_vm._s(packet.packetSku))]), packet.sunsetDate ? _c('span', [_vm._v("Sunset: " + _vm._s(packet.sunsetDate))]) : _vm._e()])]);
  }), 0) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" No warehouse packets ")])])]), _c('div', {
    staticClass: "col-md-4 detail-column"
  }, [_c('div', {
    staticClass: "column-item"
  }, [_c('h4', [_vm._v("Dimensions")]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Weight in Pounds:")]), _c('span', [_vm._v(_vm._s(_vm.productSku.weightInPounds))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Length in Inches:")]), _c('span', [_vm._v(_vm._s(_vm.productSku.lengthInInches))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Width in Inches:")]), _c('span', [_vm._v(_vm._s(_vm.productSku.widthInInches))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Height in Inches:")]), _c('span', [_vm._v(_vm._s(_vm.productSku.heightInInches))])])]), _c('div', {
    staticClass: "column-item"
  }, [_c('h4', [_vm._v("Internal Notes")]), _vm.productSku.internalNotes ? _c('div', [_vm._v(" " + _vm._s(_vm.productSku.internalNotes) + " ")]) : _c('span', {
    staticClass: "no-items"
  }, [_vm._v(" No internal notes ")])])]), _c('div', {
    staticClass: "col-md-4 detail-column lease-schedule"
  }, [_vm.hasFixedTerm ? _c('div', {
    staticClass: "column-item"
  }, [_c('h4', [_vm._v("Lease Schedule")]), _c('span', {
    staticClass: "percentage-override"
  }, [_c('b', [_vm._v("Override Multiplier:")]), _vm._v(" " + _vm._s(_vm.fixedPricingPlan.leasePercentageOverride) + " ")]), _vm._l(_vm.productSku.leaseSchedule, function (value, key) {
    return _c('div', {
      key: key,
      staticClass: "month"
    }, [_c('b', [_vm._v(_vm._s(key) + ":")]), _vm._v(" " + _vm._s(_vm.numberToCurrency(value)) + " ")]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "column-item"
  }, [_c('h4', [_vm._v("Pricing Plans")]), _vm._l(_vm.sortedPricingPlans, function (pricingPlan) {
    return _c('div', {
      key: pricingPlan.id,
      staticClass: "pricing-plan-group"
    }, [_c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v("Pricing Model:")]), _c('span', [_vm._v(_vm._s(pricingPlan.pricingModel))])]), _c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v("Plan ID:")]), _c('span', [_vm._v(_vm._s(pricingPlan.id))])]), _c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v("Current?:")]), _c('span', {
      class: {
        'good': pricingPlan.current
      }
    }, [_vm._v(" " + _vm._s(pricingPlan.current ? 'true' : 'false') + " ")])]), _c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v("Base price:")]), _c('span', [_vm._v(_vm._s(pricingPlan.basePriceDollars))])]), _c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v("Retail price:")]), _c('span', [_vm._v(_vm._s(pricingPlan.retailPriceDollars))])]), _c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v("Pickup price:")]), _c('span', [_vm._v(_vm._s(pricingPlan.pickupPriceDollars))])]), _c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v("Lease Override:")]), _c('span', [_vm._v(_vm._s(pricingPlan.leasePercentageOverride))])]), pricingPlan.purchaseScalePercentage ? _c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v("Purchase Scale:")]), _c('span', [_vm._v(_vm._s(pricingPlan.purchaseScalePercentage))])]) : _vm._e(), _c('div', {
      staticClass: "gs-standard-detail"
    }, [_c('label', [_vm._v("Created At:")]), _c('span', [_vm._v(_vm._s(_vm.formatShortDateString(pricingPlan.createdAt)))])]), !pricingPlan.current ? _c('button', {
      staticClass: "gs-standard-button",
      attrs: {
        "disable": pricingPlan.current
      },
      on: {
        "click": function click($event) {
          return _vm.makePlanCurrent(pricingPlan.id);
        }
      }
    }, [_vm._v(" Make Current ")]) : _vm._e()]);
  })], 2)])]), _c('section', {
    staticClass: "product-sku-images"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" SKU Images ")]), _vm.isLoadingProductSkuImages ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _vm.productSkuImages.length ? _c('div', {
    staticClass: "row image-list"
  }, _vm._l(_vm.productSkuImages, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "image col-md-2"
    }, [_c('b', [_vm._v(_vm._s(item.imageType))]), item.publicId ? _c('div', [_vm.isImageTypeVideo(item.imageType) ? _c('cld-video', {
      attrs: {
        "public-id": item.publicId,
        "fallback-content": "Your browser does not support HTML5 video tags.",
        "controls": "true",
        "width": "200",
        "height": "200",
        "loop": "true"
      }
    }) : _c('cld-image', {
      attrs: {
        "public-id": item.publicId,
        "width": "200",
        "height": "200",
        "crop": "limit"
      }
    })], 1) : _c('div', [_vm.isImageTypeVideo(item.imageType) ? _c('video', {
      attrs: {
        "src": item.imageUrl,
        "controls": ""
      }
    }) : _c('img', {
      attrs: {
        "src": item.imageUrl
      }
    })])]);
  }), 0) : _c('div', [_c('span', {
    staticClass: "no-items"
  }, [_vm._v("No Product Sku Images")])])], 1), _c('section', {
    staticClass: "ops-details"
  }, [_c('h3', [_vm._v("Ops Details")]), _c('vue-json-editor', {
    attrs: {
      "expanded-on-start": true,
      "mode": "view"
    },
    model: {
      value: _vm.productSku.opsDetails,
      callback: function callback($$v) {
        _vm.$set(_vm.productSku, "opsDetails", $$v);
      },
      expression: "productSku.opsDetails"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }