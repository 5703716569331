<template>
    <div class="layout">
        <section class="gs-generic-header">
            <h1>Layout</h1>
            <router-link class="gs-standard-button"
                         :to="{ name: 'EditLayout', params: { layoutId: $route.params.layoutId } }">
                Edit
            </router-link>
        </section>

        <LoadingSpinner v-if="isLoadingLayout"
                        is-black="true" />
        <div v-else
             class="gs-standard-content">
            <section class="row layout-details">
                <h3 class="gs-standard-sub-header">
                    General Info
                </h3>

                <div class="col-md-4">
                    <div class="gs-standard-detail">
                        <label>Name:</label>
                        <span>{{ layout.name }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Category:</label>
                        <span>
                            {{ _.get(layout.productCategory, 'name', 'None') }}
                        </span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Is Active:</label>
                        <span>{{ layout.isActive || 'false' }}
                            <span v-if="!layout.isActive"
                                  :class="readyClass">{{ ready }}</span>
                        </span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>supported views:</label>
                        <span>
                            <span v-for="view in layout.supportedViews"
                                  :key="view">{{ ` ${view} ` }}</span>
                        </span>
                    </div>
                </div>
            </section>


            <section class="layout-images">
                <h3 class="gs-standard-sub-header">
                    Layout Image
                </h3>

                <div v-if="layout.heroImage"
                     class="row image-list">
                    <div class="image col-md-2">
                        <cld-image
                            :public-id="layout.heroImage"
                            height="400" />
                    </div>
                </div>
                <div v-else>
                    <span class="no-items">No layout image</span>
                </div>
            </section>

            <section class="gs-generic-header">
                <h3>Product Types</h3>
            </section>

            <section v-if="isLoadingProductTypes">
                <LoadingSpinner is-black="true" />
            </section>
            <section v-else
                     class="layout-product-types-table gs-hide-filter">
                <v-client-table
                    v-if="layoutProductTypes.length > 0"
                    :data="layoutProductTypes"
                    :columns="columns"
                    :options="options">
                    <div slot="productType"
                         slot-scope="data">
                        {{ data.row.name }}
                    </div>

                    <!-- <div slot="quantity" slot-scope="data" >
            {{data.row.quantity}}
          </div> -->
                </v-client-table>
                <div v-else
                     class="no-items">
                    There are no product types for this layout
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'Layout',
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        layout               : {},
        isLoadingLayout      : true,
        isLoadingProductTypes: true,
        layoutProductTypes   : [],
        columns              : ['productType', 'quantity'],
        options              : {
          perPage            : 20,
          perPageValues      : [20, 50, 100, 250, 500],
          headings           : {
            'quantity'       : 'Quantity',
            'productType'    : 'Product Type',
          },
        },
      }
    },
    computed: {
      ready() {
        const unreadyIndicators = _.keys(_.pickBy(this.layout.readyIndicators, v => !v))
        if (unreadyIndicators.length === 0) {
          return 'Ready!'
        } else {
          return _.join(unreadyIndicators.map(i => `x ${i}`), ' | ')
        }
      },
      readyClass() {
        if (this.ready === 'Ready!') {
          return 'good'
        } else {
          return 'bad'
        }
      },
    },
    mounted() {
      this.getLayout()
    },
    methods: {
      getLayout() {
        this.isLoadingLayout = true
        this.axios.get('/layouts/' + this.$route.params.layoutId)
          .then(response => { this.onLayoutRetrieved(response.data) })
      },
      getProductTypes() {
        this.isLoadingProductTypes = true

        this.axios.get('/product_types')
          .then(response => this.onProductTypesRetrieved(response.data))
      },

      //////////// CALLBACKS ////////////
      onLayoutRetrieved(data) {
        this.layout = data.layout
        this.isLoadingLayout = false

        this.getProductTypes()
      },
      onProductTypesRetrieved(data) {
        this.layoutProductTypes = _.flatMap(this.layout.data.pieces, piece => {
          var type = _.find(data.productTypes, (t) => piece.productType === t.identifier)
          if (!type) return null // This shouldn't happen
          return _.merge(type, { quantity: piece.quantity })
        })
        this.isLoadingProductTypes = false
      },
    },
  }
</script>

<style lang="scss">

.layout {

  .gs-standard-content {

    .bad {
      color:                    #ff0000;
    }

    .good {
      color:                    #008000;
    }

    .layout-images {

      margin-bottom:            3em;

      .image-list {

        .image {

          text-align:           center;
          margin-bottom:        1em;

          img {
            display:            block;
            min-height:         1.5em;
            margin-top:         0.5em;
          }
        }
      }
    }
  }
}

</style>
