<template>
    <div id="app">
        <Modal v-if="showModal"
               :component-class="modalContentComponent"
               @close="closeModal">
            <component :is="modalContentComponent"
                       v-bind="modalProps"
                       :success-callback="modalSuccessCallback"
                       @close="closeModal" />
        </Modal>

        <GlobalError />
        <GlobalAlert />

        <Navbar v-if="currentUser"
                :is-hidden="hideNavbar" />

        <div class="container-fluid">
            <router-view />
        </div>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import EventBus from '@/components/utilities/EventBus'
  import Modal from '@/components/utilities/Modal'
  import JITIssueReporter from '@/components/modals/JITIssueReporter'
  import JourneyIssueReporter from '@/components/modals/JourneyIssueReporter.vue'
  import AdditionalItemPickupModal from '@/components/modals/AdditionalItemPickupModal.vue'
  import CustomerNotesModal from '@/components/modals/CustomerNotesModal'
  import ContactModal from '@/components/modals/ContactModal.vue'
  import InventoryItemSummary from '@/components/modals/InventoryItemSummary.vue'
  import Navbar from '@/components/Navbar'
  import GlobalError from '@/components/utilities/GlobalError'
  import GlobalAlert from '@/components/utilities/GlobalAlert'

  export default {
    name: 'App',
    components: {
      Navbar,
      GlobalError,
      GlobalAlert,
      Modal,
      JITIssueReporter,
      JourneyIssueReporter,
      CustomerNotesModal,
      AdditionalItemPickupModal,
      ContactModal,
      InventoryItemSummary,
    },
    data() {
      return {
        allowedRoutes: ['/'],
        showModal: false,
        modalContentComponent: null,
        modalProps: {},
        modalSuccessCallback: () => {},
      }
    },
    computed: {
      ...mapGetters({ currentUser: 'currentUser' }),
      hideNavbar() {
        return this.$route.path.includes('journey')
      },
    },
    created() {
      this.checkCurrentLogin()
    },
    mounted() {
      EventBus.$on('showModal', payload => {
        this.modalContentComponent = payload.modalContentComponent
        this.modalSuccessCallback = payload.modalSuccessCallback
        this.modalProps = payload.modalProps
        this.showModal = true

        $('body').addClass('noscroll')
      })
    },
    updated() {
      this.checkCurrentLogin()
    },
    methods: {
      checkCurrentLogin() {
        if (!this.currentUser && !this.allowedRoutes.includes(this.$route.path)) {
          this.$router.push('/?redirect=' + this.$route.path)
        }
      },
      closeModal() {
        this.showModal = false
        this.modalContentComponent = null

        $('body').removeClass('noscroll')
      },
    },
  }
</script>

<style lang="scss">
  html,
  body {
    height: 100%;
  }

  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100%;

    .container-fluid {
      padding: 3em 2em;
      height: 100%;
    }
  }
</style>
