var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "modal"
    }
  }, [_c('div', {
    staticClass: "modal-mask"
  }, [_c('div', {
    staticClass: "modal-wrapper",
    on: {
      "click": _vm.closeModal
    }
  }, [_c('div', {
    staticClass: "modal-container"
  }, [_c('div', {
    staticClass: "body"
  }, [_vm._t("body", function () {
    return [_vm._v(" Are you sure you want to delete this? ")];
  })], 2), _c('div', {
    staticClass: "footer tablet-hide"
  }, [_vm._t("footer", function () {
    return [_c('button', {
      staticClass: "gs-standard-button cancel",
      attrs: {
        "disabled": _vm.showLoadingState
      },
      on: {
        "click": function click($event) {
          return _vm.onCancelClick();
        }
      }
    }, [_vm._v(" Cancel ")]), _c('button', {
      staticClass: "gs-standard-button confirm",
      attrs: {
        "disabled": _vm.showLoadingState
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('confirm');
        }
      }
    }, [_vm.showLoadingState ? _c('LoadingSpinner', {
      attrs: {
        "max-height": "1"
      }
    }) : _c('span', [_vm._v("Confirm")])], 1)];
  })], 2)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }