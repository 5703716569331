var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "simple-add-remove-list"
  }, [_c('div', {
    staticClass: "item-zone"
  }, [_vm._l(_vm.selectedItems, function (item, index) {
    return _c('span', {
      key: index,
      staticClass: "item"
    }, [_c('span', {
      staticClass: "item-name"
    }, [_vm._v(" " + _vm._s(_vm.displayKey ? item[_vm.displayKey] : item) + " ")]), _c('i', {
      staticClass: "fa fa-close remove-item",
      on: {
        "click": function click($event) {
          return _vm.itemRemoved(item, index);
        }
      }
    })]);
  }), _vm.selectedItems.length === 0 ? _c('span', {
    staticClass: "no-selected-items"
  }, [_vm._v(" No " + _vm._s(_vm.pluralizeOrNot(2, _vm.itemType)) + " selected ")]) : _vm._e()], 2), _c('SimpleTypeahead', {
    attrs: {
      "items": _vm.availableItems,
      "display-key": _vm.displayKey,
      "show-on-empty": _vm.showOnEmpty,
      "input-placeholder": "Search ".concat(_vm.pluralizeOrNot(2, _vm.itemType), "...")
    },
    on: {
      "itemClicked": _vm.addItem
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }