<template>
    <div class="customer-trips">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">
                        Type
                    </th>

                    <th scope="col">
                        ID
                    </th>
                    <th scope="col">
                        Driver
                    </th>
                    <th scope="col">
                        Location
                    </th>

                    <th scope="col">
                        Items
                    </th>
                    <th scope="col">
                        Capacity
                    </th>
                    <th scope="col">
                        Assembly
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="trip in trips"
                    :key="trip.id"
                    :ref="trip.id">
                    <td>
                        <a v-if="onfleetLink(trip)"
                           :href="onfleetLink(trip)"
                           target="_blank">
                            {{ tripPurpose(trip) }}
                        </a>
                        <span v-else>
                            {{ tripPurpose(trip) }}
                        </span>
                    </td>
                    <td>
                        {{ trip.id }}
                    </td>
                    <td>
                        {{ driverName(trip) }}
                    </td>
                    <td>
                        <a v-if="googleMapsLink(trip)"
                           :href="googleMapsLink(trip)"
                           target="_blank">
                            {{ address(trip).join(', ') }}
                        </a>
                        <span v-else>
                            {{ address(trip).join(', ') }}
                        </span>
                    </td>
                    <td class="clickable"
                        @click="showItemInfo(trip)">
                        {{ inventoryItemsLength(trip) }}
                    </td>

                    <td>
                        {{ capacity(trip) }}
                    </td>
                    <td>
                        {{ assemblyTime(trip) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
  import { get, titleCase, compact } from '@/helpers/utility'
  import EventBus from '@/components/utilities/EventBus'

  export default {
    name: 'CustomerTrips',
    props: {
      trips: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        rowExpaneded: false,
      }
    },
    methods: {
      assemblyTime(trip) {
        const minutes = trip.serviceTimeMinutes || 0
        const hours = Math.floor(minutes / 60)
        let str = `${minutes % 60} ${this.pluralizeOrNot(minutes % 60, 'minute')}`
        if (hours) {
          str = `${hours} ${this.pluralizeOrNot(hours, 'hour')} ${str}`
        }

        return str
      },
      driverName(trip) {
        return get(trip, 'driver.name', '_')
      },
      inventoryItemsLength(trip) {
        return get(trip, 'inventoryItems.length', 0)
      },
      capacity(trip) {
        const capacity = Math.round(trip.totalCubicFeetCapacity || 0)
        return `${capacity} cubic ft.`
      },
      address(trip) {
        const { line1, line2, city, postalCode } = trip.location

        return compact([line1, line2, city, postalCode])
      },
      onfleetLink(trip) {
        const taskId = get(trip, 'onfleetTask.taskId')
        if (!taskId || process.env.NODE_ENV !== 'production') return ''

        return `https://onfleet.com/dashboard#/table?taskEdit=false&open=task&taskId=${taskId}`
      },
      googleMapsLink(trip) {
        if (!trip.location) return ''
        const addressString = this.address(trip).join(',+')

        return `https://www.google.com/maps?q=${addressString}`
      },
      tripPurpose(trip) {
        return titleCase(trip.purpose)
      },
      showItemInfo(trip) {
        const itemIds = get(trip, 'inventoryItems', []).map(ii => ii.id)
        EventBus.$emit('showModal', {
          modalContentComponent: 'InventoryItemSummary',
          modalProps: {
            inventoryItemIds: itemIds,
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .customer-trips {
    box-shadow: inset 0px 1px 5px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 1rem;

    .table {
      margin: 0;

      thead th {
        border: none;
        font-size: 13px;
        color: #6b6b6b;
        font-weight: bold;
        padding-bottom: 16px;
      }

      td,
      th {
        border: none;
        vertical-align: center !important;
        padding: 0.75rem 0.25rem;
        max-width: 10rem;
        color: #6b6b6b;
        font-size: 13px;
        font-weight: 400;

        &.clickable {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
</style>
