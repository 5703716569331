<template>
    <div class="discounts-list">
        <section class="gs-generic-header">
            <h1>Discounts</h1>

            <div class="header-links">
                <button class="gs-standard-button gs-add-button top-button">
                    <router-link :to="{ name: 'CreateDiscount' }">
                        Add new discount
                    </router-link>
                    <i class="fa fa-plus-circle" />
                </button>
            </div>
        </section>

        <section class="row gs-standard-form gs-special-content">
            <h3 class="gs-standard-sub-header">
                Filter discounts:
            </h3>

            <div class="col-md-6">
                <div class="gs-standard-detail">
                    <label>Code:</label>
                    <input v-model="codeSearch" />
                </div>

                <div class="gs-standard-detail">
                    <input v-model="includeExpired"
                           type="checkbox"
                           @change="getDiscounts" />
                    <label>Include expired</label>
                </div>
            </div>
        </section>

        <section v-if="isLoadingDiscounts">
            <LoadingSpinner is-black="true" />
        </section>
        <section v-else
                 class="discounts-list-table gs-standard-table gs-hide-filter">
            <v-client-table
                v-if="filteredDiscounts.length > 0"
                :data="filteredDiscounts"
                :columns="columns"
                :options="options">
                <div slot="startsAt"
                     slot-scope="data">
                    {{ data.row['startsAt'] ? $moment(data.row['startsAt']).tz('America/Los_Angeles').format('MM/DD/YYYY') : '' }}
                </div>

                <div slot="expiresAt"
                     slot-scope="data">
                    {{ data.row['expiresAt'] ? $moment(data.row['expiresAt']).tz('America/Los_Angeles').format('MM/DD/YYYY') : '' }}
                </div>

                <div slot="code"
                     slot-scope="data">
                    <router-link :to="{ name: 'Discount', params: { discountId: data.row.id } }">
                        {{ data.row['code'] }}
                    </router-link>
                </div>
            </v-client-table>

            <div v-else
                 class="no-items">
                There are no discounts to display. Scrooge.
            </div>
        </section>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'Discounts',
    components: {
      LoadingSpinner,
    },
    props: {},
    data() {
      return {
        codeSearch: '',
        discounts: [],
        includeExpired: false,
        isLoadingDiscounts: true,
        columns: ['id', 'code', 'startsAt', 'expiresAt', 'usesRemaining', 'displayAmount'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'startsAt': 'Starts',
            'expiresAt': 'Expires',
            'usesRemaining': 'Uses remaining',
            'displayAmount': 'Amount',
          },
        },
      }
    },
    computed: {
      displayAmount() {
        return _.map(this.selectedCategories, (category) => category.id)
      },
      filteredDiscounts() {
        return this.discounts.filter((discount) => {
          return _.toString(discount.code).toLowerCase().includes(this.codeSearch.toLowerCase())
        }, this)
      },
    },
    mounted() {
      this.getDiscounts()
    },
    methods: {
      getDiscounts() {
        this.axios.get('/discounts', { params: { include_expired: this.includeExpired } })
          .then(response => this.onDiscountsRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      onDiscountsRetrieved(data) {
        this.isLoadingDiscounts = false
        _.forEach(data.discounts, (d) => {
          d.displayAmount = ''
          if (d.discountType === 'dollars') {
            d.displayAmount += '$'
          }
          d.displayAmount += d.amount
          if (d.discountType === 'percentage') {
            d.displayAmount += '%'
          }
        })
        data.discounts.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
        this.discounts = data.discounts
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

  .discounts-list {

    min-height: 10em;

  }

</style>
