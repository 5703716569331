var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "journey",
    staticClass: "journey",
    class: {
      'rescheduled-journey': _vm.isRescheduled
    }
  }, [_c('div', {
    staticClass: "gs-standard-content"
  }, [_c('div', {
    staticClass: "journey-header"
  }, [_c('div', [_c('span', {
    class: {
      'h3-emphasized': true,
      strikethrough: _vm.isRescheduled
    }
  }, [_vm._v(" " + _vm._s(_vm.customerName) + " ")]), !_vm.isRescheduled ? [_c('span', {
    staticClass: "h6"
  }, [_vm._v(" " + _vm._s(_vm.customerPhone) + " ")]), _c('span', {
    staticClass: "h6"
  }, [_vm._v(" " + _vm._s(_vm.scheduledTime) + " ")]), _c('div', {
    staticClass: "user-notes",
    on: {
      "click": _vm.openUserNotes
    }
  }, [_vm.shouldEmphasiseUserNotes() ? _c('img', {
    attrs: {
      "src": require("@/assets/images/icons/information-red.svg")
    }
  }) : _c('img', {
    attrs: {
      "src": require("@/assets/images/icons/information.svg")
    }
  }), _vm._v(" notes ")])] : [_c('span', {
    staticClass: "rescheduled-to"
  }, [_vm._v(" Rescheduled to: " + _vm._s(_vm.scheduledDate) + " ")])]], 2), _vm.showingForAllDrivers ? _c('div', {
    staticClass: "driverName"
  }, [_c('h5', [_vm._v(" Driver: " + _vm._s(_vm.driverName) + " ")])]) : _vm._e(), _c('div', {
    class: {
      'customer-address': true,
      strikethrough: _vm.isRescheduled
    }
  }, [_c('p', {
    staticClass: "h6"
  }, [_vm._v(" " + _vm._s(_vm.customerAddressFirstPart) + " ")]), _c('p', {
    staticClass: "h6"
  }, [_vm._v(" " + _vm._s(_vm.customerAddressSecondPart) + " ")])]), _c('div', {
    staticClass: "attribute-list"
  }, _vm._l(_vm.userAttributes, function (attribute) {
    return _c('div', {
      key: attribute.name,
      staticClass: "attribute-buble"
    }, [_vm._v(" " + _vm._s(_vm.getAttributeText(attribute)) + " ")]);
  }), 0), _c('hr')]), _vm.journeyObject.includesRepair ? _c('div', {
    staticClass: "repair"
  }, [_c('div', {
    staticClass: "trip-header"
  }, [_c('h3', {
    staticClass: "h3-emphasized"
  }, [_vm._v(" Repair/Service Trip ")]), _c('button', {
    class: {
      'issue': _vm.journeyIssues.length
    },
    on: {
      "click": function click($event) {
        return _vm.showJourneyIssueModal('repair');
      }
    }
  }, [_vm._v(" + repair issue ")])]), _c('div', [_vm._v(" " + _vm._s(_vm.journeyObject.journeyNote) + " ")])]) : _vm._e(), _vm.groupedDeliveryJITS.length ? _c('div', {
    staticClass: "deliveries"
  }, [_c('div', {
    staticClass: "trip-header"
  }, [_c('h3', {
    staticClass: "h3-emphasized"
  }, [_vm._v(" Delivery Items ")]), _c('button', {
    class: {
      'issue': _vm.journeyIssues.length
    },
    on: {
      "click": function click($event) {
        return _vm.showJourneyIssueModal('delivery');
      }
    }
  }, [_vm._v(" + delivery issue ")])]), _c('JourneyItemsTable', {
    attrs: {
      "initial-grouped-jits": _vm.groupedDeliveryJITS,
      "is-grouped": true,
      "is-delivery": true
    }
  })], 1) : _vm._e(), _vm.pickupJITS.length ? _c('div', {
    class: {
      pickups: true,
      strikethrough: _vm.isRescheduled
    }
  }, [_c('div', {
    staticClass: "trip-header"
  }, [_c('h3', {
    staticClass: "h3-emphasized"
  }, [_vm._v(" Pickup Items ")]), _c('button', {
    class: {
      'issue': _vm.journeyIssues.length
    },
    on: {
      "click": function click($event) {
        return _vm.showJourneyIssueModal('pickup');
      }
    }
  }, [_vm._v(" + pickup issue ")])]), _c('JourneyItemsTable', {
    attrs: {
      "initial-jits": _vm.pickupJITS,
      "is-delivery": false,
      "is-grouped": false
    }
  })], 1) : _vm._e(), _vm.additionalPickupEnabled ? _c('div', {
    staticClass: "extra-pickup",
    on: {
      "click": function click($event) {
        return _vm.showAdditionalPickupModal();
      }
    }
  }, [_c('div', {
    staticClass: "add-icon"
  }, [_vm._v(" + ")]), _c('div', {
    staticClass: "pickup-description"
  }, [_vm._v(" Picking up an item not shown here? Add it to the list. ")])]) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }