var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v(_vm._s(_vm.fullName))]), _c('router-link', {
    staticClass: "gs-standard-button",
    attrs: {
      "to": {
        name: 'EditUser',
        params: {
          userId: _vm.$route.params.userId
        }
      }
    }
  }, [_vm._v(" Edit User ")])], 1), _vm.isLoadingUser ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "gs-standard-content"
  }, [_c('section', {
    staticClass: "row user-details"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("User Id:")]), _c('span', [_vm._v(_vm._s(_vm.user.id))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("First Name:")]), _vm.user.firstName ? _c('span', [_vm._v(_vm._s(_vm.user.firstName))]) : _vm._e()]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Last Name:")]), _vm.user.lastName ? _c('span', [_vm._v(_vm._s(_vm.user.lastName))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("None")])])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Email:")]), _c('span', [_vm._v(_vm._s(_vm.user.emailAddress))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Phone:")]), _vm.user.phone ? _c('span', [_vm._v(_vm._s(_vm.user.phone))]) : _c('span', {
    staticClass: "no-info"
  }, [_vm._v("None")])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Joined:")]), _c('span', [_vm._v(_vm._s(_vm.user.createdAt))])])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }