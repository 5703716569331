<template>
    <transition name="overlay">
        <div class="modal-mask full-page-overlay"
             @click="$emit('close')">
            <div class="modal-container"
                 :class="componentClass"
                 @click.stop>
                <button class="modal-close-button"
                        @click="$emit('close')">
                    <img src="@/assets/images/icons/close-button.svg" />
                </button>
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
  export default {
    name: 'Modal',
    props: {
      componentClass: {
        type: String,
        default: '',
      },
    },
    mounted() {
      document.addEventListener('keydown', (e) => {
        if (e.keyCode === 27) {
          this.$emit('close')
        }
      })
    },
  }
</script>

<style lang="scss" scoped>
  .modal-mask {
    .modal-container {
      position: relative;
      overflow: auto;
    }
  }
</style>
