<template>
    <div class="simple-image-manager">
        <div v-if="images.length"
             class="row image-list">
            <div v-for="(item, index) in images"
                 :key="index"
                 class="image col-md-2">
                <div v-if="item.publicId">
                    <cld-video v-if="isImageTypeVideo(item.imageType)"
                               :public-id="item.publicId"
                               fallback-content="Your browser does not support HTML5 video tags."
                               controls="true"
                               width="200"
                               height="200"
                               loop="true" />
                    <cld-image v-else
                               :public-id="item.publicId"
                               width="200"
                               height="200"
                               crop="limit" />
                </div>
                <div v-else-if="item.imageUrl">
                    <video v-if="isImageTypeVideo(item.imageType)"
                           :src="item.imageUrl"
                           controls />
                    <img v-else
                         :src="item.imageUrl" />
                </div>

                <div v-if="item.imageName"
                     class="image-name">
                    {{ item.imageName }}
                </div>
                <div v-if="item.credit">
                    Credit: {{ item.credit }}
                </div>
                <button class="gs-standard-button gs-delete-button">
                    <span @click="removeImage(index)">Remove</span>
                </button>
            </div>
        </div>
        <div v-else
             class="no-items">
            No images
        </div>

        <div class="new-image">
            <SimpleArrayDropdown
                :items="imageTypes"
                :items-to-exclude="itemsToExclude"
                :selected-item="currentImageType"
                :show-loading-state="showLoadingState"
                prompt="Please select an image type"
                form-width="20em"
                @itemSelected="imageTypeSelected" />

            <input ref="hidden-uploader"
                   type="file"
                   name="profile-image"
                   accept="image/*,video/*"
                   class="input-file"
                   @change="onFileSelect($event.target.files)" />

            <button class="gs-standard-button upload-image-link"
                    @click="uploadImage">
                <LoadingSpinner v-if="isSavingImage"
                                max-height="1" />
                <span v-else
                      :class="{ disabled : isSavingImage }">Select Image</span>
            </button>

            {{ _.get(file, 'name') }}

            <input v-if="showPhotoCredit"
                   v-model="photoCredit"
                   placeholder="Photo credit (optional)" />
        </div>

        <button :disabled="isAddPropertyDisabled "
                class="gs-standard-button add-button"
                @click="addImage">
            Add image
        </button>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleArrayDropdown from '@/components/forms/SimpleArrayDropdown'

  export default {
    name: 'SimpleImageManager',
    components: {
      SimpleArrayDropdown,
      LoadingSpinner,
    },
    props: {
      images: {
        type: Array,
        default: () => [],
      },
      imageTypes: {
        type: Array,
        default: () => [],
      },
      itemsToExclude: {
        type: Array,
        default: () => [],
      },
      showLoadingState: {
        type: Boolean,
        default: false,
      },
      showPhotoCredit: {
        type: Boolean,
        default: false,
      },
      isSavingImage: {
        type: Boolean,
        default: false,
      },
      removingImageIndex: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        currentImageType: '',
        file: null,
        photoCredit: null,
      }
    },
    computed: {
      isAddPropertyDisabled() {
        return !this.currentImageType || this.isSavingImage || _.isNil(this.file)
      },
    },
    methods: {
      addImage() {
        if (!this.currentImageType) return

        this.$emit('itemAdded', this.currentImageType, this.file, this.photoCredit)
        this.file = null
        this.currentImageType = ''
        this.photoCredit = null
      },
      imageTypeSelected(type) {
        this.currentImageType = type
      },
      removeImage(index) {
        this.$emit('removeImage', index)
      },
      onFileSelect(files) {
        if (files.length === 0) return
        this.file = files[0]
      },
      isImageTypeVideo(imageType) {
        return imageType === 'VIDEO'
      },
      uploadImage() {
        if (this.isSavingImage) return
        this.$refs['hidden-uploader'].click()
      },
    },
  }
</script>

<style lang="scss" scoped>

.simple-image-manager {

  padding:                    0 1em;

  .no-items {
    margin-bottom:            1.5em;
  }

  .new-image {

    .value {
      margin-left:            1em;
      padding:                0.5em;
    }

    .input-file {
      display:                none;
    }

    .upload-image-link {
      margin-left:            1em;
      margin-right:           1em;
      vertical-align:         middle;
    }
  }

  .image-list {

    .image {

      text-align:             center;
      margin-bottom:          1em;

      .image-name {
        margin:               1em 0;
        white-space:          nowrap;
        overflow:             hidden;
        text-overflow:        ellipsis;
      }

      .gs-delete-button {
        margin-top:           0.5em;
      }

      > img, video {
        display:              block;
        min-height:           1.5em;
        width:                100%;
        margin-top:           0.5em;
        height:               200px;
      }
    }
  }

  .add-button {
    margin-top:               1em;
  }
}

</style>
