<template>
    <div class="loading">
        <img v-if="isBlack"
             src="@/assets/images/icons/spinner-black.svg"
             :style="{ maxHeight: maxHeight + 'em' }" />
        <img v-else
             src="@/assets/images/icons/spinner-white.svg"
             :style="{ maxHeight: maxHeight + 'em' }" />
    </div>
</template>

<script>
  export default {
    name: 'LoadingSpinner',
    props: {
      isBlack: {
        type: String,
        default: 'false',
      },
      maxHeight: {
        type: String,
        default: '1',
      },
    },
  }
</script>

<style lang="scss" scoped>

.loading {

  width:                  100%;
  height:                 100%;

  > img {
    margin:               0.25em auto;
    display:              block;
    height:               100%;
  }
}

</style>
