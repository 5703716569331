<template>
    <div class="journey-scheduling">
        <div class="header-panel">
            <div class="region">
                <form class="form-inline">
                    <LoadingSpinner v-if="isLoadingRegions"
                                    class="region-spinner"
                                    is-black="true" />

                    <div class="input-group mb-3">
                        <select class="custom-select"
                                @change="selectRegion($event)">
                            <option>Region</option>
                            <option v-for="option in regions"
                                    :key="option.id"
                                    :selected="selectedRegion.id == option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </form>
                <div v-if="selectedRegion.id"
                     class="region-section">
                    <div class="region-name">
                        {{ selectedRegion.name }}
                    </div>
                    <div class="configuration-trigger">
                        <img class="conf-image"
                             src="@/assets/images/icons/settings.svg"
                             @click="triggerShowRegionConfiguration" />
                    </div>
                </div>
            </div>
        </div>

        <region-configuration v-if="selectedRegion.id"
                              :show-region-configuration="showRegionConfiguration"
                              :region-object="selectedRegion" />

        <div class="main-section">
            <!-- Capacity component -->
            <CapacityVisualizer v-if="!_.isEmpty(selectedRegion)"
                                ref="capacityVisualizer"
                                :capacity-object="capacityMetricsGroupedByDate"
                                :soft-schedule-changes="softScheduleChanges"
                                :region-object="selectedRegion"
                                :journey-count-grouped-by-subregion="journeyCountGroupedBySubregion"
                                @selectedDateChange="selectedDateChange" />

            <hr class="divider" />

            <div class="journeys-wrapper">
                <LoadingSpinner v-if="isLoadingJourneys"
                                is-black="true" />
                <div v-else-if="filteredJourneys.length">
                    <h4 class="journey-header">
                        Journeys
                    </h4>
                    <h5 class="header-copy">
                        Unscheduled: {{ filteredUnscheduledJourneys.length }}
                    </h5>
                    <journey-table-header v-if="filteredUnscheduledJourneys.length" />
                    <div v-for="journey in filteredUnscheduledJourneys"
                         :key="journey.id">
                        <CustomerJourney ref="customerUnscheduledJourneys"
                                         :journey-object="journey"
                                         :show-requested-date="true"
                                         @pendingDateScheduled="journeyScheduledEvent"
                                         @journeyScheduled="journeyScheduled" />
                    </div>
                    <div v-if="selectedDate">
                        <h5 class="header-copy">
                            Scheduled: {{ filteredScheduledJourneys.length }}
                        </h5>
                        <journey-table-header v-if="filteredScheduledJourneys.length" />
                        <div v-for="journey in filteredScheduledJourneys"
                             :key="journey.id">
                            <CustomerJourney ref="customerScheduledJourneys"
                                             :journey-object="journey"
                                             :show-requested-date="false"
                                             @pendingDateScheduled="journeyScheduledEvent"
                                             @journeyScheduled="journeyScheduled" />
                        </div>
                    </div>
                </div>
                <h3 v-else>
                    {{ noItemsCopy }}
                </h3>
            </div>
        </div>

        <div v-if="pendingScheduleChange"
             class="schedule-footer">
            <button class="schedule-button"
                    @click="scheduleJourneys">
                Confirm
            </button>
        </div>
    </div>
</template>

<script>
  import LoadingSpinner from '@/components/utilities/LoadingSpinner.vue'
  import CapacityVisualizer from '@/components/resources/scheduling/CapacityVisualizer.vue'
  import CustomerJourney from '@/components/resources/scheduling/CustomerJourney.vue'
  import paramsMixin from '@/mixins/params'
  import { get, sumBy } from '@/helpers/utility'
  import * as dayjs from 'dayjs'
  import JourneyTableHeader from '@/views/journeys/JourneyTableHeader.vue'
  import RegionConfiguration from '@/views/region-configuration/RegionConfiguration.vue'
  import EventBus from '@/components/utilities/EventBus'

  export default {
    name: 'JourneyScheduling',
    components: {
      LoadingSpinner,
      CapacityVisualizer,
      CustomerJourney,
      JourneyTableHeader,
      RegionConfiguration,
    },
    mixins: [paramsMixin],
    data() {
      return {
        isLoadingJourneys: true,
        isLoadingRegions: true,
        regions: [],
        selectedRegion: {},
        journeys: [],
        // an object where keys are dates and values
        // represent a delta of capcity metrics
        softScheduleChanges: {},
        // used only for the footer button
        pendingScheduleChange: false,
        selectedDate: '',
        showRegionConfiguration: false,
        configurationEnabled: false,
        skipQuery: true,
      }
    },
    apollo: {
      regions: {
        query: require('@/graphql/queries/Regions.gql'),
        result(response, _key) {
          this.isLoadingRegions = false

          const regions = response.data.regions
          const urlRegionId = this.$route.query.regionId

          this.selectedRegion = regions.find(r => r.id === urlRegionId) || {}
        },
      },
      journeys: {
        query: require('@/graphql/queries/Journeys.gql'),
        variables() {
          return { upcoming: true, regionId: this.selectedRegion.id || 0 }
        },
        result(response, _key) {
          this.isLoadingJourneys = false
          const returnedJourneys = response.data.journeys

          this.journeys = this.transformJourneyData(returnedJourneys)
        },
      },
      featureFlagEnabled: {
        query: require('@/graphql/queries/FeatureFlagEnabled.gql'),
        variables() {
          let regionIdentifier
          if (!this.selectedRegion.identifier) {
            regionIdentifier = null
          } else {
            regionIdentifier = this.selectedRegion.identifier
          }
          return {
            featureFlag: 'region_configuration',
            regionIdentifier: regionIdentifier,
          }
        },
        result(response, _key) {
          this.configurationEnabled = response.data.featureFlagEnabled
        },
        skip() {
          return this.skipQuery
        },
      },
    },
    computed: {
      // only called when there are no filtered journeys
      noItemsCopy() {
        if (Object.keys(this.selectedRegion).length === 0) return 'Please select a region'
        if (!this.selectedDate) return 'No unscheduled journeys for selected region'

        return `No journeys scheduled for ${this.selectedDate}`
      },
      filteredJourneysHeaderCopy() {
        if (!this.selectedDate) return 'Unscheduled:'

        return `Scheduled on or requested for ${this.selectedDate}:`
      },
      capacityMetricsGroupedByDate() {
        // group journeys by date and sum capacity, also consider softScheduleChanges
        let datesAndJourneys = _.groupBy(this.journeys, journey => this.dateFormat(journey.scheduledDate))
        let averageDrivingTime = 0
        if (this.configurationEnabled) {
          averageDrivingTime = this.selectedRegion.drivingTimeMinutes
        }

        for (const [date, journeys] of Object.entries(datesAndJourneys)) {
          const capacitySum = sumBy(journeys, journey => journey.capacity || 0)
          const assemblyTimeSum = sumBy(journeys, journey => (journey.assemblyTime || 0) + averageDrivingTime)

          datesAndJourneys[date] = {
            capacity: capacitySum,
            assemblyTime: assemblyTimeSum,
          }
        }

        return datesAndJourneys
      },
      filteredJourneys() {
        // let's show only unscheduled by default
        if (!this.selectedDate) return this.journeys.filter(j => !j.scheduledDate)
        const dateString = this.selectedDate

        let filteredJourneys = this.journeys.filter(j => {
          const scheduledDateMatch = j.scheduledDate === dateString
          const requestedDateMatch = !!(j.requestedTimes || []).find(t => this.dateFormat(t) === dateString)

          return scheduledDateMatch || (!j.scheduledDate && requestedDateMatch)
        })

        if (!this.selectedDate) return filteredJourneys

        // the idea here is that if we're filtering by some date let's override
        // the assembly time ordering and sort by scheduled time
        return _.orderBy(filteredJourneys, j => {
          let dateTime = new Date()
          const timeString = j.windowStartsAt || '00:00 am'

          let hours = parseInt(timeString.split(':')[0])
          if (hours != 12 && timeString.indexOf('pm') !== -1) {
            hours += 12
          }

          dateTime.setHours(hours)

          return dateTime.getTime()
        })
      },
      filteredUnscheduledJourneys() {
        return this.filteredJourneys.filter(j => !j.scheduledDate)
      },
      filteredScheduledJourneys() {
        return this.filteredJourneys.filter(j => j.scheduledDate)
      },
      disabledDates() {
        let today = new Date()
        today.setHours(0, 0, 0, 0)

        return {
          days: [0], // Disable Sunday
          customPredictor(date) {
            return date.getTime() < today.getTime()
          },
        }
      },
      journeyCountGroupedBySubregion() {
        let subregionJourneyCount = {}
        this.filteredScheduledJourneys.forEach(journey => {
          const subregionName = get(journey, 'location.subregion.name') || null
          if (subregionName) {
            if (!Object.prototype.hasOwnProperty.call(subregionJourneyCount, subregionName)) {
              subregionJourneyCount[subregionName] = 1
            } else {
              subregionJourneyCount[subregionName]++
            }
          }
        })
        return subregionJourneyCount
      },
    },
    watch: {
      selectedRegion(value, oldValue) {
        const regionId = value.id
        if (regionId) {
          const params = _.merge(this.$route.query, { regionId })
          this.updateQueryParams(params)
        }

        if (regionId !== oldValue.id) {
          this.isLoadingJourneys = true
        }

        this.triggerFeatureFlagEnabledQuery()
      },
    },
    mounted() {
      EventBus.$on('hideRegionConfiguration', () => {
        this.triggerHideRegionConfiguration()
      })
    },
    methods: {
      triggerShowRegionConfiguration() {
        this.showRegionConfiguration = true
      },
      triggerHideRegionConfiguration() {
        this.showRegionConfiguration = false
      },
      selectRegion(event) {
        const regionName = event.target.value
        this.selectedRegion = this.regions.find(r => r.name == regionName) || { id: 0 }
        // every region switch clear the selected date
        this.selectedDate = ''
        this.softScheduleChanges = {}
      },
      dateFormat(date) {
        if (!date) return 'unset'
        let utc = require('dayjs/plugin/utc')
        dayjs.extend(utc)

        return dayjs.utc(date).format('YYYY-MM-DD')
      },
      // compute journey capacity and assembly time from customer facing trips
      transformJourneyData(returnedJourneys) {
        const transformedList = returnedJourneys.map(journey => {
          const customerFacingTrips = journey.unignoredTrips.filter(trip => trip.purpose.indexOf('customer') !== -1)
          const assemblyTime = sumBy(customerFacingTrips, trip => trip.serviceTimeMinutes || 0)
          const capacity = sumBy(customerFacingTrips, trip => trip.totalCubicFeetCapacity || 0)
          const itemCount = sumBy(customerFacingTrips, trip => get(trip, 'inventoryItems.length', 0))

          let inventoryItemIds = []
          customerFacingTrips.forEach(trip => {
            const inventoryItems = trip.inventoryItems || []
            if (inventoryItems.length > 0) {
              inventoryItemIds.push(...inventoryItems)
            }
          })

          return _.merge(journey, {
            assemblyTime,
            capacity,
            itemCount,
            inventoryItemIds: inventoryItemIds,
          })
        })

        return _.orderBy(transformedList, ['assemblyTime'], ['desc'])
      },
      scheduleJourneys() {
        // the assumption is that if one of these journeys has an error
        // saveing that their values are set back to onMount values
        this.softScheduleChanges = {}

        this.$refs.customerUnscheduledJourneys?.forEach(journey => journey.attemptSchedule())
        if (this.$refs.customerScheduledJourneys) {
          this.$refs.customerScheduledJourneys.forEach(journey => journey.attemptSchedule())
        }
      },
      // a customer journey child component will send this event
      // when it changes a scheduled date. We use this event to
      // keep the capacity visualizer up to date
      journeyScheduledEvent(scheduleEventObject) {
        // this happens only on time changes
        if (!scheduleEventObject) {
          this.refreshPendingScheduleChangeValue()
          return
        }

        const journey = this.journeys.find(j => j.id === scheduleEventObject.journeyId)
        if (!journey) return

        this.updateSoftScheduleChangesObject(scheduleEventObject)
        this.refreshAfterSoftSchedule()
      },
      refreshPendingScheduleChangeValue() {
        this.pendingScheduleChange =
          this.$refs.customerUnscheduledJourneys?.findIndex(j => j.pendingChange) !== -1 ||
          (this.$refs.customerScheduledJourneys &&
            this.$refs.customerScheduledJourneys.findIndex(j => j.pendingChange) !== -1)
      },
      updateSoftScheduleChangesObject(scheduleEventObject) {
        const journey = this.journeys.find(j => j.id === scheduleEventObject.journeyId)
        const newDate = scheduleEventObject.newDate
        // not guarenteed to be defined
        const oldDate = scheduleEventObject.oldDate

        const capacityDelta = journey.capacity
        const assemblyDelta = journey.assemblyTime
        let drivingTimeMinutes = 0
        if (this.configurationEnabled) {
          drivingTimeMinutes = this.selectedRegion.drivingTimeMinutes
        }
        // ensure something's defined on these dates
        this.softScheduleChanges[newDate] = this.softScheduleChanges[newDate] || {
          capacityChange: 0,
          assemblyChange: 0,
        }
        if (oldDate) {
          this.softScheduleChanges[oldDate] = this.softScheduleChanges[oldDate] || {
            capacityChange: 0,
            assemblyChange: 0,
          }
        }

        // update based on deltas
        // new date gets added capacity
        // old date frees up capacity
        this.softScheduleChanges[newDate] = {
          capacityChange: this.softScheduleChanges[newDate].capacityChange + capacityDelta,
          assemblyChange: this.softScheduleChanges[newDate].assemblyChange + assemblyDelta + drivingTimeMinutes,
        }
        if (oldDate) {
          this.softScheduleChanges[oldDate] = {
            capacityChange: this.softScheduleChanges[oldDate].capacityChange - capacityDelta,
            assemblyChange: this.softScheduleChanges[oldDate].assemblyChange - assemblyDelta - drivingTimeMinutes,
          }
        }
      },
      // tell any non-reactive properties or child components to
      // refresh after a soft schedule event
      refreshAfterSoftSchedule() {
        this.refreshPendingScheduleChangeValue()
        this.$refs.capacityVisualizer.refreshDayCapacityMetrics()
      },
      journeyScheduled(_journeyId) {
        this.refreshAfterSoftSchedule()
      },
      selectedDateChange(newDate) {
        this.selectedDate = newDate
      },
      triggerFeatureFlagEnabledQuery() {
        this.$apollo.queries.featureFlagEnabled.skip = false
        this.$apollo.queries.featureFlagEnabled.refetch()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .journey-scheduling {
    padding: 0 0 2em 0;

    .region-section {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      .region-name {
        font-size: 28px;
        font-weight: 700;
      }
      .configuration-trigger {
        margin-left: 8px;
        .conf-image {
          cursor: pointer;
          height: 32px;
          width: 32px;
          margin: 2px;
          border: 1px solid #000000;
        }
      }
    }

    .divider {
      border: 1px solid #000000;
      margin-bottom: 40px;
    }

    .journey-header {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 24px;
    }

    .header-copy {
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 16px;
      margin-top: 36px;
    }

    .header-panel {
      height: 70px;
      margin-left: 5%;

      > div {
        display: inline-block;
      }

      .date {
        margin-right: 1rem;
      }

      > div:not(:first-of-type) {
        float: right;

        h5 {
          display: inline;
        }
      }

      .region {
        span {
          font-size: 1.25rem;
          padding-bottom: 20px;
          padding-right: 10px;
          font-weight: 500;
        }

        .custom-select {
          border: 1px solid;
          height: 30px;
          padding: 0 40px 0 10px;
        }
      }

      .date .vdp-datepicker input {
        border: none;
        border-bottom: 1px solid;
      }
    }

    .main-section {
      max-width: 90%;
      margin: 0 auto;
    }

    .schedule-footer {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 75px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 2rem;
      box-shadow: 4px -2px 7px 1px rgb(0 0 0 / 30%);

      button {
        background-color: #ffe82f;
        width: 200px;
        height: 40px;
        border: none;
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }
</style>

<style lang="scss">
  .journey-scheduling {
    .date .vdp-datepicker {
      display: inline;

      div:first-child {
        display: inline;
      }

      input {
        border: 1px solid;
        border-radius: 5px;
        width: 140px;
        height: 30px;
        padding-left: 10px;
      }
    }

    .date .fa {
      font-family: 'FontAwesome';
      color: black;
      position: relative;
      left: -27px;
    }

    .date .fa:hover {
      cursor: pointer;
    }
  }
</style>
