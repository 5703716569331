<template>
    <div class="customer-journey"
         :class="{ 'change' : pendingChange }">
        <div class="main-row">
            <div class="table-row">
                <div class="table-column">
                    <p class="info">
                        {{ journeyPurpose }}
                    </p>
                </div>
                <div class="table-column">
                    <p class="info">
                        {{ customerName }}
                    </p>
                </div>
                <div class="table-column">
                    <div class="info">
                        <div v-for="(date, index) in requestedDates"
                             :key="index">
                            {{ date }}
                        </div>
                    </div>
                </div>
                <div class="table-column">
                    <div v-if="!isSavingJourney"
                         class="journey-date-time">
                        <div>
                            <datepicker v-model="selectedDate"
                                        :format="dateFormat"
                                        placeholder="Schedule"
                                        :disabled-dates="disabledDates" />
                            <i v-if="!selectedDate || !pendingDateChange"
                               class="fa fa-calendar"
                               aria-hidden="true" />
                            <i v-else
                               class="fa fa-times-circle"
                               aria-hidden="true"
                               @click="clearSelectedDate" />
                        </div>
                    </div>
                    <LoadingSpinner v-else
                                    is-black="true" />
                </div>
                <div class="table-column">
                    <div class="time-picker-parent">
                        <vue-timepicker v-model="selectedTime"
                                        format="hh:mm a"
                                        placeholder="Schedule"
                                        hide-disabled-items
                                        hide-clear-button
                                        :minute-interval="30"
                                        auto-scroll />
                        <i v-if="!selectedTimeString || !pendingTimeChange"
                           class="fa fa-clock-o"
                           aria-hidden="true" />
                        <i v-else
                           class="fa fa-times-circle"
                           aria-hidden="true"
                           @click="clearSelectedTime" />
                    </div>
                </div>
                <div class="table-column">
                    <div class="info clickable"
                         @click="showContactInfo">
                        Show
                    </div>
                </div>
                <div class="table-column">
                    <div class="info clickable"
                         @click="showItemInfo">
                        {{ itemCount }}
                    </div>
                </div>
                <div class="table-column">
                    <p class="info">
                        {{ assemblyTime }}
                    </p>
                </div>
                <div class="table-column last-column">
                    <div class="half">
                        <p class="info">
                            {{ journeySubregion }}
                        </p>
                    </div>
                    <div class="half clickable">
                        <div v-if="trips.length"
                             class="chevron"
                             @click="toggleRow">
                            <i v-if="!rowExpaneded"
                               class="fa fa-chevron-down"
                               aria-hidden="true" />
                            <i v-else
                               class="fa fa-chevron-up"
                               aria-hidden="true" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="rowExpaneded"
                 class="hidden-row">
                <CustomerTrips :trips="trips" />
            </div>
        </div>
    </div>
</template>

<script>
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import Datepicker from 'vuejs-datepicker'
  import CustomerTrips from '@/components/resources/scheduling/CustomerTrips'
  import EventBus from '@/components/utilities/EventBus'
  import phoneMixin from '@/mixins/phoneFormatting'
  import { capitalize, deepEqual, get } from '@/helpers/utility'
  import * as dayjs from 'dayjs'
  import utc from 'dayjs/plugin/utc'
  dayjs.extend(utc)

  export default {
    name: 'CustomerJourney',
    components: {
      LoadingSpinner,
      Datepicker,
      CustomerTrips,
    },
    mixins: [phoneMixin],
    props: {
      journeyObject: {
        type: Object,
        default: () => {},
      },
      showRequestedDate: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        selectedDate: this.scheduledDateOnMount(),
        selectedTime: this.scheduledTimeOnMount(),
        rowExpaneded: false,
        isSavingJourney: false,
      }
    },
    computed: {
      journeyPurpose() {
        return capitalize(get(this.journeyObject, 'purpose'))
      },
      customerName() {
        return get(this.journeyObject, 'customer.name')
      },
      customerEmail() {
        return get(this.journeyObject, 'customer.emailAddress')
      },
      customerPhone() {
        const phone = get(this.journeyObject, 'customer.phone')
        if (!phone) return

        return this.formatPhone(phone)
      },
      customerAddress() {
        const address = get(this.journeyObject, 'location.fulltext')
        if (!address) return ''
        return address
      },
      journeySubregion() {
        const subregion = get(this.journeyObject, 'location.subregion.name') || 'N/A'
        return subregion
      },
      itemCount() {
        return this.journeyObject.itemCount || '?'
      },
      inventoryItems() {
        return get(this.journeyObject, 'inventoryItemIds', []).map(ii => ii.id)
      },
      requestedDates() {
        const requestedTimes = this.journeyObject.requestedTimes || []
        return requestedTimes.map(timestamp => timestamp.split('T')[0])
      },
      assemblyTime() {
        const minutes = this.journeyObject.assemblyTime || 0
        const hours = Math.floor(minutes / 60)
        let str = `${minutes % 60} ${this.pluralizeOrNot(minutes % 60, 'minute')}`
        if (hours) {
          str = `${hours} ${this.pluralizeOrNot(hours, 'hour')} ${str}`
        }

        return str
      },
      capacity() {
        const capacity = Math.round(this.journeyObject.capacity || 0)
        return `${capacity} cubic ft.`
      },
      selectedTimeString() {
        const timeString = `${this.selectedTime.hh}:${this.selectedTime.mm} ${this.selectedTime.a}`
        if (timeString.length !== 'xx:00 xx'.length) return null

        return timeString
      },
      pendingDateChange() {
        const passedDate = this.scheduledDateOnMount()
        return this.dateFormat(passedDate) !== this.dateFormat(this.selectedDate)
      },
      pendingTimeChange() {
        const passedTime = this.scheduledTimeOnMount()
        return this.selectedTimeString && !deepEqual(passedTime, this.selectedTime)
      },
      pendingChange() {
        // don't want to permit scheduling if no date is set
        return this.selectedDate && (this.pendingDateChange || this.pendingTimeChange)
      },
      trips() {
        const trips = this.journeyObject.unignoredTrips || []
        // customer facing trips first
        return trips.concat().sort((a, b) => {
          const aIdx = -a.purpose.indexOf('customer')
          const bIdx = -b.purpose.indexOf('customer')
          return aIdx > bIdx ? 1 : bIdx > aIdx ? -1 : 0
        })
      },
      disabledDates() {
        let today = new Date()
        today.setHours(0, 0, 0, 0)

        return {
          days: [0], // Disable Sunday
          customPredictor(date) {
            return date.getTime() < today.getTime()
          },
        }
      },
    },
    watch: {
      selectedDate(value, oldValue) {
        // send an event to parent to let it know we're pending a reschedule
        const dateScheduleEvent = {
          journeyId: this.journeyObject.id,
          oldDate: this.dateFormat(oldValue),
          newDate: this.dateFormat(value),
        }
        this.$emit('pendingDateScheduled', dateScheduleEvent)
      },
      selectedTime() {
        // could be expressed better
        this.$emit('pendingDateScheduled')
      },
    },
    methods: {
      dateFormat(date) {
        if (!date) return

        return dayjs(date).format('YYYY-MM-DD')
      },
      scheduledTimeOnMount() {
        const passedTime = this.journeyObject.windowStartsAt
        if (!passedTime) return { a: null, hh: null, mm: null }

        return {
          a: passedTime.slice(6),
          hh: passedTime.slice(0, 2),
          mm: passedTime.slice(3, 5),
        }
      },
      scheduledDateOnMount() {
        const stringDate = this.journeyObject.scheduledDate // YYYY-MM-DD
        if (stringDate) {
          return new Date(`${stringDate}T00:00:00`) // interpret date in local time zone
        }

        return null
      },
      toggleRow() {
        if (this.trips.length === 0) return

        this.rowExpaneded = !this.rowExpaneded
      },
      clearSelectedDate() {
        this.selectedDate = this.scheduledDateOnMount()
      },
      clearSelectedTime() {
        this.selectedTime = this.scheduledTimeOnMount()
      },
      attemptSchedule() {
        if (!this.pendingChange) return
        this.isSavingJourney = true

        this.$apollo
          .mutate({
            mutation: require('@/graphql/mutations/ScheduleJourney.gql'),
            variables: {
              id: this.journeyObject.id,
              scheduledDate: this.dateFormat(this.selectedDate),
              windowStartsAt: this.selectedTimeString,
            },
          })
          .then(_data => {
            // TODO: figure out a better way to handle cached updates
            // eslint-disable-next-line vue/no-mutating-props
            this.journeyObject.scheduledDate = this.dateFormat(this.selectedDate)
            this.journeyObject.windowStartsAt = this.selectedTimeString /* eslint-disable-line vue/no-mutating-props */

            // TODO: get the state back because it might've been updated
            // trip states too...
            this.$emit('journeyScheduled', this.journeyObject.id)
          })
          .catch(_error => {
            // TODO: add error state to row
            // reset to mounted values
            this.selectedDate = this.scheduledDateOnMount()
            this.selectedTime = this.scheduledTimeOnMount()

            EventBus.$emit('globalAlert', {
              message: `Could not schedule ${this.customerName}'s journey. Make sure schedule date is in the future`,
              statusCode: 3,
            })
          })
          .finally(() => (this.isSavingJourney = false))
      },
      showContactInfo() {
        EventBus.$emit('showModal', {
          modalContentComponent: 'ContactModal',
          modalProps: {
            customerName: this.customerName,
            customerPhone: this.customerPhone,
            customerEmail: this.customerEmail,
            customerAddress: this.customerAddress,
          },
        })
      },
      showItemInfo() {
        EventBus.$emit('showModal', {
          modalContentComponent: 'InventoryItemSummary',
          modalProps: {
            inventoryItemIds: this.inventoryItems,
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .customer-journey {
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid rgba(151, 151, 151, 0.15);

    .main-row {
      display: flex;
      flex-direction: column;

      .table-row {
        width: 98%;
        display: flex;
        margin-left: 1%;
        flex-direction: row;
        height: 130px;
        justify-content: space-between;

        .table-column {
          display: flex;
          width: 11%;
          height: 100%;
          justify-content: center;
          flex-direction: column;
          &.last-column {
            flex-direction: row;
            align-items: center;
            justify-content: initial;
          }

          .time-picker-parent .fa {
            font-family: 'FontAwesome';
            color: black;
            position: relative;
            left: -10px;
            top: 2px;
          }
          .journey-date-time {
            padding: 0.5rem 0;
            display: flex;
            justify-content: space-between;
            ::-webkit-input-placeholder {
              font-size: 13px;
              padding-left: 4px;
            }

            .fa {
              font-family: 'FontAwesome';
              color: black;
              position: relative;
              left: -27px;
            }
          }

          .half {
            width: 50%;

            &.clickable {
              cursor: pointer;
            }
          }
          p {
            margin-bottom: 0px;
          }

          .info {
            font-size: 13px;

            &.clickable {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .hidden-row {
      padding-top: 1rem;
      .trips-info {
        background-color: grey;
        width: 100%;
      }
    }
  }

  .customer-journey.change {
    background-color: lightyellow;
  }

  //   .customer-journey:hover {
  //     cursor: pointer;
  //   }
</style>

<style lang="scss">
  $timeWidth: 100px;
  $inputHeight: 30px;
  $inputBorder: 1px solid;
  $inputBorderRadius: 3px;

  .customer-journey {
    .vdp-datepicker {
      display: inline;
      div:first-child {
        display: inline;
      }

      input {
        border: $inputBorder;
        border-radius: $inputBorderRadius;
        width: 120px;
        height: $inputHeight;
        font-size: 13px;
      }
    }

    .vue__time-picker.time-picker {
      width: $timeWidth;
      display: inline;
      margin-right: -14px;
      font-size: 13px;

      input {
        border: $inputBorder;
        border-radius: $inputBorderRadius;
        width: $timeWidth;
        height: $inputHeight;
      }
      ::-webkit-input-placeholder {
        font-size: 13px;
      }
    }
  }
</style>
