<template>
    <SimpleImageManager
        :images="productImages"
        :image-types="productImageTypes"
        :items-to-exclude="existingProductImageTypes"
        :show-loading-state="isLoadingImageTypes"
        :is-saving-image="isSavingImage"
        :removing-image-index="removingImageIndex"
        @itemAdded="addProductImage"
        @removeImage="removeProductImage" />
</template>

<script>
  import SimpleImageManager from '@/components/forms/SimpleImageManager'

  export default {
    name: 'ProductImageManager',
    components: {
      SimpleImageManager,
    },
    props: {
      productImages: {
        type: Array,
        default: () => [],
      },
      isSavingImage: {
        type: Boolean,
        default: false,
      },
      removingImageIndex: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        singleProductImageTypes: [],
        multipleProductImageTypes: [],
        isLoadingImageTypes: true,
      }
    },
    computed: {
      productImageTypes() {
        return [...this.singleProductImageTypes, ...this.multipleProductImageTypes]
      },
      existingProductImageTypes() {
        const existingProductImageTypes = _.map(this.productImages, (image) => { return image.imageType })

        // Don't include any image types that allow multiple uploads in the list
        // of existing product image types to exclude from the select dropdown
        return _.difference(existingProductImageTypes, this.multipleProductImageTypes)
      },
    },
    mounted() {
      this.getProductImageTypes()
    },
    methods: {
      //////////// CREATED/MOUNTED METHODS ////////////
      getProductImageTypes() {
        this.isLoadingImageTypes = true
        this.axios.get('/product_image_types')
          .then(response => this.onProductImageTypesRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      addProductImage(imageType, file) {
        this.$emit('addProductImage', imageType, file)
      },
      removeProductImage(index) {
        this.$emit('removeProductImage', index)
      },
      //////////// CALLBACKS ////////////
      onProductImageTypesRetrieved(data) {
        this.isLoadingImageTypes = false
        this.singleProductImageTypes = _.without(data.productImageTypes, 'HERO_IMAGE', 'THUMBNAIL_IMAGE', 'THUMBNAIL_HOVER')
        this.multipleProductImageTypes = data.multipleProductImageTypes
      },
    },
  }
</script>
