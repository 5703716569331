var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "discounts-list"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Discounts")]), _c('div', {
    staticClass: "header-links"
  }, [_c('button', {
    staticClass: "gs-standard-button gs-add-button top-button"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'CreateDiscount'
      }
    }
  }, [_vm._v(" Add new discount ")]), _c('i', {
    staticClass: "fa fa-plus-circle"
  })], 1)])]), _c('section', {
    staticClass: "row gs-standard-form gs-special-content"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Filter discounts: ")]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Code:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.codeSearch,
      expression: "codeSearch"
    }],
    domProps: {
      "value": _vm.codeSearch
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.codeSearch = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.includeExpired,
      expression: "includeExpired"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.includeExpired) ? _vm._i(_vm.includeExpired, null) > -1 : _vm.includeExpired
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.includeExpired,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.includeExpired = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.includeExpired = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.includeExpired = $$c;
        }
      }, _vm.getDiscounts]
    }
  }), _c('label', [_vm._v("Include expired")])])])]), _vm.isLoadingDiscounts ? _c('section', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('section', {
    staticClass: "discounts-list-table gs-standard-table gs-hide-filter"
  }, [_vm.filteredDiscounts.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.filteredDiscounts,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "startsAt",
      fn: function fn(data) {
        return _c('div', {}, [_vm._v(" " + _vm._s(data.row['startsAt'] ? _vm.$moment(data.row['startsAt']).tz('America/Los_Angeles').format('MM/DD/YYYY') : '') + " ")]);
      }
    }, {
      key: "expiresAt",
      fn: function fn(data) {
        return _c('div', {}, [_vm._v(" " + _vm._s(data.row['expiresAt'] ? _vm.$moment(data.row['expiresAt']).tz('America/Los_Angeles').format('MM/DD/YYYY') : '') + " ")]);
      }
    }, {
      key: "code",
      fn: function fn(data) {
        return _c('div', {}, [_c('router-link', {
          attrs: {
            "to": {
              name: 'Discount',
              params: {
                discountId: data.row.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.row['code']) + " ")])], 1);
      }
    }], null, false, 3045743297)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no discounts to display. Scrooge. ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }