var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_vm.showModal ? _c('Modal', {
    attrs: {
      "component-class": _vm.modalContentComponent
    },
    on: {
      "close": _vm.closeModal
    }
  }, [_c(_vm.modalContentComponent, _vm._b({
    tag: "component",
    attrs: {
      "success-callback": _vm.modalSuccessCallback
    },
    on: {
      "close": _vm.closeModal
    }
  }, 'component', _vm.modalProps, false))], 1) : _vm._e(), _c('GlobalError'), _c('GlobalAlert'), _vm.currentUser ? _c('Navbar', {
    attrs: {
      "is-hidden": _vm.hideNavbar
    }
  }) : _vm._e(), _c('div', {
    staticClass: "container-fluid"
  }, [_c('router-view')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }