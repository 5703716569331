var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-sku-variations-manager"
  }, [_vm.productSkuVariationsExist ? _c('div', {
    staticClass: "variation-list"
  }, _vm._l(_vm.productSkuVariations, function (variation) {
    return _c('div', {
      key: variation.id,
      staticClass: "variation"
    }, [_vm._v(" " + _vm._s(variation.variationType) + ": "), _c('b', [_vm._v(_vm._s(variation.value))]), _vm._v(" "), variation.sortOrder ? _c('span', [_vm._v(" (" + _vm._s(variation.sortOrder) + ")")]) : _vm._e(), _c('i', {
      staticClass: "fa fa-close remove-variation",
      on: {
        "click": function click($event) {
          return _vm.removeVariation(variation);
        }
      }
    })]);
  }), 0) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" No Variations ")]), _c('div', {
    staticClass: "new-variation"
  }, [_c('SimpleArrayDropdown', {
    attrs: {
      "items": _vm.productSkuVariationTypes,
      "items-to-exclude": _vm.productSkuVariationsToExclude,
      "selected-item": _vm.currentProductSkuVariation,
      "prompt": "Please select a variation (if applicable)",
      "form-width": "20em"
    },
    on: {
      "itemSelected": _vm.variationSelected
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentVariationValue,
      expression: "currentVariationValue"
    }],
    staticClass: "value",
    attrs: {
      "placeholder": "Value"
    },
    domProps: {
      "value": _vm.currentVariationValue
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addVariation();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.currentVariationValue = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentVariationSortOrder,
      expression: "currentVariationSortOrder"
    }],
    staticClass: "sort-order",
    attrs: {
      "type": "number",
      "placeholder": "Sort Order"
    },
    domProps: {
      "value": _vm.currentVariationSortOrder
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addVariation();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.currentVariationSortOrder = $event.target.value;
      }
    }
  })], 1), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isAddVariationDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.addVariation();
      }
    }
  }, [_vm._v(" Add ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }