<template>
    <div class="simple-array-dropdown"
         :style="{ width: formWidth }">
        <!-- bootstrap custom select -->
        <div v-if="!showLoadingState"
             class="input-group mb-3 issue-select">
            <select class="custom-select"
                    @change="selectOption($event)">
                <option>{{ prompt }}</option>
                <option v-for="(item, index) in filteredItems"
                        :key="index"
                        :selected="selected == item">
                    {{ item }}
                </option>
            </select>
        </div>
        <LoadingSpinner v-else
                        is-black="true" />
    </div>
</template>

<script>
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'SimpleArrayDropdown',
    components: {
      LoadingSpinner,
    },
    props: {
      object: {
        type: Object,
        default: () => {},
      },
      items: {
        type: Array,
        default: () => [],
      },
      itemsToExclude: {
        type: Array,
        default: () => [],
      },
      selectedItem: {
        type: String,
        default: '',
      },
      prompt: {
        type: String,
        default: '',
      },
      formWidth: {
        type: String,
        default: '100%',
      },
      showLoadingState: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selected: this.selectedItem,
      }
    },
    computed: {
      filteredItems() {
        return _.difference(this.items, this.itemsToExclude)
      },
    },
    methods: {
      selectOption(event) {
        this.selected = event.target.value
        this.$emit('itemSelected', this.selected, this.object)
      },
    },
  }

</script>

<style lang="scss" scoped>
  .simple-array-dropdown {
    position: relative;
    width: auto;
    display: inline-block;
  }
</style>
