<template>
    <!-- eslint-disable vue/no-unused-vars -->
    <!-- eslint-disable vue/no-template-shadow -->
    <div class="product-attribute-instance gs-simple-crud">
        <ApolloQuery
            :query="require('@/graphql/queries/ProductAttributeInstancesByAttr.gql')"
            :variables="{productAttributeId: productAttributeId}"
            @result="onProductAttributeInstancesRetrieved">
            <template #default="{ result: { loading, error, data } }">
                <!-- Loading -->
                <div v-if="loading"
                     class="loading apollo">
                    Loading...
                </div>

                <!-- Error -->
                <div v-else-if="error"
                     class="error apollo">
                    An error occurred: {{ data.error }}
                </div>

                <!-- Result -->
                <div v-else-if="data"
                     class="result apollo">
                    <h1>{{ data.productAttributes[0].name }}</h1>

                    <ApolloMutation
                        :mutation="require('@/graphql/mutations/CreateProductAttributeInstances.gql')"
                        :variables="{instances: productsToAdd.map(product =>_.merge({}, newProductAttributeInstance, {productId: product.id}))}"
                        @done="onProductAttributeInstancesCreated">
                        <template #default="{ mutate, loading, error }">
                            <SimpleAddRemoveList
                                :items="products"
                                :selected-items="productsToAdd"
                                :allow-duplicates="true"
                                display-key="name"
                                match-key="id"
                                item-type="product"
                                @itemAdded="addProductToList"
                                @itemRemoved="removeProductFromList" />
                            <SimpleCreateForm
                                :primary-error-message="primaryErrorMessage"
                                :error-messages="errorMessages"
                                :show-loading-state="loading"
                                :new-model-instance="newProductAttributeInstance"
                                @formSubmit="mutate" />
                            <p v-if="error">
                                An error occurred: {{ error }}
                            </p>
                        </template>
                    </ApolloMutation>

                    <section class="product-attribute-instance gs-standard-table">
                        <v-client-table
                            v-if="products.length > 0"
                            :data="productsWithInstances(products, data.productAttributes[0].instances)"
                            :columns="columns"
                            :options="options">
                            <div slot="name"
                                 slot-scope="data">
                                <router-link :to="{ name: 'Product', params: { productId: data.row.productId } }">
                                    {{ data.row.name }}
                                </router-link>
                            </div>

                            <div slot="editValue"
                                 slot-scope="data"
                                 class="product-attribute-instance column">
                                <div v-if="isEditingThisItem(data.row.id)">
                                    <SimpleArrayDropdown
                                        v-if="productAttribute.allowedValues"
                                        :items="productAttribute.allowedValues"
                                        :selected-item="data.row.value"
                                        :object="data.row"
                                        prompt="Please select a value..."
                                        class="product-attribute-instance dropdown"
                                        @itemSelected="selectValue" />
                                    <input v-else
                                           v-model="data.row.value" />
                                </div>
                                <span v-else>{{ data.row.value }}</span>
                            </div>

                            <div slot="edit"
                                 slot-scope="data"
                                 class="gs-standard-table-edit">
                                <ApolloMutation
                                    :mutation="require('@/graphql/mutations/UpdateProductAttributeInstances.gql')"
                                    :variables="data.row"
                                    @done="onProductAttributeInstancesUpdated">
                                    <template #default="{ mutate, _loading, updateError }">
                                        <TableEditButton
                                            :is-editing-this-item="isEditingThisItem(data.row.id)"
                                            :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                                            @saveButtonClick="mutate"
                                            @editButtonClick="currentEditProductAttributeInstanceId = data.row.id" />
                                        <p v-if="error">
                                            An error occurred: {{ updateError }}
                                        </p>
                                    </template>
                                </ApolloMutation>
                            </div>

                            <div slot="delete"
                                 slot-scope="data"
                                 class="gs-standard-table-delete">
                                <DeleteIcon @delete="setCurrentDeleteProductAttributeInstance(data.row)" />
                            </div>
                        </v-client-table>

                        <div v-else
                             class="no-items">
                            There are no instances to display
                        </div>
                    </section>

                    <ApolloMutation
                        :mutation="require('@/graphql/mutations/UpdateProductAttributeInstances.gql')"
                        :variables="currentDeletingProductAttributeInstance"
                        @done="onProductAttributeInstancesDeleted">
                        <!-- eslint-disable vue/no-unused-vars -->
                        <template #default="{ mutate, _loading, updateError }">
                            <ConfirmModal
                                v-if="showConfirmDeleteModal"
                                :show-loading-state="isDeletingProductAttributeInstance"
                                @cancel="showConfirmDeleteModal = false"
                                @confirm="mutate">
                                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingProductAttributeInstance.product.name }}</b>? This will also any delete data for this attribute!</span>
                            </ConfirmModal>
                            <p v-if="error">
                                An error occurred: {{ updateError }}
                            </p>
                        </template>
                    </ApolloMutation>
                </div>

                <!-- No result -->
                <div v-else
                     class="no-result apollo">
                    No result :(
                </div>
            </template>
        </ApolloQuery>
    </div>
</template>

<script>

  import SimpleAddRemoveList from '@/components/forms/SimpleAddRemoveList'
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import SimpleArrayDropdown from '@/components/forms/SimpleArrayDropdown'
  import EventBus from '@/components/utilities/EventBus'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'
  import gql from 'graphql-tag'

  export default {
    name: 'ProductAttributeInstances',
    components: {
      SimpleAddRemoveList,
      SimpleCreateForm,
      SimpleArrayDropdown,
      ConfirmModal,
      DeleteIcon,
      TableEditButton,
    },
    data() {
      return {
        products:                                   [],
        productAttributeId:                         this.$route.params.productAttributeId,
        productId:                                  this.$route.params.productId,
        isLoadingProductAttributeInstances:         false,
        errorMessages:                              [],
        primaryErrorMessage:                        '',
        productAttribute:                           null,
        productAttributeInstances:                  [],
        productsToAdd:                              [],
        newProductAttributeInstance:                this.initializeProductAttributeInstance(),
        isCreatingProductAttributeInstance:         false,
        currentEditProductAttributeInstanceId:      null,
        currentSavingProductAttributeInstanceId:    null,
        currentDeletingProductAttributeInstance:    null,
        isDeletingProductAttributeInstance:         false,
        showConfirmDeleteModal:                     false,
        columns:                  ['id', 'name', 'editValue', 'edit', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [10, 20, 50, 100, 250],
          headings: {
            'editValue': 'Value',
          },
        },
      }
    },
    apollo: { products:  gql`{ products { id name } }` },
    methods: {
      addProductToList(product) {
        this.productsToAdd.push(product)
      },
      removeProductFromList(product) {
        arrayHelper.removeObjectByValue(this.productsToAdd, product.id, 'id')
      },
      //////////// TEMPLATE ACTIONS ////////////
      setCurrentDeleteProductAttributeInstance(productAttributeInstance) {
        this.showConfirmDeleteModal = true
        this.currentDeletingProductAttributeInstance = productAttributeInstance
      },
      //////////// CALLBACKS ////////////
      onProductAttributeInstancesRetrieved(data) {
        this.isLoadingProductAttributeInstances = false
        this.productAttributeInstances = data.data.productAttributes[0].instances
        this.productAttribute = data.data.productAttributes[0]
      },
      onProductAttributeInstancesCreated(data) {
        this.isCreatingProductAttributeInstance = false
        data.data.createProductAttributeInstances.forEach(instance => this.productAttributeInstances.push(instance))
        EventBus.$emit('globalAlert', {
          message: 'Successfully created productAttributeInstances!',
          statusCode: 1,
        })
        this.newProductAttributeInstance = this.initializeProductAttributeInstance()
      },
      onProductAttributeInstancesUpdated(data) {
        data.data.updateProductAttributeInstances.forEach(instance => arrayHelper.replaceObjectByValue(this.productAttributeInstances, instance, 'id'))
        this.currentEditProductAttributeInstanceId = null
        this.currentSavingProductAttributeInstanceId = null
      },
      onProductAttributeInstancesDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingProductAttributeInstance = null

        data.data.deleteProductAttributeInstances.forEach(instance => arrayHelper.removeObjectByValue(this.productAttributeInstances, instance.id, 'id'))
        EventBus.$emit('globalAlert', {
          message: 'Product attribute instance successfully deleted!',
          statusCode: 1,
        })
      },
      selectValue(value, instance) {
        instance.value = value
      },
      initializeProductAttributeInstance() {
        this.productsToAdd = _.compact([this.productId || this.$route.params.productId])
        return {
          productAttributeId  : this.productAttributeId || this.$route.params.productAttributeId,
          value               : null,
        }
      },
      isSavingThisItem(id) {
        return this.currentSavingProductAttributeInstanceId === id
      },
      isEditingThisItem(id) {
        return this.currentEditProductAttributeInstanceId === id
      },
      productsWithInstances(products, instances) {
        return products.flatMap(product => {
          return instances.filter(i => i.product.id === product.id)
            .map(instance => _.merge(instance, { productId: product.id, name: product.name }))
        })
      },
    },
  }
</script>

<style lang="scss" scoped>

.product-attribute-instance {
  .column {
    min-width: 15em;
  }

  .dropdown {
    min-width: 10em;
    position: absolute;
  }

  .content {
    margin-top: 1em;
  }
}

</style>
