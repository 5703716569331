var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-list gs-simple-crud"
  }, [_vm.isLoadingUsers ? _c('div', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('section', {
    staticClass: "row gs-standard-form filter-users"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Filter Users: ")]), _c('div', {
    staticClass: "gs-standard-detail col-md-6"
  }, [_c('label', [_vm._v("ID:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.idSearchQuery,
      expression: "idSearchQuery"
    }],
    domProps: {
      "value": _vm.idSearchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.idSearchQuery = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail col-md-6"
  }, [_c('label', [_vm._v("First Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.firstNameSearchQuery,
      expression: "firstNameSearchQuery"
    }],
    domProps: {
      "value": _vm.firstNameSearchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.firstNameSearchQuery = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail col-md-6"
  }, [_c('label', [_vm._v("Last Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.lastNameSearchQuery,
      expression: "lastNameSearchQuery"
    }],
    domProps: {
      "value": _vm.lastNameSearchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.lastNameSearchQuery = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail col-md-6"
  }, [_c('label', [_vm._v("Email:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.emailSearchQuery,
      expression: "emailSearchQuery"
    }],
    domProps: {
      "value": _vm.emailSearchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.emailSearchQuery = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail col-md-6"
  }, [_c('label', [_vm._v("Phone:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phoneSearchQuery,
      expression: "phoneSearchQuery"
    }],
    domProps: {
      "value": _vm.phoneSearchQuery
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.phoneSearchQuery = $event.target.value;
      }
    }
  })])]), _c('section', {
    staticClass: "users-table gs-standard-table gs-hide-filter"
  }, [_vm.filteredUsers.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.filteredUsers,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "id",
      fn: function fn(data) {
        return _c('div', {}, [_c('router-link', {
          attrs: {
            "to": {
              name: 'User',
              params: {
                userId: data.row.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.row.id) + " ")])], 1);
      }
    }], null, false, 1686760858)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no Users to display ")])], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Users")])]);
}]

export { render, staticRenderFns }