var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "journey-scheduling"
  }, [_c('div', {
    staticClass: "header-panel"
  }, [_c('div', {
    staticClass: "region"
  }, [_c('form', {
    staticClass: "form-inline"
  }, [_vm.isLoadingRegions ? _c('LoadingSpinner', {
    staticClass: "region-spinner",
    attrs: {
      "is-black": "true"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectRegion($event);
      }
    }
  }, [_c('option', [_vm._v("Region")]), _vm._l(_vm.regions, function (option) {
    return _c('option', {
      key: option.id,
      domProps: {
        "selected": _vm.selectedRegion.id == option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)])], 1), _vm.selectedRegion.id ? _c('div', {
    staticClass: "region-section"
  }, [_c('div', {
    staticClass: "region-name"
  }, [_vm._v(" " + _vm._s(_vm.selectedRegion.name) + " ")]), _c('div', {
    staticClass: "configuration-trigger"
  }, [_c('img', {
    staticClass: "conf-image",
    attrs: {
      "src": require("@/assets/images/icons/settings.svg")
    },
    on: {
      "click": _vm.triggerShowRegionConfiguration
    }
  })])]) : _vm._e()])]), _vm.selectedRegion.id ? _c('region-configuration', {
    attrs: {
      "show-region-configuration": _vm.showRegionConfiguration,
      "region-object": _vm.selectedRegion
    }
  }) : _vm._e(), _c('div', {
    staticClass: "main-section"
  }, [!_vm._.isEmpty(_vm.selectedRegion) ? _c('CapacityVisualizer', {
    ref: "capacityVisualizer",
    attrs: {
      "capacity-object": _vm.capacityMetricsGroupedByDate,
      "soft-schedule-changes": _vm.softScheduleChanges,
      "region-object": _vm.selectedRegion,
      "journey-count-grouped-by-subregion": _vm.journeyCountGroupedBySubregion
    },
    on: {
      "selectedDateChange": _vm.selectedDateChange
    }
  }) : _vm._e(), _c('hr', {
    staticClass: "divider"
  }), _c('div', {
    staticClass: "journeys-wrapper"
  }, [_vm.isLoadingJourneys ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _vm.filteredJourneys.length ? _c('div', [_c('h4', {
    staticClass: "journey-header"
  }, [_vm._v(" Journeys ")]), _c('h5', {
    staticClass: "header-copy"
  }, [_vm._v(" Unscheduled: " + _vm._s(_vm.filteredUnscheduledJourneys.length) + " ")]), _vm.filteredUnscheduledJourneys.length ? _c('journey-table-header') : _vm._e(), _vm._l(_vm.filteredUnscheduledJourneys, function (journey) {
    return _c('div', {
      key: journey.id
    }, [_c('CustomerJourney', {
      ref: "customerUnscheduledJourneys",
      refInFor: true,
      attrs: {
        "journey-object": journey,
        "show-requested-date": true
      },
      on: {
        "pendingDateScheduled": _vm.journeyScheduledEvent,
        "journeyScheduled": _vm.journeyScheduled
      }
    })], 1);
  }), _vm.selectedDate ? _c('div', [_c('h5', {
    staticClass: "header-copy"
  }, [_vm._v(" Scheduled: " + _vm._s(_vm.filteredScheduledJourneys.length) + " ")]), _vm.filteredScheduledJourneys.length ? _c('journey-table-header') : _vm._e(), _vm._l(_vm.filteredScheduledJourneys, function (journey) {
    return _c('div', {
      key: journey.id
    }, [_c('CustomerJourney', {
      ref: "customerScheduledJourneys",
      refInFor: true,
      attrs: {
        "journey-object": journey,
        "show-requested-date": false
      },
      on: {
        "pendingDateScheduled": _vm.journeyScheduledEvent,
        "journeyScheduled": _vm.journeyScheduled
      }
    })], 1);
  })], 2) : _vm._e()], 2) : _c('h3', [_vm._v(" " + _vm._s(_vm.noItemsCopy) + " ")])], 1)], 1), _vm.pendingScheduleChange ? _c('div', {
    staticClass: "schedule-footer"
  }, [_c('button', {
    staticClass: "schedule-button",
    on: {
      "click": _vm.scheduleJourneys
    }
  }, [_vm._v(" Confirm ")])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }