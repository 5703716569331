var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-attributes-manager"
  }, [_vm.attributesExist ? _c('div', {
    staticClass: "attribute-list"
  }, _vm._l(_vm.productAttributeInstances, function (i) {
    return _c('div', {
      key: i.id,
      staticClass: "attribute"
    }, [_vm._v(" " + _vm._s(i.name) + ": "), _c('b', [_vm._v(_vm._s(i.value))]), _c('i', {
      staticClass: "fa fa-close remove-attribute",
      on: {
        "click": function click($event) {
          return _vm.removeAttribute(i);
        }
      }
    }), i.description ? _c('div', {
      staticClass: "attr-description"
    }, [_vm._v(" " + _vm._s(i.description) + " ")]) : _vm._e()]);
  }), 0) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" No Attributes ")]), _c('div', {
    staticClass: "new-attribute"
  }, [_c('SimpleArrayDropdown', {
    attrs: {
      "items": _vm.productAttributes.map(function (a) {
        return a.name;
      }),
      "selected-item": _vm.currentAttribute.name,
      "prompt": "Please select an attribute",
      "form-width": "20em"
    },
    on: {
      "itemSelected": _vm.attributeSelected
    }
  }), !_vm._.isEmpty(_vm.currentAttribute.allowedValues) ? _c('SimpleArrayDropdown', {
    attrs: {
      "items": _vm.currentAttribute.allowedValues,
      "selected-item": _vm.currentValue,
      "prompt": "Please select a value",
      "form-width": "20em"
    },
    on: {
      "itemSelected": _vm.valueSelected
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currentValue,
      expression: "currentValue"
    }],
    staticClass: "value",
    attrs: {
      "placeholder": "Value"
    },
    domProps: {
      "value": _vm.currentValue
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.addAttribute();
      },
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.currentValue = $event.target.value;
      }
    }
  }), _vm.currentAttribute.description ? _c('div', {
    staticClass: "attr-description"
  }, [_vm._v(" " + _vm._s(_vm.currentAttribute.description) + " ")]) : _vm._e()], 1), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isAddAttributeDisabled
    },
    on: {
      "click": function click($event) {
        return _vm.addAttribute();
      }
    }
  }, [_vm._v(" Add ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }