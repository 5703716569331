var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "discount"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v(_vm._s(_vm.discount.code))]), _c('router-link', {
    attrs: {
      "to": {
        name: 'EditDiscount',
        params: {
          discountId: _vm.$route.params.discountId
        }
      }
    }
  }, [_c('button', {
    staticClass: "gs-standard-button"
  }, [_vm._v(" Edit ")])])], 1), _vm.isLoadingDiscount ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "gs-standard-content"
  }, [_c('section', {
    staticClass: "row discount-details"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Base ")]), _c('div', {
    staticClass: "col-md-4 detail-column"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Code:")]), _c('span', [_vm._v(_vm._s(_vm.discount.code))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Start date:")]), _vm.discount.startsAt ? _c('span', [_vm._v(" " + _vm._s(_vm.$moment(_vm.discount.startsAt).tz('America/Los_Angeles').format('MM/DD/YYYY')) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Expire date:")]), _vm.discount.expiresAt ? _c('span', [_vm._v(" " + _vm._s(_vm.$moment(_vm.discount.expiresAt).tz('America/Los_Angeles').format('MM/DD/YYYY')) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Uses Remaining:")]), _c('span', [_vm._v(_vm._s(_vm.discount.usesRemaining))])])]), _c('div', {
    staticClass: "col-md-4 detail-column"
  }, [_c('h4', [_vm._v("Details")]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Amount:")]), _c('span', [_vm._v(_vm._s(_vm.discount.amount))]), _vm.discount.discountType === 'percentage' ? _c('span', [_vm._v("%")]) : _vm.discount.discountType === 'dollars' ? _c('span', [_vm._v(" ($)")]) : _vm._e()]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Frequency:")]), _c('span', [_vm._v(_vm._s(_vm.discount.frequency))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Minimum cart size ($):")]), _c('span', [_vm._v(_vm._s(_vm.discount.minimumCartDollars))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Credit Rollover:")]), _c('span', [_vm._v(_vm._s(_vm.discount.creditRollover))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("First order only:")]), _c('span', [_vm._v(_vm._s(_vm.discount.firstOrderOnly))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Part of referral program:")]), _c('span', [_vm._v(_vm._s(_vm.discount.referralProgram))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Referral reward:")]), _c('span', [_vm._v(_vm._s(_vm.discount.referralRewardAmount))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Label:")]), _c('span', [_vm._v(_vm._s(_vm.discount.label))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Title:")]), _c('span', [_vm._v(_vm._s(_vm.discount.title))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Description:")]), _c('span', [_vm._v(_vm._s(_vm.discount.description))])])]), _c('div', {
    staticClass: "col-md-4 detail-column"
  }, [_c('h4', [_vm._v("Administration")]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Creator:")]), _c('span', [_vm._v(_vm._s(_vm.discount.creatorEmail))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Times applied:")]), _c('span', [_vm._v(_vm._s(_vm.discount.stats.applicationCount))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Unique users:")]), _c('span', [_vm._v(_vm._s(_vm.discount.stats.uniqueUsers))])]), _vm.discount.internalNotes ? _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Internal notes:")]), _vm._v(" " + _vm._s(_vm.discount.internalNotes) + " ")]) : _c('span', {
    staticClass: "no-items"
  }, [_vm._v(" No internal notes ")])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }