<template>
    <div class="blackout-dates gs-simple-crud">
        <section class="row">
            <div class="col-md-10 detail-column">
                <div class="column-item">
                    <h2>Blackout dates</h2>

                    <div v-if="blackoutDates.length">
                        <div v-for="row in blackoutDates"
                             :key="row.id"
                             class="blackout-date-row">
                            <label>{{ row.date }}</label>
                            <label>{{ row.regionName || 'All Regions' }}</label>
                            <label>allow delivery [{{ row.allowDelivery ? '✔' : '✘' }}]</label>
                            <label>allow processing [{{ row.allowProcessing ? '✔' : '✘' }}] </label>
                            <label>allow pickup [{{ row.allowPickup ? '✔' : '✘' }}] </label>
                            <label>{{ row.description ? `(${row.description})` : null }}</label>
                            <button class="gs-standard-button"
                                    @click="removeDate(row.id)">
                                X
                            </button>
                        </div>
                    </div>
                    <div v-else>
                        No blackout dates
                    </div>
                </div>
            </div>
        </section>
        <section class="row">
            <div class="col-md-6 detail-column">
                <div class="column-item">
                    <h2>Create New Blackout Date</h2>
                    <div class="gs-standard-detail">
                        <label>Date</label>
                        <datepicker v-model="dateToBlackout"
                                    :format="formatDate"
                                    :disabled-dates="disabledDates" />
                    </div>
                    <div class="gs-standard-detail">
                        <label>Region (optional)</label>
                        <select class="custom-select"
                                @change="selectRegion($event)">
                            <option>All</option>
                            <option v-for="option in regions"
                                    :key="option.id"
                                    :selected="selectedRegion.id == option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                    <div class="gs-standard-detail">
                        <input v-model="allowDelivery"
                               type="checkbox" />
                        <label>Allow delivery</label>
                    </div>
                    <div class="gs-standard-detail">
                        <input v-model="allowProcessing"
                               type="checkbox" />
                        <label>Allow processing</label>
                    </div>
                    <div class="gs-standard-detail">
                        <input v-model="allowPickup"
                               type="checkbox" />
                        <label>Allow pickup</label>
                    </div>
                    <div class="gs-standard-detail">
                        <textarea v-model="description"
                                  placeholder="Description (optional)" />
                    </div>
                    <div class="gs-standard-detail">
                        <button class="gs-standard-button"
                                :disabled="!dateToBlackout"
                                @click="createBlackoutDate">
                            Add +
                        </button>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
  import Datepicker from 'vuejs-datepicker'
  import EventBus from '@/components/utilities/EventBus'

  export default {
    name: 'BlackoutDates',
    components: {
      Datepicker,
    },
    data() {
      return {
        blackoutDates: [],
        regions: [],
        // new blackout date attrs
        allowDelivery: false,
        allowProcessing: false,
        allowPickup: false,
        selectedRegion: 'All',
        dateToBlackout: null,
        description: null,
      }
    },
    apollo: {
      regions: {
        query: require('@/graphql/queries/Regions.gql'),
        result(response, _key) {
          this.regions = response.data.regions
        },
      },
    },
    computed: {
      disabledDates() {
        let today = new Date()
        today.setHours(0, 0, 0, 0)

        return {
          days: [0], // Disable Sunday
          customPredictor(date) {
            return date.getTime() < today.getTime()
          },
        }
      },
    },
    mounted() {
      this.getBlackoutDates()
    },
    methods: {
      getBlackoutDates() {
        this.axios
          .get('/blackout_dates')
          .then(response => {
            this.blackoutDates = response.data.blackoutDates
          })
          .catch(() => {
            EventBus.$emit('globalAlert', {
              message: 'could not fetch existing blackout dates',
              statusCode: 3,
            })
          })
      },
      createBlackoutDate() {
        this.axios
          .post('/blackout_dates', {
            blackoutDate: {
              date: this.formatDate(this.dateToBlackout),
              allowDelivery: this.allowDelivery,
              allowProcessing: this.allowProcessing,
              allowPickup: this.allowPickup,
              description: this.description,
              region_name: _.get(this.selectedRegion, 'name'), // if null, that means "all"
            },
          })
          .then(() => {
            this.getBlackoutDates()
            this.resetNewBlackoutDateAttributes()
          })
        // no catch, the endpoint doesn't return errors, wtf
      },
      removeDate(id) {
        this.axios
          .delete(`/blackout_dates/${id}`)
          .then(() => this.getBlackoutDates())
          .catch(() => {
            EventBus.$emit('globalAlert', {
              message: 'could not delete blackout date',
              statusCode: 3,
            })
          })
      },
      selectRegion(event) {
        const regionName = event.target.value
        this.selectedRegion = _.find(this.regions, r => r.name == regionName) || { id: 0 }
      },
      formatDate(date) {
        return this.$moment(date).format('YYYY-MM-DD')
      },
      resetNewBlackoutDateAttributes() {
        this.allowDelivery = false
        this.allowProcessing = false
        this.allowPickup = false
        this.selectedRegion = '(all regions)'
        this.dateToBlackout = null
        this.description = null
      },
    },
  }
</script>

<style lang="scss" scoped>
  .blackout-dates {
    .row {
      margin-bottom: 3rem;
    }

    .blackout-date-row {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8px;

      label {
        min-width: 10rem;
      }

      label:first-child {
        font-weight: bold;
      }

      .gs-standard-button {
        width: 45px;
        padding: 2px;
        color: black;
      }
    }

    .column-item .gs-standard-detail {
      padding-bottom: 12px;

      input[type='checkbox'] {
        margin-right: 1rem;
        width: 1rem;
        height: 1rem;
      }

      input[type='checkbox']:hover {
        cursor: pointer;
      }

      textarea {
        width: 20rem;
      }
    }

    .custom-select {
      max-width: 20rem;
      display: block;
    }
  }
</style>
