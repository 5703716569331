<template>
    <div class="product-attributes-manager">
        <div v-if="attributesExist"
             class="attribute-list">
            <div v-for="i in productAttributeInstances"
                 :key="i.id"
                 class="attribute">
                {{ i.name }}: <b>{{ i.value }}</b><i class="fa fa-close remove-attribute"
                                                     @click="removeAttribute(i)" />
                <div v-if="i.description"
                     class="attr-description">
                    {{ i.description }}
                </div>
            </div>
        </div>
        <div v-else
             class="no-items">
            No Attributes
        </div>

        <div class="new-attribute">
            <SimpleArrayDropdown
                :items="productAttributes.map(a => a.name)"
                :selected-item="currentAttribute.name"
                prompt="Please select an attribute"
                form-width="20em"
                @itemSelected="attributeSelected" />
            <SimpleArrayDropdown
                v-if="!_.isEmpty(currentAttribute.allowedValues)"
                :items="currentAttribute.allowedValues"
                :selected-item="currentValue"
                prompt="Please select a value"
                form-width="20em"
                @itemSelected="valueSelected" />
            <input v-else
                   v-model="currentValue"
                   class="value"
                   placeholder="Value"
                   @keyup.enter="addAttribute()" />
            <div v-if="currentAttribute.description"
                 class="attr-description">
                {{ currentAttribute.description }}
            </div>
        </div>

        <button :disabled="isAddAttributeDisabled"
                class="gs-standard-button"
                @click="addAttribute()">
            Add
        </button>
    </div>
</template>

<script>

  import SimpleArrayDropdown from '@/components/forms/SimpleArrayDropdown'
  import gql from 'graphql-tag'

  export default {
    name: 'ProductAttributeManager',
    components: {
      SimpleArrayDropdown,
    },
    props: {
      productId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        currentValue:       '',
        currentAttribute:   {},
        productAttributes: [],
        productAttributeInstances: [],
      }
    },
    apollo: {
      productAttributes: gql`{productAttributes { id name allowedValues description } }`,
      productAttributeInstances: {
        query: gql`query ($productId: ID!) {
                    productAttributes(productIds: [$productId]) {
                        id name allowedValues description
                        instances(productIds: [$productId]) {
                            id value product { id }
                        }
                    }
                }`,
        variables() { return { productId: this.productId } },
        update(data) {
          return _.flatMap(data.productAttributes, a => _.map(a.instances,
                                                              i => _.merge({}, i, {
                                                                name: a.name, description: a.description, allowedValues: a.allowedValues,
                                                              })))
        },
      },
    },
    computed: {
      isAddAttributeDisabled() {
        return !this.currentValue || !this.currentAttribute
      },
      attributesExist() {
        return Object.keys(this.productAttributes).length > 0
      },
    },
    methods: {
      //////////// TEMPLATE ACTIONS ////////////
      attributeSelected(name) {
        this.currentAttribute = _.find(this.productAttributes, ['name', name])
      },
      valueSelected(value) {
        this.currentValue = value
      },
      addAttribute() {
        if (!this.currentValue || !this.currentAttribute) return
        const id = this.currentAttribute.id
        // XXX: there's no error recovery here - we should either refactor this to use a different pattern, or add error handling into this block
        // Also, we need to get rid of the duplication in `refetchQueries` here
        this.$apollo.mutate({
          mutation: gql`mutation($productAttributeId: ID!, $productId: ID!, $value: Json!) {
                        createProductAttributeInstances(instances: [{productAttributeId: $productAttributeId, productId: $productId, value: $value}]) { id value }
                     }`,
          variables: { productAttributeId: id, value: this.currentValue, productId: this.productId },
        }).then(() => this.$apollo.queries.productAttributeInstances.refetch({ productId: this.productId }))
      },
      removeAttribute(instance) {
        // XXX: there's no error recovery here - we should either refactor this to use a different pattern, or add error handling into this block
        // Also, we need to get rid of the duplication in `refetchQueries` here
        this.$apollo.mutate({
          mutation: gql`mutation($id: ID!) {
                        deleteProductAttributeInstances(ids: [$id]) { id value }
                     }`,
          variables: { id: instance.id },
        }).then(() => this.$apollo.queries.productAttributeInstances.refetch({ productId: this.productId }))
      },
    },
  }
</script>

<style lang="scss" scoped>

.product-attributes-manager {

  .attribute-list {
    margin:                  1em;

    .attribute {
      padding:                0.5em;
      border:                 1px solid lightgray;
      border-radius:          0.5em;
      width:                  50%;
      margin-top:             0.5em;

      .remove-attribute {
        float:                right;
        cursor:               pointer;
        padding:              0.25em;
      }
    }
  }

  .attr-description {
    padding-left: 30px;
  }
  .attr-description::before {
    content: "- "
  }

  .new-attribute {

    .value {
      margin-left:            1em;
      padding:                0.5em;
    }
  }

  .gs-standard-button {
    margin-top:               1em;
    display:                  inline-block;
  }
}

</style>
