var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "simple-create-remove-list"
  }, [_c('div', {
    staticClass: "value-zone"
  }, _vm._l(_vm.values, function (value, index) {
    return _c('span', {
      key: index,
      staticClass: "value"
    }, [_c('span', {
      staticClass: "value-name"
    }, [_vm._v(" " + _vm._s(value) + " ")]), _c('i', {
      staticClass: "fa fa-close change-value",
      on: {
        "click": function click($event) {
          return _vm.valueRemoved(value, index);
        }
      }
    })]);
  }), 0), _c('div', {
    staticClass: "add-value"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newValue,
      expression: "newValue"
    }],
    domProps: {
      "value": _vm.newValue
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.newValue = $event.target.value;
      }
    }
  }), _c('i', {
    staticClass: "fa fa-plus change-value",
    on: {
      "click": function click($event) {
        return _vm.addValue(_vm.newValue);
      }
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }