<template>
    <div class="journey-issue">
        <div class="body">
            <div class="title">
                <h4 class="h4-emphasized capitalized">
                    {{ title }}
                </h4>
                <hr />
            </div>

            <form @submit.prevent="attemptSubmit">
                <div v-if="existingIssues?.length"
                     class="issue-select">
                    <span class="capitalized">Existing {{ tripPurpose }} Trip Issues:</span>
                    <ul id="existingIssueList">
                        <li v-for="issue in existingIssues"
                            :key="issue.id">
                            <form @submit.prevent="">
                                <div>
                                    <DeleteIcon @delete="deleteIssue(issue)" />
                                    {{ issue.issue?.split('_')?.join(' ')?.trim() }}
                                    <span v-if="issue.note"> ({{ issue.note.trim() }}) </span>
                                </div>
                            </form>
                        </li>
                    </ul>
                </div>

                <div class="issue-select">
                    <span class="capitalized">{{ tripPurpose }} Trip Issue Type</span>
                    <div class="input-group mb-3 issue-select">
                        <select class="custom-select"
                                @change="selectIssue($event)">
                            <option>Select issue</option>
                            <option v-for="option in _.keys(issueOptions)"
                                    :key="option"
                                    :selected="selectedIssue == option">
                                {{ option }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="issue-notes">
                    <span>Notes</span>
                    <textarea v-model="issueNote"
                              type="text"
                              name="issue-notes"
                              placeholder="(extra details)" />
                </div>
                <div class="submit">
                    <button type="submit"
                            :disabled="isSubmitting">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

  import DeleteIcon from '@/components/widgets/DeleteIcon'

  export default {
    name: 'JourneyIssueReporter',
    components: {DeleteIcon},
    props: {
      journeyId: {
        type: String,
        default: null,
      },
      existingIssues: {
        type: Array,
        default: () => [],
      },
      tripPurpose: {
        type: String,
        default: 'delivery',
      },
      successCallback: {
        type: Function,
        default: () => {},
      },
    },
    data() {
      return {
        deliveryIssueOptions: {
          'Customer unable to receive delivery': 'customer_unable_to_receive_delivery',
          'Customer not present': 'customer_not_present',
          'Day-of cancellation': 'day_of_cancellation',
          'Missed task': 'missed_task',
          'Damage to customer property': 'damage_to_customer_property',
          'On site pickup request (Oliver Item)': 'onsite_pickup_request_for_oliver_item',
          'On site pickup request (Non-Oliver Item)': 'onsite_pickup_request_for_non_oliver_item',
          'Other (add a comment)': 'journey_issue_other',
        },
        pickupIssueOptions: {
          'Customer unable to receive pickup': 'customer_unable_to_receive_pickup',
          'Customer not present': 'customer_not_present',
          'Day-of cancellation': 'day_of_cancellation',
          'Missed task': 'missed_task',
          'Damage to customer property': 'damage_to_customer_property',
          'Additional on site pickup request (Oliver Item)': 'onsite_pickup_request_for_oliver_item',
          'Additional on site pickup request (Non-Oliver Item)': 'onsite_pickup_request_for_non_oliver_item',
          'Other (add a comment)': 'journey_issue_other',
        },
        otherIssueOptions: {
          'Other (add a comment)': 'journey_issue_other',
        },
        selectedIssue: '',
        issueNote: '',
        isSubmitting: false,
      }
    },
    computed: {
      title() {
        return `${this.tripPurpose} Issue`
      },
      issueOptions() {
        switch (this.tripPurpose) {
          case 'delivery':
            return this.deliveryIssueOptions
          case 'pickup':
            return this.pickupIssueOptions
          default:
            return this.otherIssueOptions
        }
      },
    },
    methods: {
      deleteIssue(issue) {
        if (confirm("Really delete issue? This cannot be undone.")) {
          const issueVariables = {
            id: issue.id,
          }
          this.$apollo.mutate({
            mutation: require('@/graphql/mutations/DeleteJourneyIssue.gql'),
            variables: issueVariables,
          }).then((_data) => {
            issueVariables.deleted = true
            this.successCallback(issueVariables)
            this.$emit('close')
          }).catch((error) => {
            alert(error)
          })
        }
      },
      selectIssue(event) {
        const issueValue = event.target.value
        this.selectedIssue = this.issueOptions[issueValue]
      },
      submitIssue() {
        const issueVariables = {
          journeyId: this.journeyId,
          issue: this.selectedIssue,
          note: this.issueNote,
        }

        this.isSubmitting = true

        this.$apollo.mutate({
          mutation: require('@/graphql/mutations/CreateJourneyIssue.gql'),
          variables: issueVariables,
        }).then((response) => {
          issueVariables.id = response.data.createJourneyIssue.id
          this.successCallback(issueVariables)
          this.$emit('close')
        }).catch((error) => {
          alert(error)
        }).finally(() => this.isSubmitting = false)
      },
      attemptSubmit() {
        if (!this.selectedIssue) {
          alert('Please select an issue option')
          return
        }

        if (this.selectedIssue.indexOf('_other') !== -1 && !this.issueNote) {
          alert('Please provide an issue note')
          return
        }

        this.submitIssue()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .journey-issue {

    .title {
      margin-bottom: 1rem;

      h4 {
        font-weight: bold;
        text-align: left;
      }

      hr {
        border-bottom: 1px solid;
        margin: 2px 0 0 0;
      }
    }

    .capitalized {
      text-transform: capitalize;
    }

    span {
      font-weight: bold;
    }

    .issue-select {
      margin-bottom: 1rem;
    }

    .issue-notes {
      margin-bottom: 1rem;

      textarea {
        width: 100%;
        height: 10em;
        padding: 0.5rem;
        border: none;
        background: rgba(151, 151, 151, 0.1);
        box-shadow: inset 0px 1px 4px 2px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
      }

      textarea.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .submit {
      button {
        background-color: #FFE82F;
        color: black;
        border: none;
        width: 180px;
        font-weight: bold;
        height: 40px;
        border-radius: 2px;
        float: right;
      }
      button.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
</style>

<style lang="scss">
  .journey-issue {
    .simple-dropdown {
      width: 50%;
      margin-left: 1rem;
      padding: 0 2.5rem 0 1rem;
      height: 30px;
      line-height: 30px;

      .select-area > i {
        top: 0.3rem;
      }
    }

    .simple-dropdown.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
</style>
