var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.disableButton
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('buttonClick');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }