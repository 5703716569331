var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "create-discount"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Create Discount")]), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "type": "submit",
      "disabled": _vm.isSavingDiscount
    },
    on: {
      "click": function click($event) {
        return _vm.createDiscount();
      }
    }
  }, [!_vm.isSavingDiscount ? _c('span', [_vm._v("Create")]) : _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.5"
    }
  })], 1)]), _c('div', {
    staticClass: "create-form gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "row discount-details"
  }, [_c('h2', [_vm._v("Discount Details")]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Code")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.code,
      expression: "newDiscount.code"
    }],
    domProps: {
      "value": _vm.newDiscount.code
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "code", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Start Date")]), _c('datepicker', {
    attrs: {
      "disabled-dates": _vm.disabledDates
    },
    model: {
      value: _vm.newDiscount.startsAt,
      callback: function callback($$v) {
        _vm.$set(_vm.newDiscount, "startsAt", $$v);
      },
      expression: "newDiscount.startsAt"
    }
  })], 1), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Expire Date")]), _c('datepicker', {
    attrs: {
      "disabled-dates": _vm.disabledDates
    },
    model: {
      value: _vm.newDiscount.expiresAt,
      callback: function callback($$v) {
        _vm.$set(_vm.newDiscount, "expiresAt", $$v);
      },
      expression: "newDiscount.expiresAt"
    }
  })], 1), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Uses remaining")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.usesRemaining,
      expression: "newDiscount.usesRemaining"
    }],
    domProps: {
      "value": _vm.newDiscount.usesRemaining
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "usesRemaining", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Amount")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.amount,
      expression: "newDiscount.amount"
    }],
    staticClass: "amount",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.newDiscount.amount
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "amount", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.discountType,
      expression: "newDiscount.discountType"
    }],
    attrs: {
      "id": "dollars",
      "type": "radio",
      "value": "dollars"
    },
    domProps: {
      "checked": _vm._q(_vm.newDiscount.discountType, "dollars")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.newDiscount, "discountType", "dollars");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "dollars"
    }
  }, [_vm._v("$")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.discountType,
      expression: "newDiscount.discountType"
    }],
    attrs: {
      "id": "percentage",
      "type": "radio",
      "value": "percentage"
    },
    domProps: {
      "checked": _vm._q(_vm.newDiscount.discountType, "percentage")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.newDiscount, "discountType", "percentage");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "percentage"
    }
  }, [_vm._v("%")])]), _c('div', {
    staticClass: "discount-detail frequency"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Frequency")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.frequency,
      expression: "newDiscount.frequency"
    }],
    attrs: {
      "id": "once",
      "type": "radio",
      "value": "once"
    },
    domProps: {
      "checked": _vm._q(_vm.newDiscount.frequency, "once")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.newDiscount, "frequency", "once");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "once"
    }
  }, [_vm._v("once")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.frequency,
      expression: "newDiscount.frequency"
    }],
    attrs: {
      "id": "recurring",
      "type": "radio",
      "value": "recurring"
    },
    domProps: {
      "checked": _vm._q(_vm.newDiscount.frequency, "recurring")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.newDiscount, "frequency", "recurring");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "recurring"
    }
  }, [_vm._v("recurring")])]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Credit Rollover?")]), _c('label', {
    staticClass: "explanation"
  }, [_vm._v(" If a user's order is less than the discount amount, should they receive a credit for the subsequent months? ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.creditRollover,
      expression: "newDiscount.creditRollover"
    }],
    attrs: {
      "type": "radio",
      "value": "true"
    },
    domProps: {
      "checked": _vm._q(_vm.newDiscount.creditRollover, "true")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.newDiscount, "creditRollover", "true");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "true"
    }
  }, [_vm._v("true")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.creditRollover,
      expression: "newDiscount.creditRollover"
    }],
    attrs: {
      "type": "radio",
      "value": "false"
    },
    domProps: {
      "checked": _vm._q(_vm.newDiscount.creditRollover, "false")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.newDiscount, "creditRollover", "false");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "false"
    }
  }, [_vm._v("false")])]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Minimum cart size ($)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.minimumCartDollars,
      expression: "newDiscount.minimumCartDollars"
    }],
    staticClass: "amount",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.newDiscount.minimumCartDollars
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "minimumCartDollars", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.firstOrderOnly,
      expression: "newDiscount.firstOrderOnly"
    }],
    attrs: {
      "id": "first-order-checkbox",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.newDiscount.firstOrderOnly) ? _vm._i(_vm.newDiscount.firstOrderOnly, null) > -1 : _vm.newDiscount.firstOrderOnly
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.newDiscount.firstOrderOnly,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.newDiscount, "firstOrderOnly", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.newDiscount, "firstOrderOnly", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.newDiscount, "firstOrderOnly", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "first-order-checkbox"
    }
  }, [_vm._v("First order only")])]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.referralProgram,
      expression: "newDiscount.referralProgram"
    }],
    attrs: {
      "id": "referral-checkbox",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.newDiscount.referralProgram) ? _vm._i(_vm.newDiscount.referralProgram, null) > -1 : _vm.newDiscount.referralProgram
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.newDiscount.referralProgram,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.newDiscount, "referralProgram", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.newDiscount, "referralProgram", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.newDiscount, "referralProgram", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "referral-checkbox"
    }
  }, [_vm._v("Part of referral program")])]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Referral reward ($)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.referralRewardAmount,
      expression: "newDiscount.referralRewardAmount"
    }],
    staticClass: "amount",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.newDiscount.referralRewardAmount
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "referralRewardAmount", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Minimum cart size ($)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.minimumCartDollars,
      expression: "newDiscount.minimumCartDollars"
    }],
    staticClass: "amount",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.newDiscount.minimumCartDollars
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "minimumCartDollars", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "discount-detail"
  }, [_c('label', [_vm._v("Label")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.label,
      expression: "newDiscount.label"
    }],
    domProps: {
      "value": _vm.newDiscount.label
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "label", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', [_vm._v("Title")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.title,
      expression: "newDiscount.title"
    }],
    domProps: {
      "value": _vm.newDiscount.title
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "title", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', [_vm._v("Description")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.description,
      expression: "newDiscount.description"
    }],
    domProps: {
      "value": _vm.newDiscount.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "description", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Internal Notes")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newDiscount.internalNotes,
      expression: "newDiscount.internalNotes"
    }],
    domProps: {
      "value": _vm.newDiscount.internalNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newDiscount, "internalNotes", $event.target.value);
      }
    }
  })])])])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }