<template>
    <div class="simple-create-form">
        <form class="add-new gs-standard-form col-md-6"
              @submit.prevent="$emit('formSubmit')">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />

            <div v-for="(value, key) in newModelInstance"
                 :key="key"
                 class="gs-standard-detail">
                <label :for="key">{{ makeCamelCaseReadable(key) }}:</label>
                <slot :name="key"
                      :value="value"
                      :model="newModelInstance[key]">
                    <!-- eslint-disable vue/no-mutating-props -->
                    <!-- this is bad, we shouldn't be doing this -->
                    <input :key="key"
                           v-model="newModelInstance[key]"
                           :name="key"
                           :placeholder="makeCamelCaseReadable(key)"
                           :type="inputType(key, value)" />
                </slot>
            </div>

            <button type="submit"
                    :disabled="showLoadingState || !isValid"
                    class="gs-standard-button">
                <span v-if="!showLoadingState">Create</span>
                <LoadingSpinner v-else
                                max-height="1.5" />
            </button>
        </form>
    </div>
</template>

<script>

// Note that objects and arrays in JavaScript are passed by reference, so if the prop is an array or object,
// mutating the object or array itself inside the child component WILL affect parent state.

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import FormErrors from '@/components/forms/FormErrors'
  import { stringHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'SimpleCreateForm',
    components: {
      FormErrors,
      LoadingSpinner,
    },
    props: {
      primaryErrorMessage: {
        type: String,
        default: '',
      },
      errorMessages: {
        type: Array,
        default: () => [],
      },
      showLoadingState: {
        type: Boolean,
        default: false,
      },
      newModelInstance: {
        type: Object,
        default: () => {},
      },
      isValid: {
        type: Boolean,
      },
    },
    methods: {
      makeCamelCaseReadable(str) {
        return stringHelper.makeCamelCaseReadable(str)
      },
      inputType(key, value) {
        switch ((_.isNil(value) ? '' : value).constructor.name) {
          case 'Boolean':
            return 'checkbox'
          default:
            return 'text'
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

.simple-create-form {

  .gs-standard-form {

    > input {
      display:                block;
    }
  }

}

</style>
