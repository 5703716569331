import axios from '@/api/axios'

var apiHelper = (function() {
  // See https://gist.github.com/ghinda/8442a57f22099bdb2e34
  function convertModelToFormData(data = {}, form = null, namespace = '') {
    let files = {}
    let model = {}

    for (const [key, value] of Object.entries(data)) {
      if (value instanceof File) {
        files[key] = value
      } else {
        model[key] = value
      }
    }

    let localFormData = form || new FormData()

    for (const [key, value] of Object.entries(model)) {
      if (!value) continue

      const formKey = namespace ? `${namespace}[${_.snakeCase(key)}]` : _.snakeCase(key)

      if (value instanceof Date) {
        localFormData.append(formKey, value.toISOString())
      } else if (value instanceof File) {
        localFormData.append(formKey, value)
      } else if (value instanceof Array) {
        value.forEach((element, index) => {
          const tempFormKey = `${formKey}[${index}]`
          if (typeof element === 'object') {
            this.convertModelToFormData(element, localFormData, tempFormKey)
          } else {
            localFormData.append(tempFormKey, element.toString())
          }
        })
      } else if (typeof value === 'object' && !(value instanceof File)) {
        this.convertModelToFormData(value, localFormData, formKey)
      } else {
        localFormData.append(formKey, value.toString())
      }
    }

    for (const [key, value] of Object.entries(files)) {
      let formKey = namespace ? `${namespace}[${_.snakeCase(key)}]` : _.snakeCase(key)
      localFormData.append(formKey, value)
    }

    return localFormData
  }

  function submitMultipartForm(params, endpoint, onSuccess, onFailure, method = 'post') {
    let config = {
      url: endpoint,
      method: method,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: this.convertModelToFormData(params),
    }

    axios.request(config)
      .then((response) => onSuccess(response.data, params))
      .catch((error) => onFailure(error.response))
  }

  return {
    convertModelToFormData: convertModelToFormData,
    submitMultipartForm: submitMultipartForm,
  }
})()

export default apiHelper
