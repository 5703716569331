var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inventory-item-summary"
  }, [_vm._m(0), _c('div', {
    staticClass: "body"
  }, [_vm.isLoadingItems ? _c('div', [_c('loading-spinner')], 1) : _c('div', {
    staticClass: "item-details"
  }, [_vm._m(1), _vm._l(_vm.inventoryItems, function (item) {
    return _c('div', {
      key: item.key
    }, [_c('div', {
      staticClass: "item-table-row"
    }, [_c('div', {
      staticClass: "item-column"
    }, [_c('cld-image', {
      staticClass: "inventory-item-photo",
      attrs: {
        "public-id": _vm.getPublicID(item.productSku.productImages),
        "width": "75",
        "height": "75",
        "crop": "limit"
      }
    })], 1), _c('div', {
      staticClass: "item-column name"
    }, [_vm._v(" " + _vm._s(item.productSku.name) + " - " + _vm._s(item.productSku.sku) + " ")]), _c('div', {
      staticClass: "item-column item-id"
    }, [_vm._v(" " + _vm._s(item.id) + " ")]), _c('div', {
      staticClass: "item-column"
    }, [_vm._v(" " + _vm._s(_vm.getAssemblyTime(item)) + " ")]), _c('div', {
      staticClass: "item-column"
    }, [_vm._v(" " + _vm._s(_vm.getPacketDimensions(item)) + " ")])]), _c('hr')]);
  })], 2)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('h3', {
    staticClass: "emphasized"
  }, [_vm._v(" Journey Items ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "item-table-header"
  }, [_c('div', {
    staticClass: "item-column"
  }), _c('div', {
    staticClass: "item-column name"
  }, [_vm._v(" Name ")]), _c('div', {
    staticClass: "item-column item-id"
  }, [_vm._v(" Item ID ")]), _c('div', {
    staticClass: "item-column"
  }, [_vm._v(" Assembly ")]), _c('div', {
    staticClass: "item-column"
  }, [_vm._v(" Capacity ")])]);
}]

export { render, staticRenderFns }