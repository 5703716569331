<template>
    <div class="row product-association-manager">
        <div class="col-md-3">
            <LoadingSpinner v-if="isLoadingCategories"
                            is-black="true" />
            <SimpleAddRemoveList
                v-else
                :items="categories"
                :selected-items="selectedCategories"
                display-key="name"
                match-key="id"
                item-type="category"
                @itemAdded="addCategory"
                @itemRemoved="removeCategory" />
        </div>

        <div class="col-md-3">
            <LoadingSpinner v-if="isLoadingStyles"
                            is-black="true" />
            <SimpleAddRemoveList
                v-else
                :items="styles"
                :selected-items="selectedStyles"
                display-key="name"
                match-key="id"
                item-type="style"
                @itemAdded="addStyle"
                @itemRemoved="removeStyle" />
        </div>

        <div class="col-md-3">
            <LoadingSpinner v-if="isLoadingMaterials"
                            is-black="true" />
            <SimpleAddRemoveList
                v-else
                :items="types"
                :selected-items="selectedTypes"
                display-key="name"
                match-key="id"
                item-type="type"
                @itemAdded="addType"
                @itemRemoved="removeType" />
        </div>

        <div class="col-md-3">
            <LoadingSpinner v-if="isLoadingCategories"
                            is-black="true" />
            <SimpleAddRemoveList
                v-else
                :items="materials"
                :selected-items="selectedMaterials"
                display-key="name"
                match-key="id"
                item-type="material"
                @itemAdded="addMaterial"
                @itemRemoved="removeMaterial" />
        </div>
    </div>
</template>

<script>

// TODO Think about another option where this component manages the state of each list
// and only when the state of the list is requested does it pass it up to the parent.
// Not sure which is better?

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleAddRemoveList from '@/components/forms/SimpleAddRemoveList'

  export default {
    name: 'ProductAssociationManager',
    components: {
      LoadingSpinner,
      SimpleAddRemoveList,
    },
    props: {
      selectedCategories: {
        type: Array,
        default: () => [],
      },
      selectedTypes: {
        type: Array,
        default: () => [],
      },
      selectedMaterials: {
        type: Array,
        default: () => [],
      },
      selectedStyles: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        categories:             [],
        styles:                 [],
        types:                  [],
        materials:              [],
        isLoadingTypes:         false,
        isLoadingStyles:        false,
        isLoadingMaterials:     false,
        isLoadingCategories:    false,
      }
    },
    mounted() {
      this.getCategories()
      this.getStyles()
      this.getTypes()
      this.getMaterials()
    },
    methods: {
      //////////// CREATED/MOUNTED METHODS ////////////
      getCategories() {
        this.isLoadingCategories = true
        this.axios.get('/product_categories')
          .then(response => this.onCategoriesRetrieved(response.data))
      },
      getStyles() {
        this.isLoadingStyles = true
        this.axios.get('/product_styles')
          .then(response => this.onStylesRetrieved(response.data))
      },
      getTypes() {
        this.isLoadingTypes = true
        this.axios.get('/product_types')
          .then(response => this.onTypesRetrieved(response.data))
      },
      getMaterials() {
        this.isLoadingMaterials = true
        this.axios.get('/product_materials')
          .then(response => this.onMaterialsRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      addCategory(category) {
        this.$emit('addCategory', category)
      },
      removeCategory(category) {
        this.$emit('removeCategory', category)
      },
      addStyle(style) {
        this.$emit('addStyle', style)
      },
      removeStyle(style) {
        this.$emit('removeStyle', style)
      },
      addType(type) {
        this.$emit('addType', type)
      },
      removeType(type) {
        this.$emit('removeType', type)
      },
      addMaterial(material) {
        this.$emit('addMaterial', material)
      },
      removeMaterial(material) {
        this.$emit('removeMaterial', material)
      },
      //////////// CALLBACKS ////////////
      onStylesRetrieved(data) {
        this.isLoadingStyles = false
        this.styles = data.productStyles
      },
      onCategoriesRetrieved(data) {
        this.isLoadingCategories = false
        this.categories = data.productCategories
      },
      onTypesRetrieved(data) {
        this.isLoadingTypes = false
        this.types = data.productTypes
      },
      onMaterialsRetrieved(data) {
        this.isLoadingMaterials = false
        this.materials = data.productMaterials
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>


.product-association-manager {
  // This should mimic the HTML above.
  // PLEASE INDENT
}

</style>
