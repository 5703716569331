<template>
    <div class="alert alert-danger">
        <span v-if="primaryMessage"
              class="primary-message">{{ primaryMessage }}</span>
        <span v-else
              class="primary-message">The following errors occurred:</span>

        <ul v-if="errorMessages && errorMessages.length > 0">
            <li v-for="(message, index) in errorMessages"
                :key="index">
                {{ message }}
            </li>
        </ul>
    </div>
</template>

<script>
  export default {
    name: 'FormErrors',
    props: {
      errorMessages: {
        type: Array,
        default: () => [],
      },
      primaryMessage: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style lang="scss" scoped>

.primary-message {
  display:                  block;
  margin-bottom:            0.5em;
}

</style>
