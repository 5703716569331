<template>
    <div class="journey-issue">
        <div class="body">
            <div class="title">
                <h4 class="h4-emphasized">
                    Add to Pickup
                </h4>
                <hr />
            </div>
            <div v-if="!inventoryItemIdsFetched">
                <LoadingSpinner />
            </div>
            <div v-else-if="orderItemsWithoutPickup.length"
                 class="order-items-list">
                <span class="title">Adding one of the following items? </span>
                <table class="table">
                    <thead>
                        <tr>
                            <!-- checkbox -->
                            <th scope="col" />
                            <!-- image -->
                            <th scope="col" />
                            <th scope="col">
                                Item
                            </th>
                            <th scope="col">
                                Condition
                            </th>
                            <th scope="col">
                                Early Return Fee
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="orderItem in orderItemsWithoutPickup"
                            :key="orderItem.id"
                            class="order-item">
                            <td class="item-checkbox">
                                <input v-model="orderItem.added"
                                       type="checkbox" />
                            </td>
                            <td class="item-image">
                                <cld-image :public-id="getPublicID(orderItem.productSku.productImages)"
                                           class="inventory-item-photo"
                                           width="36"
                                           height="36"
                                           crop="limit" />
                            </td>
                            <td>
                                {{ orderItem.productSku.name }} - {{ orderItem.productSku.sku }}
                            </td>
                            <td>
                                {{ orderItem.journeyInventoryItems[0].inventoryItem.conditionGrade?.name || '??' }}
                            </td>
                            <td class="early-return">
                                <template v-if="orderItem.earlyReturn">
                                    ${{ Number(orderItem.earlyReturnCharge / 100).toFixed(2) }}
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <span class="total-fee"> Total Fee: ${{ totalEarlyReturnCost }}</span>

            <form @submit.prevent="submit">
                <div class="issue-notes">
                    <span>If not from the list above, can you describe the item?</span>
                    <textarea v-model="issueNote"
                              type="text"
                              name="issue-notes"
                              placeholder="(Details of the item)" />
                </div>
                <div class="submit">
                    <button type="submit"
                            :disabled="isSubmitting">
                        {{ isSubmitting ? "Submitting" : "Submit" }}
                    </button>
                </div>
            </form>
            <div />
        </div>
    </div>
</template>

<script>
  import { imageMixin } from '@/mixins/imageMixin'
  import EventBus from '../utilities/EventBus.vue'
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: "AdditionalItemPickupModal",
    components: { LoadingSpinner },
    mixins: [imageMixin],
    props: {
      journeyId: {
        type: String,
        default: null,
      },
      customerUuid: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        selectedIssue: 'onsite_pickup_request_for_oliver_item',
        issueNote: '',
        isSubmitting: false,
        orderItems: [],
        additionalPickupItem: [],
        inventoryItemIdsFromOrderItems: [],
        inventoryItemIds: [],
        orderItemsFetched: false,
        inventoryItemIdsFetched: false,
      }
    },
    computed: {
      orderItemsWithoutPickup() {
        return this.orderItems.filter(oi => !this.inventoryItemIds.includes(oi.journeyInventoryItems[0]?.inventoryItem?.id))
      },
      totalEarlyReturnCost() {
        let totalCost = this.orderItemsWithoutPickup
          .filter(orderItem => orderItem.added && orderItem.earlyReturn)
          .reduce((previousValue, currentValue) => previousValue + Number(currentValue.earlyReturnCharge), 0)
        return (totalCost / 100).toFixed(2)
      },
      skipInventoryItemIdsQuery() {
        return !this.orderItemsFetched
      },
    },
    apollo: {
      orderItems: {
        query: require('@/graphql/queries/OrderItems.gql'),
        variables() {
          return {
            userUuid: this.customerUuid,
          }
        },
        result(response, _key) {
          this.orderItemsFetched = true
          this.inventoryItemIdsFromOrderItems = response.data?.orderItems?.map(oi => oi.journeyInventoryItems[0]?.inventoryItem?.id) || []
          this.triggerInventoryItemIdsQuery()
        },
        update(response) {
          return response.orderItems.map(oi => ({ ...oi, added: false }))
        },
      },
      inventoryItemIds: {
        query: require('@/graphql/queries/InventoryItemSummary.gql'),
        variables() {
          return {
            ids: this.inventoryItemIdsFromOrderItems,
            state: "awaiting_pickup",
          }
        },
        skip() {
          return this.skipInventoryItemIdsQuery
        },
        update(response) {
          this.inventoryItemIdsFetched = true
          return response.inventoryItems.map(ii => ii.id)
        },
      },
    },
    methods: {
      async submit() {
        let checkedItems = this.orderItemsWithoutPickup
          .filter(orderItem => orderItem.added)
          .map(checkedOrderedItem => {
            return {
              orderItemId: Number(checkedOrderedItem.id),
            }
          })

        this.isSubmitting = true
        if(checkedItems.length > 0) {
          try {
            const res = await this.$apollo.mutate({
              mutation: require("@/graphql/mutations/EditJourney.gql"),
              variables: {
                returns: checkedItems,
                journeyId: this.journeyId,
              },
            })
            if (res.data.editJourney.errors?.length) {
              throw res.data.editJourney.errors[0].message
            }
            else {
              alert('Successfully added items to pickup!')
            }
          } catch(error) {
            this.isSubmitting = false
            alert(error)
            return
          }
        }

        if (this.issueNote.length) {
          let issueVariables = {
            journeyId: this.journeyId,
            issue: this.selectedIssue,
            note: this.issueNote,
          }
          try {
            await this.$apollo.mutate({
              mutation: require('@/graphql/mutations/CreateJourneyIssue.gql'),
              variables: issueVariables,
            })
          } catch(error) {
            this.isSubmitting = false
            alert("Couldn't add notes about unknown item.")
          }
        }
        this.isSubmitting = false
        EventBus.$emit('refetchDriversTasks')
        this.$emit('close')
      },
      getPublicID(productImages) {
        const productImage = this.getProductImage(productImages)
        if (productImage) {
          return productImage.body.split('upload/')[1]
        }
        return ''
      },
      triggerInventoryItemIdsQuery() {
        if(this.$apollo.queries.inventoryItemIds) {
          this.$apollo.queries.inventoryItemIds.refetch()
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .journey-issue {
    .title {
      margin-bottom: 1rem;

      h4 {
        font-weight: bold;
        text-align: left;
      }

      hr {
        border-bottom: 1px solid;
        margin: 2px 0 0 0;
      }
    }

    span {
      font-weight: bold;
    }

    .issue-select {
      margin-bottom: 1rem;
    }

    .issue-notes {
      margin-bottom: 1rem;

      textarea {
        width: 100%;
        height: 5em;
        padding: 0.5rem;
        border: none;
        background: rgba(151, 151, 151, 0.1);
        box-shadow: inset 0px 1px 4px 2px rgba(0, 0, 0, 0.08);
        border-radius: 2px;
      }

      textarea.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

	.total-fee {
		margin-top: 16px;
		margin-bottom: 16px;
	}

    .submit {
      button {
        background-color: #ffe82f;
        color: black;
        border: none;
        width: 180px;
        font-weight: bold;
        height: 40px;
        border-radius: 2px;
        float: right;
      }
      button.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    input[type='checkbox'] {
      -webkit-appearance: none;
      background-color: white;
      border: 2px solid #262223;
      padding: 16px;
      border-radius: 4px;
      position: relative;
    }

    input[type='checkbox']:checked {
      background-color: #262223;
      border: 2px solid #262223;
      color: white;
      border-radius: 4px;
    }

    input[type='checkbox']:checked:after {
      content: '\2714';
      font-size: 28px;
      position: absolute;
      top: -4px;
      left: 5px;
      color: white;
    }

    .order-items-list {
      height: 30vh;
      overflow-y: scroll;
      margin-bottom: 16px;
      .order-item {
        .item-checkbox {
          margin-right: 8px;
        }
        .item-image {
          margin-right: 8px;
        }
        .early-return {
          font-weight: bold;
          color: red;
        }
      }
    }
  }
</style>

<style lang="scss">
  .journey-issue {
    .simple-dropdown {
      width: 50%;
      margin-left: 1rem;
      padding: 0 2.5rem 0 1rem;
      height: 30px;
      line-height: 30px;

      .select-area > i {
        top: 0.3rem;
      }
    }

    .simple-dropdown.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
</style>
