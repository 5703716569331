import axios from '@/api/axios'

export default class User {
  // cookie auth is handled by rails api
  static fromToken() {
    try {
      return axios
        .get(`/admin_users/_`)
        .then(response => new User(response.data.adminUser))
        .catch(error => Promise.reject(error))
    } catch (_) {
      return null
    }
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  constructor(user) {
    this.id = user.id
    this.emailAddress = user.emailAddress
    this.firstName = user.firstName
    this.lastName = user.lastName
  }
}
