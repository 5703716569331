var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-skus"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Product SKUs")]), _c('div', {
    staticClass: "header-links"
  }, [_c('button', {
    staticClass: "gs-standard-button gs-add-button top-button"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'CreateProductSku'
      }
    }
  }, [_vm._v(" Add new product SKU ")]), _c('i', {
    staticClass: "fa fa-plus-circle"
  })], 1), _c('button', {
    staticClass: "gs-standard-button gs-add-button"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'CreateProduct'
      }
    }
  }, [_vm._v(" Add new product ")]), _c('i', {
    staticClass: "fa fa-plus-circle"
  })], 1)])]), _c('section', {
    staticClass: "row gs-standard-form gs-special-content"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Filter: ")]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Product Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productNameSearch,
      expression: "productNameSearch"
    }],
    domProps: {
      "value": _vm.productNameSearch
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.productNameSearch = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Product Brand:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.productBrandSearch,
      expression: "productBrandSearch"
    }],
    domProps: {
      "value": _vm.productBrandSearch
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.productBrandSearch = $event.target.value;
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("SKU:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.skuSearch,
      expression: "skuSearch"
    }],
    domProps: {
      "value": _vm.skuSearch
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.skuSearch = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-6"
  }, [_vm.isLoadingCategories ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('SimpleAddRemoveList', {
    attrs: {
      "items": _vm.productCategories,
      "selected-items": _vm.selectedCategories,
      "match-key": "id",
      "display-key": "name",
      "item-type": "category"
    },
    on: {
      "itemAdded": _vm.addCategory,
      "itemRemoved": _vm.removeCategory
    }
  }), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.inactiveOnly,
      expression: "inactiveOnly"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.inactiveOnly) ? _vm._i(_vm.inactiveOnly, null) > -1 : _vm.inactiveOnly
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.inactiveOnly,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.inactiveOnly = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.inactiveOnly = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.inactiveOnly = $$c;
        }
      }
    }
  }), _c('label', [_vm._v("Inactive only")])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.excludeSunset,
      expression: "excludeSunset"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.excludeSunset) ? _vm._i(_vm.excludeSunset, null) > -1 : _vm.excludeSunset
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.excludeSunset,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.excludeSunset = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.excludeSunset = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.excludeSunset = $$c;
        }
      }
    }
  }), _c('label', [_vm._v("Exclude sunset skus")])])], 1)]), _c('section', {
    staticClass: "links"
  }, [_c('router-link', {
    class: {
      active: _vm.isSkusListActive
    },
    attrs: {
      "to": {
        name: 'ProductSkusList'
      }
    }
  }, [_vm._v(" All SKUs ")]), _c('router-link', {
    class: {
      active: !_vm.isSkusListActive
    },
    attrs: {
      "to": {
        name: 'ProductSkusSkuless'
      }
    }
  }, [_vm._v(" SKU-less Products ")])], 1), _c('router-view', {
    attrs: {
      "product-name-search": _vm.productNameSearch,
      "product-brand-search": _vm.productBrandSearch,
      "selected-categories": _vm.selectedCategories,
      "sku-search": _vm.skuSearch,
      "inactive-only": _vm.inactiveOnly,
      "exclude-sunset": _vm.excludeSunset
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }