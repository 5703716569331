var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "create-product-sku"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Create Product SKU")]), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "type": "submit",
      "disabled": _vm.isSavingProductSku
    },
    on: {
      "click": _vm.createProductSku
    }
  }, [!_vm.isSavingProductSku ? _c('span', [_vm._v("Create")]) : _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.5"
    }
  })], 1)]), _c('section', {
    staticClass: "parent-product gs-standard-form"
  }, [_c('label', [_vm._v("Parent Product:")]), _vm.parentProductExists ? _c('div', {
    staticClass: "edit-parent-product"
  }, [_vm._v(" " + _vm._s(_vm.parentProduct.name) + " "), _c('i', {
    staticClass: "fa fa-close remove-item",
    on: {
      "click": _vm.removeParentProduct
    }
  })]) : _c('SimpleTypeahead', {
    attrs: {
      "items": _vm.products,
      "display-key": "name",
      "input-placeholder": "Search products...",
      "typeahead-width": "20em"
    },
    on: {
      "itemClicked": _vm.selectParentProduct
    }
  })], 1), _c('div', {
    staticClass: "create-form gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "row product-sku-details"
  }, [_c('h2', [_vm._v("SKU Details")]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('label', [_vm._v("SKU")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.sku,
      expression: "newProductSku.sku"
    }],
    domProps: {
      "value": _vm.newProductSku.sku
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "sku", $event.target.value);
      }
    }
  }), _c('label', [_vm._v("UPC")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.upc,
      expression: "newProductSku.upc"
    }],
    domProps: {
      "value": _vm.newProductSku.upc
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "upc", $event.target.value);
      }
    }
  }), _c('label', [_vm._v("Sunset")]), _c('toggle-button', {
    attrs: {
      "value": _vm.newProductSku.opsDetails.sunset,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.newProductSku.opsDetails.sunset = !_vm.newProductSku.opsDetails.sunset;
      }
    }
  })], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_c('label', [_vm._v("Retail Price ($)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.retailPriceDollars,
      expression: "newProductSku.retailPriceDollars"
    }],
    domProps: {
      "value": _vm.newProductSku.retailPriceDollars
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "retailPriceDollars", $event.target.value);
      }
    }
  }), _c('label', [_vm._v("Base Price ($)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.basePriceDollars,
      expression: "newProductSku.basePriceDollars"
    }],
    domProps: {
      "value": _vm.newProductSku.basePriceDollars
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "basePriceDollars", $event.target.value);
      }
    }
  }), _c('label', [_vm._v("Lease Override Multiplier")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.leasePercentageOverride,
      expression: "newProductSku.leasePercentageOverride"
    }],
    domProps: {
      "value": _vm.newProductSku.leasePercentageOverride
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "leasePercentageOverride", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('label', [_vm._v("Weight (lbs)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.weightInPounds,
      expression: "newProductSku.weightInPounds"
    }],
    domProps: {
      "value": _vm.newProductSku.weightInPounds
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "weightInPounds", $event.target.value);
      }
    }
  }), _c('label', [_vm._v("Length (in.)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.lengthInInches,
      expression: "newProductSku.lengthInInches"
    }],
    domProps: {
      "value": _vm.newProductSku.lengthInInches
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "lengthInInches", $event.target.value);
      }
    }
  }), _c('label', [_vm._v("Height (in.)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.heightInInches,
      expression: "newProductSku.heightInInches"
    }],
    domProps: {
      "value": _vm.newProductSku.heightInInches
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "heightInInches", $event.target.value);
      }
    }
  }), _c('label', [_vm._v("Width (in.)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.widthInInches,
      expression: "newProductSku.widthInInches"
    }],
    domProps: {
      "value": _vm.newProductSku.widthInInches
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "widthInInches", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('label', [_vm._v("Internal Notes")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newProductSku.internalNotes,
      expression: "newProductSku.internalNotes"
    }],
    domProps: {
      "value": _vm.newProductSku.internalNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newProductSku, "internalNotes", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Is Active?")]), _c('toggle-button', {
    attrs: {
      "value": _vm.newProductSku.isActive,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.newProductSku.isActive = !_vm.newProductSku.isActive;
      }
    }
  })], 1)])]), _c('section', {
    staticClass: "row variations"
  }, [_c('h2', [_vm._v("Variations")]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('ProductSkuVariationsManager', {
    attrs: {
      "product-sku-variations": _vm.newProductSkuVariations
    },
    on: {
      "addVariation": _vm.addProductSkuVariation,
      "removeVariation": _vm.removeProductSkuVariation
    }
  })], 1)]), _c('section', {
    staticClass: "product-images"
  }, [_c('h2', [_vm._v("Product SKU Images")]), _c('ProductImageManager', {
    attrs: {
      "product-images": _vm.newProductSkuImages
    },
    on: {
      "addProductImage": _vm.addProductSkuImage,
      "removeProductImage": _vm.removeProductSkuImage
    }
  })], 1), _c('section', {
    staticClass: "ops-details"
  }, [_c('h2', [_vm._v("Ops Details")]), _c('vue-json-editor', {
    attrs: {
      "expanded-on-start": true
    },
    model: {
      value: _vm.newProductSku.opsDetails,
      callback: function callback($$v) {
        _vm.$set(_vm.newProductSku, "opsDetails", $$v);
      },
      expression: "newProductSku.opsDetails"
    }
  })], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }