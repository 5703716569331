<template>
    <span v-if="isEditingThisItem">
        <button :disabled="showLoadingState"
                class="gs-standard-button"
                @click="$emit('saveButtonClick')">
            <LoadingSpinner v-if="showLoadingState"
                            max-height="1.5" />
            <span v-else-if="showSaveButton">Save</span>
        </button>
    </span>
    <button v-else
            class="gs-standard-button"
            @click="$emit('editButtonClick')">
        Edit
    </button>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'TableEditButton',
    components: {
      LoadingSpinner,
    },
    props: {
      isEditingThisItem: {
        type: Boolean,
        default: false,
      },
      showLoadingState: {
        type: Boolean,
        default: false,
      },
      showSaveButton: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style lang="scss" scoped>


</style>
