<template>
    <div ref="journey"
         class="journey"
         :class="{ 'rescheduled-journey': isRescheduled }">
        <div class="gs-standard-content">
            <div class="journey-header">
                <div>
                    <span :class="{'h3-emphasized': true, strikethrough: isRescheduled}">
                        {{ customerName }}
                    </span>
                    <template v-if="!isRescheduled">
                        <span class="h6">
                            {{ customerPhone }}
                        </span>
                        <span class="h6">
                            {{ scheduledTime }}
                        </span>
                        <div class="user-notes"
                             @click="openUserNotes">
                            <img v-if="shouldEmphasiseUserNotes()"
                                 src="@/assets/images/icons/information-red.svg" />
                            <img v-else
                                 src="@/assets/images/icons/information.svg" />
                            notes
                        </div>
                    </template>
                    <template v-else>
                        <span class="rescheduled-to">
                            Rescheduled to: {{ scheduledDate }}
                        </span>
                    </template>
                </div>
                <div v-if="showingForAllDrivers"
                     class="driverName">
                    <h5>
                        Driver: {{ driverName }}
                    </h5>
                </div>
                <div :class="{'customer-address': true, strikethrough: isRescheduled}">
                    <p class="h6">
                        {{ customerAddressFirstPart }}
                    </p>
                    <p class="h6">
                        {{ customerAddressSecondPart }}
                    </p>
                </div>
                <div class="attribute-list">
                    <div v-for="attribute in userAttributes"
                         :key="attribute.name"
                         class="attribute-buble">
                        {{ getAttributeText(attribute) }}
                    </div>
                </div>
                <hr />
            </div>

            <div v-if="journeyObject.includesRepair"
                 class="repair">
                <div class="trip-header">
                    <h3 class="h3-emphasized">
                        Repair/Service Trip
                    </h3>
                    <button :class="{ 'issue' : journeyIssues.length }"
                            @click="showJourneyIssueModal('repair')">
                        + repair issue
                    </button>
                </div>
                <div>
                    {{ journeyObject.journeyNote }}
                </div>
            </div>

            <div v-if="groupedDeliveryJITS.length"
                 class="deliveries">
                <div class="trip-header">
                    <h3 class="h3-emphasized">
                        Delivery Items
                    </h3>
                    <button :class="{ 'issue' : journeyIssues.length }"
                            @click="showJourneyIssueModal('delivery')">
                        + delivery issue
                    </button>
                </div>
                <JourneyItemsTable :initial-grouped-jits="groupedDeliveryJITS"
                                   :is-grouped="true"
                                   :is-delivery="true" />
            </div>

            <div v-if="pickupJITS.length"
                 :class="{pickups: true, strikethrough: isRescheduled}">
                <div class="trip-header">
                    <h3 class="h3-emphasized">
                        Pickup Items
                    </h3>
                    <button :class="{ 'issue' : journeyIssues.length }"
                            @click="showJourneyIssueModal('pickup')">
                        + pickup issue
                    </button>
                </div>
                <JourneyItemsTable :initial-jits="pickupJITS"
                                   :is-delivery="false"
                                   :is-grouped="false" />
            </div>
            <div v-if="additionalPickupEnabled"
                 class="extra-pickup"
                 @click="showAdditionalPickupModal()">
                <div class="add-icon">
                    +
                </div>
                <div class="pickup-description">
                    Picking up an item not shown here? Add it to the list.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import JourneyItemsTable from '@/components/resources/journeys/JourneyItemsTable.vue'
  import phoneMixin from '@/mixins/phoneFormatting'
  import EventBus from '@/components/utilities/EventBus'
  import { get, uniq, compact } from '@/helpers/utility'

  export default {
    name: 'Journey',
    components: {
      JourneyItemsTable,
    },
    mixins: [phoneMixin],
    props: {
      journeyObject: {
        type: Object,
        default: () => {},
      },
      selectedRegion: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        journeyIssues: _.clone(this.journeyObject.journeySpecificIssues) || [],
        addedPickupItems: [],
        additionalPickupEnabled: false,
      }
    },
    apollo: {
      additionalPickupEnabled: {
        query: require('@/graphql/queries/FeatureFlagEnabled.gql'),
        variables() {
          return {
            featureFlag: 'additional_pickup',
            regionIdentifier: this.selectedRegion?.identifier,
          }
        },
        update(data) {
          return data.featureFlagEnabled
        },
      },
    },
    computed: {
      customerName() {
        return get(this.journeyObject, 'customer.name')
      },
      customerPhone() {
        let phone = get(this.journeyObject, 'customer.phone')
        if (!phone) return

        return this.formatPhone(phone)
      },
      customerId() {
        return get(this.journeyObject, 'customer.id')
      },
      customerUuid() {
        return get(this.journeyObject, 'customer.uuid')
      },
      customerAddressFirstPart() {
        const line1 = get(this.journeyObject, 'locationObject.line1', '')
        const line2 = get(this.journeyObject, 'locationObject.line2', '')
        return `${line1}${line2 ? `, ${line2}` : ''}`
      },
      customerAddressSecondPart() {
        const city = get(this.journeyObject, 'locationObject.city', '')
        const postalCode = get(this.journeyObject, 'locationObject.postalCode', '')
        const region = get(this.journeyObject, 'locationObject.region', '')
        return `${city}, ${region} ${postalCode}`
      },
      scheduledDate() {
        return this.journeyObject?.scheduledDate
      },
      scheduledTime() {
        // format: 10:00 am, 03:00 pm
        const time = get(this.journeyObject, 'scheduledTime')
        if (!time) return ''
        const timeString = time.split(':00 ').join('')

        return timeString[0] == '0' ? timeString.slice(1) : timeString
      },
      groupedDeliveryJITS() {
        const deliveryJITS = this.itemsForTripType('customer_dropoff')
        const warehouseLocations = uniq(deliveryJITS.map(dJIT => dJIT.location))
        const groupedJITS = warehouseLocations.map(warehouseLocation => ({
          location: warehouseLocation,
          jits: [...deliveryJITS.filter(deliveryJIT => deliveryJIT.location === warehouseLocation)],
        }))
        return groupedJITS
      },
      pickupJITS() {
        return [...this.itemsForTripType('customer_pickup'), ...this.addedPickupItems]
      },
      userAttributes() {
        return get(this.journeyObject, 'customer.customerNotes.relevantAttributes')
      },
      isRescheduled() {
        return !!this.journeyObject.isRescheduled
      },
      driverName() {
        return this.journeyObject.driverName
      },
      showingForAllDrivers() {
        return this.journeyObject.showingForAllDrivers
      },
    },
    methods: {
      itemsForTripType(tripPurpose) {
        // a journey object may have many customer facing trips of the
        // same type because the object contains trips grouped by customer
        const tripItems = this.journeyObject.trips.flatMap(trip => {
          if (trip.purpose !== tripPurpose) return
          return trip.inventoryItems.map(ii => {
            const checkObject = trip.tripItemChecks.find(check => check.inventoryItemId === ii.id)
            const jitIssue = trip.journey.issues.find(ji => get(ji, 'inventoryItem.id') == ii.id)
            const additionalProperties = {
              tripId: trip.id,
              journeyId: trip.journey.id,
              customerFulfilled: get(checkObject, 'customerFulfilled', false),
              warehouseFulfilled: get(checkObject, 'warehouseFulfilled', false),
              issue: jitIssue,
              itemState: ii.conditionGrade?.name || '??',
            }
            return _.merge(ii, additionalProperties)
          })
        })

        return compact(tripItems)
      },
      showJourneyIssueModal(tripPurpose) {
        EventBus.$emit('showModal', {
          modalContentComponent: 'JourneyIssueReporter',
          modalProps: {
            journeyId: get(this.journeyObject, 'id'),
            existingIssues: this.journeyIssues,
            tripPurpose,
          },
          modalSuccessCallback: issueObject => {
            if (issueObject.deleted) {
              this.journeyIssues = this.journeyIssues.filter( e => e.id !== issueObject.id )
            }
            else {
              this.journeyIssues.push(issueObject)
            }
          },
        })
      },
      showAdditionalPickupModal() {
        EventBus.$emit('showModal', {
          modalContentComponent: 'AdditionalItemPickupModal',
          modalProps: {
            journeyId: get(this.journeyObject, 'id'),
            customerUuid: this.customerUuid,
          },
        })
      },
      getAttributeText(attributeObject) {
        return attributeObject.name.split('_').join(' ')
      },
      shouldEmphasiseUserNotes() {
        return this.journeyObject.journeyNote
      },
      openUserNotes() {
        EventBus.$emit('showModal', {
          modalContentComponent: 'CustomerNotesModal',
          modalProps: {
            customerNotes: get(this.journeyObject, 'customer.customerNotes'),
            journeyNote: this.journeyObject.journeyNote,
            journeyId: this.journeyObject.id,
          },
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .journey {
    .journey-header {
      padding-top: 1em;

      span {
        font-size: 1.25rem;
      }
      span:first-child {
        font-weight: bold;
        font-size: 1.75rem;
      }

      span:not(:first-of-type)::before {
        content: '|';
      }

      .customer-address {
        margin-top: 8px;
      }

      .user-notes {
        margin-top: 8px;
        display: inline;
        float: right;

        img {
          max-width: 22px;
        }
      }

      .user-notes:hover {
        cursor: pointer;
      }

      .attribute-list {
        display: flex;

        .attribute-buble {
          background-color: #262223;
          color: white;
          margin-right: 1em;
          padding: 0.3rem 1.5em;
          border-radius: 24px;
          min-width: 75px;
          text-align: center;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      hr {
        border-top: 2px solid black;
      }
    }

    .repair,
    .deliveries,
    .pickups {
      .trip-header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1em;

        h3 {
          font-size: 1.5rem;
          font-weight: bold;
          text-transform: uppercase;
        }

        button {
          background-color: #ffe82f;
          width: 200px;
          height: 40px;
          border: none;
          font-size: 1rem;
          font-weight: bold;
          box-shadow: 4px 5px 7px -1px rgb(0 0 0 / 30%);
        }

        button.issue {
          background-color: #ff708b;
          color: white;
        }
      }
    }

    .extra-pickup {
      margin-top: 32px;
      margin-left: 32px;
      display: flex;
      flex-direction: row;
      margin-bottom: 45px;
      cursor: pointer;
      .add-icon {
        height: 40px;
        width: 40px;
        font-size: 31px;
        display: flex;
        justify-content: center;
        background: #eeeeee;
        border-radius: 2px;
      }
      .pickup-description {
        font-size: 1rem;
        margin-top: 8px;
        margin-left: 16px;
      }
    }

    .strikethrough {
      text-decoration: line-through;
    }

    .rescheduled-to {
      display: inline-block;
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
</style>
