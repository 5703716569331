<template>
    <div class="user-list gs-simple-crud">
        <div v-if="isLoadingUsers">
            <LoadingSpinner is-black="true" />
        </div>

        <div v-else
             class="content">
            <section class="gs-generic-header">
                <h1>Users</h1>
            </section>

            <section class="row gs-standard-form filter-users">
                <h3 class="gs-standard-sub-header">
                    Filter Users:
                </h3>

                <div class="gs-standard-detail col-md-6">
                    <label>ID:</label>
                    <input v-model="idSearchQuery" />
                </div>

                <div class="gs-standard-detail col-md-6">
                    <label>First Name:</label>
                    <input v-model="firstNameSearchQuery" />
                </div>

                <div class="gs-standard-detail col-md-6">
                    <label>Last Name:</label>
                    <input v-model="lastNameSearchQuery" />
                </div>

                <div class="gs-standard-detail col-md-6">
                    <label>Email:</label>
                    <input v-model="emailSearchQuery" />
                </div>

                <div class="gs-standard-detail col-md-6">
                    <label>Phone:</label>
                    <input v-model="phoneSearchQuery" />
                </div>
            </section>

            <section class="users-table gs-standard-table gs-hide-filter">
                <v-client-table
                    v-if="filteredUsers.length > 0"
                    :data="filteredUsers"
                    :columns="columns"
                    :options="options">
                    <div slot="id"
                         slot-scope="data">
                        <router-link :to="{ name: 'User', params: { userId: data.row.id} }">
                            {{ data.row.id }}
                        </router-link>
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no Users to display
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'UserList',
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        users:                  [],
        isLoadingUsers:         false,
        idSearchQuery:          '',
        firstNameSearchQuery:   '',
        lastNameSearchQuery:    '',
        emailSearchQuery:       '',
        phoneSearchQuery:       '',
        columns:  ['id', 'firstName', 'lastName', 'emailAddress', 'phone', 'createdAt'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'id': 'ID',
            'firstName': 'First Name',
            'lastName': 'Last Name',
            'emailAddress': 'Email',
            'phone': 'Phone',
            'createdAt': 'Joined',
          },
        },

      }
    },
    computed: {
      filteredUsers() {
        return this.users.filter((user) => {
          return _.toString(user.firstName).toLowerCase().includes(this.firstNameSearchQuery.toLowerCase()) &&
            _.toString(user.lastName).toLowerCase().includes(this.lastNameSearchQuery.toLowerCase()) &&
            _.toString(user.emailAddress).toLowerCase().includes(this.emailSearchQuery.toLowerCase()) &&
            _.toString(user.phone).includes(this.phoneSearchQuery) &&
            _.toString(user.id).includes(this.idSearchQuery)
        }, this)
      },
    },
    mounted() {
      this.isLoadingUsers = true
      this.axios.get('/users')
        .then(response => { this.onUsersRetrieved(response.data) })
    },
    methods: {
      //////////// TEMPLATE ACTIONS ////////////

      //////////// CALLBACKS ////////////
      onUsersRetrieved(data) {
        this.users = data.users
        this.isLoadingUsers = false
      },
    //////////// INTERNAL METHODS ////////////
    },
  }
</script>

<style lang="scss" scoped>

.user-list {

  .filter-users {
    margin-top:                       2em;
    padding:                          2em;
    border:                           1px solid lightgray;
    border-radius:                    1em;
    background-color:                 #f6f8f9;

    > h3 {
      width:                          100%;
      display:                        block;
    }
  }

  .users-table {
    margin-top:                       2em;
  }
}

</style>
