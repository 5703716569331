<template>
    <div class="tags gs-simple-crud">
        <section class="gs-generic-header">
            <h1>Tags</h1>
        </section>

        <SimpleCreateForm
            :primary-error-message="primaryErrorMessage"
            :error-messages="errorMessages"
            :show-loading-state="isCreatingTag"
            :new-model-instance="newTag"
            @formSubmit="createTag" />

        <div v-if="isLoadingTags">
            <LoadingSpinner is-black="true" />
        </div>

        <div v-else
             class="content">
            <section class="tags-table gs-standard-table">
                <v-client-table
                    v-if="tags.length > 0"
                    :data="tags"
                    :columns="columns"
                    :options="options">
                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteTag(data.row)" />
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no tags to display
                </div>
            </section>

            <ConfirmModal
                v-if="showConfirmDeleteModal"
                :show-loading-state="isDeletingTag"
                @cancel="showConfirmDeleteModal = false"
                @confirm="deleteTag()">
                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingTag.name }}</b>? This will also destroy any associations with this tag!</span>
            </ConfirmModal>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import EventBus from '@/components/utilities/EventBus'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'Tags',
    components: {
      LoadingSpinner,
      SimpleCreateForm,
      ConfirmModal,
      DeleteIcon,
    },
    data() {
      return {
        isLoadingTags:        false,
        errorMessages:        [],
        primaryErrorMessage:  '',
        tags:                 [],
        newTag:               this.initializeTag(),
        isCreatingTag:        false,
        currentSavingTagName: null,
        currentDeletingTag:   null,
        isDeletingTag:        false,
        showConfirmDeleteModal:       false,
        columns:                      ['name', 'description', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
          },
        },
      }
    },
    mounted() {
      this.isLoadingTags = true
      this.axios.get('/tags')
        .then(response => { this.onTagsRetrieved(response.data) })
    },
    methods: {
      //////////// TEMPLATE ACTIONS ////////////
      createTag() {
        this.isCreatingTag = true
        this.axios.post('/tags', { tag: this.newTag })
          .then(response => this.onTagCreated(response.data))
          .catch(error => this.onTagCreatedFailure(error.response))
      },
      updateTag(tag) {
        this.currentSavingTagName = tag.name
        this.axios.patch('/tags/' + tag.name, { tag: tag })
          .then(response => this.onTagUpdated(response.data))
          .catch(error => this.onTagUpdatedFailure(error.response))
      },
      setCurrentDeleteTag(tag) {
        this.showConfirmDeleteModal = true
        this.currentDeletingTag = tag
      },
      //////////// CALLBACKS ////////////
      onTagsRetrieved(data) {
        this.tags = data.tags
        this.isLoadingTags = false
      },
      onTagCreated(data) {
        this.isCreatingTag = false

        this.tags.push(data.tag)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created tag!',
          statusCode: 1,
        })
        this.newTag = this.initializeTag()
      },
      onTagCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating tag'
          this.errorMessages = JSON.stringify(response)
        }

        this.isCreatingTag = false
      },
      onTagDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingTag = null
        this.isDeletingTag = false

        arrayHelper.removeObjectByValue(this.tags, data.tag.name, 'name')
        EventBus.$emit('globalAlert', {
          message: 'Tag successfully deleted!',
          statusCode: 1,
        })
      },
      onTagDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error deleting tag'
          this.errorMessages = JSON.stringify(response)
        }

        this.isDeletingTag = false
      },
      onTagUpdated(data) {
        arrayHelper.replaceObjectByValue(this.tags, data.tag, 'name')
        this.currentSavingTagName = null
      },
      onTagUpdatedFailure(response) {
        EventBus.$emit('globalAlert', {
          message: response.data.messages[0],
          statusCode: 3,
        })
        this.currentSavingTagName = null
      },
      //////////// INTERNAL METHODS ////////////
      initializeTag() {
        return {
          name:         '',
          description:  '',
        }
      },
      isSavingThisItem(item) {
        return this.currentSavingTagName === item.name
      },
      deleteTag() {
        this.isDeletingTag = true
        this.axios.delete('/tags/' + this.currentDeletingTag.name)
          .then(response => this.onTagDeleted(response.data))
          .catch(error => this.onTagDeletedFailure(error.response))
      },
    },
  }
</script>

<style lang="scss" scoped>

.tags {

  .tags-table {
    margin-top:                       2em;
  }
}

</style>
