var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "current-configuration"
  }, [_c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-description"
  }, [_vm._v(" Max hours per truck ")]), _c('div', {
    staticClass: "configuration-value"
  }, [_vm._v(" " + _vm._s(_vm.getFormattedTime(_vm.selectedRegion.availableMinutesPerTruck)) + " ")])]), _c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-description"
  }, [_vm._v(" Number of trucks ")]), _c('div', {
    staticClass: "configuration-value"
  }, [_vm._v(" " + _vm._s(_vm.selectedRegion.numberOfTrucks) + " ")])]), _c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-description"
  }, [_vm._v(" Max cubic feet per truck ")]), _c('div', {
    staticClass: "configuration-value"
  }, [_vm._v(" " + _vm._s(_vm.selectedRegion.totalTruckCuft) + " cuft ")])]), _c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-description"
  }, [_vm._v(" Avg. driving time b/w stops ")]), _c('div', {
    staticClass: "configuration-value"
  }, [_vm._v(" " + _vm._s(_vm.getFormattedTime(_vm.selectedRegion.drivingTimeMinutes)) + " ")])]), _c('div', {
    staticClass: "configuration"
  }, [_c('div', {
    staticClass: "configuration-description"
  }, [_vm._v(" Buffer Time Per Truck ")]), _c('div', {
    staticClass: "configuration-value"
  }, [_vm._v(" " + _vm._s(_vm.getFormattedTime(_vm.selectedRegion.bufferTimeMinutes)) + " ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }