var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "journey-list"
  }, [_c('div', {
    staticClass: "header-panel"
  }, [_vm._m(0), _c('div', {
    staticClass: "driver"
  }, [_c('form', {
    staticClass: "form-inline"
  }, [_vm.$apollo.queries.drivers.loading ? _c('LoadingSpinner', {
    staticClass: "driver-spinner",
    attrs: {
      "is-black": "true"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('select', {
    staticClass: "custom-select",
    attrs: {
      "disabled": _vm.skipDriversQuery
    },
    on: {
      "change": function change($event) {
        return _vm.selectDriver($event);
      }
    }
  }, [_c('option', [_vm._v(" " + _vm._s(_vm.optionHeader) + " ")]), _vm._l(_vm.withAllDriverInfo, function (option) {
    return _c('option', {
      key: option.id,
      domProps: {
        "selected": _vm.selectedDriver && _vm.selectedDriver.id == option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)])], 1)]), _c('div', {
    staticClass: "date"
  }, [_c('datepicker', {
    attrs: {
      "format": _vm.dateFormat
    },
    model: {
      value: _vm.selectedDate,
      callback: function callback($$v) {
        _vm.selectedDate = $$v;
      },
      expression: "selectedDate"
    }
  })], 1), _c('div', {
    staticClass: "region"
  }, [_c('form', {
    staticClass: "form-inline"
  }, [_vm.$apollo.queries.regions.loading ? _c('LoadingSpinner', {
    staticClass: "region-spinner",
    attrs: {
      "is-black": "true"
    }
  }) : _vm._e(), _c('div', {
    staticClass: "input-group mb-3"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectRegion($event);
      }
    }
  }, [_c('option', [_vm._v("Select a region")]), _vm._l(_vm.regions, function (option) {
    return _c('option', {
      key: option.id,
      domProps: {
        "selected": _vm.selectedRegion.id == option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)])], 1)])]), _vm.$apollo.queries.driversTasks.loading ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _vm.allJourneys.length ? [_vm._l(_vm.journeys, function (journey) {
    return _c('div', {
      key: journey.id
    }, [_c('Journey', {
      staticClass: "journey-section",
      attrs: {
        "journey-object": journey,
        "selected-region": _vm.selectedRegion
      }
    }), _c('div', {
      staticClass: "divider"
    })], 1);
  }), _vm.rescheduledJourneys.length ? _c('div', {
    staticClass: "rescheduled-journey-list"
  }, [_c('h2', {
    staticClass: "h2-emphasized"
  }, [_vm._v(" Rescheduled Journeys: ")]), _vm._l(_vm.rescheduledJourneys, function (journey) {
    return _c('div', {
      key: journey.id
    }, [_c('Journey', {
      staticClass: "journey-section rescheduled-journey-section",
      attrs: {
        "journey-object": journey,
        "selected-region": _vm.selectedRegion
      }
    }), _c('div', {
      staticClass: "divider"
    })], 1);
  })], 2) : _vm._e()] : _c('h3', [_vm._v(" No journeys found for selected region, driver, and date :( ")])], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-header"
  }, [_c('h1', {
    staticClass: "h1-emphasized"
  }, [_vm._v(" Journeys ")])]);
}]

export { render, staticRenderFns }