<template>
    <div class="manufacturers gs-simple-crud">
        <section class="gs-generic-header">
            <h1>Manufacturers</h1>
        </section>

        <SimpleCreateForm
            :primary-error-message="primaryErrorMessage"
            :error-messages="errorMessages"
            :show-loading-state="isCreatingManufacturer"
            :new-model-instance="newManufacturer"
            @formSubmit="createManufacturer" />

        <div v-if="isLoadingManufacturers">
            <LoadingSpinner is-black="true" />
        </div>

        <div v-else
             class="content">
            <section class="manufacturers-table gs-standard-table">
                <v-client-table
                    v-if="manufacturers.length > 0"
                    :data="manufacturers"
                    :columns="columns"
                    :options="options">
                    <div slot="editName"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row['name']" />
                        <span v-else>{{ data.row['name'] }}</span>
                    </div>

                    <div slot="editSourceCountry"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row['sourceCountry']" />
                        <span v-else>{{ data.row['sourceCountry'] }}</span>
                    </div>

                    <div slot="edit"
                         slot-scope="data"
                         class="gs-standard-table-edit">
                        <TableEditButton
                            :is-editing-this-item="isEditingThisItem(data.row.id)"
                            :show-loading-state="isEditingThisItem(data.row.id) && isSavingThisItem(data.row.id)"
                            :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                            @saveButtonClick="updateManufacturer(data.row)"
                            @editButtonClick="currentEditManufacturerId = data.row.id" />
                    </div>

                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteManufacturer(data.row)" />
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no Manufacturers to display
                </div>
            </section>

            <ConfirmModal
                v-if="showConfirmDeleteModal"
                :show-loading-state="isDeletingManufacturer"
                @cancel="showConfirmDeleteModal = false"
                @confirm="deleteManufacturer()">
                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingManufacturer.name }}</b>? This will also destroy any associations with this manufacturer!</span>
            </ConfirmModal>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import EventBus from '@/components/utilities/EventBus'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'Manufacturers',
    components: {
      LoadingSpinner,
      SimpleCreateForm,
      ConfirmModal,
      DeleteIcon,
      TableEditButton,
    },
    data() {
      return {
        isLoadingManufacturers:       false,
        errorMessages:                [],
        primaryErrorMessage:          '',
        manufacturers:                [],
        newManufacturer:              this.initializeManufacturer(),
        isCreatingManufacturer:       false,
        currentEditManufacturerId:    null,
        currentSavingManufacturerId:  null,
        currentDeletingManufacturer:  null,
        isDeletingManufacturer:       false,
        showConfirmDeleteModal:       false,
        columns:                      ['id', 'editName', 'editSourceCountry', 'edit', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'editName': 'Name',
            'editSourceCountry': 'Source Country',
          },
        },
      }
    },
    mounted() {
      this.isLoadingManufacturers = true
      this.axios.get('/manufacturers')
        .then(response => { this.onManufacturersRetrieved(response.data) })
    },
    methods: {
      //////////// TEMPLATE ACTIONS ////////////
      createManufacturer() {
        this.isCreatingManufacturer = true
        this.axios.post('/manufacturers', { manufacturer: this.newManufacturer })
          .then(response => this.onManufacturerCreated(response.data))
          .catch(error => this.onManufacturerCreatedFailure(error.response))
      },
      updateManufacturer(manufacturer) {
        this.currentSavingManufacturerId = manufacturer.id
        this.axios.patch('/manufacturers/' + manufacturer.id, { manufacturer: manufacturer })
          .then(response => this.onManufacturerUpdated(response.data))
          .catch(error => this.onManufacturerUpdatedFailure(error.response))
      },
      setCurrentDeleteManufacturer(manufacturer) {
        this.showConfirmDeleteModal = true
        this.currentDeletingManufacturer = manufacturer
      },
      //////////// CALLBACKS ////////////
      onManufacturersRetrieved(data) {
        this.manufacturers = data.manufacturers
        this.isLoadingManufacturers = false
      },
      onManufacturerCreated(data) {
        this.isCreatingManufacturer = false

        this.manufacturers.push(data.manufacturer)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created manufacturer!',
          statusCode: 1,
        })
        this.newManufacturer = this.initializeManufacturer()
      },
      onManufacturerCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating manufacturer'
          this.errorMessages = JSON.stringify(response)
        }

        this.isCreatingManufacturer = false
      },
      onManufacturerDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingManufacturer = null
        this.isDeletingManufacturer = false

        arrayHelper.removeObjectByValue(this.manufacturers, data.manufacturer.id, 'id')
        EventBus.$emit('globalAlert', {
          message: 'Manufacturer successfully deleted!',
          statusCode: 1,
        })
      },
      onManufacturerDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error deleting manufacturer'
          this.errorMessages = JSON.stringify(response)
        }

        this.isDeletingManufacturer = false
      },
      onManufacturerUpdated(data) {
        arrayHelper.replaceObjectByValue(this.manufacturers, data.manufacturer, 'id')
        this.currentEditManufacturerId = null
        this.currentSavingManufacturerId = null
      },
      onManufacturerUpdatedFailure(response) {
        EventBus.$emit('globalAlert', {
          message: response.data.messages[0],
          statusCode: 3,
        })
        this.currentSavingManufacturerId = null
      },
      //////////// INTERNAL METHODS ////////////
      initializeManufacturer() {
        return {
          name: '',
          sourceCountry: '',
        }
      },
      isSavingThisItem(id) {
        return this.currentSavingManufacturerId === id
      },
      isEditingThisItem(id) {
        return this.currentEditManufacturerId === id
      },
      deleteManufacturer() {
        this.isDeletingManufacturer = true
        this.axios.delete('/manufacturers/' + this.currentDeletingManufacturer.id)
          .then(response => this.onManufacturerDeleted(response.data))
          .catch(error => this.onManufacturerDeletedFailure(error.response))
      },
    },
  }
</script>

<style lang="scss" scoped>

.manufacturers {

  .manufacturers-table {
    margin-top:                       2em;
  }
}

</style>
