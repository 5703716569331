<template>
    <div class="journey-list">
        <div class="header-panel">
            <div class="page-header">
                <h1 class="h1-emphasized">
                    Journeys
                </h1>
            </div>

            <div class="driver">
                <form class="form-inline">
                    <LoadingSpinner v-if="$apollo.queries.drivers.loading"
                                    class="driver-spinner"
                                    is-black="true" />
                    <div class="input-group mb-3">
                        <select :disabled="skipDriversQuery"
                                class="custom-select"
                                @change="selectDriver($event)">
                            <option>
                                {{ optionHeader }}
                            </option>
                            <option v-for="option in withAllDriverInfo"
                                    :key="option.id"
                                    :selected="selectedDriver && selectedDriver.id == option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>

            <div class="date">
                <datepicker v-model="selectedDate"
                            :format="dateFormat" />
            </div>
            <div class="region">
                <form class="form-inline">
                    <LoadingSpinner v-if="$apollo.queries.regions.loading"
                                    class="region-spinner"
                                    is-black="true" />

                    <div class="input-group mb-3">
                        <select class="custom-select"
                                @change="selectRegion($event)">
                            <option>Select a region</option>
                            <option v-for="option in regions"
                                    :key="option.id"
                                    :selected="selectedRegion.id == option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </form>
            </div>
        </div>

        <LoadingSpinner v-if="$apollo.queries.driversTasks.loading"
                        is-black="true" />
        <template v-else-if="allJourneys.length">
            <div v-for="journey in journeys"
                 :key="journey.id">
                <Journey :journey-object="journey"
                         :selected-region="selectedRegion"
                         class="journey-section" />
                <div class="divider" />
            </div>
            <div v-if="rescheduledJourneys.length"
                 class="rescheduled-journey-list">
                <h2 class="h2-emphasized">
                    Rescheduled Journeys:
                </h2>
                <div v-for="journey in rescheduledJourneys"
                     :key="journey.id">
                    <Journey :journey-object="journey"
                             :selected-region="selectedRegion"
                             class="journey-section rescheduled-journey-section" />
                    <div class="divider" />
                </div>
            </div>
        </template>

        <h3 v-else>
            No journeys found for selected region, driver, and date :(
        </h3>
    </div>
</template>

<script>
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import Datepicker from 'vuejs-datepicker'
  import Journey from '@/components/resources/journeys/Journey'
  import paramsMixin from '@/mixins/params'
  import { groupBy, get, getUnixValue } from '@/helpers/utility'
  import * as dayjs from 'dayjs'
  import EventBus from '@/components/utilities/EventBus'

  const allDriverIdName = 'All drivers'

  export default {
    name: 'JourneyList',
    components: {
      LoadingSpinner,
      Journey,
      Datepicker,
    },
    mixins: [paramsMixin],
    data() {
      return {
        selectedDate: this.dateOnMount(),
        drivers: [],
        selectedDriver: {},
        driversTasks: [],
        regions: [],
        selectedRegion: {},
        allDriverInfo:  { name: allDriverIdName, id: allDriverIdName },
        showRescheduledTrips: false,
      }
    },
    apollo: {
      driversTasks: {
        query: require('@/graphql/queries/DriverTasks.gql'),
        variables() {
          const driverId = this.selectedDriver.id
          const allDriverQuery = driverId === allDriverIdName
          return {
            driverId: allDriverQuery ? null : driverId,
            scheduledDate: this.formattedSelectedDate,
            // theoretically we'd want to pass this through on all queries, but that changes
            // the existing behavior of "despite the fact a region is shown, actually just query
            // for any tasks owned by the selected driver." In the case of a driver who's working
            // in multiple regions in the same day, which is possible for LA & SD, this could
            // break existing usage of the app
            regionId: allDriverQuery ? this.selectedRegion.id : null,
            showRescheduled: this.showRescheduledTrips,
          }
        },
        skip() {
          return this.skipDriverTasksQuery
        },
      },
      drivers: {
        fetchPolicy: 'cache-and-network',
        query: require('@/graphql/queries/Drivers.gql'),
        variables() {
          return {
            scheduledOnly: false,
            withTripsOn: this.formattedSelectedDate,
            regionId: this.selectedRegion.id || 0,
            showRescheduled: this.showRescheduledTrips,
          }
        },
        result(_response, _key) {
          const driverId = this.$route.query.driverId
          this.selectedDriver = this.drivers.find(d => d.id === driverId) || {}
        },
        skip() {
          return this.skipDriversQuery
        },
      },
      regions: {
        query: require('@/graphql/queries/Regions.gql'),
        result(response, _key) {
          const regions = response.data.regions
          const urlRegionId = this.$route.query.regionId
          this.selectedRegion = regions.find(r => r.id === urlRegionId) || {}
        },
      },
      showRescheduledTrips: {
        query: require('@/graphql/queries/FeatureFlagEnabled.gql'),
        variables() {
          return {
            regionIdentifier: this.selectedRegion?.identifier,
            featureFlag: 'show_rescheduled_tasks_noodl',
          }
        },
        update(data) {
          return data.featureFlagEnabled
        },
      },
    },
    computed: {
      formattedSelectedDate() {
        return this.dateFormat(this.selectedDate)
      },
      withAllDriverInfo() {
        return [this.allDriverInfo].concat(this.drivers)
      },
      allJourneys() {
        // driver tasks are a list of trips. we can group by customer
        const tripsGroupedByCustomer =
          Object.values(groupBy(this.driversTasks, task => (task.customer.id + '_' + task.scheduledDate)))

        const journeys = tripsGroupedByCustomer.map(journeyTrips => {
          const firstTrip = journeyTrips[0]
          const journeyIssues = journeyTrips.map(jt => jt.journey.issues)
          const journeySpecificIssues = journeyIssues.flat().filter(issue => !issue.inventoryItem)
          return {
            id: get(firstTrip, 'journey.id'),
            journeySpecificIssues,
            customer: firstTrip.customer,
            scheduledDate: firstTrip.scheduledDate,
            scheduledTime: firstTrip.scheduledTime,
            trips: journeyTrips,
            locationObject: firstTrip.journey.location,
            journeyNote: firstTrip?.journey?.internalNotes,
            includesRepair: journeyTrips.filter(trip => trip.purpose === 'customer_repair').length,
            isRescheduled: firstTrip.scheduledDate !== this.formattedSelectedDate,
            driverName: firstTrip.driver?.name || 'Unset',
            showingForAllDrivers: this.showingForAllDrivers,
          }
        })

        // sort first by scheduled time
        journeys.sort((firstElem, secondElem) => {
          const firstElemUnix = getUnixValue(firstElem.scheduledTime)
          const secondElemUnix = getUnixValue(secondElem.scheduledTime)
          if (firstElemUnix < secondElemUnix) return -1
          else if (firstElemUnix > secondElemUnix) return 1
          return 0
        })

        // then sort by schedule date
        journeys.sort((firstElem, secondElem) => {
          if (firstElem.scheduledDate === secondElem.scheduledDate) return 0
          else if (firstElem.scheduledDate < secondElem.scheduledDate) return -1
          return 1
        })

        // then by if is rescheduled
        journeys.sort((firstElem, secondElem) => {
          if (firstElem.isRescheduled === secondElem.isRescheduled) return 0
          else if (firstElem.isRescheduled && !secondElem.isRescheduled) return 1
          return -1
        })

        // final return sort is:
        // - "All items on selected day, sorted by scheduled time"
        // - "All items that have been rescheduled, sorted by their currently scheduled date/time"
        return journeys
      },
      journeys() {
        return this.allJourneys.filter((journey) => !journey.isRescheduled)
      },
      rescheduledJourneys() {
        return this.allJourneys.filter((journey) => journey.isRescheduled)
      },
      optionHeader() {
        return !this.selectedRegion?.id ? 'Region not selected' : this.drivers.length === 0 ? 'None' : 'Drivers'
      },
      skipDriversQuery() {
        return !(this.selectedRegion?.id)
      },
      skipDriverTasksQuery() {
        return this.skipDriversQuery || !(this.selectedDriver?.id)
      },
      showingForAllDrivers() {
        return this.selectedDriver?.id === this.allDriverInfo.id
      },
    },
    watch: {
      skipDriverTasksQuery(value, _oldValue) {
        // driversTasks apollo query will be skipped when this value is true, but we still need
        // to clear the existing data
        if (value) {
          this.driversTasks = []
        }
      },
      skipDriversQuery(value, _oldValue) {
        // drivers apollo query will be skipped when this value is true, but we still need
        // to clear the existing data
        if (value) {
          this.drivers = []
        }
      },
      selectedDate(value, _oldValue) {
        const date = this.dateFormat(value)
        this.updateVueRouteQueryWithNewParams({ date })
      },
      selectedDriver(value, _oldValue) {
        const driverId = value.id || 0

        if (!driverId) {
          this.deleteParamsFromVueRouteQuery(['driverId'])
          return
        }

        this.updateVueRouteQueryWithNewParams({ driverId })
      },
      selectedRegion(value, _oldValue) {
        const regionId = value.id || 0
        if (!regionId) {
          this.deleteParamsFromVueRouteQuery(['regionId'])
          return
        }

        this.updateVueRouteQueryWithNewParams({ regionId })
      },
    },
    created() {
      EventBus.$on('refetchDriversTasks', () => {
        this.triggerDriversTasksQuery()
      })

    },
    beforeDestroy() {
      EventBus.$off('refetchDriversTasks')
    },
    methods: {
      dateOnMount() {
        const urlDate = this.$route.query.date
        if (urlDate) {
          return new Date(`${urlDate}T00:00:00`) // interpret date in local time zone
        }

        return new Date()
      },
      selectDriver(event) {
        const driverName = event.target.value
        this.selectedDriver = this.withAllDriverInfo.find(d => d.name === driverName) || {}
      },
      dateFormat(date) {
        return dayjs(date).format('YYYY-MM-DD')
      },
      selectRegion(event) {
        const regionName = event.target.value
        this.selectedRegion = this.regions.find(r => r.name === regionName) || {
          id: 0,
        }
      },
      triggerDriversTasksQuery() {
        this.$apollo.queries.driversTasks.refetch()
      },
    },
  }
</script>

<style lang="scss" scoped>
  .rescheduled-journey-list {
    pointer-events: none;
    filter: grayscale(80%) opacity(80%);
  }

  .journey-list {
    padding: 0;

    .header-panel {
      height: 70px;
      max-width: 90%;
      margin-left: 5%;

      > div {
        display: inline-block;
      }

      .date {
        margin-right: 1rem;
      }

      > div:not(:first-of-type) {
        float: right;

        h5 {
          display: inline;
        }
      }

      .region {
        span {
          font-size: 1.25rem;
          padding-bottom: 20px;
          padding-right: 10px;
          font-weight: 500;
        }

        .custom-select {
          border: 1px solid;
          height: 30px;
          padding: 0 40px 0 10px;
        }
      }

      .region {
        margin-right: 1rem;
        .simple-dropdown {
          width: 10em;
          padding: 5px 0.5em;

          .select-area i.fa.fa-angle-down {
            top: 0.5em;
          }
        }
      }

      .driver {
        h5 {
          padding-bottom: 10px;
          padding-right: 10px;
        }
        .custom-select {
          border: 1px solid;
          height: 30px;
          padding: 0 40px 0 10px;
        }
      }

      .date .vdp-datepicker input {
        border: none;
        border-bottom: 1px solid;
      }
    }

    .journey-section {
      margin: 0 auto;
    }

    .divider {
      // bit of a hack back #container-fluid has us boxed in
      width: 150%;
      margin-left: -20%;
      height: 16px;
      background-color: #d8d8d8;
      box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.15);
    }
  }
</style>

<style lang="scss">
  .journey-list {
    .simple-dropdown {
      width: auto;
      padding: 0 2.5rem 0 1rem;
      height: 30px;
      line-height: 30px;
      border: none;
      border-bottom: 1px solid;

      .select-area > i {
        top: 0.5rem;
      }
    }

    .vdp-datepicker {
      display: inline;

      div:first-child {
        display: inline;
      }

      input {
        border: none;
        border-bottom: 1px solid;
      }
    }

    .driver-spinner {
      display: inline;
      img {
        display: inline;
      }
    }
  }
</style>
