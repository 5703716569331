<template>
    <div class="product-colors gs-simple-crud">
        <h1>Product Colors</h1>

        <SimpleCreateForm
            :primary-error-message="primaryErrorMessage"
            :error-messages="errorMessages"
            :show-loading-state="isCreatingColor"
            :new-model-instance="newColor"
            @formSubmit="createColor" />

        <div v-if="isLoadingColors">
            <LoadingSpinner is-black="true" />
        </div>

        <div v-else
             class="content">
            <section class="product-colors-table gs-standard-table">
                <v-client-table
                    v-if="productColors.length > 0"
                    :data="productColors"
                    :columns="columns"
                    :options="options">
                    <div slot="editName"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row['name']" />
                        <span v-else>{{ data.row['name'] }}</span>
                    </div>

                    <div slot="edit"
                         slot-scope="data"
                         class="gs-standard-table-edit">
                        <TableEditButton
                            :is-editing-this-item="isEditingThisItem(data.row.id)"
                            :show-loading-state="isEditingThisItem(data.row.id) && isSavingThisItem(data.row.id)"
                            :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                            @saveButtonClick="updateColor(data.row)"
                            @editButtonClick="currentEditColorId = data.row.id" />
                    </div>

                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteColor(data.row)" />
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no Product Colors to display
                </div>
            </section>

            <ConfirmModal
                v-if="showConfirmDeleteModal"
                :show-loading-state="isDeletingColor"
                @cancel="showConfirmDeleteModal = false"
                @confirm="deleteColor()">
                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingColor.name }}</b>? This will also destroy any associations with this product color!</span>
            </ConfirmModal>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import EventBus from '@/components/utilities/EventBus'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'Colors',
    components: {
      LoadingSpinner,
      SimpleCreateForm,
      ConfirmModal,
      DeleteIcon,
      TableEditButton,
    },
    data() {
      return {
        isLoadingColors:          false,
        errorMessages:                   [],
        primaryErrorMessage:             '',
        productColors:                   [],
        newColor:                 this.initializeNewColor(),
        isCreatingColor:          false,
        currentEditColorId:       null,
        currentSavingColorId:     null,
        currentDeletingColor:     null,
        isDeletingColor:          false,
        showConfirmDeleteModal:          false,
        columns:                         ['id', 'editName', 'identifier', 'productSkuCount', 'edit', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'editName': 'Name',
            'productSkuCount': 'Product Count',
          },
        },
      }
    },
    mounted() {
      this.getColors()
    },
    methods: {
      getColors() {
        this.isLoadingColors = true
        this.axios.get('/product_colors')
          .then(response => this.onColorsRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      createColor() {
        this.isCreatingColor = true
        this.axios.post('/product_colors', { productColor: this.newColor })
          .then(response => this.onColorCreated(response.data))
          .catch(error => this.onColorCreatedFailure(error.response))
      },
      updateColor(productColor) {
        this.currentSavingColorId = productColor.id
        this.axios.patch('/product_colors/' + productColor.id, { productColor: productColor })
          .then(response => this.onColorUpdated(response.data))
          .catch(error => this.onColorUpdatedFailure(error.response))
      },
      setCurrentDeleteColor(productColor) {
        this.showConfirmDeleteModal = true
        this.currentDeletingColor = productColor
      },
      //////////// CALLBACKS ////////////
      onColorsRetrieved(data) {
        this.isLoadingColors = false
        this.productColors = data.productColors
      },
      onColorCreated(data) {
        this.isCreatingColor = false
        this.productColors.push(data.productColor)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created product color!',
          statusCode: 1,
        })
        this.newColor = this.initializeNewColor()
      },
      onColorCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating color'
          this.errorMessages = JSON.stringify(response)
        }

        this.isCreatingColor = false
      },
      onColorUpdated(data) {
        arrayHelper.replaceObjectByValue(this.productColors, data.productColor, 'id')
        this.currentEditColorId = null
        this.currentSavingColorId = null
      },
      onColorUpdatedFailure(response) {
        EventBus.$emit('globalAlert', {
          message: response.data.messages[0],
          statusCode: 3,
        })

        this.currentSavingColorId = null
      },
      onColorDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingColor = null

        arrayHelper.removeObjectByValue(this.productColors, data.productColor.id, 'id')
        EventBus.$emit('globalAlert', {
          message: 'Product color successfully deleted!',
          statusCode: 1,
        })
      },
      onColorDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating color'
          this.errorMessages = JSON.stringify(response)
        }

        this.showConfirmDeleteModal = false
        this.currentDeletingColor = false
      },
      onColorDeleteDone() {
        this.isDeletingColor = false
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewColor() {
        return {
          name:         '',
        }
      },
      isSavingThisItem(id) {
        return this.currentSavingColorId === id
      },
      isEditingThisItem(id) {
        return this.currentEditColorId === id
      },
      deleteColor() {
        this.isDeletingColor = true
        this.axios.delete('/product_colors/' + this.currentDeletingColor.id)
          .then(response => this.onColorDeleted(response.data))
          .catch(error => this.onColorDeletedFailure(error.response))
          .finally(() => this.onColorDeleteDone())
      },
    },
  }
</script>

<style lang="scss" scoped>

.product-colors {

  .content {
    margin-top:                   1em;
  }
}

</style>
