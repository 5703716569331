<template>
    <button class="gs-standard-button"
            :disabled="disableButton"
            @click="$emit('buttonClick')">
        {{ buttonText }}
    </button>
</template>

<script>

// NOTE
// This is a simple implemendation and will likely need to be updated to handle other cases
// Either watching other types of objects, or displaying a different UI

  export default {
    name: 'EnableDisableButton',
    props: {
      objectToWatch: {
        type: Object,
        default: () => {},
      },
      fieldsToObserve: {
        type: Array,
        default: () => [],
      },
      disableButtonComputed: {
        type: Boolean,
        default: false,
      },
      buttonText: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        objectHasChanged:     false,
        observeEntireObject:  !this.fieldsToObserveExist(),
        originalObject:       JSON.parse(JSON.stringify(this.objectToWatch)), // Does a simple object clone
      }
    },
    computed: {
      disableButton() {
        return !this.objectHasChanged || this.disableButtonComputed
      },
    },
    watch: {
      objectToWatch: {
        handler(obj) {
          let objectHasChanged = false
          if (this.observeEntireObject) {
            if (!_.isEqual(this.objectToWatch, this.originalObject)) objectHasChanged = true
          } else {
            this.fieldsToObserve.forEach((field) => {
              if (typeof (this.objectToWatch[field]) === 'object') {
                // NOTE
                // Use this for debugging: JSON.stringify(this.objectToWatch[field])
                if (!_.isEqual(this.objectToWatch[field], this.originalObject[field])) objectHasChanged = true
              } else if (this.objectToWatch[field] !== this.originalObject[field]) {
                objectHasChanged = true
              }
            }, this)
          }
          this.objectHasChanged = objectHasChanged
        },
        deep: true,
      },
    },
    methods: {
      fieldsToObserveExist() {
        if (!this.fieldsToObserve) return false
        if (this.fieldsToObserve.length === 0) return false
        return true
      },
    },
  }

</script>


<style lang="scss" scoped>

  .gs-standard-button {
    z-index:    10;
  }

</style>
