var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-types gs-simple-crud"
  }, [_c('h1', [_vm._v("Product Types")]), _c('SimpleCreateForm', {
    attrs: {
      "primary-error-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages,
      "show-loading-state": _vm.isCreatingProductType,
      "new-model-instance": _vm.newProductType
    },
    on: {
      "formSubmit": _vm.createProductType
    }
  }, [_c('SimpleArrayDropdown', {
    staticClass: "dropdown",
    attrs: {
      "items": ['initial', 'term_payback'],
      "selected-item": _vm.newProductType.pricingModel,
      "object": _vm.newProductType
    },
    on: {
      "itemSelected": _vm.selectPricingModel
    }
  })], 1), _vm.isLoadingProductTypes ? _c('div', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_c('section', {
    staticClass: "product-types-table gs-standard-table"
  }, [_vm.productTypes.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.productTypes,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "editName",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.name,
            expression: "data.row.name"
          }],
          domProps: {
            "value": data.row.name
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, "name", $event.target.value);
            }
          }
        }) : _c('span', [_vm._v(_vm._s(data.row.name))])]);
      }
    }, {
      key: "saleType",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('div', [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.saleType,
            expression: "data.row.saleType"
          }],
          attrs: {
            "id": "leaseOnly",
            "type": "radio",
            "name": "saleType",
            "value": "lease_only"
          },
          domProps: {
            "checked": _vm._q(data.row.saleType, "lease_only")
          },
          on: {
            "change": function change($event) {
              return _vm.$set(data.row, "saleType", "lease_only");
            }
          }
        }), _c('label', {
          attrs: {
            "for": "leaseOnly"
          }
        }, [_vm._v("Lease")]), _c('br'), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.saleType,
            expression: "data.row.saleType"
          }],
          attrs: {
            "id": "leaseOrPurchase",
            "type": "radio",
            "name": "saleType",
            "value": "lease_or_purchase"
          },
          domProps: {
            "checked": _vm._q(data.row.saleType, "lease_or_purchase")
          },
          on: {
            "change": function change($event) {
              return _vm.$set(data.row, "saleType", "lease_or_purchase");
            }
          }
        }), _c('label', {
          attrs: {
            "for": "leaseOrPurchase"
          }
        }, [_vm._v("Either")]), _c('br'), _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.saleType,
            expression: "data.row.saleType"
          }],
          attrs: {
            "id": "purchaseOnly",
            "type": "radio",
            "name": "saleType",
            "value": "purchase_only"
          },
          domProps: {
            "checked": _vm._q(data.row.saleType, "purchase_only")
          },
          on: {
            "change": function change($event) {
              return _vm.$set(data.row, "saleType", "purchase_only");
            }
          }
        }), _c('label', {
          attrs: {
            "for": "purchaseOnly"
          }
        }, [_vm._v("Purchase")])]) : _c('span', [_vm._v(_vm._s(data.row.saleType))])]);
      }
    }, {
      key: "purchaseScalePercentage",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.purchaseScalePercentage,
            expression: "data.row.purchaseScalePercentage"
          }],
          domProps: {
            "value": data.row.purchaseScalePercentage
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, "purchaseScalePercentage", $event.target.value);
            }
          }
        }) : data.row.saleType.includes('purchase') ? _c('span', [_vm._v(_vm._s(data.row.purchaseScalePercentage))]) : _vm._e()]);
      }
    }, {
      key: "pricingModel",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('select', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.pricingModel,
            expression: "data.row.pricingModel"
          }],
          on: {
            "change": function change($event) {
              var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
                return o.selected;
              }).map(function (o) {
                var val = "_value" in o ? o._value : o.value;
                return val;
              });

              _vm.$set(data.row, "pricingModel", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
            }
          }
        }, _vm._l(['initial', 'term_payback'], function (pm) {
          return _c('option', {
            key: pm,
            domProps: {
              "value": pm
            }
          }, [_vm._v(" " + _vm._s(pm) + " ")]);
        }), 0) : _c('span', [_vm._v(_vm._s(data.row.pricingModel || 'initial'))])]);
      }
    }, {
      key: "parentTypeId",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row.parentTypeId,
            expression: "data.row.parentTypeId"
          }],
          domProps: {
            "value": data.row.parentTypeId
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, "parentTypeId", $event.target.value);
            }
          }
        }) : _c('span', [_vm._v(_vm._s(data.row.parentTypeId))])]);
      }
    }, {
      key: "edit",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-edit"
        }, [_c('TableEditButton', {
          attrs: {
            "is-editing-this-item": _vm.isEditingThisItem(data.row.id),
            "show-loading-state": _vm.isEditingThisItem(data.row.id) && _vm.isSavingThisItem(data.row.id),
            "show-save-button": _vm.isEditingThisItem(data.row.id) && !_vm.isSavingThisItem(data.row.id)
          },
          on: {
            "saveButtonClick": function saveButtonClick($event) {
              return _vm.updateProductType(data.row);
            },
            "editButtonClick": function editButtonClick($event) {
              _vm.currentEditProductTypeId = data.row.id;
            }
          }
        })], 1);
      }
    }, {
      key: "delete",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-delete"
        }, [_c('DeleteIcon', {
          on: {
            "delete": function _delete($event) {
              return _vm.setCurrentDeleteProductType(data.row);
            }
          }
        })], 1);
      }
    }], null, false, 4062545918)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no Product Types to display ")])], 1), _vm.showConfirmDeleteModal ? _c('ConfirmModal', {
    attrs: {
      "show-loading-state": _vm.isDeletingProductType
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showConfirmDeleteModal = false;
      },
      "confirm": function confirm($event) {
        return _vm.deleteProductType();
      }
    }
  }, [_c('span', {
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_vm._v("Are you sure you want to remove "), _c('b', [_vm._v(_vm._s(_vm.currentDeletingProductType.name))]), _vm._v("? This will also destroy any associations with this product type!")])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }