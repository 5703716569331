var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row product-association-manager"
  }, [_c('div', {
    staticClass: "col-md-3"
  }, [_vm.isLoadingCategories ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('SimpleAddRemoveList', {
    attrs: {
      "items": _vm.categories,
      "selected-items": _vm.selectedCategories,
      "display-key": "name",
      "match-key": "id",
      "item-type": "category"
    },
    on: {
      "itemAdded": _vm.addCategory,
      "itemRemoved": _vm.removeCategory
    }
  })], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_vm.isLoadingStyles ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('SimpleAddRemoveList', {
    attrs: {
      "items": _vm.styles,
      "selected-items": _vm.selectedStyles,
      "display-key": "name",
      "match-key": "id",
      "item-type": "style"
    },
    on: {
      "itemAdded": _vm.addStyle,
      "itemRemoved": _vm.removeStyle
    }
  })], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_vm.isLoadingMaterials ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('SimpleAddRemoveList', {
    attrs: {
      "items": _vm.types,
      "selected-items": _vm.selectedTypes,
      "display-key": "name",
      "match-key": "id",
      "item-type": "type"
    },
    on: {
      "itemAdded": _vm.addType,
      "itemRemoved": _vm.removeType
    }
  })], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_vm.isLoadingCategories ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('SimpleAddRemoveList', {
    attrs: {
      "items": _vm.materials,
      "selected-items": _vm.selectedMaterials,
      "display-key": "name",
      "match-key": "id",
      "item-type": "material"
    },
    on: {
      "itemAdded": _vm.addMaterial,
      "itemRemoved": _vm.removeMaterial
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }