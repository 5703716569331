<template>
    <button class="delete-icon gs-standard-button"
            @click="$emit('delete')">
        Delete
    </button>
</template>

<script>

  export default {
    name: 'DeleteIcon',
  }
</script>

<style lang="scss" scoped>


</style>
