var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "overlay"
    }
  }, [_c('div', {
    staticClass: "modal-mask full-page-overlay",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('div', {
    staticClass: "modal-container",
    class: _vm.componentClass,
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_c('button', {
    staticClass: "modal-close-button",
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/icons/close-button.svg")
    }
  })]), _vm._t("default")], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }