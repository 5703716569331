var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-user"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v(_vm._s(_vm.fullName))]), !_vm.isLoadingUser ? _c('div', {
    staticClass: "gs-standard-detail"
  }, [_vm._v(" User Id: " + _vm._s(_vm.user.id) + " ")]) : _vm._e(), !_vm.isLoadingUser ? _c('div', {
    staticClass: "gs-standard-detail"
  }, [_vm._v(" Joined: " + _vm._s(_vm.user.createdAt) + " ")]) : _vm._e()]), _vm.isLoadingUser ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "gs-standard-form"
  }, [_c('section', {
    staticClass: "user-details"
  }, [_c('h5', [_vm._v("Contact Information:")]), _c('EnableDisableButton', {
    attrs: {
      "object-to-watch": _vm.user,
      "fields-to-observe": ['firstName', 'lastName', 'emailAddress', 'phone'],
      "disable-button-computed": _vm.isSavingUser,
      "button-text": "Save"
    },
    on: {
      "buttonClick": function buttonClick($event) {
        return _vm.updateCurrentUser();
      }
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("First Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.firstName,
      expression: "user.firstName"
    }],
    attrs: {
      "placeholder": "First Name"
    },
    domProps: {
      "value": _vm.user.firstName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.user, "firstName", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Last Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.lastName,
      expression: "user.lastName"
    }],
    attrs: {
      "placeholder": "Last Name"
    },
    domProps: {
      "value": _vm.user.lastName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.user, "lastName", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Email:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.emailAddress,
      expression: "user.emailAddress"
    }],
    attrs: {
      "placeholder": "Email"
    },
    domProps: {
      "value": _vm.user.emailAddress
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.user, "emailAddress", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Phone:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.phone,
      expression: "user.phone"
    }],
    attrs: {
      "placeholder": "Phone"
    },
    domProps: {
      "value": _vm.user.phone
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.user, "phone", $event.target.value);
      }
    }
  })])])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }