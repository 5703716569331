<template>
    <div class="create-curated-set">
        <section class="gs-generic-header">
            <h1>Create Curated Set</h1>
            <button :disabled="isSavingCuratedSet"
                    class="gs-standard-button"
                    @click="createCuratedSet">
                <span v-if="!isSavingCuratedSet">Create</span>
                <LoadingSpinner v-else
                                max-height="1.5" />
            </button>
        </section>

        <div class="create-form gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />

            <section class="curated-set-details">
                <h2>Set Basics</h2>

                <div class="gs-standard-detail">
                    <label>Name:</label>
                    <input v-model="newCuratedSet.name" />
                </div>
                <div class="gs-standard-detail">
                    <label>Identifier:</label>
                    <input v-model="newCuratedSet.identifier" />
                </div>

                <div class="gs-standard-detail">
                    <label>Category:</label>
                    <div class="input-group mb-3 category-select">
                        <select class="custom-select"
                                @change="selectProductCategory($event)">
                            <option selected>
                                Please select a category...
                            </option>
                            <option v-for="option in allProductCategories"
                                    :key="option.id">
                                {{ option.name }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="gs-standard-detail">
                    <label>Description:</label>
                    <textarea v-model="newCuratedSet.description" />
                </div>

                <div class="gs-toggle-container">
                    <label class="tag">Is Active?</label>
                    <toggle-button :value="newCuratedSet.isActive"
                                   color="#82C7EB"
                                   @change="newCuratedSet.isActive = !newCuratedSet.isActive" />
                </div>
            </section>


            <section class="row select-products">
                <h2>Product Skus</h2>

                <div class="col-md-4">
                    <LoadingSpinner v-if="isLoadingProductSkus"
                                    is-black="true" />
                    <SimpleAddRemoveList
                        v-else
                        :items="allProductSkus"
                        :selected-items="newCuratedSetProductSkus"
                        :allow-duplicates="true"
                        display-key="name"
                        match-key="sku"
                        item-type="product sku"
                        @itemAdded="addProductSku"
                        @itemRemoved="removeProductSku" />
                </div>
            </section>

            <section class="product-images">
                <h2>Set Image</h2>

                <input
                    ref="hidden-uploader"
                    type="file"
                    name="curated-set-image"
                    accept="image/*"
                    class="input-file"
                    @change="onImageSelect($event.target.name, $event.target.files)" />
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'
  import SimpleAddRemoveList from '@/components/forms/SimpleAddRemoveList'
  import apiHelper from '@/helpers/apiHelper'

  export default {
    name: 'CreateCuratedSet',
    components: {
      LoadingSpinner,
      FormErrors,
      SimpleAddRemoveList,
    },
    data() {
      return {
        newCuratedSet: {
          name: '',
          productCategoryId: null,
          data: {
            pieces: [],
          },
          heroImage: null,
          isActive: false,
        },
        newCuratedSetProductSkus: [],
        primaryErrorMessage: '',
        errorMessages: [],
        allProductSkus: [],
        heroImage: null,
        isSavingCuratedSet: false,
        allProductCategories: [],
        selectedProductCategory: null,
        isLoadingProductSkus: true,
      }
    },
    watch:{
      'newCuratedSet.name' : function(val) {
        this.newCuratedSet.identifier = _.kebabCase(val)
      },
    },
    mounted() {
      this.getProductSkus()
      this.getAllProductCategories()
    },
    methods: {
      getProductSkus() {
        this.isLoadingProducts = true
        this.axios.get('/product_skus', { params: { active: true } })
          .then(response => this.onProductSkusRetrieved(response.data))
      },
      getAllProductCategories() {
        this.axios.get('/product_categories')
          .then(response => { this.allProductCategories = response.data.productCategories })
      },
      //////////// TEMPLATE ACTIONS ////////////
      selectProductCategory(event) {
        const categoryName = event.target.value
        this.selectedProductCategory = _.find(this.allProductCategories, (pc) => pc.name == categoryName)
        this.newCuratedSet.productCategoryId = _.get(this.selectedProductCategory, 'id')
      },
      createCuratedSet() {
        this.isSavingCuratedSet = true
        this.newCuratedSet.heroImage = this.heroImage
        this.heroImage = null

        // TODO: Figure out submitting json object via submitMultipartForm
        apiHelper.submitMultipartForm(
          _.omit(this.newCuratedSet, ['data']), // params
          '/layout_instances/', // path
          this.updateData, // on success
          this.onCuratedSetCreatedFailure, // on failure
        )
      },
      updateData(_response) {
        this.axios.post('/layout_instances', { data: this.newCuratedSet.data })
          .then((response) => this.onCuratedSetCreated(response.data))
          .catch((error) => this.onCuratedSetCreatedFailure(error.response))
      },
      addProductSku(productSku) {
        const existingPiece = _.find(this.newCuratedSet.data.pieces, (piece) => {
          return piece.productSku === productSku.sku
        })

        this.newCuratedSetProductSkus.push(productSku)

        if (existingPiece) {
          existingPiece.quantity = parseInt(existingPiece.quantity) + 1
          return
        }

        this.newCuratedSet.data.pieces.push({
          productSku: productSku.sku,
          quantity: 1,
        })
      },
      removeProductSku(productSku, skus, index) {
        const removedSku = _.find(this.newCuratedSet.data.pieces, piece => {
          return piece.productSku === productSku.sku
        })
        if (removedSku.quantity > 1) {
          removedSku.quantity -= 1
        } else {
          _.pull(this.newCuratedSet.data.pieces, removedSku)
        }

        this.newCuratedSetProductSkus.splice(index, 1)
      },
      onImageSelect(name, files) {
        if (files.length === 0) return
        this.heroImage = files[0]
      },
      //////////// CALLBACKS ////////////
      onProductSkusRetrieved(data) {
        this.allProductSkus = data.productSkus
        this.isLoadingProductSkus = false
      },
      onCuratedSetCreated(data) {
        this.isSavingCuratedSet = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully created Curated Set!',
          statusCode: 1,
        })
        this.$router.push({ name: 'CuratedSet', params: { curatedSetId: data.layoutInstance.id } })
      },
      onCuratedSetCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating curated set'
          this.errorMessages = JSON.stringify(response)
        }

        this.isSavingCuratedSet = false
      },
    },
  }
</script>

<style lang="scss" scoped>

.create-curated-set {

  margin-bottom:                  5em;

  .create-form {

    .category-select {
      width: 30em;
    }

    > section {
      padding:                    1em;

      > h2 {
        margin:                   0.5em 0;
        width:                    100%;
      }
    }

    .curated-set-details {

      input, textarea {
        display:                  block;
        width:                    30em;
      }

      textarea {
        height:                   10em;
      }

      .gs-toggle-container {
        margin-top:               2em;
      }
    }

  }
}

</style>
