<template>
    <div v-if="statusMessage"
         class="notification-container">
        <div class="global-alert"
             @click="clearAlert()">
            <span class="primary-message">An error occurred and your request was not processed.</span>
            <span class="status-code"><b>Code:</b> {{ statusCode || 'Unknown' }}</span>
            <span class="status-message"><b>Error:</b> {{ statusMessage }}</span>
            <span v-if="redirectUser"
                  class="redirect-user">You are being redirected to the login page...</span>
        </div>
    </div>
</template>

<script>

  import EventBus from '@/components/utilities/EventBus'

  export default {
    name: 'GlobalError',
    data() {
      return {
        // statusMessage is required!
        statusMessage:  null,
        statusCode:     null,
        redirectUser:   false,
      }
    },
    mounted() {
      EventBus.$on('globalError', (payload) => {
        this.statusMessage = payload.statusMessage
        this.statusCode = payload.statusCode

        if (payload.redirectUser) {
          this.redirectUser = true
          setTimeout(() => {
            if (this.$router.currentRoute.name !== 'Login') {
              this.$router.push({ name: 'Login' })
            }
            this.clearAlert()
          }, 5000)
        }
      })
    },
    methods: {
      clearAlert() {
        this.statusMessage = null
        this.statusCode = null
        this.redirectUser = false
      },
    },
  }
</script>

<style lang="scss" scoped>

.global-alert {
  width:                      100%;
  text-align:                 center;
  padding:                    1em;
  position:                   fixed;
  z-index:                    999999;
  top:                        0;
  cursor:                     pointer;
  color:                      $danger-primary;
  background-color:           $danger-background;

  > span {
    display:                  block;
  }

  .primary-message {
    margin-bottom:            1em;
  }

  .redirect-user {
    margin-top:               0.75em;
    font-style:               italic;
  }
}


</style>
