var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "simple-image-manager"
  }, [_vm.images.length ? _c('div', {
    staticClass: "row image-list"
  }, _vm._l(_vm.images, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "image col-md-2"
    }, [item.publicId ? _c('div', [_vm.isImageTypeVideo(item.imageType) ? _c('cld-video', {
      attrs: {
        "public-id": item.publicId,
        "fallback-content": "Your browser does not support HTML5 video tags.",
        "controls": "true",
        "width": "200",
        "height": "200",
        "loop": "true"
      }
    }) : _c('cld-image', {
      attrs: {
        "public-id": item.publicId,
        "width": "200",
        "height": "200",
        "crop": "limit"
      }
    })], 1) : item.imageUrl ? _c('div', [_vm.isImageTypeVideo(item.imageType) ? _c('video', {
      attrs: {
        "src": item.imageUrl,
        "controls": ""
      }
    }) : _c('img', {
      attrs: {
        "src": item.imageUrl
      }
    })]) : _vm._e(), item.imageName ? _c('div', {
      staticClass: "image-name"
    }, [_vm._v(" " + _vm._s(item.imageName) + " ")]) : _vm._e(), item.credit ? _c('div', [_vm._v(" Credit: " + _vm._s(item.credit) + " ")]) : _vm._e(), _c('button', {
      staticClass: "gs-standard-button gs-delete-button"
    }, [_c('span', {
      on: {
        "click": function click($event) {
          return _vm.removeImage(index);
        }
      }
    }, [_vm._v("Remove")])])]);
  }), 0) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" No images ")]), _c('div', {
    staticClass: "new-image"
  }, [_c('SimpleArrayDropdown', {
    attrs: {
      "items": _vm.imageTypes,
      "items-to-exclude": _vm.itemsToExclude,
      "selected-item": _vm.currentImageType,
      "show-loading-state": _vm.showLoadingState,
      "prompt": "Please select an image type",
      "form-width": "20em"
    },
    on: {
      "itemSelected": _vm.imageTypeSelected
    }
  }), _c('input', {
    ref: "hidden-uploader",
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "name": "profile-image",
      "accept": "image/*,video/*"
    },
    on: {
      "change": function change($event) {
        return _vm.onFileSelect($event.target.files);
      }
    }
  }), _c('button', {
    staticClass: "gs-standard-button upload-image-link",
    on: {
      "click": _vm.uploadImage
    }
  }, [_vm.isSavingImage ? _c('LoadingSpinner', {
    attrs: {
      "max-height": "1"
    }
  }) : _c('span', {
    class: {
      disabled: _vm.isSavingImage
    }
  }, [_vm._v("Select Image")])], 1), _vm._v(" " + _vm._s(_vm._.get(_vm.file, 'name')) + " "), _vm.showPhotoCredit ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.photoCredit,
      expression: "photoCredit"
    }],
    attrs: {
      "placeholder": "Photo credit (optional)"
    },
    domProps: {
      "value": _vm.photoCredit
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.photoCredit = $event.target.value;
      }
    }
  }) : _vm._e()], 1), _c('button', {
    staticClass: "gs-standard-button add-button",
    attrs: {
      "disabled": _vm.isAddPropertyDisabled
    },
    on: {
      "click": _vm.addImage
    }
  }, [_vm._v(" Add image ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }