<template>
    <div class="jits-table">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col" />
                    <th scope="col">
                        Name | Item ID
                    </th>
                    <th v-if="isDelivery"
                        scope="col">
                        Packets
                    </th>
                    <th scope="col">
                        Ref
                    </th>
                    <th scope="col">
                        Picked Up
                    </th>
                    <th v-if="isDelivery"
                        scope="col">
                        Delivered
                    </th>
                    <th scope="col">
                        Issues?
                    </th>
                </tr>
            </thead>
            <tbody v-for="(jitsObject,idx) in groupedJits"
                   :key="idx">
                <div v-if="jitsObject.location"
                     class="location">
                    {{ jitsObject.location }}
                </div>
                <tr v-for="jit in jitsObject.jits"
                    :key="jit.id"
                    :ref="jit.id">
                    <td>
                        <cld-image v-if="getPublicID(jit)"
                                   :public-id="getPublicID(jit)"
                                   class="jit-photo"
                                   width="75"
                                   height="75"
                                   crop="limit" />
                    </td>
                    <td>
                        <div>
                            {{ jit.name }} | {{ jit.id }}
                        </div>
                        <div>
                            Condition: {{ jit.itemState }}
                        </div>
                    </td>
                    <td v-if="isDelivery">
                        {{ getWarehousePackets(jit) }}
                    </td>
                    <td>
                        {{ jit.tripId }}
                    </td>
                    <td v-if="isDelivery">
                        <input v-model="jit.warehouseFulfilled"
                               type="checkbox"
                               name="warehouseFulfilled"
                               disabled="disabled"
                               @change="postCheckmarks(jit)" />
                    </td>
                    <td>
                        <input v-model="jit.customerFulfilled"
                               type="checkbox"
                               name="customerFulfilled"
                               disabled="disabled"
                               @change="postCheckmarks(jit)" />
                    </td>
                    <td class="issue-ellipse"
                        @click="showIssueModal(jit)">
                        <img v-if="jit.issue"
                             src="@/assets/images/icons/warning-ellipses.svg" />
                        <img v-else
                             src="@/assets/images/icons/grey-ellipses.svg" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
  import { imageMixin } from '@/mixins/imageMixin'
  import EventBus from '@/components/utilities/EventBus'

  export default {
    name: 'JourneyItemsTable',

    mixins: [imageMixin],
    props: {
      initialGroupedJits: {
        type: Array,
        default: () => [],
      },
      isDelivery: {
        type: Boolean,
        default: true,
      },
      isGrouped: {
        type: Boolean,
        default: false,
      },
      initialJits: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        groupedJits: this.isGrouped ? _.cloneDeep(this.initialGroupedJits) : [{ jits: _.cloneDeep(this.initialJits) }],
      }
    },
    methods: {
      getPublicID(jit) {
        const productImage = this.getProductImage(jit.productImages)
        return productImage?.body?.split('upload/')[1]
      },
      showIssueModal(jit) {
        EventBus.$emit('showModal', {
          modalContentComponent: 'JITIssueReporter',
          modalProps: {
            jitObject: _.merge(jit, { publicId: this.getPublicID(jit), isDelivery: this.isDelivery }),
          },
          modalSuccessCallback: issueObject => {
            if (issueObject.deleted) {
              this.$set(jit, 'issue', undefined)
            } else {
              this.$set(jit, 'issue', issueObject)
            }
          },
        })
      },
      postCheckmarks(jit) {
        let obj = {
          inventoryItemId: jit.id,
          tripId: jit.tripId,
          customerFulfilled: jit.customerFulfilled,
          warehouseFulfilled: jit.warehouseFulfilled,
        }

        this.$apollo.mutate({
          mutation: require('@/graphql/mutations/PostTripItemChecks.gql'),
          variables: {
            tripItemChecks: [obj],
          },
        })
      },
      getWarehousePackets(jit) {
        const packets = jit.warehousePackets
        if (_.isEmpty(packets)) return ''

        return _.map(packets, p => p.packetSku).join(', ')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .jits-table {
    background-color: white;

    .table tbody + tbody {
      border: none;
    }
    table {
      table-layout: fixed;
      margin-bottom: 48px;
      thead {
        tr {
          height: 40px;
        }
        th {
          border: none;
          border-top: 1px solid #d8d8d8;
        }
      }

      tr {
        td,
        th {
          margin: 0 auto;
          padding: 0;
          vertical-align: inherit;
          font-size: 0.75rem;
          text-align: center;
        }

        td {
          border: none;
        }
      }

      .issue-ellipse:hover {
        cursor: pointer;
      }
    }

    .jit-photo img {
      max-height: 40px;
      max-width: 40px;
    }

    .location {
      margin-top: 24px;
      font-weight: bold;
      margin-left: 34%;
      width: 500px;
      color: #000000;
      font-size: 16px;
      margin-bottom: 24px;
    }

    input[type='checkbox'] {
      -webkit-appearance: none;
      border: 2px solid #262223;
      padding: 16px;
      border-radius: 4px;
      position: relative;
      background-color: lightgray;
    }

    input[type='checkbox']:checked {
      background-color: #262223;
      border: 2px solid #262223;
      color: white;
      border-radius: 4px;
    }

    input[type='checkbox']:checked:after {
      content: '\2714';
      font-size: 28px;
      position: absolute;
      top: -4px;
      left: 5px;
      color: white;
    }
  }
</style>
