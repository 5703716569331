<template>
    <div class="discount">
        <section class="gs-generic-header">
            <h1>{{ discount.code }}</h1>

            <router-link
                :to="{ name: 'EditDiscount', params: { discountId: $route.params.discountId } }">
                <button class="gs-standard-button">
                    Edit
                </button>
            </router-link>
        </section>

        <LoadingSpinner v-if="isLoadingDiscount"
                        is-black="true" />
        <div v-else
             class="gs-standard-content">
            <section class="row discount-details">
                <h3 class="gs-standard-sub-header">
                    Base
                </h3>

                <div class="col-md-4 detail-column">
                    <div class="gs-standard-detail">
                        <label>Code:</label>
                        <span>{{ discount.code }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Start date:</label>
                        <span v-if="discount.startsAt">
                            {{ $moment(discount.startsAt).tz('America/Los_Angeles').format('MM/DD/YYYY') }}
                        </span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Expire date:</label>
                        <span v-if="discount.expiresAt">
                            {{ $moment(discount.expiresAt).tz('America/Los_Angeles').format('MM/DD/YYYY') }}
                        </span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Uses Remaining:</label>
                        <span>{{ discount.usesRemaining }}</span>
                    </div>
                </div>

                <div class="col-md-4 detail-column">
                    <h4>Details</h4>

                    <div class="gs-standard-detail">
                        <label>Amount:</label>
                        <span>{{ discount.amount }}</span>
                        <span v-if="discount.discountType === 'percentage'">%</span>
                        <span v-else-if="discount.discountType === 'dollars'"> ($)</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Frequency:</label>
                        <span>{{ discount.frequency }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Minimum cart size ($):</label>
                        <span>{{ discount.minimumCartDollars }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Credit Rollover:</label>
                        <span>{{ discount.creditRollover }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>First order only:</label>
                        <span>{{ discount.firstOrderOnly }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Part of referral program:</label>
                        <span>{{ discount.referralProgram }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Referral reward:</label>
                        <span>{{ discount.referralRewardAmount }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Label:</label>
                        <span>{{ discount.label }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Title:</label>
                        <span>{{ discount.title }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Description:</label>
                        <span>{{ discount.description }}</span>
                    </div>
                </div>

                <div class="col-md-4 detail-column">
                    <h4>Administration</h4>

                    <div class="gs-standard-detail">
                        <label>Creator:</label>
                        <span>{{ discount.creatorEmail }}</span>
                    </div>
                    <div class="gs-standard-detail">
                        <label>Times applied:</label>
                        <span>{{ discount.stats.applicationCount }}</span>
                    </div>
                    <div class="gs-standard-detail">
                        <label>Unique users:</label>
                        <span>{{ discount.stats.uniqueUsers }}</span>
                    </div>
                    <div v-if="discount.internalNotes"
                         class="gs-standard-detail">
                        <label>Internal notes:</label>
                        {{ discount.internalNotes }}
                    </div>
                    <span v-else
                          class="no-items">
                        No internal notes
                    </span>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'Discount',
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        discount: {},
        isLoadingDiscount: true,
      }
    },
    computed: {},
    mounted() {
      this.getDiscount()
    },
    methods: {
      getDiscount() {
        this.isLoadingDiscount = true
        this.axios.get('/discounts/' + this.$route.params.discountId)
          .then(response => {
            this.onDiscountRetrieved(response.data)
          })
      },
      //////////// TEMPLATE ACTIONS ////////////
      //////////// CALLBACKS ////////////
      onDiscountRetrieved(data) {
        this.discount = data.discount
        this.isLoadingDiscount = false
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

  .discount {

    .gs-standard-content {

      .discount-details {

        .gs-standard-detail {

          > label {
            width: 10em;
          }
        }

        .detail-column {

          margin-bottom: 2em;

          > h4 {
            margin-bottom: 1em;
            padding-bottom: 0.25em;
            border-bottom: 1px solid black;
            width: 75%;
          }
        }
      }
    }
  }

</style>
