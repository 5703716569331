var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-discount"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Edit Discount: " + _vm._s(_vm.discount.code))]), _c('router-link', {
    attrs: {
      "to": {
        name: 'Discount',
        params: {
          discountId: _vm.$route.params.discountId
        }
      }
    }
  }, [_c('button', {
    staticClass: "gs-standard-button"
  }, [_vm._v(" Go Back ")])])], 1), _vm.isLoadingDiscount ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "content gs-standard-form"
  }, [_c('div', {
    staticClass: "create-form gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('EnableDisableButton', {
    attrs: {
      "object-to-watch": _vm.discount,
      "observe-entire-object": true,
      "disable-button-computed": _vm.isUpdatingDiscount,
      "button-text": "Update"
    },
    on: {
      "buttonClick": function buttonClick($event) {
        return _vm.updateDiscount();
      }
    }
  }), _c('section', {
    staticClass: "row discount-details"
  }, [_c('h2', [_vm._v("Discount Details")]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Code")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.code,
      expression: "discount.code"
    }],
    domProps: {
      "value": _vm.discount.code
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.discount, "code", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Start Date")]), _c('datepicker', {
    attrs: {
      "disabled-dates": _vm.disabledDates
    },
    model: {
      value: _vm.discount.startsAt,
      callback: function callback($$v) {
        _vm.$set(_vm.discount, "startsAt", $$v);
      },
      expression: "discount.startsAt"
    }
  })], 1), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Expire Date")]), _c('datepicker', {
    attrs: {
      "disabled-dates": _vm.disabledDates
    },
    model: {
      value: _vm.discount.expiresAt,
      callback: function callback($$v) {
        _vm.$set(_vm.discount, "expiresAt", $$v);
      },
      expression: "discount.expiresAt"
    }
  })], 1), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Uses remaining")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.usesRemaining,
      expression: "discount.usesRemaining"
    }],
    domProps: {
      "value": _vm.discount.usesRemaining
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.discount, "usesRemaining", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Amount")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.amount,
      expression: "discount.amount"
    }],
    staticClass: "amount",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.discount.amount
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.discount, "amount", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.discountType,
      expression: "discount.discountType"
    }],
    attrs: {
      "id": "dollars",
      "type": "radio",
      "value": "dollars"
    },
    domProps: {
      "checked": _vm._q(_vm.discount.discountType, "dollars")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.discount, "discountType", "dollars");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "dollars"
    }
  }, [_vm._v("$")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.discountType,
      expression: "discount.discountType"
    }],
    attrs: {
      "id": "percentage",
      "type": "radio",
      "value": "percentage"
    },
    domProps: {
      "checked": _vm._q(_vm.discount.discountType, "percentage")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.discount, "discountType", "percentage");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "percentage"
    }
  }, [_vm._v("%")])]), _c('div', {
    staticClass: "discount-detail frequency"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Frequency")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.frequency,
      expression: "discount.frequency"
    }],
    attrs: {
      "id": "once",
      "type": "radio",
      "value": "once"
    },
    domProps: {
      "checked": _vm._q(_vm.discount.frequency, "once")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.discount, "frequency", "once");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "once"
    }
  }, [_vm._v("once")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.frequency,
      expression: "discount.frequency"
    }],
    attrs: {
      "id": "recurring",
      "type": "radio",
      "value": "recurring"
    },
    domProps: {
      "checked": _vm._q(_vm.discount.frequency, "recurring")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.discount, "frequency", "recurring");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "recurring"
    }
  }, [_vm._v("recurring")])]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Credit Rollover?")]), _c('label', {
    staticClass: "explanation"
  }, [_vm._v(" If a user's order is less than the discount amount, should they receive a credit for the subsequent months? ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.creditRollover,
      expression: "discount.creditRollover"
    }],
    attrs: {
      "type": "radio",
      "value": "true"
    },
    domProps: {
      "checked": _vm._q(_vm.discount.creditRollover, "true")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.discount, "creditRollover", "true");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "true"
    }
  }, [_vm._v("true")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.creditRollover,
      expression: "discount.creditRollover"
    }],
    attrs: {
      "type": "radio",
      "value": "false"
    },
    domProps: {
      "checked": _vm._q(_vm.discount.creditRollover, "false")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.discount, "creditRollover", "false");
      }
    }
  }), _c('label', {
    attrs: {
      "for": "false"
    }
  }, [_vm._v("false")])]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Minimum cart size ($)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.minimumCartDollars,
      expression: "discount.minimumCartDollars"
    }],
    staticClass: "amount",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.discount.minimumCartDollars
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.discount, "minimumCartDollars", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.firstOrderOnly,
      expression: "discount.firstOrderOnly"
    }],
    attrs: {
      "id": "first-order-checkbox",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.discount.firstOrderOnly) ? _vm._i(_vm.discount.firstOrderOnly, null) > -1 : _vm.discount.firstOrderOnly
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.discount.firstOrderOnly,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.discount, "firstOrderOnly", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.discount, "firstOrderOnly", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.discount, "firstOrderOnly", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "first-order-checkbox"
    }
  }, [_vm._v("First order only")])]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.referralProgram,
      expression: "discount.referralProgram"
    }],
    attrs: {
      "id": "referral-checkbox",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.discount.referralProgram) ? _vm._i(_vm.discount.referralProgram, null) > -1 : _vm.discount.referralProgram
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.discount.referralProgram,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.discount, "referralProgram", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.discount, "referralProgram", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.discount, "referralProgram", $$c);
        }
      }
    }
  }), _c('label', {
    attrs: {
      "for": "referral-checkbox"
    }
  }, [_vm._v("Part of referral program")])]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Referral reward ($)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.referralRewardAmount,
      expression: "discount.referralRewardAmount"
    }],
    staticClass: "amount",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.discount.referralRewardAmount
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.discount, "referralRewardAmount", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Label")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.label,
      expression: "discount.label"
    }],
    staticClass: "amount",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.discount.label
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.discount, "label", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Title")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.title,
      expression: "discount.title"
    }],
    domProps: {
      "value": _vm.discount.title
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.discount, "title", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "discount-detail"
  }, [_c('label', {
    staticClass: "block-label"
  }, [_vm._v("Description")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.description,
      expression: "discount.description"
    }],
    domProps: {
      "value": _vm.discount.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.discount, "description", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Internal Notes")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.discount.internalNotes,
      expression: "discount.internalNotes"
    }],
    domProps: {
      "value": _vm.discount.internalNotes
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.discount, "internalNotes", $event.target.value);
      }
    }
  })])])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }