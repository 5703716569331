<template>
    <div class="simple-add-remove-list">
        <div class="item-zone">
            <span v-for="(item, index) in selectedItems"
                  :key="index"
                  class="item">
                <span class="item-name">
                    {{ displayKey ? item[displayKey] : item }}
                </span>
                <i class="fa fa-close remove-item"
                   @click="itemRemoved(item, index)" />
            </span>
            <span v-if="selectedItems.length === 0"
                  class="no-selected-items">
                No {{ pluralizeOrNot(2, itemType) }} selected
            </span>
        </div>

        <SimpleTypeahead
            :items="availableItems"
            :display-key="displayKey"
            :show-on-empty="showOnEmpty"
            :input-placeholder="`Search ${pluralizeOrNot(2, itemType)}...`"
            @itemClicked="addItem" />
    </div>
</template>

<script>

  import SimpleTypeahead from '@/components/forms/SimpleTypeahead'

  export default {
    name: 'SimpleAddRemoveList',
    components: {
      SimpleTypeahead,
    },
    props: {
      items: {
        type: Array,
        default: () => [],
      },
      selectedItems: {
        type: Array,
        default: () => [],
      },
      allowDuplicates: {
        type: Boolean,
        default: false,
      },
      showOnEmpty: {
        type: Boolean,
        default: false,
      },
      itemType: {
        type: String,
        default: '',
      },
      displayKey: {
        type: String,
        default: '',
      },
      matchKey: {
        type: String,
        default: '',
      },
    },
    computed: {
      availableItems() {
        if (this.allowDuplicates) return this.items
        return _.differenceWith(this.items, this.selectedItems, (a, b) => {
          if (this.matchKey) {
            return a[this.matchKey] === b[this.matchKey]
          }

          return a === b
        })
      },
    },
    methods: {
      itemRemoved(item, index) {
        this.$emit('itemRemoved', item, this.selectedItems, index)
      },
      addItem(item) {
        this.$emit('itemAdded', item, this.selectedItems)
      },
    },
  }

</script>

<style lang="scss" scoped>

.simple-add-remove-list {

  .item-zone {
    border:                           1px solid lightgray;
    padding:                          1em;
    min-height:                       5em;
    background-color:                 #fff;
    -moz-box-shadow:                  inset 0 0 4px lightgray;
    -webkit-box-shadow:               inset 0 0 4px lightgray;
    box-shadow:                       inset 0 0 4px lightgray;

    .item {
      padding:                        0.25em 0.4em;
      border:                         1px solid lightgray;
      margin-right:                   0.5em;
      margin-bottom:                  0.5em;
      display:                        inline-block;
      font-size:                      0.9em;

      &:hover {
        opacity:                      0.8;
      }

      .item-name {
        margin-right:                 0.25em;
      }

      .remove-item {
        cursor:                       pointer;
      }
    }
  }

  .no-selected-items {
    color:                            gray;
  }

}

</style>
