<template>
    <div class="partnership-page">
        <ApolloMutation :mutation="require('@/graphql/mutations/CreatePartnership.gql')"
                        :variables="newPartnership"
                        @done="onPartnershipCreate">
            <template #default="{ mutate, loading, error }">
                <SimpleCreateForm :show-loading-state="loading"
                                  :new-model-instance="newPartnership"
                                  class="create-form"
                                  @formSubmit="mutate">
                    <template #bannerImage>
                        <div class="drop-zone"
                             @drop.prevent="dropFile"
                             @dragover.prevent>
                            <h4>Drop Image</h4>
                            <div :if="newPartnership.bannerImage">
                                <p>{{ newPartnership.bannerImage.name }}</p>
                            </div>
                        </div>
                    </template>
                </SimpleCreateForm>
                <p v-if="error">
                    An error occurred: {{ error }}
                </p>
            </template>
        </ApolloMutation>
        <v-client-table v-if="partnerships && partnerships.length"
                        :data="partnerships"
                        :columns="columns"
                        :options="options">
            <div slot="identifier"
                 slot-scope="data">
                <input v-if="isEditing(data.row.id)"
                       v-model="editingPartnership.identifier" />
                <span v-else>{{ data.row.identifier }}</span>
            </div>
            <div slot="brandName"
                 slot-scope="data">
                <input v-if="isEditing(data.row.id)"
                       v-model="editingPartnership.brandName" />
                <span v-else>{{ data.row.brandName }}</span>
            </div>
            <div slot="discountCode"
                 slot-scope="data">
                <input v-if="isEditing(data.row.id)"
                       v-model="editingPartnership.discountCode" />
                <span v-else>{{ data.row.discountCode }}</span>
            </div>
            <div slot="bannerImage"
                 slot-scope="data">
                <img v-if="data.row.bannerImage && !isEditing(data.row.id)"
                     :src="data.row.bannerImage"
                     width="300" />
                <div v-if="isEditing(data.row.id)"
                     class="drop-zone dz-editing"
                     @drop.prevent="dropFileEdit"
                     @dragover.prevent>
                    <h4>Drop Image</h4>
                    <div :if="editingPartnership.bannerImage">
                        <p>{{ editingPartnership.bannerImage.name }}</p>
                    </div>
                </div>
            </div>
            <div slot="edit"
                 slot-scope="data"
                 class="gs-standard-table-edit">
                <TableEditButton :is-editing-this-item="isEditing(data.row.id)"
                                 :show-loading-state="loadingRow === data.row.id"
                                 :show-save-button="isEditing(data.row.id) && loadingRow !== data.row.id"
                                 @saveButtonClick="saveEdit"
                                 @editButtonClick="setEditing(data.row)" />
            </div>
            <div slot="delete"
                 slot-scope="data"
                 class="gs-standard-table-delete">
                <ApolloMutation :mutation="require('@/graphql/mutations/DeletePartnership.gql')"
                                :variables="{id: data.row.id}"
                                @done="onPartnershipDelete">
                    <template #default="{ mutate }">
                        <DeleteIcon @delete="mutate" />
                    </template>
                </ApolloMutation>
            </div>
        </v-client-table>
    </div>
</template>

<script>
  import { defineComponent } from "@vue/composition-api"
  import SimpleCreateForm from "@/components/forms/SimpleCreateForm"
  import DeleteIcon from "@/components/widgets/DeleteIcon"
  import TableEditButton from "@/components/tables/TableEditButton"
  import { get } from "lodash"
  import gql from "graphql-tag"

  export default defineComponent({
    name: "Partnerships",
    components: { SimpleCreateForm, DeleteIcon, TableEditButton },
    apollo: {
      partnerships: {
        query: gql`
          {
            partnerships {
              id
              brandName
              bannerImage
              identifier
              discount {
                code
              }
            }
          }
        `,
        result(response) {
          this.partnerships = response.data.partnerships.map(item => {
            return {
              id: item.id,
              brandName: item.brandName,
              identifier: item.identifier,
              bannerImage: item.bannerImage,
              discountCode: item.discount.code,
            }
          })
        },
      },
    },
    setup() {
      const columns = [
        "id",
        "identifier",
        "brandName",
        "bannerImage",
        "discountCode",
        "edit",
        "delete",
      ]
      const options = {
        perPage: 20,
        perPageValues: [20, 50, 100, 250, 500],
        headings: {
          brandName: "Brand Name",
          bannerImage: "Banner Image",
          discountCode: "Discount Code",
        },
      }
      return {
        columns,
        options,
      }
    },
    data: () => {
      return {
        newPartnership: {
          identifier: "",
          brandName: "",
          discountCode: "",
          bannerImage: "",
        },
        partnerships: [],
        editingPartnership: {},
        loadingRow: 0,
      }
    },
    methods: {
      dropFile(e) {
        const droppedFiles = e.dataTransfer.files
        if (!droppedFiles) return
        this.newPartnership.bannerImage = droppedFiles[0]
      },
      setEditing(row) {
        this.editingPartnership = row
      },
      isEditing(id) {
        return id === this.editingPartnership.id
      },
      dropFileEdit(e) {
        const droppedFiles = e.dataTransfer.files
        if (!droppedFiles) return
        this.editingPartnership.bannerImage = droppedFiles[0]
      },
      onPartnershipCreate() {
        this.refreshList()
        this.newPartnership = {}
      },
      onPartnershipDelete(res) {
        const id = get(res, "data.deletePartnership.id")
        if (this.editingPartnership.id === id) {
          this.editingPartnership = {}
        }
        if (this.loadingRow === id) {
          this.loadingRow = 0
        }
        this.refreshList()
      },
      refreshList() {
        this.$apollo.queries.partnerships.refetch()
      },
      saveEdit() {
        const valid = !Object.values(this.editingPartnership).some(
          item => item === "",
        )
        if (!valid) {
          alert("Can not update value to be empty")
          return
        }
        this.loadingRow = this.editingPartnership.id
        const original = this.partnerships.find(
          item => item.id === this.loadingRow,
        )
        const sendValue = Object.keys(this.editingPartnership).reduce(
          (acc, key) => {
            if (original[key] !== this.editingPartnership[key]) {
              acc[key] = this.editingPartnership[key]
            }
            return acc
          },
          { id: this.editingPartnership.id },
        )
        this.$apollo
          .mutate({
            mutation: require("@/graphql/mutations/UpdatePartnership.gql"),
            variables: sendValue,
          })
          .then(_data => {
            this.loadingRow = 0
            this.editingPartnership = {}
            this.refreshList()
          })
          .catch(err => {
            alert(err)
          })
      },
    },
  })
</script>

<style lang="scss" scoped>
  .partnership-page {
    .drop-zone {
      border: 2px dashed black;
      padding: 16px 32px 32px;
      margin-bottom: 24px;
      &.dz-editing {
        width: 300px;
        height: 300px;
      }
    }
    .create-form {
      margin-bottom: 32px;
    }
  }
</style>
