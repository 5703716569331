var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Layout")]), _c('router-link', {
    staticClass: "gs-standard-button",
    attrs: {
      "to": {
        name: 'EditLayout',
        params: {
          layoutId: _vm.$route.params.layoutId
        }
      }
    }
  }, [_vm._v(" Edit ")])], 1), _vm.isLoadingLayout ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "gs-standard-content"
  }, [_c('section', {
    staticClass: "row layout-details"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" General Info ")]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Name:")]), _c('span', [_vm._v(_vm._s(_vm.layout.name))])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Category:")]), _c('span', [_vm._v(" " + _vm._s(_vm._.get(_vm.layout.productCategory, 'name', 'None')) + " ")])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Is Active:")]), _c('span', [_vm._v(_vm._s(_vm.layout.isActive || 'false') + " "), !_vm.layout.isActive ? _c('span', {
    class: _vm.readyClass
  }, [_vm._v(_vm._s(_vm.ready))]) : _vm._e()])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("supported views:")]), _c('span', _vm._l(_vm.layout.supportedViews, function (view) {
    return _c('span', {
      key: view
    }, [_vm._v(_vm._s(" ".concat(view, " ")))]);
  }), 0)])])]), _c('section', {
    staticClass: "layout-images"
  }, [_c('h3', {
    staticClass: "gs-standard-sub-header"
  }, [_vm._v(" Layout Image ")]), _vm.layout.heroImage ? _c('div', {
    staticClass: "row image-list"
  }, [_c('div', {
    staticClass: "image col-md-2"
  }, [_c('cld-image', {
    attrs: {
      "public-id": _vm.layout.heroImage,
      "height": "400"
    }
  })], 1)]) : _c('div', [_c('span', {
    staticClass: "no-items"
  }, [_vm._v("No layout image")])])]), _c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h3', [_vm._v("Product Types")])]), _vm.isLoadingProductTypes ? _c('section', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('section', {
    staticClass: "layout-product-types-table gs-hide-filter"
  }, [_vm.layoutProductTypes.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.layoutProductTypes,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "productType",
      fn: function fn(data) {
        return _c('div', {}, [_vm._v(" " + _vm._s(data.row.name) + " ")]);
      }
    }], null, false, 3206376258)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no product types for this layout ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }