<template>
    <div class="product-material gs-simple-crud">
        <h1>Product Materials</h1>

        <SimpleCreateForm
            :primary-error-message="primaryErrorMessage"
            :error-messages="errorMessages"
            :show-loading-state="isCreatingProductMaterial"
            :new-model-instance="newProductMaterial"
            @formSubmit="createProductMaterial" />

        <div v-if="isLoadingProductMaterials">
            <LoadingSpinner is-black="true" />
        </div>


        <div v-else
             class="content">
            <section class="product-material-table gs-standard-table">
                <v-client-table
                    v-if="productMaterials.length > 0"
                    :data="productMaterials"
                    :columns="columns"
                    :options="options">
                    <div slot="editName"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row['name']" />
                        <span v-else>{{ data.row['name'] }}</span>
                    </div>

                    <div slot="edit"
                         slot-scope="data"
                         class="gs-standard-table-edit">
                        <TableEditButton
                            :is-editing-this-item="isEditingThisItem(data.row.id)"
                            :show-loading-state="isEditingThisItem(data.row.id) && isSavingThisItem(data.row.id)"
                            :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                            @saveButtonClick="updateProductMaterial(data.row)"
                            @editButtonClick="currentEditProductMaterialId = data.row.id" />
                    </div>

                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteProductMaterial(data.row)" />
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no Product Materials to display
                </div>
            </section>

            <ConfirmModal
                v-if="showConfirmDeleteModal"
                :show-loading-state="isDeletingProductMaterial"
                @cancel="showConfirmDeleteModal = false"
                @confirm="deleteProductMaterial()">
                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingProductMaterial.name }}</b>? This will also destroy any associations with this product material!</span>
            </ConfirmModal>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import EventBus from '@/components/utilities/EventBus'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'ProductMaterials',
    components: {
      LoadingSpinner,
      SimpleCreateForm,
      ConfirmModal,
      DeleteIcon,
      TableEditButton,
    },
    data() {
      return {
        isLoadingProductMaterials:          false,
        errorMessages:                      [],
        primaryErrorMessage:                '',
        productMaterials:                   [],
        newProductMaterial:                 this.initializeNewProductMaterial(),
        isCreatingProductMaterial:          false,
        currentEditProductMaterialId:       null,
        currentSavingProductMaterialId:     null,
        currentDeletingProductMaterial:     null,
        isDeletingProductMaterial:          false,
        showConfirmDeleteModal:             false,
        columns:                            ['id', 'editName', 'identifier', 'productCount', 'edit', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'editName': 'Name',
            'productCount': 'Product Count',
          },
        },
      }
    },
    mounted() {
      this.getProductMaterials()
    },
    methods: {
      getProductMaterials() {
        this.isLoadingProductMaterials = true
        this.axios.get('/product_materials')
          .then(response => this.onProductMaterialsRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      createProductMaterial() {
        this.isCreatingProductMaterial = true
        this.axios.post('/product_materials', { productMaterial: this.newProductMaterial })
          .then(response => this.onProductMaterialCreated(response.data))
          .catch(error => this.onProductMaterialCreatedFailure(error.response))
      },
      updateProductMaterial(productMaterial) {
        this.currentSavingProductMaterialId = productMaterial.id
        this.axios.patch('/product_materials/' + productMaterial.id, { productMaterial: productMaterial })
          .then(response => this.onProductMaterialUpdated(response.data))
          .catch(error => this.onProductMaterialUpdatedFailure(error.response))
      },
      setCurrentDeleteProductMaterial(productMaterial) {
        this.showConfirmDeleteModal = true
        this.currentDeletingProductMaterial = productMaterial
      },
      //////////// CALLBACKS ////////////
      onProductMaterialsRetrieved(data) {
        this.isLoadingProductMaterials = false
        this.productMaterials = data.productMaterials
      },
      onProductMaterialCreated(data) {
        this.isCreatingProductMaterial = false
        this.productMaterials.push(data.productMaterial)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created product material!',
          statusCode: 1,
        })
        this.newProductMaterial = this.initializeNewProductMaterial()
      },
      onProductMaterialCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating productMaterial'
          this.errorMessages = JSON.stringify(response)
        }

        this.isCreatingProductMaterial = false
      },
      onProductMaterialUpdated(data) {
        arrayHelper.replaceObjectByValue(this.productMaterials, data.productMaterial, 'id')
        this.currentEditProductMaterialId = null
        this.currentSavingProductMaterialId = null
      },
      onProductMaterialUpdatedFailure(response) {
        EventBus.$emit('globalAlert', {
          message: response.data.messages[0],
          statusCode: 3,
        })

        this.currentSavingProductMaterialId = null
      },
      onProductMaterialDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingProductMaterial = null

        arrayHelper.removeObjectByValue(this.productMaterials, data.productMaterial.id, 'id')
        EventBus.$emit('globalAlert', {
          message: 'Product material successfully deleted!',
          statusCode: 1,
        })
      },
      onProductMaterialDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating productMaterial'
          this.errorMessages = JSON.stringify(response)
        }

        this.showConfirmDeleteModal = false
        this.currentDeletingProductMaterial = false
      },
      onProductMaterialDeleteDone() {
        this.isDeletingProductMaterial = false
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewProductMaterial() {
        return {
          name:       '',
        }
      },
      isSavingThisItem(id) {
        return this.currentSavingProductMaterialId === id
      },
      isEditingThisItem(id) {
        return this.currentEditProductMaterialId === id
      },
      deleteProductMaterial() {
        this.isDeletingProductMaterial = true
        this.axios.delete('/product_materials/' + this.currentDeletingProductMaterial.id)
          .then(response => this.onProductMaterialDeleted(response.data))
          .catch(error => this.onProductMaterialDeletedFailure(error.response))
          .finally(() => this.onProductMaterialDeleteDone())
      },
    },
  }
</script>

<style lang="scss" scoped>

.product-material {

  .content {
    margin-top:                   1em;
  }
}

</style>
