var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-attribute-instance gs-simple-crud"
  }, [_c('ApolloQuery', {
    attrs: {
      "query": require('@/graphql/queries/ProductAttributeInstancesByAttr.gql'),
      "variables": {
        productAttributeId: _vm.productAttributeId
      }
    },
    on: {
      "result": _vm.onProductAttributeInstancesRetrieved
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var _ref$result = _ref.result,
            loading = _ref$result.loading,
            error = _ref$result.error,
            data = _ref$result.data;
        return [loading ? _c('div', {
          staticClass: "loading apollo"
        }, [_vm._v(" Loading... ")]) : error ? _c('div', {
          staticClass: "error apollo"
        }, [_vm._v(" An error occurred: " + _vm._s(data.error) + " ")]) : data ? _c('div', {
          staticClass: "result apollo"
        }, [_c('h1', [_vm._v(_vm._s(data.productAttributes[0].name))]), _c('ApolloMutation', {
          attrs: {
            "mutation": require('@/graphql/mutations/CreateProductAttributeInstances.gql'),
            "variables": {
              instances: _vm.productsToAdd.map(function (product) {
                return _vm._.merge({}, _vm.newProductAttributeInstance, {
                  productId: product.id
                });
              })
            }
          },
          on: {
            "done": _vm.onProductAttributeInstancesCreated
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var mutate = _ref2.mutate,
                  loading = _ref2.loading,
                  error = _ref2.error;
              return [_c('SimpleAddRemoveList', {
                attrs: {
                  "items": _vm.products,
                  "selected-items": _vm.productsToAdd,
                  "allow-duplicates": true,
                  "display-key": "name",
                  "match-key": "id",
                  "item-type": "product"
                },
                on: {
                  "itemAdded": _vm.addProductToList,
                  "itemRemoved": _vm.removeProductFromList
                }
              }), _c('SimpleCreateForm', {
                attrs: {
                  "primary-error-message": _vm.primaryErrorMessage,
                  "error-messages": _vm.errorMessages,
                  "show-loading-state": loading,
                  "new-model-instance": _vm.newProductAttributeInstance
                },
                on: {
                  "formSubmit": mutate
                }
              }), error ? _c('p', [_vm._v(" An error occurred: " + _vm._s(error) + " ")]) : _vm._e()];
            }
          }], null, true)
        }), _c('section', {
          staticClass: "product-attribute-instance gs-standard-table"
        }, [_vm.products.length > 0 ? _c('v-client-table', {
          attrs: {
            "data": _vm.productsWithInstances(_vm.products, data.productAttributes[0].instances),
            "columns": _vm.columns,
            "options": _vm.options
          },
          scopedSlots: _vm._u([{
            key: "name",
            fn: function fn(data) {
              return _c('div', {}, [_c('router-link', {
                attrs: {
                  "to": {
                    name: 'Product',
                    params: {
                      productId: data.row.productId
                    }
                  }
                }
              }, [_vm._v(" " + _vm._s(data.row.name) + " ")])], 1);
            }
          }, {
            key: "editValue",
            fn: function fn(data) {
              return _c('div', {
                staticClass: "product-attribute-instance column"
              }, [_vm.isEditingThisItem(data.row.id) ? _c('div', [_vm.productAttribute.allowedValues ? _c('SimpleArrayDropdown', {
                staticClass: "product-attribute-instance dropdown",
                attrs: {
                  "items": _vm.productAttribute.allowedValues,
                  "selected-item": data.row.value,
                  "object": data.row,
                  "prompt": "Please select a value..."
                },
                on: {
                  "itemSelected": _vm.selectValue
                }
              }) : _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.row.value,
                  expression: "data.row.value"
                }],
                domProps: {
                  "value": data.row.value
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(data.row, "value", $event.target.value);
                  }
                }
              })], 1) : _c('span', [_vm._v(_vm._s(data.row.value))])]);
            }
          }, {
            key: "edit",
            fn: function fn(data) {
              return _c('div', {
                staticClass: "gs-standard-table-edit"
              }, [_c('ApolloMutation', {
                attrs: {
                  "mutation": require('@/graphql/mutations/UpdateProductAttributeInstances.gql'),
                  "variables": data.row
                },
                on: {
                  "done": _vm.onProductAttributeInstancesUpdated
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref3) {
                    var mutate = _ref3.mutate,
                        _loading = _ref3._loading,
                        updateError = _ref3.updateError;
                    return [_c('TableEditButton', {
                      attrs: {
                        "is-editing-this-item": _vm.isEditingThisItem(data.row.id),
                        "show-save-button": _vm.isEditingThisItem(data.row.id) && !_vm.isSavingThisItem(data.row.id)
                      },
                      on: {
                        "saveButtonClick": mutate,
                        "editButtonClick": function editButtonClick($event) {
                          _vm.currentEditProductAttributeInstanceId = data.row.id;
                        }
                      }
                    }), error ? _c('p', [_vm._v(" An error occurred: " + _vm._s(updateError) + " ")]) : _vm._e()];
                  }
                }], null, true)
              })], 1);
            }
          }, {
            key: "delete",
            fn: function fn(data) {
              return _c('div', {
                staticClass: "gs-standard-table-delete"
              }, [_c('DeleteIcon', {
                on: {
                  "delete": function _delete($event) {
                    return _vm.setCurrentDeleteProductAttributeInstance(data.row);
                  }
                }
              })], 1);
            }
          }], null, true)
        }) : _c('div', {
          staticClass: "no-items"
        }, [_vm._v(" There are no instances to display ")])], 1), _c('ApolloMutation', {
          attrs: {
            "mutation": require('@/graphql/mutations/UpdateProductAttributeInstances.gql'),
            "variables": _vm.currentDeletingProductAttributeInstance
          },
          on: {
            "done": _vm.onProductAttributeInstancesDeleted
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var mutate = _ref4.mutate,
                  _loading = _ref4._loading,
                  updateError = _ref4.updateError;
              return [_vm.showConfirmDeleteModal ? _c('ConfirmModal', {
                attrs: {
                  "show-loading-state": _vm.isDeletingProductAttributeInstance
                },
                on: {
                  "cancel": function cancel($event) {
                    _vm.showConfirmDeleteModal = false;
                  },
                  "confirm": mutate
                }
              }, [_c('span', {
                attrs: {
                  "slot": "body"
                },
                slot: "body"
              }, [_vm._v("Are you sure you want to remove "), _c('b', [_vm._v(_vm._s(_vm.currentDeletingProductAttributeInstance.product.name))]), _vm._v("? This will also any delete data for this attribute!")])]) : _vm._e(), error ? _c('p', [_vm._v(" An error occurred: " + _vm._s(updateError) + " ")]) : _vm._e()];
            }
          }], null, true)
        })], 1) : _c('div', {
          staticClass: "no-result apollo"
        }, [_vm._v(" No result :( ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }