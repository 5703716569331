var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm.primaryMessage ? _c('span', {
    staticClass: "primary-message"
  }, [_vm._v(_vm._s(_vm.primaryMessage))]) : _c('span', {
    staticClass: "primary-message"
  }, [_vm._v("The following errors occurred:")]), _vm.errorMessages && _vm.errorMessages.length > 0 ? _c('ul', _vm._l(_vm.errorMessages, function (message, index) {
    return _c('li', {
      key: index
    }, [_vm._v(" " + _vm._s(message) + " ")]);
  }), 0) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }