var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "region-configuration"
  }, [_c('transition', {
    attrs: {
      "name": "overlay"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showRegionConfiguration,
      expression: "showRegionConfiguration"
    }],
    staticClass: "cart-opacity full-page-overlay",
    on: {
      "click": _vm.hideRegionConfiguration
    }
  })]), _c('div', {
    staticClass: "region-configuration-visible",
    class: {
      visible: _vm.showRegionConfiguration
    }
  }, [_c('section', {
    class: ['menu-top', {
      shadow: _vm.isScrolling
    }]
  }, [_c('div', {
    staticClass: "menu-top-header"
  }, [_c('h2', {
    staticClass: "bold"
  }, [_vm._v(" Region Configuration ")]), _c('h2', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.regionObject.name) + " ")])]), _c('configuration', {
    attrs: {
      "selected-region": _vm.regionObject
    }
  }), _c('configuration-form', {
    attrs: {
      "selected-region": _vm.regionObject
    }
  })], 1), _c('div', {
    staticClass: "close-container"
  }, [_c('img', {
    staticClass: "close-right",
    attrs: {
      "src": require("@/assets/images/icons/close-button.svg")
    },
    on: {
      "click": _vm.hideRegionConfiguration
    }
  })])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }