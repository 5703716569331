<template>
    <div v-if="message"
         class="notification-container">
        <div class="global-alert"
             :class="['status-' + statusCode]"
             @click="clearAlert()">
            <span v-html="message" />
        </div>
    </div>
</template>

<script>

  import EventBus from '@/components/utilities/EventBus'

  export default {
    name: 'GlobalAlert',
    data() {
      return {
        message:      null,
        statusCode:   null,
      }
    },
    mounted() {
      EventBus.$on('globalAlert', (payload) => {
        this.message = payload.message
        this.statusCode = payload.statusCode

        if (!payload.linger) {
          setTimeout(() => {
            this.clearAlert()
          }, 3000)
        }
      })
    },
    methods: {
      clearAlert() {
        this.message = null
        this.statusCode = null
      },
    },
  }
</script>

<style lang="scss" scoped>

.global-alert {
  width:                      100%;
  text-align:                 center;
  padding:                    1em;
  position:                   fixed;
  z-index:                    999999;
  background-color:           #000000;
  color:                      #fff;
  top:                        0;
  cursor:                     pointer;

  &.status-1 {
    color:                    $success-primary;
    background-color:         $success-background;
  }

  &.status-2 {
    color:                    $warning-primary;
    background-color:         $warning-background;
  }

  &.status-3 {
    color:                    $danger-primary;
    background-color:         $danger-background;
  }

}


</style>
