import User from '@/models/User'

const state = {
  user: null,
}

const mutations = {
  logout(state) {
    state.user = null
  },
  setCurrentUser(state, user) {
    state.user = user
  },
}

const getters = {
  currentUser(state) {
    return state.user
  },
}

const actions = {
  logout({ commit }) {
    localStorage.removeItem('loggedIn')
    commit('logout')
  },
  setCurrentUser({ commit }) {
    const loggedIn = localStorage.getItem('loggedIn')
    if (!loggedIn) return Promise.reject('no local token found')

    return User.fromToken()
      .then((user) => commit('setCurrentUser', user))
      .catch((error) => {
        commit('setCurrentUser', null)
        localStorage.removeItem('loggedIn')
        return Promise.reject(error)
      })
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
