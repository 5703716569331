var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.message ? _c('div', {
    staticClass: "notification-container"
  }, [_c('div', {
    staticClass: "global-alert",
    class: ['status-' + _vm.statusCode],
    on: {
      "click": function click($event) {
        return _vm.clearAlert();
      }
    }
  }, [_c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  })])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }