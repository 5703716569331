var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "create-suggestion-override"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Create Curated Set")]), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isSavingSuggestionOverride
    },
    on: {
      "click": function click($event) {
        return _vm.createSuggestionOverride();
      }
    }
  }, [!_vm.isSavingSuggestionOverride ? _c('span', [_vm._v("Create")]) : _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.5"
    }
  })], 1)]), _c('div', {
    staticClass: "create-form gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "suggestion-override-details"
  }, [_c('h2', [_vm._v("Override Basics")]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Source Product Sku Id:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newSuggestionOverride.sourceProductSkuId,
      expression: "newSuggestionOverride.sourceProductSkuId"
    }],
    domProps: {
      "value": _vm.newSuggestionOverride.sourceProductSkuId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newSuggestionOverride, "sourceProductSkuId", $event.target.value);
      }
    }
  }), _vm.sourceProductSkuExists ? _c('div', {
    staticClass: "edit-source-product-sku"
  }, [_vm._v(" " + _vm._s(_vm.selectedSourceProductSku.name) + " "), _c('i', {
    staticClass: "fa fa-close remove-item",
    on: {
      "click": function click($event) {
        return _vm.removeSourceProductSku();
      }
    }
  })]) : _c('SimpleTypeahead', {
    attrs: {
      "items": _vm.allProductSkus,
      "display-key": "name",
      "input-placeholder": "Search product skus...",
      "typeahead-width": "20em"
    },
    on: {
      "itemClicked": _vm.selectSourceProductSku
    }
  })], 1), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Target Product Sku Id:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newSuggestionOverride.targetProductSkuId,
      expression: "newSuggestionOverride.targetProductSkuId"
    }],
    domProps: {
      "value": _vm.newSuggestionOverride.targetProductSkuId
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newSuggestionOverride, "targetProductSkuId", $event.target.value);
      }
    }
  }), _vm.targetProductSkuExists ? _c('div', {
    staticClass: "edit-target-product-sku"
  }, [_vm._v(" " + _vm._s(_vm.selectedTargetProductSku.name) + " "), _c('i', {
    staticClass: "fa fa-close remove-item",
    on: {
      "click": function click($event) {
        return _vm.removeTargetProductSku();
      }
    }
  })]) : _c('SimpleTypeahead', {
    attrs: {
      "items": _vm.allProductSkus,
      "display-key": "name",
      "input-placeholder": "Search product skus...",
      "typeahead-width": "20em"
    },
    on: {
      "itemClicked": _vm.selectTargetProductSku
    }
  })], 1), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Priority:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newSuggestionOverride.priority,
      expression: "newSuggestionOverride.priority"
    }],
    domProps: {
      "value": _vm.newSuggestionOverride.priority
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newSuggestionOverride, "priority", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Block?:")]), _c('toggle-button', {
    attrs: {
      "value": _vm.newSuggestionOverride.block,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.newSuggestionOverride.block = !_vm.newSuggestionOverride.block;
      }
    }
  })], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }