var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-account"
  }, [_c('section', {
    staticClass: "header"
  }, [_c('h1', [_vm._v(_vm._s(_vm.currentUser.fullName))]), _c('span', {
    staticClass: "email"
  }, [_vm._v(_vm._s(_vm.currentUser.emailAddress))])]), _c('section', {
    staticClass: "row change-password"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('h2', [_vm._v("Change Password")]), _c('div', {
    staticClass: "password-form gs-standard-form"
  }, [_vm.primaryPasswordErrorMessage || _vm.passwordErrorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryPasswordErrorMessage,
      "error-messages": _vm.passwordErrorMessages
    }
  }) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.oldPassword,
      expression: "oldPassword"
    }],
    staticClass: "current-password",
    attrs: {
      "placeholder": "Old Password",
      "type": "password"
    },
    domProps: {
      "value": _vm.oldPassword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.oldPassword = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newPassword,
      expression: "newPassword"
    }],
    staticClass: "new-password",
    attrs: {
      "placeholder": "New Password",
      "type": "password"
    },
    domProps: {
      "value": _vm.newPassword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.newPassword = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.confirmNewPassword,
      expression: "confirmNewPassword"
    }],
    staticClass: "confirm-new-password",
    attrs: {
      "placeholder": "Confirm New Password",
      "type": "password"
    },
    domProps: {
      "value": _vm.confirmNewPassword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.confirmNewPassword = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isSavingPassword
    },
    on: {
      "click": _vm.updatePassword
    }
  }, [!_vm.isSavingPassword ? _c('span', [_vm._v("Update")]) : _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.5"
    }
  })], 1)], 1)])]), _c('section', {
    staticClass: "row add-user"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('h2', [_vm._v("Add New Admin User")]), _c('div', {
    staticClass: "add-user-form gs-standard-form"
  }, [_vm.primaryUserErrorMessage || _vm.userErrorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryUserErrorMessage,
      "error-messages": _vm.userErrorMessages
    }
  }) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newUser.firstName,
      expression: "newUser.firstName"
    }],
    staticClass: "first-name",
    attrs: {
      "placeholder": "First name"
    },
    domProps: {
      "value": _vm.newUser.firstName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newUser, "firstName", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newUser.lastName,
      expression: "newUser.lastName"
    }],
    staticClass: "last-name",
    attrs: {
      "placeholder": "Last name"
    },
    domProps: {
      "value": _vm.newUser.lastName
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newUser, "lastName", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newUser.emailAddress,
      expression: "newUser.emailAddress"
    }],
    staticClass: "current-password",
    attrs: {
      "placeholder": "Company email address"
    },
    domProps: {
      "value": _vm.newUser.emailAddress
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newUser, "emailAddress", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newUser.password,
      expression: "newUser.password"
    }],
    staticClass: "new-user-password",
    attrs: {
      "placeholder": "Password",
      "type": "password"
    },
    domProps: {
      "value": _vm.newUser.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newUser, "password", $event.target.value);
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newUser.confirmPassword,
      expression: "newUser.confirmPassword"
    }],
    staticClass: "new-user-confirm-password",
    attrs: {
      "placeholder": "Confirm Password",
      "type": "password"
    },
    domProps: {
      "value": _vm.newUser.confirmPassword
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newUser, "confirmPassword", $event.target.value);
      }
    }
  }), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isSavingUser
    },
    on: {
      "click": _vm.addNewUser
    }
  }, [!_vm.isSavingUser ? _c('span', [_vm._v("Create")]) : _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.5"
    }
  })], 1)], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }