var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "contact"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('h3', {
    staticClass: "emphasized"
  }, [_vm._v(" " + _vm._s(_vm.customerName) + " ")])]), _c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "contact-info"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" Address ")]), _c('div', [_vm._v(" " + _vm._s(_vm.customerAddress) + " ")])]), _c('div', {
    staticClass: "contact-info"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" Email ")]), _c('div', [_vm._v(" " + _vm._s(_vm.customerEmail) + " ")])]), _c('div', {
    staticClass: "contact-info"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(" Phone ")]), _c('div', [_vm._v(" " + _vm._s(_vm.customerPhone) + " ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }