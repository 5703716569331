var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "loading"
  }, [_vm.isBlack ? _c('img', {
    style: {
      maxHeight: _vm.maxHeight + 'em'
    },
    attrs: {
      "src": require("@/assets/images/icons/spinner-black.svg")
    }
  }) : _c('img', {
    style: {
      maxHeight: _vm.maxHeight + 'em'
    },
    attrs: {
      "src": require("@/assets/images/icons/spinner-white.svg")
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }