<template>
    <div class="create-discount">
        <section class="gs-generic-header">
            <h1>Create Discount</h1>
            <button type="submit"
                    :disabled="isSavingDiscount"
                    class="gs-standard-button"
                    @click="createDiscount()">
                <span v-if="!isSavingDiscount">Create</span>
                <LoadingSpinner v-else
                                max-height="1.5" />
            </button>
        </section>

        <div class="create-form gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />

            <section class="row discount-details">
                <h2>Discount Details</h2>

                <div class="col-md-3">
                    <div class="gs-standard-detail">
                        <label>Code</label>
                        <input v-model="newDiscount.code" />
                    </div>

                    <div class="gs-standard-detail">
                        <label>Start Date</label>
                        <datepicker v-model="newDiscount.startsAt"
                                    :disabled-dates="disabledDates" />
                    </div>

                    <div class="gs-standard-detail">
                        <label>Expire Date</label>
                        <datepicker v-model="newDiscount.expiresAt"
                                    :disabled-dates="disabledDates" />
                    </div>

                    <div class="gs-standard-detail">
                        <label>Uses remaining</label>
                        <input v-model="newDiscount.usesRemaining" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="discount-detail">
                        <label class="block-label">Amount</label>
                        <input v-model="newDiscount.amount"
                               class="amount"
                               type="text" />

                        <input id="dollars"
                               v-model="newDiscount.discountType"
                               type="radio"
                               value="dollars" />
                        <label for="dollars">$</label>
                        <input id="percentage"
                               v-model="newDiscount.discountType"
                               type="radio"
                               value="percentage" />
                        <label for="percentage">%</label>
                    </div>

                    <div class="discount-detail frequency">
                        <label class="block-label">Frequency</label>
                        <input id="once"
                               v-model="newDiscount.frequency"
                               type="radio"
                               value="once" />
                        <label for="once">once</label>
                        <input id="recurring"
                               v-model="newDiscount.frequency"
                               type="radio"
                               value="recurring" />
                        <label for="recurring">recurring</label>
                    </div>

                    <div class="discount-detail">
                        <label class="block-label">Credit Rollover?</label>
                        <label class="explanation">
                            If a user's order is less than the discount amount,
                            should they receive a credit for the subsequent months?
                        </label>
                        <input v-model="newDiscount.creditRollover"
                               type="radio"
                               value="true" />
                        <label for="true">true</label>
                        <input v-model="newDiscount.creditRollover"
                               type="radio"
                               value="false" />
                        <label for="false">false</label>
                    </div>

                    <div class="discount-detail">
                        <label class="block-label">Minimum cart size ($)</label>
                        <input v-model="newDiscount.minimumCartDollars"
                               class="amount"
                               type="text" />
                    </div>

                    <div class="discount-detail">
                        <input id="first-order-checkbox"
                               v-model="newDiscount.firstOrderOnly"
                               type="checkbox" />
                        <label for="first-order-checkbox">First order only</label>
                    </div>

                    <div class="discount-detail">
                        <input id="referral-checkbox"
                               v-model="newDiscount.referralProgram"
                               type="checkbox" />
                        <label for="referral-checkbox">Part of referral program</label>
                    </div>

                    <div class="discount-detail">
                        <label class="block-label">Referral reward ($)</label>
                        <input v-model="newDiscount.referralRewardAmount"
                               class="amount"
                               type="text" />
                    </div>

                    <div class="discount-detail">
                        <label class="block-label">Minimum cart size ($)</label>
                        <input v-model="newDiscount.minimumCartDollars"
                               class="amount"
                               type="text" />
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="discount-detail">
                        <label>Label</label>
                        <input v-model="newDiscount.label" />
                    </div>

                    <div class="discount-detail">
                        <label>Title</label>
                        <textarea v-model="newDiscount.title" />
                    </div>

                    <div class="discount-detail">
                        <label>Description</label>
                        <textarea v-model="newDiscount.description" />
                    </div>
                    <div class="gs-standard-detail">
                        <label>Internal Notes</label>
                        <textarea v-model="newDiscount.internalNotes" />
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import Datepicker from 'vuejs-datepicker'
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'

  export default {
    name: 'CreateDiscount',
    components: {
      Datepicker,
      LoadingSpinner,
      FormErrors,
    },
    data() {
      return {
        primaryErrorMessage:    '',
        errorMessages:          [],
        isSavingDiscount:       false,
        newDiscount:            this.initializeNewDiscount(),
        // Disabled until yesterday
        disabledDates:          { to: (d => new Date(d.setDate(d.getDate() - 1)))(new Date()) },
      }
    },
    computed: {
      correctedDiscount() {
        return Object.assign({}, this.newDiscount, {
          startsAt: this.newDiscount.startsAt ? this.$moment.tz(this.newDiscount.startsAt, 'America/Los_Angeles').startOf('day').format() : null,
          expiresAt: this.newDiscount.expiresAt ? this.$moment.tz(this.newDiscount.expiresAt, 'America/Los_Angeles').startOf('day').format() : null,
        })
      },
    },
    mounted() {
    },
    methods: {
      //////////// TEMPLATE ACTIONS ////////////
      createDiscount() {
        this.primaryErrorMessage = ''
        this.errorMessages = ''
        this.isSavingDiscount = true

        this.axios.post('/discounts', {
          discount: this.correctedDiscount,
        })
          .then(response => this.onDiscountCreated(response.data))
          .catch(error => this.onDiscountCreatedFailure(error.response))
      },
      //////////// CALLBACKS ////////////
      onDiscountCreated(data) {
        EventBus.$emit('globalAlert', {
          message: 'Successfully created discount!',
          statusCode: 1,
        })
        this.$router.push({ name: 'Discount', params: { discountId: data.discount.id } })
      },
      onDiscountCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating discount'
          this.errorMessages = JSON.stringify(response)
        }

        this.isSavingDiscount = false
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewDiscount() {
        return {
          code:                 '',
          frequency:            'once',
          discountType:         'dollars',
          usesRemaining:        1,
          amount:               0,
          minimumCartDollars:   0,
          referralProgram:      false,
          referralRewardAmount: 0,
          firstOrderOnly:       false,
          startsAt:             null,
          expiresAt:            null,
          creditRollover:       true,
        }
      },
    },
  }
</script>

<style lang="scss" scoped>

  .create-discount {

    margin-bottom:            5em;

    .create-form {

      > section {
        padding:              1em;

        > h2 {
          margin:             0.5em 0;
          width:              100%;
        }
      }

      .discount-details {

        .discount-detail {

          margin-bottom:      1em;

          .block-label {
            display:          block;
            margin-bottom:    0.25em;
          }

          .explanation {
            color: gray;
            font-size: 0.8em;
          }

          .amount {
            width:   50%;
          }

          &.frequency {

            > input[type="radio"]:first-of-type {
              margin-left:    0;
            }
          }

          > input[type="radio"] {
            margin-left:      0.75em;
            margin-right:     0.25em;
          }

          > input[type="checkbox"] {
            margin-right:     0.25em;
          }
        }

        label {
          margin-bottom:      0;
        }

        input {
          margin-top:         0;
        }

        textarea {
          height:             9em;
          width:              100%;
        }
      }
    }
  }

</style>
