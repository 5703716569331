<template>
    <div class="product-skus">
        <section class="gs-generic-header">
            <h1>Product SKUs</h1>

            <div class="header-links">
                <button class="gs-standard-button gs-add-button top-button">
                    <router-link :to="{ name: 'CreateProductSku' }">
                        Add new product SKU
                    </router-link>
                    <i class="fa fa-plus-circle" />
                </button>
                <button class="gs-standard-button gs-add-button">
                    <router-link :to="{ name: 'CreateProduct' }">
                        Add new product
                    </router-link>
                    <i class="fa fa-plus-circle" />
                </button>
            </div>
        </section>

        <section class="row gs-standard-form gs-special-content">
            <h3 class="gs-standard-sub-header">
                Filter:
            </h3>

            <div class="col-md-6">
                <div class="gs-standard-detail">
                    <label>Product Name:</label>
                    <input v-model="productNameSearch" />
                </div>

                <div class="gs-standard-detail">
                    <label>Product Brand:</label>
                    <input v-model="productBrandSearch" />
                </div>

                <div class="gs-standard-detail">
                    <label>SKU:</label>
                    <input v-model="skuSearch" />
                </div>
            </div>

            <div class="col-md-6">
                <LoadingSpinner v-if="isLoadingCategories"
                                is-black="true" />
                <SimpleAddRemoveList
                    v-else
                    :items="productCategories"
                    :selected-items="selectedCategories"
                    match-key="id"
                    display-key="name"
                    item-type="category"
                    @itemAdded="addCategory"
                    @itemRemoved="removeCategory" />

                <div class="gs-standard-detail">
                    <input v-model="inactiveOnly"
                           type="checkbox" />
                    <label>Inactive only</label>
                </div>

                <div class="gs-standard-detail">
                    <input v-model="excludeSunset"
                           type="checkbox" />
                    <label>Exclude sunset skus</label>
                </div>
            </div>
        </section>

        <section class="links">
            <router-link :to="{ name: 'ProductSkusList' }"
                         :class="{ active : isSkusListActive }">
                All SKUs
            </router-link>
            <router-link :to="{ name: 'ProductSkusSkuless' }"
                         :class="{ active : !isSkusListActive }">
                SKU-less Products
            </router-link>
        </section>

        <router-view
            :product-name-search="productNameSearch"
            :product-brand-search="productBrandSearch"
            :selected-categories="selectedCategories"
            :sku-search="skuSearch"
            :inactive-only="inactiveOnly"
            :exclude-sunset="excludeSunset" />
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleAddRemoveList from '@/components/forms/SimpleAddRemoveList'
  import { arrayHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'ProductSkus',
    components: {
      LoadingSpinner,
      SimpleAddRemoveList,
    },
    data() {
      return {
        productBrandSearch:         '',
        productNameSearch:          '',
        skuSearch:                  '',
        inactiveOnly:               false,
        excludeSunset:              false,
        isLoadingCategories:        true,
        productCategories:          [],
        selectedCategories:         [],
        columns:                    ['sku', 'productName', 'brand.name', 'currentInventory', 'sunset'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'sku'             : 'SKU',
            'productName'     : 'Product',
            'brand.name'      : 'Brand',
            'currentInventory': 'Current Inventory',
            'sunset'          : 'Sunset?',
          },
        },
      }
    },
    computed: {
      isSkusListActive() {
        return this.$route.path.includes('list')
      },
      selectedCategoryIds() {
        return _.map(this.selectedCategories, (category) => category.id)
      },
      skusFilteredByCategories() {
        if (!this.selectedCategories.length) return this.productSkus

        // If the product sku belongs to ANY of the selected categories, it will be included in result set
        return this.productSkus.filter((productSku) => {
          let productSkuCategoryIds = productSku.categories.map((category) => { return category.id })
          let sharedCategories = _.intersection(this.selectedCategoryIds, productSkuCategoryIds)
          return sharedCategories.length > 0
        }, this)
      },
      filteredSkus() {
        return this.skusFilteredByCategories.filter((productSku) => {
          return productSku.product.name.toLowerCase().includes(this.productNameSearch.toLowerCase()) &&
            productSku.brand.name.toLowerCase().includes(this.productBrandSearch.toLowerCase()) &&
            productSku.sku.toLowerCase().includes(this.skuSearch.toLowerCase()) &&
            (!this.inactiveOnly || !productSku.isActive)
        }, this)
      },
    },
    mounted() {
      this.getProductCategories()
    },
    methods: {
      getProductCategories() {
        this.isLoadingCategories = true
        this.axios.get('/product_categories')
          .then(response => this.onCategoriesRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      addCategory(category) {
        this.selectedCategories.push(category)
      },
      removeCategory(category) {
        arrayHelper.removeObjectByValue(this.selectedCategories, category.id, 'id')
      },
      //////////// CALLBACKS ////////////
      onCategoriesRetrieved(data) {
        this.isLoadingCategories = false
        this.productCategories = data.productCategories
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

.product-skus {

  .gs-generic-header {

    .header-links {

      > button {

        &.top-button {
          top:                        -2em;
        }
      }

    }
  }

  .links {

    margin:                           2em 0;

    > a {
      color:                          black;
      margin-right:                   1em;
      font-size:                      1.5em;

      &.active {
        font-weight:                  bold;
        border-bottom:                2px solid black;
      }

      &:hover {
        text-decoration:              none;
      }
    }
  }
}

</style>
