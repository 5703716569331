<template>
    <div class="home">
        <h1>{{ homepageCopy }}</h1>
    </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import complimentsMixin from '@/mixins/compliments'

  export default {
    name: 'Home',
    mixins: [complimentsMixin],
    computed: {
      ...mapGetters({ currentUser: 'currentUser' }),
      firstName() {
        return _.get(this.currentUser, 'firstName')
      },
      homepageCopy() {
        const compliment = _.first(_.shuffle(this.compliments))
        let message = compliment
        if (this.firstName) {
          message += `, ${this.firstName}`
        }

        return `${message}.`
      },
    },
  }
</script>
