var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.statusMessage ? _c('div', {
    staticClass: "notification-container"
  }, [_c('div', {
    staticClass: "global-alert",
    on: {
      "click": function click($event) {
        return _vm.clearAlert();
      }
    }
  }, [_c('span', {
    staticClass: "primary-message"
  }, [_vm._v("An error occurred and your request was not processed.")]), _c('span', {
    staticClass: "status-code"
  }, [_c('b', [_vm._v("Code:")]), _vm._v(" " + _vm._s(_vm.statusCode || 'Unknown'))]), _c('span', {
    staticClass: "status-message"
  }, [_c('b', [_vm._v("Error:")]), _vm._v(" " + _vm._s(_vm.statusMessage))]), _vm.redirectUser ? _c('span', {
    staticClass: "redirect-user"
  }, [_vm._v("You are being redirected to the login page...")]) : _vm._e()])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }