var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "inline-message",
    class: ['status-' + _vm.statusCode]
  }, [_c('div', [_vm._v(_vm._s(_vm.message))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }