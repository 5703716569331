var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-category gs-simple-crud"
  }, [_c('h1', [_vm._v("Product Categories")]), _c('SimpleCreateForm', {
    attrs: {
      "primary-error-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages,
      "show-loading-state": _vm.isCreatingProductCategory,
      "new-model-instance": _vm.newProductCategory
    },
    on: {
      "formSubmit": _vm.createProductCategory
    }
  }, [_c('input', {
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "name": "icon",
      "accept": "image/*"
    },
    on: {
      "change": function change($event) {
        return _vm.onIconFileSelect($event.target.name, $event.target.files);
      }
    }
  })]), _vm.isLoadingProductCategories || _vm.isLoadingProductTypes ? _c('div', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_c('section', {
    staticClass: "product-category-table gs-standard-table"
  }, [_vm.productCategories.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.productCategories,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "editName",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row['name'],
            expression: "data.row['name']"
          }],
          domProps: {
            "value": data.row['name']
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, 'name', $event.target.value);
            }
          }
        }) : _c('span', [_vm._v(_vm._s(data.row['name']))])]);
      }
    }, {
      key: "editIcon",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          staticClass: "input-file",
          attrs: {
            "type": "file",
            "name": "icon",
            "accept": "image/*"
          },
          on: {
            "change": function change($event) {
              return _vm.onIconFileSelect($event.target.name, $event.target.files);
            }
          }
        }) : _c('img', {
          attrs: {
            "src": data.row['icon'],
            "width": "32"
          }
        })]);
      }
    }, {
      key: "bundlesOnly",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('toggle-button', {
          attrs: {
            "value": data.row['bundlesOnly'],
            "color": "#82C7EB"
          },
          on: {
            "change": function change($event) {
              data.row['bundlesOnly'] = !data.row['bundlesOnly'];
            }
          }
        }) : _c('span', [_vm._v(_vm._s(!!data.row['bundlesOnly']))])], 1);
      }
    }, {
      key: "editTypes",
      fn: function fn(data) {
        return _c('div', {}, [_c('button', {
          staticClass: "gs-standard-button",
          on: {
            "click": function click($event) {
              return _vm.setShowEditTypesModal(data.row.id, data, data.row);
            }
          }
        }, [_vm._v(" Edit types ")])]);
      }
    }, {
      key: "edit",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-edit"
        }, [_c('TableEditButton', {
          attrs: {
            "is-editing-this-item": _vm.isEditingThisItem(data.row.id),
            "show-loading-state": _vm.isEditingThisItem(data.row.id) && _vm.isSavingThisItem(data.row.id),
            "show-save-button": _vm.isEditingThisItem(data.row.id) && !_vm.isSavingThisItem(data.row.id)
          },
          on: {
            "saveButtonClick": function saveButtonClick($event) {
              return _vm.updateProductCategory(data.row, data.index - 1);
            },
            "editButtonClick": function editButtonClick($event) {
              _vm.currentEditProductCategoryId = data.row.id;
            }
          }
        })], 1);
      }
    }, {
      key: "delete",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-delete"
        }, [_c('DeleteIcon', {
          on: {
            "delete": function _delete($event) {
              return _vm.setCurrentDeleteProductCategory(data.row);
            }
          }
        })], 1);
      }
    }, {
      key: "reorder",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-reorder"
        }, [_c('button', {
          attrs: {
            "disabled": _vm.topRanked(data.row)
          },
          on: {
            "click": function click($event) {
              return _vm.promoteRank(data.row);
            }
          }
        }, [_vm._v(" ⇧ ")]), _c('button', {
          attrs: {
            "disabled": data.row.rank == null || data.row.rank === 0
          },
          on: {
            "click": function click($event) {
              return _vm.demoteRank(data.row);
            }
          }
        }, [_vm._v(" ⇩ ")])]);
      }
    }], null, false, 3015542431)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no Product Categories to display ")])], 1), _vm.showConfirmDeleteModal ? _c('ConfirmModal', {
    attrs: {
      "show-loading-state": _vm.isDeletingProductCategory
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showConfirmDeleteModal = false;
      },
      "confirm": function confirm($event) {
        return _vm.deleteProductCategory();
      }
    }
  }, [_c('span', {
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_vm._v("Are you sure you want to remove "), _c('b', [_vm._v(_vm._s(_vm.currentDeletingProductCategory.name))]), _vm._v("? This will also destroy any associations with this product category!")])]) : _vm._e(), _vm.showEditTypesModal ? _c('EditTypesModal', {
    attrs: {
      "hide-edit-types-modal": _vm.hideEditTypesModal,
      "current-product-types": _vm.currentProductCategoryForEditingTypes.productTypes,
      "all-product-types": _vm.productTypes,
      "accent-type-id": _vm.accentTypeId,
      "category-name": _vm.currentProductCategoryForEditingTypes.name,
      "category-id": _vm.currentlySelectedCategory,
      "has-accent": _vm.currentProductCategoryForEditingTypes.hasAccent
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }