var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-header"
  }, [_c('div', {
    staticClass: "table-column"
  }, [_vm._v(" Type ")]), _c('div', {
    staticClass: "table-column"
  }, [_vm._v(" Name ")]), _c('div', {
    staticClass: "table-column"
  }, [_vm._v(" Requested Dates ")]), _c('div', {
    staticClass: "table-column"
  }, [_vm._v(" Confirmed Date ")]), _c('div', {
    staticClass: "table-column"
  }, [_vm._v(" Time ")]), _c('div', {
    staticClass: "table-column"
  }, [_vm._v(" Contact ")]), _c('div', {
    staticClass: "table-column"
  }, [_vm._v(" Items ")]), _c('div', {
    staticClass: "table-column"
  }, [_vm._v(" Assembly ")]), _c('div', {
    staticClass: "table-column"
  }, [_vm._v(" Subregion ")])]);
}]

export { render, staticRenderFns }