<template>
    <div class="edit-user">
        <section class="gs-generic-header">
            <h1>{{ fullName }}</h1>
            <div v-if="!isLoadingUser"
                 class="gs-standard-detail">
                User Id: {{ user.id }}
            </div>
            <div v-if="!isLoadingUser"
                 class="gs-standard-detail">
                Joined: {{ user.createdAt }}
            </div>
        </section>

        <LoadingSpinner v-if="isLoadingUser"
                        is-black="true" />
        <div v-else
             class="gs-standard-form">
            <section class="user-details">
                <h5>Contact Information:</h5>

                <EnableDisableButton
                    :object-to-watch="user"
                    :fields-to-observe="['firstName', 'lastName', 'emailAddress', 'phone']"
                    :disable-button-computed="isSavingUser"
                    button-text="Save"
                    @buttonClick="updateCurrentUser()" />

                <div class="row">
                    <div class="col-md-4">
                        <div class="gs-standard-detail">
                            <label>First Name:</label>
                            <input v-model="user.firstName"
                                   placeholder="First Name" />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Last Name:</label>
                            <input v-model="user.lastName"
                                   placeholder="Last Name" />
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="gs-standard-detail">
                            <label>Email:</label>
                            <input v-model="user.emailAddress"
                                   placeholder="Email" />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Phone:</label>
                            <input v-model="user.phone"
                                   placeholder="Phone" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import EnableDisableButton from '@/components/forms/EnableDisableButton'

  export default {
    name: 'EditUser',
    components: {
      LoadingSpinner,
      EnableDisableButton,
    },
    data() {
      return {
        user:             {},
        isLoadingUser:    false,
        isSavingUser:     false,
      }
    },
    computed: {
      fullName() {
        if (!this.user) return ''
        return this.user.firstName + ' ' + this.user.lastName
      },
    },
    mounted() {
      this.getUser()
    },
    methods: {
      //////////// CREATED/MOUNTED METHODS ////////////
      getUser() {
        this.isLoadingUser = true
        this.axios.get('/users/' + this.$route.params.userId)
          .then(response => { this.onUserRetrieved(response.data) })
      },
      //////////// TEMPLATE ACTIONS ////////////
      updateCurrentUser() {
        this.isSavingUser = true
        this.axios.patch('/users/' + this.user.id, { user: this.user })
          .then(response => this.onCurrentUserUpdated(response.data))
          .catch(error => this.onCurrentUserUpdatedFailure(error.response))
      },
      //////////// CALLBACKS ////////////
      onUserRetrieved(data) {
        this.user = data.user
        this.isLoadingUser = false
      },
      onCurrentUserUpdated(data) {
        this.isSavingUser = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully updated user!',
          statusCode: 1,
        })
        this.$router.push({ name: 'User', params: { userId: this.$route.params.userId } })
      },
      onCurrentUserUpdatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error updating user'
          this.errorMessages = JSON.stringify(response)
        }

        this.isSavingUser = false
      },
    },
  }
</script>

<style lang="scss" scoped>


.edit-user {

  .user-details {

    position:                   relative;
    margin-top:                 2em;
    padding:                    2em;

    .detail {

      margin-top:               0.5em;

      > label {
        min-width:              6em;
      }
    }

    > button {
      position:                 absolute;
      bottom:                   2em;
      right:                    2em;
    }

  }

  .no-info {
    color: lightgray;
  }

}

</style>
