var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row login-container text-center"
  }, [_c('div', {
    staticClass: "login-wrapper col-md-6 col-sm-8 col-xs-10"
  }, [_c('form', {
    staticClass: "form-signin",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.login.apply(null, arguments);
      }
    }
  }, [_c('h2', {
    staticClass: "form-signin-heading"
  }, [_vm._v(" Sign in ")]), _vm.error ? _c('InlineMessage', {
    attrs: {
      "message": _vm.error,
      "status-code": "3"
    }
  }) : _vm._e(), _c('label', {
    staticClass: "sr-only",
    attrs: {
      "for": "input-email"
    }
  }, [_vm._v("Email address")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email_address,
      expression: "email_address"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "input-email",
      "type": "email",
      "placeholder": "Email address",
      "required": "",
      "autofocus": ""
    },
    domProps: {
      "value": _vm.email_address
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.email_address = $event.target.value;
      }
    }
  }), _c('label', {
    staticClass: "sr-only",
    attrs: {
      "for": "input-password"
    }
  }, [_vm._v("Password")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "form-control",
    attrs: {
      "id": "input-password",
      "type": "password",
      "placeholder": "Password",
      "required": ""
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.password = $event.target.value;
      }
    }
  }), _c('button', {
    staticClass: "gs-standard-button sign-in-button",
    attrs: {
      "type": "submit"
    }
  }, [!_vm.isLoading ? _c('span', [_vm._v("Sign in")]) : _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.25"
    }
  })], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }