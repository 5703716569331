<template>
    <div class="product-skus-list">
        <section v-if="isLoadingProductSkus">
            <LoadingSpinner is-black="true" />
        </section>
        <section v-else
                 class="product-skus-list-table gs-standard-table gs-hide-filter">
            <v-client-table
                v-if="filteredSkus.length > 0"
                :data="filteredSkus"
                :columns="columns"
                :options="options">
                <div slot="sku"
                     slot-scope="data">
                    <router-link :to="{ name: 'ProductSku', params: { productSkuId: data.row.id } }">
                        {{ data.row['sku'] }}
                    </router-link>
                </div>

                <div slot="productName"
                     slot-scope="data">
                    <router-link :to="{ name: 'Product', params: { productId: data.row['product']['id'] } }">
                        {{ data.row['product']['name'] }}
                    </router-link>
                </div>

                <div slot="thumbnail"
                     slot-scope="data">
                    <cld-image
                        v-if="data.row['thumbnailPublicId']"
                        :public-id="data.row['thumbnailPublicId']"
                        width="100"
                        height="100"
                        crop="limit" />
                </div>

                <div slot="sunset"
                     slot-scope="data">
                    {{ data.row.opsDetails.sunset ? '✔' : '✘' }}
                </div>
            </v-client-table>

            <div v-else
                 class="no-items">
                There are no product SKUs to display. Please refine your search.
            </div>
        </section>
    </div>
</template>

<script>
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import * as Sentry from '@sentry/browser'

  export default {
    name: 'ProductSkus',
    components: {
      LoadingSpinner,
    },
    props: {
      productNameSearch: {
        type: String,
        default: '',
      },
      productBrandSearch: {
        type: String,
        default: '',
      },
      skuSearch: {
        type: String,
        default: '',
      },
      inactiveOnly: {
        type: Boolean,
        default: false,
      },
      excludeSunset: {
        type: Boolean,
        default: false,
      },
      selectedCategories: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        productSkus:                [],
        isLoadingProductSkus:       true,
        columns:                    ['id', 'sku', 'productName', 'brand.name', 'currentInventory', 'thumbnail', 'sunset'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'sku'             : 'SKU',
            'productName'     : 'Product',
            'brand.name'      : 'Brand',
            'currentInventory': 'Current Inventory',
            'sunset'          : 'Sunset?',
          },
        },
      }
    },
    computed: {
      selectedCategoryIds() {
        return _.map(this.selectedCategories, (category) => category.id)
      },
      skusFilteredByCategories() {
        if (!this.selectedCategories.length) return this.productSkus
        // If the product sku belongs to ANY of the selected categories, it will be included in result set
        return this.productSkus.filter((productSku) => {
          let productSkuCategoryIds = productSku.categories.map((category) => { return category.id })
          let sharedCategories = _.intersection(this.selectedCategoryIds, productSkuCategoryIds)
          return sharedCategories.length > 0
        }, this)
      },
      filteredSkus() {
        return this.skusFilteredByCategories.filter((productSku) => {
          return productSku.product.name.toLowerCase().includes(this.productNameSearch.toLowerCase()) &&
            productSku.brand.name.toLowerCase().includes(this.productBrandSearch.toLowerCase()) &&
            productSku.sku.toLowerCase().includes(this.skuSearch.toLowerCase()) &&
            (!this.inactiveOnly || !productSku.isActive) &&
            (!this.excludeSunset || !productSku.opsDetails.sunset)
        }, this)
      },
    },
    mounted() {
      this.getProductSkus()
    },
    methods: {
      async getProductSkus() {
        try {
          const response = await this.axios.get('/product_skus')
          this.onProductSkusRetrieved(response.data)
        } catch (error) {
          Sentry.captureException(error)
        }
      },
      //////////// TEMPLATE ACTIONS ////////////
      onProductSkusRetrieved(data) {
        this.isLoadingProductSkus = false
        this.productSkus = data.productSkus
      },
    },
  }
</script>

<style lang="scss" scoped>
  .product-skus-list {
    min-height: 10em;
  }
</style>
