var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "create-curated-set"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Create Curated Set")]), _c('button', {
    staticClass: "gs-standard-button",
    attrs: {
      "disabled": _vm.isSavingCuratedSet
    },
    on: {
      "click": _vm.createCuratedSet
    }
  }, [!_vm.isSavingCuratedSet ? _c('span', [_vm._v("Create")]) : _c('LoadingSpinner', {
    attrs: {
      "max-height": "1.5"
    }
  })], 1)]), _c('div', {
    staticClass: "create-form gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "curated-set-details"
  }, [_c('h2', [_vm._v("Set Basics")]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Name:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newCuratedSet.name,
      expression: "newCuratedSet.name"
    }],
    domProps: {
      "value": _vm.newCuratedSet.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newCuratedSet, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Identifier:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newCuratedSet.identifier,
      expression: "newCuratedSet.identifier"
    }],
    domProps: {
      "value": _vm.newCuratedSet.identifier
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newCuratedSet, "identifier", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Category:")]), _c('div', {
    staticClass: "input-group mb-3 category-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectProductCategory($event);
      }
    }
  }, [_c('option', {
    attrs: {
      "selected": ""
    }
  }, [_vm._v(" Please select a category... ")]), _vm._l(_vm.allProductCategories, function (option) {
    return _c('option', {
      key: option.id
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  })], 2)])]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Description:")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newCuratedSet.description,
      expression: "newCuratedSet.description"
    }],
    domProps: {
      "value": _vm.newCuratedSet.description
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newCuratedSet, "description", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Is Active?")]), _c('toggle-button', {
    attrs: {
      "value": _vm.newCuratedSet.isActive,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.newCuratedSet.isActive = !_vm.newCuratedSet.isActive;
      }
    }
  })], 1)]), _c('section', {
    staticClass: "row select-products"
  }, [_c('h2', [_vm._v("Product Skus")]), _c('div', {
    staticClass: "col-md-4"
  }, [_vm.isLoadingProductSkus ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('SimpleAddRemoveList', {
    attrs: {
      "items": _vm.allProductSkus,
      "selected-items": _vm.newCuratedSetProductSkus,
      "allow-duplicates": true,
      "display-key": "name",
      "match-key": "sku",
      "item-type": "product sku"
    },
    on: {
      "itemAdded": _vm.addProductSku,
      "itemRemoved": _vm.removeProductSku
    }
  })], 1)]), _c('section', {
    staticClass: "product-images"
  }, [_c('h2', [_vm._v("Set Image")]), _c('input', {
    ref: "hidden-uploader",
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "name": "curated-set-image",
      "accept": "image/*"
    },
    on: {
      "change": function change($event) {
        return _vm.onImageSelect($event.target.name, $event.target.files);
      }
    }
  })])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }