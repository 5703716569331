<template>
    <div class="grouped-journey-count">
        <div class="header">
            Number of Trips
        </div>
        <div v-for="(value,name) in journeyCountGroupedBySubregion"
             :key="name"
             class="data-column">
            <span class="field">{{ name }}</span> : <span class="value">{{ value }} </span>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'GroupedJourneyCount',
    props: {
      journeyCountGroupedBySubregion: {
        type: Object,
        default: () => {},
      },
    },
  }
</script>

<style lang="scss" scoped>
  .grouped-journey-count {
    display: flex;
    flex-direction: row;
    width: 60%;
    flex-wrap: wrap;

    .header {
      color: #6b6b6b;
      margin-top: 16px;
      font-size: 13px;
      font-weight: bold;
      width: 100%;
      margin-bottom: 16px;
    }

    .data-column {
      width: 50%;
      font-size: 13px;
      margin-bottom: 4px;
      .field {
        font-weight: bold;
      }
    }
  }
</style>