var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-suggestion-override"
  }, [_c('section', {
    staticClass: "gs-generic-header"
  }, [_c('h1', [_vm._v("Edit Suggestion Override")]), _c('button', {
    staticClass: "gs-standard-button"
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'SuggestionOverride',
        params: {
          suggestionOverrideId: _vm.$route.params.suggestionOverrideId
        }
      }
    }
  }, [_vm._v(" Go Back ")])], 1)]), _vm.isLoadingSuggestionOverride ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('div', {
    staticClass: "content gs-standard-form"
  }, [_vm.primaryErrorMessage || _vm.errorMessages.length > 0 ? _c('FormErrors', {
    attrs: {
      "primary-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages
    }
  }) : _vm._e(), _c('section', {
    staticClass: "suggestion-override-details gs-special-content"
  }, [_c('div', {
    staticClass: "gs-generic-header"
  }, [_c('h2', [_vm._v("Suggestion Override Details")]), _vm.suggestionOverride ? _c('EnableDisableButton', {
    attrs: {
      "object-to-watch": _vm.suggestionOverride,
      "fields-to-observe": ['sourceProductSkuId', 'targetProductSkuId', 'priority', 'block'],
      "disable-button-computed": _vm.isUpdatingSuggestionOverride,
      "button-text": "Update"
    },
    on: {
      "buttonClick": function buttonClick($event) {
        return _vm.updateSuggestionOverride();
      }
    }
  }) : _c('button', {
    staticClass: "gs-standard-button"
  }, [_c('LoadingSpinner')], 1)], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Source Product Sku Id:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.suggestionOverride.sourceProductSku.id,
      expression: "suggestionOverride.sourceProductSku.id"
    }],
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.suggestionOverride.sourceProductSku.id
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.suggestionOverride.sourceProductSku, "id", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Target Product Sku Id:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.suggestionOverride.targetProductSku.id,
      expression: "suggestionOverride.targetProductSku.id"
    }],
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.suggestionOverride.targetProductSku.id
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.suggestionOverride.targetProductSku, "id", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-standard-detail"
  }, [_c('label', [_vm._v("Priority:")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.suggestionOverride.priority,
      expression: "suggestionOverride.priority"
    }],
    domProps: {
      "value": _vm.suggestionOverride.priority
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.suggestionOverride, "priority", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "gs-toggle-container"
  }, [_c('label', {
    staticClass: "tag"
  }, [_vm._v("Block?:")]), _c('toggle-button', {
    attrs: {
      "value": _vm.suggestionOverride.block,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.suggestionOverride.block = !_vm.suggestionOverride.block;
      }
    }
  })], 1)])])])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }