<template>
    <div class="suggestion-override-list">
        <section class="gs-generic-header">
            <h1>Suggestion Overrides</h1>
            <button class="gs-standard-button gs-add-button">
                <router-link :to="{ name: 'CreateSuggestionOverride' }">
                    Add new suggestion override
                </router-link>
                <i class="fa fa-plus-circle" />
            </button>
        </section>

        <section class="row gs-standard-form gs-special-content">
            <h3 class="gs-standard-sub-header">
                Filter Overrides:
            </h3>

            <div>
                <span class="name-filter">
                    <span class="gs-standard-detail">
                        <label class="filter-label">Source Product Name:</label>
                        <input v-model="sourceNameSearch" />
                    </span>

                    <span class="gs-standard-detail">
                        <label class="filter-label">Target Product Name:</label>
                        <input v-model="targetNameSearch" />
                    </span>
                </span>

                <div class="gs-standard-detail">
                    <input v-model="blockedOnly"
                           type="checkbox" />
                    <label class="checkbox-label">Blocked Only</label>
                </div>
            </div>
        </section>

        <section v-if="isLoadingSuggestionOverrides">
            <LoadingSpinner is-black="true" />
        </section>
        <section v-else
                 class="suggestion-overrides-table gs-standard-table gs-hide-filter">
            <h3 class="gs-standard-sub-header">
                Suggestion Overrides
            </h3>

            <v-client-table
                v-if="filteredSets.length > 0"
                :data="filteredSets"
                :columns="columns"
                :options="options">
                <span slot="id"
                      slot-scope="data">
                    <router-link :to="{ name: 'SuggestionOverride', params: { suggestionOverrideId: data.row.id } }">
                        {{ data.row.id }}
                    </router-link>
                </span>
                <span slot="sourceProductSku"
                      slot-scope="data">
                    {{ data.row.sourceProductSku }}
                </span>
                <span slot="targetProductSku"
                      slot-scope="data">
                    {{ data.row.targetProductSku }}
                </span>
                <span slot="block"
                      slot-scope="data">
                    {{ data.row.block }}
                </span>
                <span slot="priority"
                      slot-scope="data">
                    {{ data.row.priority }}
                </span>
                <span slot="created"
                      slot-scope="data">
                    {{ formatLongDateString(data.row.createdAt) }}
                </span>
            </v-client-table>

            <div v-else
                 class="no-items">
                There are no curated sets to display. Please refine your search.
            </div>
        </section>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'

  export default {
    name: 'SuggestionOverrideList',
    components: {
      LoadingSpinner,
    },
    data() {
      return {
        suggestionOverrides:              [],
        sourceNameSearch:     '',
        targetNameSearch:     '',
        blockedOnly:             false,
        isLoadingSuggestionOverrides:     true,
        columns:                  ['id', 'sourceProductName', 'sourceProductSkuId', 'targetProductName', 'targetProductSkuId', 'block', 'priority', 'created'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'sourceProductName': 'Source Product Name',
            'sourceProductSkuId': 'Source Sku Id',
            'targetProductName': 'Target Product Name',
            'targetProductSkuId': 'Target Sku Id',
            'block': 'Block?',
            'created': 'Created At',
            'priority': 'Priority',
          },
        },
      }
    },
    computed: {
      filteredSets() {
        return this.suggestionOverrides.filter((override) => {
          return _.toString(override.sourceProductName).toLowerCase().includes(this.sourceNameSearch.toLowerCase()) &&
            _.toString(override.targetProductName).toLowerCase().includes(this.targetNameSearch.toLowerCase()) &&
            (!this.blockedOnly || override.block)
        }, this)
      },
    },
    mounted() {
      this.isLoadingSuggestionOverrides = true
      this.axios.get('/suggestion_overrides')
        .then(response => this.onSuggestionOverridesRetrieved(response.data))
    },
    methods: {
      //////////// TEMPLATE ACTIONS ////////////

      //////////// CALLBACKS ////////////
      onSuggestionOverridesRetrieved(data) {
        this.suggestionOverrides = data.suggestionOverrides
        this.isLoadingSuggestionOverrides = false
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

.suggestion-override-list {
  .name-filter {
    display: inline-block;
    position: relative;

    .gs-standard-detail {
      display: inline-block;
      position: relative;
      margin-right: 2.5em;
    }
  }

  .filter-label {
    margin-right: 0.5em;
  }

  .checkbox-label {
    margin-left: 0.5em;
  }

  .suggestion-overrides-table {
    margin-top:                   2em;
  }
}

</style>
