export const phoneFormatting = {
  methods: {
    // expecting a valid phone string
    formatPhone(phoneString) {
      const digits = phoneString.replace(/[^0-9]/g, '')

      const internationalPrefix = digits.slice(0, digits.length - 10)
      const areaCode = digits.slice(-10, -7)

      return `+${internationalPrefix} (${areaCode}) ${digits.slice(-7, -4)}-${digits.slice(-4)}`
    },
  },
}

export default phoneFormatting