<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper"
                 @click="closeModal">
                <div class="modal-container">
                    <div class="body">
                        <slot name="body">
                            Are you sure you want to delete this?
                        </slot>
                    </div>

                    <div class="footer tablet-hide">
                        <slot name="footer">
                            <button :disabled="showLoadingState"
                                    class="gs-standard-button cancel"
                                    @click="onCancelClick()">
                                Cancel
                            </button>
                            <button :disabled="showLoadingState"
                                    class="gs-standard-button confirm"
                                    @click="$emit('confirm')">
                                <LoadingSpinner v-if="showLoadingState"
                                                max-height="1" />
                                <span v-else>Confirm</span>
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'

  // TODO
  // If another modal is created, we should refactor this into a wrapper component and render child component inside of it
  // Wrapper should contain the HTML for the outermost of the modal (modal-container and up)
  // It should also handle the clearModal method
  // Child modal might be passed in as a slot each time we render?

  export default {
    name: 'ConfirmModal',
    components: {
      LoadingSpinner,
    },
    props: {
      showLoadingState: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      EventBus.$on('clearModals', () => {
        this.$emit('cancel')
      })
    },
    methods: {
      closeModal(event) {
        // Checks to see if the user has clicked outside of the modal
        if (!event.target.closest('.modal-container')) this.$emit('cancel')
      },
      onCancelClick() {
        this.$emit('cancel')
      },
    },
  }
</script>

<style lang="scss" scoped>


.modal-mask {
  position:                           fixed;
  z-index:                            999999;
  top:                                0;
  left:                               0;
  width:                              100%;
  height:                             100%;
  background-color:                   rgba(0, 0, 0, .5);
  display:                            table;
  transition:                         opacity .3s ease;

  .modal-wrapper {
    display:                          table-cell;
    vertical-align:                   middle;

    .modal-container {
      width:                          50%;
      margin:                         0 auto;
      padding:                        2em;
      background-color:               #fff;
      border-radius:                  1em;
      box-shadow:                     0 2px 8px rgba(0, 0, 0, .33);
      transition:                     all .3s ease;
      position:                       relative;

      .body {
        margin:                       20px 0;
        text-align:                   center;
      }

      .footer {
        display:                      block;

        .gs-standard-button {

          &.cancel {
            background:               #fff;
            border:                   1px solid #000000;
            color:                    #000000;
          }

          &.confirm {
            float:                    right;
          }
        }
      }
    }

  }
}


</style>
