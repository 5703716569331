var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "simple-array-dropdown",
    style: {
      width: _vm.formWidth
    }
  }, [!_vm.showLoadingState ? _c('div', {
    staticClass: "input-group mb-3 issue-select"
  }, [_c('select', {
    staticClass: "custom-select",
    on: {
      "change": function change($event) {
        return _vm.selectOption($event);
      }
    }
  }, [_c('option', [_vm._v(_vm._s(_vm.prompt))]), _vm._l(_vm.filteredItems, function (item, index) {
    return _c('option', {
      key: index,
      domProps: {
        "selected": _vm.selected == item
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  })], 2)]) : _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }