var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-skus-list"
  }, [_vm.isLoadingProductSkus ? _c('section', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('section', {
    staticClass: "product-skus-list-table gs-standard-table gs-hide-filter"
  }, [_vm.filteredSkus.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.filteredSkus,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "sku",
      fn: function fn(data) {
        return _c('div', {}, [_c('router-link', {
          attrs: {
            "to": {
              name: 'ProductSku',
              params: {
                productSkuId: data.row.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.row['sku']) + " ")])], 1);
      }
    }, {
      key: "productName",
      fn: function fn(data) {
        return _c('div', {}, [_c('router-link', {
          attrs: {
            "to": {
              name: 'Product',
              params: {
                productId: data.row['product']['id']
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.row['product']['name']) + " ")])], 1);
      }
    }, {
      key: "thumbnail",
      fn: function fn(data) {
        return _c('div', {}, [data.row['thumbnailPublicId'] ? _c('cld-image', {
          attrs: {
            "public-id": data.row['thumbnailPublicId'],
            "width": "100",
            "height": "100",
            "crop": "limit"
          }
        }) : _vm._e()], 1);
      }
    }, {
      key: "sunset",
      fn: function fn(data) {
        return _c('div', {}, [_vm._v(" " + _vm._s(data.row.opsDetails.sunset ? '✔' : '✘') + " ")]);
      }
    }], null, false, 1132371351)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no product SKUs to display. Please refine your search. ")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }