<template>
    <div class="product-category gs-simple-crud">
        <h1>Product Categories</h1>

        <SimpleCreateForm
            :primary-error-message="primaryErrorMessage"
            :error-messages="errorMessages"
            :show-loading-state="isCreatingProductCategory"
            :new-model-instance="newProductCategory"
            @formSubmit="createProductCategory">
            <input type="file"
                   name="icon"
                   accept="image/*"
                   class="input-file"
                   @change="onIconFileSelect($event.target.name, $event.target.files)" />
        </SimpleCreateForm>

        <div v-if="isLoadingProductCategories || isLoadingProductTypes">
            <LoadingSpinner is-black="true" />
        </div>

        <div v-else
             class="content">
            <section class="product-category-table gs-standard-table">
                <v-client-table
                    v-if="productCategories.length > 0"
                    :data="productCategories"
                    :columns="columns"
                    :options="options">
                    <div slot="editName"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row['name']" />
                        <span v-else>{{ data.row['name'] }}</span>
                    </div>

                    <div slot="editIcon"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               type="file"
                               name="icon"
                               accept="image/*"
                               class="input-file"
                               @change="onIconFileSelect($event.target.name, $event.target.files)" />
                        <img v-else
                             :src="data.row['icon']"
                             width="32" />
                    </div>

                    <div slot="bundlesOnly"
                         slot-scope="data">
                        <toggle-button
                            v-if="isEditingThisItem(data.row.id)"
                            :value="data.row['bundlesOnly']"
                            color="#82C7EB"
                            @change="data.row['bundlesOnly'] = !data.row['bundlesOnly']" />
                        <span v-else>{{ !!data.row['bundlesOnly'] }}</span>
                    </div>

                    <div slot="editTypes"
                         slot-scope="data">
                        <button class="gs-standard-button"
                                @click="setShowEditTypesModal(data.row.id,data, data.row)">
                            Edit types
                        </button>
                    </div>
                    <div slot="edit"
                         slot-scope="data"
                         class="gs-standard-table-edit">
                        <TableEditButton
                            :is-editing-this-item="isEditingThisItem(data.row.id)"
                            :show-loading-state="isEditingThisItem(data.row.id) && isSavingThisItem(data.row.id)"
                            :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                            @saveButtonClick="updateProductCategory(data.row, data.index - 1)"
                            @editButtonClick="currentEditProductCategoryId = data.row.id" />
                    </div>

                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteProductCategory(data.row)" />
                    </div>

                    <div slot="reorder"
                         slot-scope="data"
                         class="gs-standard-table-reorder">
                        <button :disabled="topRanked(data.row)"
                                @click="promoteRank(data.row)">
                            ⇧
                        </button>
                        <button :disabled="data.row.rank == null || data.row.rank === 0"
                                @click="demoteRank(data.row)">
                            ⇩
                        </button>
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no Product Categories to display
                </div>
            </section>

            <ConfirmModal
                v-if="showConfirmDeleteModal"
                :show-loading-state="isDeletingProductCategory"
                @cancel="showConfirmDeleteModal = false"
                @confirm="deleteProductCategory()">
                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingProductCategory.name }}</b>? This will also destroy any associations with this product category!</span>
            </ConfirmModal>
            <EditTypesModal
                v-if="showEditTypesModal"
                :hide-edit-types-modal="hideEditTypesModal"
                :current-product-types="currentProductCategoryForEditingTypes.productTypes"
                :all-product-types="productTypes"
                :accent-type-id="accentTypeId"
                :category-name="currentProductCategoryForEditingTypes.name"
                :category-id="currentlySelectedCategory"
                :has-accent="currentProductCategoryForEditingTypes.hasAccent" />
        </div>
    </div>
</template>

<script>
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import apiHelper from '@/helpers/apiHelper'
  import { arrayHelper } from '@petra-living/petra-vue-common'
  import EditTypesModal from '@/components/modals/EditTypesModal.vue'

  export default {
    name: 'ProductCategories',
    components: {
      LoadingSpinner,
      SimpleCreateForm,
      DeleteIcon,
      TableEditButton,
      ConfirmModal,
      EditTypesModal,
    },
    data() {
      return {
        isLoadingProductCategories:         false,
        isLoadingProductTypes: false,
        errorMessages:                      [],
        primaryErrorMessage:                '',
        productCategories:                  [],
        newProductCategory:                 this.initializeNewProductCategory(),
        isCreatingProductCategory:          false,
        currentEditProductCategoryId:       null,
        currentSavingProductCategoryId:     null,
        currentDeletingProductCategory:     null,
        isDeletingProductCategory:          false,
        showConfirmDeleteModal:             false,
        iconFile:                           null,
        showEditTypesModal: false,
        currentlySelectedCategory: null,
        columns:                            ['id', 'editName', 'identifier', 'editIcon', 'productCount', 'bundlesOnly', 'editTypes', 'edit', 'delete', 'reorder'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'editName': 'Name',
            'editIcon': 'Icon',
            'productCount': 'Product Count',
            'bundlesOnly': 'Bundles Only',
            'editTypes': 'Types',
          },
        },
        accentTypeId: null,
      }
    },
    computed: {
      currentProductCategoryForEditingTypes() {
        return this.productCategories.find(productCategory => productCategory.id === this.currentlySelectedCategory)
      },
    },
    mounted() {
      this.getProductCategories()
      this.getProductTypes()
    },
    methods: {
      getProductTypes() {
        this.isLoadingProductTypes = true
        this.axios.get('/product_types')
          .then(response => this.onProductTypesRetrieved(response.data))
      },
      getProductCategories() {
        this.isLoadingProductCategories = true
        this.axios.get('/product_categories')
          .then(response => this.onProductCategoriesRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      createProductCategory() {
        this.isCreatingProductCategory = true

        apiHelper.submitMultipartForm(
          this.mappedProductCategory(this.newProductCategory),
          '/product_categories',
          this.onProductCategoryCreated,
          this.onProductCategoryCreatedFailure,
        )
      },
      updateProductCategory(productCategory, index) {
        this.currentSavingProductCategoryId = productCategory.id

        apiHelper.submitMultipartForm(
          this.mappedProductCategory(productCategory),
          '/product_categories/' + productCategory.id,
          this.onProductCategoryUpdated,
          this.onProductCategoryUpdatedFailure,
          'patch',
        )
      },
      setCurrentDeleteProductCategory(productCategory) {
        this.showConfirmDeleteModal = true
        this.currentDeletingProductCategory = productCategory
      },
      //////////// CALLBACKS ////////////
      onProductCategoriesRetrieved(data) {
        this.isLoadingProductCategories = false
        this.productCategories = data.productCategories
      },
      onProductTypesRetrieved(data) {
        this.isLoadingProductTypes = false
        this.productTypes = data.productTypes
        this.accentTypeId = this.productTypes.filter(productType => productType.identifier === 'accents')[0].id
      },
      onProductCategoryCreated(data) {
        this.isCreatingProductCategory = false
        this.productCategories.push(data.productCategory)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created product category!',
          statusCode: 1,
        })
        this.newProductCategory = this.initializeNewProductCategory()
      },
      onProductCategoryCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating category'
          this.errorMessages = JSON.stringify(response)
        }

        this.isCreatingProductCategory = false
      },
      onProductCategoryUpdated() {
        this.currentEditProductCategoryId = null
        this.currentSavingProductCategoryId = null
        this.getProductCategories()
      },
      onProductCategoryUpdatedFailure(response) {
        EventBus.$emit('globalAlert', {
          message: response.data.messages[0],
          statusCode: 3,
        })

        this.currentEditProductCategoryId = null
        this.currentSavingProductCategoryId = null
        this.iconFile = null
      },
      onProductCategoryDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingProductCategory = null
        this.iconFile = null

        arrayHelper.removeObjectByValue(this.productCategories, data.productCategory.id, 'id')
        EventBus.$emit('globalAlert', {
          message: 'Product category successfully deleted!',
          statusCode: 1,
        })
      },
      onProductCategoryDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error deleting category'
          this.errorMessages = JSON.stringify(response)
        }

        this.showConfirmDeleteModal = false
        this.currentDeletingProductCategory = null
      },
      onProductCategoryDeleteDone() {
        this.isDeletingProductCategory = false
      },
      onIconFileSelect(name, files) {
        if (files.length === 0) return
        this.iconFile = files[0]
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewProductCategory() {
        return {
          name:        '',
          icon:        null,
          bundlesOnly: false,
        }
      },
      mappedProductCategory(productCategory) {
        // In Rails a product category can have designated features. In the noodl
        // UX we don't want to worry about anything other than is it only for bundles,
        // so here we have to do a bit of massaging to pass through the appropriate features
        productCategory['features'] = ['bundles']
        if (this.iconFile) {
          productCategory['icon'] = this.iconFile
        }
        if (!productCategory.bundlesOnly) {
          productCategory['features'].push('filters')
        }
        delete productCategory.bundlesOnly
        return { productCategory }
      },
      isSavingThisItem(id) {
        return this.currentSavingProductCategoryId === id
      },
      isEditingThisItem(id) {
        return this.currentEditProductCategoryId === id
      },
      deleteProductCategory() {
        this.isDeletingProductCategory = true
        this.axios.delete('/product_categories/' + this.currentDeletingProductCategory.id)
          .then(response => this.onProductCategoryDeleted(response.data))
          .catch(error => this.onProductCategoryDeletedFailure(error.response))
          .finally(() => this.onProductCategoryDeleteDone())
      },
      promoteRank(category) {
        const newRank = (category.rank || 0) + 1
        if (!category.rank) {
          let rankToPromote = newRank
          this.productCategories.reverse().forEach(c => {
            if (c.id === category.id || !c.rank || c.rank !== rankToPromote) return
            rankToPromote = ++c.rank
            this.updateProductCategory(c)
          })
        } else {
          const categoryToSwap = this.productCategories.find(c => c.rank === newRank)
          if (categoryToSwap) {
            categoryToSwap.rank--
            this.updateProductCategory(categoryToSwap)
          }
        }
        category.rank = newRank
        this.updateProductCategory(category)
      },
      demoteRank(category) {
        category.rank = Math.max((category.rank || 0) - 1, 0)
        if (category.rank > 0) {
          const categoryToSwap = this.productCategories.find(c => c !== category && c.rank === category.rank)
          if (categoryToSwap) {
            categoryToSwap.rank++
            this.updateProductCategory(categoryToSwap)
          }
        }
        this.updateProductCategory(category)
      },
      topRanked(category) {
        return !!category.rank && category.rank === _.max(this.productCategories.map(c => c.rank))
      },
      setShowEditTypesModal(categoryId) {
        this.currentlySelectedCategory = categoryId
        this.showEditTypesModal = true
      },
      hideEditTypesModal() {
        this.showEditTypesModal = false
        this.currentlySelectedCategory = null
      },
    },
  }
</script>

<style lang="scss" scoped>

.product-category {

  .content {
    margin-top:                   1em;
  }
}

</style>
