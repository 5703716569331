<template>
    <div class="product-sku">
        <section class="gs-generic-header">
            <h1>{{ productSku.sku }}</h1>

            <router-link :to="{ name: 'EditProductSku', params: { productSkuId: $route.params.productSkuId } }">
                <button class="gs-standard-button">
                    Edit
                </button>
            </router-link>
        </section>

        <LoadingSpinner v-if="isLoadingProductSku"
                        is-black="true" />
        <div v-else
             class="gs-standard-content">
            <LoadingSpinner v-if="isLoadingProduct"
                            is-black="true" />
            <section v-else
                     class="row gs-standard-details product-section">
                <h3 class="gs-standard-sub-header">
                    Parent Product
                </h3>

                <div class="col-md-4">
                    <div class="gs-standard-detail">
                        <label>Name:</label>
                        <router-link :to="{ name: 'Product', params: { productId: product.id } }">
                            {{ product.name }}
                        </router-link>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Identifier:</label>
                        <span>{{ product.identifier }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Brand:</label>
                        <span>{{ product.brand.name }}</span>
                    </div>
                </div>

                <div class="col-md-6 additional-properties">
                    <h5>Additional Properties:</h5>

                    <div v-if="productAdditionalPropertiesExist"
                         class="property-list">
                        <div v-for="(value, key) in product.additionalProperties"
                             :key="key"
                             class="property">
                            {{ key }}: <b>{{ value }}</b>
                        </div>
                    </div>
                    <div v-else
                         class="no-items">
                        No Additional Properties
                    </div>
                </div>
            </section>

            <section class="row product-sku-details">
                <h3 class="gs-standard-sub-header">
                    SKU Details
                </h3>

                <div class="col-md-4 detail-column">
                    <div class="column-item">
                        <h4>Base Info</h4>

                        <div class="gs-standard-detail">
                            <label>SKU:</label>
                            <span>{{ productSku.sku }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>UPC:</label>
                            <span>{{ productSku.upc }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Sunset:</label>
                            <span>{{ productSku.opsDetails.sunset ? 'yes (don\'t purchase)' : 'no (continue purchasing)' }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Base Price:</label>
                            <span>{{ numberToCurrency(productSku.basePriceDollars) }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Retail Price:</label>
                            <span>{{ numberToCurrency(productSku.retailPriceDollars) }}</span>
                        </div>

                        <div v-if="indefiniteLongPricingPlan"
                             class="gs-standard-detail">
                            <label>Long term $ / mo:</label>
                            <span>{{ numberToCurrency(indefiniteLongPricingPlan.monthlyPrice) }}</span>
                        </div>

                        <div v-if="indefiniteShortPricingPlan"
                             class="gs-standard-detail">
                            <label>Short term $ / mo:</label>
                            <span>{{ numberToCurrency(indefiniteShortPricingPlan.monthlyPrice) }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Current Inventory:</label>
                            <span>{{ productSku.currentInventory }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Is Active:</label>
                            <span>{{ productSku.isActive || 'false' }}
                                <span v-if="!productSku.isActive"
                                      :class="readyClass">{{ ready }}</span>
                            </span>
                        </div>
                    </div>
                    <div class="column-item">
                        <h4>Variations</h4>

                        <div v-if="productSkuVariations.length > 0">
                            <div v-for="variation in productSkuVariations"
                                 :key="variation.id">
                                <span class="variation-type"><b>{{ variation.variationType }}</b> - </span>
                                <span class="value">{{ variation.value }}</span>
                                <span v-if="variation.sortOrder"> ({{ variation.sortOrder }})</span>
                            </div>
                        </div>
                        <span v-else
                              class="no-items">
                            No variations
                        </span>
                    </div>
                    <div class="column-item">
                        <h4>Warehouse Packets</h4>

                        <div v-if="_.get(productSku, 'warehousePackets', []).length">
                            <div v-for="packet in productSku.warehousePackets"
                                 :key="packet.packetSku">
                                <div class="gs-standard-detail">
                                    <label>{{ packet.packetSku }}</label>
                                    <span v-if="packet.sunsetDate">Sunset: {{ packet.sunsetDate }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else
                             class="no-items">
                            No warehouse packets
                        </div>
                    </div>
                </div>

                <div class="col-md-4 detail-column">
                    <div class="column-item">
                        <h4>Dimensions</h4>

                        <div class="gs-standard-detail">
                            <label>Weight in Pounds:</label>
                            <span>{{ productSku.weightInPounds }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Length in Inches:</label>
                            <span>{{ productSku.lengthInInches }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Width in Inches:</label>
                            <span>{{ productSku.widthInInches }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Height in Inches:</label>
                            <span>{{ productSku.heightInInches }}</span>
                        </div>
                    </div>
                    <div class="column-item">
                        <h4>Internal Notes</h4>

                        <div v-if="productSku.internalNotes">
                            {{ productSku.internalNotes }}
                        </div>
                        <span v-else
                              class="no-items">
                            No internal notes
                        </span>
                    </div>
                </div>

                <div class="col-md-4 detail-column lease-schedule">
                    <div v-if="hasFixedTerm"
                         class="column-item">
                        <h4>Lease Schedule</h4>

                        <span class="percentage-override">
                            <b>Override Multiplier:</b> {{ fixedPricingPlan.leasePercentageOverride }}
                        </span>

                        <div v-for="(value, key) in productSku.leaseSchedule"
                             :key="key"
                             class="month">
                            <b>{{ key }}:</b> {{ numberToCurrency(value) }}
                        </div>
                    </div>
                    <div class="column-item">
                        <h4>Pricing Plans</h4>

                        <div v-for="pricingPlan in sortedPricingPlans"
                             :key="pricingPlan.id"
                             class="pricing-plan-group">
                            <div class="gs-standard-detail">
                                <label>Pricing Model:</label>
                                <span>{{ pricingPlan.pricingModel }}</span>
                            </div>
                            <div class="gs-standard-detail">
                                <label>Plan ID:</label>
                                <span>{{ pricingPlan.id }}</span>
                            </div>
                            <div class="gs-standard-detail">
                                <label>Current?:</label>
                                <span :class="{ 'good' : pricingPlan.current }">
                                    {{ pricingPlan.current ? 'true' : 'false' }}
                                </span>
                            </div>
                            <div class="gs-standard-detail">
                                <label>Base price:</label>
                                <span>{{ pricingPlan.basePriceDollars }}</span>
                            </div>
                            <div class="gs-standard-detail">
                                <label>Retail price:</label>
                                <span>{{ pricingPlan.retailPriceDollars }}</span>
                            </div>

                            <div class="gs-standard-detail">
                                <label>Pickup price:</label>
                                <span>{{ pricingPlan.pickupPriceDollars }}</span>
                            </div>
                            <div class="gs-standard-detail">
                                <label>Lease Override:</label>
                                <span>{{ pricingPlan.leasePercentageOverride }}</span>
                            </div>
                            <div v-if="pricingPlan.purchaseScalePercentage"
                                 class="gs-standard-detail">
                                <label>Purchase Scale:</label>
                                <span>{{ pricingPlan.purchaseScalePercentage }}</span>
                            </div>
                            <div class="gs-standard-detail">
                                <label>Created At:</label>
                                <span>{{ formatShortDateString(pricingPlan.createdAt) }}</span>
                            </div>

                            <button v-if="!pricingPlan.current"
                                    class="gs-standard-button"
                                    :disable="pricingPlan.current"
                                    @click="makePlanCurrent(pricingPlan.id)">
                                Make Current
                            </button>
                        </div>
                    </div>
                </div>
            </section>

            <section class="product-sku-images">
                <h3 class="gs-standard-sub-header">
                    SKU Images
                </h3>

                <LoadingSpinner v-if="isLoadingProductSkuImages"
                                is-black="true" />
                <div v-else-if="productSkuImages.length"
                     class="row image-list">
                    <div v-for="(item, index) in productSkuImages"
                         :key="index"
                         class="image col-md-2">
                        <b>{{ item.imageType }}</b>
                        <div v-if="item.publicId">
                            <cld-video v-if="isImageTypeVideo(item.imageType)"
                                       :public-id="item.publicId"
                                       fallback-content="Your browser does not support HTML5 video tags."
                                       controls="true"
                                       width="200"
                                       height="200"
                                       loop="true" />
                            <cld-image v-else
                                       :public-id="item.publicId"
                                       width="200"
                                       height="200"
                                       crop="limit" />
                        </div>
                        <div v-else>
                            <video v-if="isImageTypeVideo(item.imageType)"
                                   :src="item.imageUrl"
                                   controls />
                            <img v-else
                                 :src="item.imageUrl" />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <span class="no-items">No Product Sku Images</span>
                </div>
            </section>

            <section class="ops-details">
                <h3>Ops Details</h3>

                <vue-json-editor v-model="productSku.opsDetails"
                                 :expanded-on-start="true"
                                 mode="view" />
            </section>
        </div>
    </div>
</template>

<script>
  import EventBus from '@/components/utilities/EventBus'
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import VueJsonEditor from 'vue-json-editor'

  export default {
    name: 'ProductSku',
    components: {
      LoadingSpinner,
      VueJsonEditor,
    },
    data() {
      return {
        productSku: {},
        product: {
          brand: {},
        },
        productSkuColors:             [],
        productSkuVariations:         [],
        productSkuImages:             [],
        pricingPlans:                 [],
        isLoadingProductSku:          true,
        isLoadingProduct:             true,
        isLoadingProductSkuColors:    true,
        isLoadingProductSkuImages:    true,
        isLoadingPricingPlans:        true,
      }
    },
    computed: {
      fixedPricingPlan() {
        return this.pricingPlans.find(p => ['initial', 'term_payback'].includes(p.pricingModel) && p.current)
      },
      indefiniteLongPricingPlan() {
        return this.pricingPlans.find(p => ['indefinite_long'].includes(p.pricingModel) && p.current)
      },
      indefiniteShortPricingPlan() {
        return this.pricingPlans.find(p => ['indefinite_short'].includes(p.pricingModel) && p.current)
      },
      hasFixedTerm() {
        const saleType = _.get(this.productSku, 'saleType', '')
        return saleType && saleType.includes('lease') && this.fixedPricingPlan
      },
      productAdditionalPropertiesExist() {
        return this.product && !_.isEmpty(this.product.additionalProperties)
      },
      sortedPricingPlans() {
        return _.orderBy(this.pricingPlans, ['createdAt'], ['desc'])
      },
      ready() {
        const unreadyIndicators = _.keys(_.pickBy(this.productSku.readyIndicators, v => !v))
        if (unreadyIndicators.length === 0) {
          return 'Ready!'
        } else {
          return _.join(unreadyIndicators.map(i => `x ${i}`), ' | ')
        }
      },
      readyClass() {
        if (this.ready === 'Ready!') {
          return 'good'
        } else {
          return 'bad'
        }
      },
    },
    mounted() {
      this.getProductSku()
    },
    methods: {
      getProductSku() {
        this.isLoadingProductSku = true
        const skuId = _.get(this.$route, 'params.productSkuId')
        if (!skuId) return

        this.axios.get(`/product_skus/${skuId}`)
          .then(response => { this.onProductSkuRetrieved(response.data) })
          .catch(() => {
            EventBus.$emit('globalAlert', {
              message: 'An error occurred when fetching the product sku',
              statusCode: 3,
            })
          })
          .finally(() => this.isLoadingProductSku = false)
      },
      getProduct() {
        this.isLoadingProduct = true
        const productId = _.get(this.productSku, 'productId')
        if (!productId) return

        this.axios.get(`/products/${productId}`)
          .then(response => { this.onProductRetrieved(response.data) })
          .catch(() => {
            EventBus.$emit('globalAlert', {
              message: 'An error occurred when fetching the product sku\'s product',
              statusCode: 3,
            })
          })
          .finally(() => this.isLoadingProduct = false)
      },
      getProductSkuColors() {
        this.isLoadingProductSkuColors = true
        const skuId = _.get(this.productSku, 'id')
        if (!skuId) return

        this.axios.get(`/product_skus/${skuId}/product_colors`)
          .then(response => { this.onProductSkuColorsRetrieved(response.data) })
          .catch(() => {
            EventBus.$emit('globalAlert', {
              message: 'An error occurred when fetching the product sku colors',
              statusCode: 3,
            })
          })
          .finally(() => this.isLoadingProductSkuColors = false)
      },
      getProductSkuVariations() {
        const skuId = _.get(this.productSku, 'id')
        if (!skuId) return

        this.axios.get(`/product_skus/${skuId}/product_sku_variations`)
          .then(response => { this.onProductSkuVariationsRetrieved(response.data) })
          .catch(() => {
            EventBus.$emit('globalAlert', {
              message: 'An error occurred when fetching the product sku variations',
              statusCode: 3,
            })
          })
      },
      getProductSkuImages() {
        this.isLoadingProductSkuImages = true
        const skuId = _.get(this.productSku, 'id')
        if (!skuId) return

        this.axios.get(`/product_skus/${skuId}/product_images`)
          .then(response => { this.onProductSkuImagesRetrieved(response.data) })
          .catch(() => {
            EventBus.$emit('globalAlert', {
              message: 'An error occurred when fetching the product sku images',
              statusCode: 3,
            })
          })
          .finally(() => this.isLoadingProductSkuImages = false)
      },
      getPricingPlans() {
        this.isLoadingPricingPlans = true
        const skuId = _.get(this.productSku, 'id')
        if (!skuId) return

        this.axios.get('/pricing_plans', { params: { product_sku_id: skuId } })
          .then(response => { this.onPricingPlansRetrieved(response.data) })
      },
      //////////// TEMPLATE ACTIONS ////////////
      makePlanCurrent(planId) {
        this.axios.patch('/pricing_plans/' + planId, { pricingPlan: { current: true } })
          .then(response => {
            this.getProductSku()
            this.getPricingPlans()
          })
      },
      //////////// CALLBACKS ////////////
      onProductSkuRetrieved(data) {
        this.productSku = data.productSku

        this.getProduct()
        this.getProductSkuColors()
        this.getProductSkuVariations()
        this.getProductSkuImages()
        this.getPricingPlans()
      },
      onProductRetrieved(data) {
        this.product = data.product
      },
      onProductSkuColorsRetrieved(data) {
        this.productSkuColors = data.productColors
      },
      onProductSkuVariationsRetrieved(data) {
        this.productSkuVariations = data.productSkuVariations
      },
      onProductSkuImagesRetrieved(data) {
        this.productSkuImages = data.productImages
      },
      onPricingPlansRetrieved(data) {
        this.pricingPlans = data.pricingPlans
        this.isLoadingPricingPlans = false
      },
      isImageTypeVideo(imageType) {
        return imageType === 'VIDEO'
      },
    },
  }
</script>

<style lang="scss" scoped>

.product-sku {

  .good {
    color: $secondary-color;
  }
  .bad {
    color: $primary-color;
  }

  .gs-standard-content {
    .product-section {
      margin-top: 1em;
      padding: 1em 1em 1em;
      border: 1px solid lightgray;
      border-radius: 1em;
      background-color:#f6f8f9;

      .additional-properties .property-list .property{
        margin-top: 0.25em;
      }
    }

    .product-sku-details {
      .gs-standard-detail {
        > label {
          margin: 0;
        }

        > span {
          float: right;
        }
      }

      .detail-column {
        padding: 0 3rem 0 1rem;

        .column-item {
          margin-bottom: 2em;
          min-height: 300px;
        }

        h4 {
          margin-bottom: 1em;
          padding-bottom: 0.25em;
          border-bottom: 1px solid black;
        }

        .percentage-override {
          margin-bottom: 1em;
          display: inline-block;
        }
      }
    }

    .pricing-plan-group {
      padding-bottom: 2rem;
    }

    .product-sku-images {
      margin-bottom:            3em;

      .image-list .image {
        text-align:           center;
        margin-bottom:        1em;

        > img {
          display:            block;
          min-height:         1.5em;
          width:              100%;
          margin-top:         0.5em;
        }
      }
    }
  }
}

</style>
