var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "best-seller gs-simple-crud"
  }, [_c('h1', [_vm._v("Best Sellers")]), _c('div', {
    staticClass: "product-add-container"
  }, [_vm.isLoadingProducts ? _c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  }) : _c('SimpleAddRemoveList', {
    attrs: {
      "items": _vm.products,
      "selected-items": _vm.selectedProducts,
      "display-key": "name",
      "match-key": "id",
      "item-type": "product"
    },
    on: {
      "itemAdded": _vm.addProduct,
      "itemRemoved": _vm.removeProduct
    }
  }), _c('button', {
    on: {
      "click": _vm.createBestSellers
    }
  }, [_vm._v(" Add First Product ")])], 1), _vm.isLoading ? _c('div', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_c('section', {
    staticClass: "best-seller-table gs-standard-table"
  }, [_vm.bestSellers.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.formattedBestSellers,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "delete",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-delete"
        }, [_c('DeleteIcon', {
          on: {
            "delete": function _delete($event) {
              return _vm.setCurrentDeleteBestSeller(data.row);
            }
          }
        })], 1);
      }
    }, {
      key: "reorder",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-reorder"
        }, [_c('button', {
          attrs: {
            "disabled": data.row.rank == null || _vm.topRanked(data.row)
          },
          on: {
            "click": function click($event) {
              return _vm.promoteRank(data.row);
            }
          }
        }, [_vm._v(" ⇩ ")]), _c('button', {
          attrs: {
            "disabled": data.row.rank === 1
          },
          on: {
            "click": function click($event) {
              return _vm.demoteRank(data.row);
            }
          }
        }, [_vm._v(" ⇧ ")])]);
      }
    }], null, false, 739940542)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no Best Sellers to display ")])], 1), _vm.showConfirmDeleteModal ? _c('ConfirmModal', {
    attrs: {
      "show-loading-state": _vm.isDeletingBestSeller
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showConfirmDeleteModal = false;
      },
      "confirm": function confirm($event) {
        return _vm.deleteBestSeller();
      }
    }
  }, [_c('span', {
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_vm._v("Are you sure you want to remove "), _c('b', [_vm._v(_vm._s(_vm.currentDeletingBestSeller.productName))]), _vm._v(" as a best seller?")])]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }