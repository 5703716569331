<template>
    <div class="product-styles gs-simple-crud">
        <h1>Product Styles</h1>

        <SimpleCreateForm
            :primary-error-message="primaryErrorMessage"
            :error-messages="errorMessages"
            :show-loading-state="isCreatingProductStyle"
            :new-model-instance="newProductStyle"
            @formSubmit="createProductStyle" />

        <div v-if="isLoadingProductStyles">
            <LoadingSpinner is-black="true" />
        </div>

        <div v-else
             class="content">
            <section class="product-styles-table gs-standard-table">
                <v-client-table
                    v-if="productStyles.length > 0"
                    :data="productStyles"
                    :columns="columns"
                    :options="options">
                    <div slot="editName"
                         slot-scope="data">
                        <input v-if="isEditingThisItem(data.row.id)"
                               v-model="data.row['name']" />
                        <span v-else>{{ data.row['name'] }}</span>
                    </div>

                    <div slot="edit"
                         slot-scope="data"
                         class="gs-standard-table-edit">
                        <TableEditButton
                            :is-editing-this-item="isEditingThisItem(data.row.id)"
                            :show-loading-state="isEditingThisItem(data.row.id) && isSavingThisItem(data.row.id)"
                            :show-save-button="isEditingThisItem(data.row.id) && !isSavingThisItem(data.row.id)"
                            @saveButtonClick="updateProductStyle(data.row)"
                            @editButtonClick="currentEditProductStyleId = data.row.id" />
                    </div>

                    <div slot="delete"
                         slot-scope="data"
                         class="gs-standard-table-delete">
                        <DeleteIcon @delete="setCurrentDeleteProductStyle(data.row)" />
                    </div>
                </v-client-table>

                <div v-else
                     class="no-items">
                    There are no Product Styles to display
                </div>
            </section>

            <ConfirmModal
                v-if="showConfirmDeleteModal"
                :show-loading-state="isDeletingProductStyle"
                @cancel="showConfirmDeleteModal = false"
                @confirm="deleteProductStyle()">
                <span slot="body">Are you sure you want to remove <b>{{ currentDeletingProductStyle.name }}</b>? This will also destroy any associations with this product style!</span>
            </ConfirmModal>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import SimpleCreateForm from '@/components/forms/SimpleCreateForm'
  import EventBus from '@/components/utilities/EventBus'
  import TableEditButton from '@/components/tables/TableEditButton'
  import DeleteIcon from '@/components/widgets/DeleteIcon'
  import ConfirmModal from '@/components/modals/ConfirmModal'
  import { arrayHelper } from '@petra-living/petra-vue-common'

  export default {
    name: 'ProductStyles',
    components: {
      LoadingSpinner,
      SimpleCreateForm,
      ConfirmModal,
      DeleteIcon,
      TableEditButton,
    },
    data() {
      return {
        isLoadingProductStyles:          false,
        errorMessages:                   [],
        primaryErrorMessage:             '',
        productStyles:                   [],
        newProductStyle:                 this.initializeNewProductStyle(),
        isCreatingProductStyle:          false,
        currentEditProductStyleId:       null,
        currentSavingProductStyleId:     null,
        currentDeletingProductStyle:     null,
        isDeletingProductStyle:          false,
        showConfirmDeleteModal:          false,
        columns:                         ['id', 'editName', 'identifier', 'productCount', 'edit', 'delete'],
        options: {
          perPage: 20,
          perPageValues: [20, 50, 100, 250, 500],
          headings: {
            'editName': 'Name',
            'productCount': 'Product Count',
          },
        },
      }
    },
    mounted() {
      this.getProductStyles()
    },
    methods: {
      getProductStyles() {
        this.isLoadingProductStyles = true
        this.axios.get('/product_styles')
          .then(response => this.onProductStylesRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      createProductStyle() {
        this.isCreatingProductStyle = true
        this.axios.post('/product_styles', { productStyle: this.newProductStyle })
          .then(response => this.onProductStyleCreated(response.data))
          .catch(error => this.onProductStyleCreatedFailure(error.response))
      },
      updateProductStyle(productStyle) {
        this.currentSavingProductStyleId = productStyle.id
        this.axios.patch('/product_styles/' + productStyle.id, { productStyle: productStyle })
          .then(response => this.onProductStyleUpdated(response.data))
          .catch(error => this.onProductStyleUpdatedFailure(error.response))
      },
      setCurrentDeleteProductStyle(productStyle) {
        this.showConfirmDeleteModal = true
        this.currentDeletingProductStyle = productStyle
      },
      //////////// CALLBACKS ////////////
      onProductStylesRetrieved(data) {
        this.isLoadingProductStyles = false
        this.productStyles = data.productStyles
      },
      onProductStyleCreated(data) {
        this.isCreatingProductStyle = false
        this.productStyles.push(data.productStyle)
        EventBus.$emit('globalAlert', {
          message: 'Successfully created product style!',
          statusCode: 1,
        })
        this.newProductStyle = this.initializeNewProductStyle()
      },
      onProductStyleCreatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating productStyle'
          this.errorMessages = JSON.stringify(response)
        }

        this.isCreatingProductStyle = false
      },
      onProductStyleUpdated(data) {
        arrayHelper.replaceObjectByValue(this.productStyles, data.productStyle, 'id')
        this.currentEditProductStyleId = null
        this.currentSavingProductStyleId = null
      },
      onProductStyleUpdatedFailure(response) {
        EventBus.$emit('globalAlert', {
          message: response.data.messages[0],
          statusCode: 3,
        })

        this.currentSavingProductStyleId = null
      },
      onProductStyleDeleted(data) {
        this.showConfirmDeleteModal = false
        this.currentDeletingProductStyle = null

        arrayHelper.removeObjectByValue(this.productStyles, data.productStyle.id, 'id')
        EventBus.$emit('globalAlert', {
          message: 'Product style successfully deleted!',
          statusCode: 1,
        })
      },
      onProductStyleDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error creating productStyle'
          this.errorMessages = JSON.stringify(response)
        }

        this.showConfirmDeleteModal = false
        this.currentDeletingProductStyle = false
      },
      onProductStyleDeleteDone() {
        this.isDeletingProductStyle = false
      },
      //////////// INTERNAL METHODS ////////////
      initializeNewProductStyle() {
        return {
          name:         '',
        }
      },
      isSavingThisItem(id) {
        return this.currentSavingProductStyleId === id
      },
      isEditingThisItem(id) {
        return this.currentEditProductStyleId === id
      },
      deleteProductStyle() {
        this.isDeletingProductStyle = true
        this.axios.delete('/product_styles/' + this.currentDeletingProductStyle.id)
          .then(response => this.onProductStyleDeleted(response.data))
          .catch(error => this.onProductStyleDeletedFailure(error.response))
          .finally(() => this.onProductStyleDeleteDone())
      },
    },
  }
</script>

<style lang="scss" scoped>

.product-styles {

  .content {
    margin-top:                   1em;
  }
}

</style>
