<template>
    <div class="edit-curated-set">
        <section class="gs-generic-header">
            <h1>Edit Curated Set</h1>
            <button class="gs-standard-button">
                <router-link :to="{ name: 'CuratedSet', params: { curatedSetId: $route.params.curatedSetId } }">
                    Go Back
                </router-link>
            </button>
        </section>

        <LoadingSpinner v-if="isLoadingCuratedSet"
                        is-black="true" />
        <div v-else
             class="content gs-standard-form">
            <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                        :primary-message="primaryErrorMessage"
                        :error-messages="errorMessages" />

            <section class="curated-set-details gs-special-content">
                <div class="gs-generic-header">
                    <h2>Curated Set Details</h2>

                    <button
                        v-if="curatedSet"
                        class="gs-standard-button"
                        :disabled="isUpdatingCuratedSet"
                        @click="updateCuratedSet()">
                        Update
                    </button>
                    <button v-else
                            class="gs-standard-button">
                        <LoadingSpinner />
                    </button>
                </div>

                <div class="row">
                    <div class="col-md-4">
                        <div class="gs-standard-detail">
                            <label>Name</label>
                            <input v-model="curatedSet.name" />
                        </div>
                        <div class="gs-standard-detail">
                            <label>Identifier</label>
                            <input v-model="curatedSet.identifier" />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Category</label>
                            <div class="input-group mb-3 category-select">
                                <select class="custom-select"
                                        @change="selectProductCategory($event)">
                                    <option>
                                        Please select a category...
                                    </option>
                                    <option v-for="option in allProductCategories"
                                            :key="option.id"
                                            :selected="curatedSet.productCategory.id == option.id">
                                        {{ option.name }}
                                    </option>
                                </select>
                            </div>
                        </div>

                        <div class="gs-toggle-container">
                            <label class="tag">Is Active?</label>
                            <toggle-button :value="curatedSet.isActive"
                                           color="#82C7EB"
                                           @change="curatedSet.isActive = !curatedSet.isActive" />
                        </div>
                    </div>
                </div>
            </section>

            <section class="row products">
                <h3>Product Skus</h3>

                <div class="col-md-6">
                    <LoadingSpinner v-if="isLoadingCuratedSetProductSkus"
                                    is-black="true" />
                    <SimpleAddRemoveList
                        v-else
                        :items="allProductSkus"
                        :selected-items="curatedSetProductSkus"
                        :allow-duplicates="true"
                        display-key="name"
                        match-key="id"
                        item-type="product sku"
                        @itemAdded="addProductSku"
                        @itemRemoved="removeProductSku" />
                </div>
            </section>

            <section class="product-images">
                <h3>Curated Set Image</h3>

                <div v-if="curatedSet.heroImage && !isUpdatingCuratedSet">
                    <cld-image
                        :public-id="curatedSet.heroImage"
                        height="200" />
                    <button
                        class="gs-standard-button gs-delete-button"
                        @click="removeCuratedSetImage">
                        Remove
                    </button>
                </div>
                <input
                    v-else-if="!isUpdatingCuratedSet"
                    ref="hidden-uploader"
                    type="file"
                    name="curated-set-image"
                    accept="image/*"
                    class="input-file"
                    @change="onImageSelect($event.target.name, $event.target.files)" />
                <h5 v-else>
                    Uploading...
                </h5>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import FormErrors from '@/components/forms/FormErrors'
  import SimpleAddRemoveList from '@/components/forms/SimpleAddRemoveList'
  import apiHelper from '@/helpers/apiHelper'

  export default {
    name: 'EditCuratedSet',
    components: {
      LoadingSpinner,
      FormErrors,
      SimpleAddRemoveList,
    },
    data() {
      return {
        curatedSet:                     {},
        primaryErrorMessage:            '',
        errorMessages:                  [],
        allProductSkus:                 [],
        allProductCategories:           [],
        curatedSetProductSkus:          [],
        isLoadingCuratedSet:            true,
        isLoadingCuratedSetProductSkus: false,
        isUpdatingCuratedSet:           false,
        isLoadingProductSkus:           false,
        heroImage:                      null,
      }
    },
    mounted() {
      this.getCuratedSet()
      this.getAllProductCategories()
      this.getProductSkus()
    },
    methods: {
      getCuratedSet() {
        this.isLoadingCuratedSet = true
        this.axios.get('/layout_instances/' + this.$route.params.curatedSetId)
          .then(response => { this.onCuratedSetRetrieved(response.data) })
      },
      getAllProductCategories() {
        this.axios.get('/product_categories')
          .then(response => { this.allProductCategories = response.data.productCategories })
      },
      getProductSkus() {
        this.axios.get('/product_skus', { params: { active: true } })
          .then(response => { this.onProductSkusRetrieved(response.data) })
      },
      getCuratedSetProductSkus() {
        const layoutSkus = this.curatedSet.data.pieces.map((p) => p.productSku)
        if (_.isEmpty(layoutSkus)) return

        this.isLoadingProductSkus = true
        this.axios.get('/product_skus', { params: { skus: layoutSkus } })
          .then(response => this.onCuratedSetProductSkusRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      selectProductCategory(event) {
        const categoryName = event.target.value
        this.curatedSet.productCategory = _.find(this.allProductCategories, (pc) => pc.name == categoryName)
        this.curatedSet.productCategoryId = _.get(this.curatedSet.productCategory, 'id')
      },
      updateCuratedSet() {
        this.isUpdatingCuratedSet = true
        this.curatedSet.heroImage = this.heroImage
        this.heroImage = null

        // TODO: Figure out submitting json object via submitMultipartForm
        this.axios.patch('/layout_instances/' + this.$route.params.curatedSetId, { data: this.curatedSet.data })
          .then(response => {
            apiHelper.submitMultipartForm(
              _.omit(this.curatedSet, ['data']),
              '/layout_instances/' + this.$route.params.curatedSetId,
              this.onCuratedSetUpdated,
              this.onCuratedSetUpdatedFailure,
              'patch',
            )
          })
          .catch(response => this.onCuratedSetUpdatedFailure(response))
      },
      addProductSku(productSku) {
        const existingPiece = _.find(this.curatedSet.data.pieces, (piece) => {
          return piece.productSku === productSku.sku
        })

        this.curatedSetProductSkus.push(productSku)

        if (existingPiece) {
          existingPiece.quantity = parseInt(existingPiece.quantity) + 1
          return
        }

        this.curatedSet.data.pieces.push({
          productSku: productSku.sku,
          quantity: 1,
        })
      },
      removeProductSku(productSku, skus, index) {
        const removedSku = _.find(this.curatedSet.data.pieces, piece => {
          return piece.productSku === productSku.sku
        })
        if (removedSku.quantity > 1) {
          removedSku.quantity -= 1
        } else {
          _.pull(this.curatedSet.data.pieces, removedSku)
        }

        this.curatedSetProductSkus.splice(index, 1)
      },
      onImageSelect(name, files) {
        if (files.length === 0) return
        this.heroImage = files[0]
      },
      removeCuratedSetImage() {
        this.curatedSet.heroImage = null
      },
      //////////// CALLBACKS ////////////
      onCuratedSetRetrieved(data) {
        this.curatedSet = data.layoutInstance
        this.curatedSet.productCategoryId = _.get(data.layoutInstance.productCategory, 'id')
        this.isLoadingCuratedSet = false

        this.getCuratedSetProductSkus()
      },
      onCuratedSetProductSkusRetrieved(data) {
        // Handle quantities greater than 1
        this.curatedSetProductSkus = _.flatMap(data.productSkus, sku => {
          const existingPiece = _.find(this.curatedSet.data.pieces, (piece) => {
            return piece.productSku === sku.sku
          })
          if (!existingPiece) return null // This shouldn't happen
          return _.times(existingPiece.quantity || 1, _.constant(sku))
        })
        this.isLoadingCuratedSetProductSkus = false
      },
      onProductSkusRetrieved(data) {
        this.isLoadingProductSkus = false
        this.allProductSkus = data.productSkus
      },
      onCuratedSetUpdated(data) {
        this.isUpdatingCuratedSet = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully updated curated set!',
          statusCode: 1,
        })
        this.$router.push({ name: 'CuratedSet', params: { curatedSetId: data.layoutInstance.id } })
      },
      onCuratedSetUpdatedFailure(response) {
        if (_.get(response.data, 'error')) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error updating curated set'
          this.errorMessages = JSON.stringify(response)
        }

        this.isUpdatingCuratedSet = false
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

.edit-curated-set {

  margin-bottom:                    5em;

  .content {

    > section {
      margin-top:                   2em;

      > h3 {
        width:                      100%;
        margin-bottom:              1em;
      }
    }

    .curated-set-details {

      .gs-standard-detail {
        margin-top:                 1.5em;
      }

      input, textarea {
        display:                    block;
        width:                      95%;
      }

      textarea {
        height:                     12em;
      }

      .gs-toggle-container {
        margin-top:                 2em;
      }
    }
  }
}

</style>
