<template>
    <div class="edit-discount">
        <section class="gs-generic-header">
            <h1>Edit Discount: {{ discount.code }}</h1>

            <router-link
                :to="{ name: 'Discount', params: { discountId: $route.params.discountId } }">
                <button class="gs-standard-button">
                    Go Back
                </button>
            </router-link>
        </section>

        <LoadingSpinner v-if="isLoadingDiscount"
                        is-black="true" />
        <div v-else
             class="content gs-standard-form">
            <div class="create-form gs-standard-form">
                <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                            :primary-message="primaryErrorMessage"
                            :error-messages="errorMessages" />

                <EnableDisableButton
                    :object-to-watch="discount"
                    :observe-entire-object="true"
                    :disable-button-computed="isUpdatingDiscount"
                    button-text="Update"
                    @buttonClick="updateDiscount()" />

                <section class="row discount-details">
                    <h2>Discount Details</h2>

                    <div class="col-md-3">
                        <div class="gs-standard-detail">
                            <label>Code</label>
                            <input v-model="discount.code" />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Start Date</label>
                            <datepicker v-model="discount.startsAt"
                                        :disabled-dates="disabledDates" />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Expire Date</label>
                            <datepicker v-model="discount.expiresAt"
                                        :disabled-dates="disabledDates" />
                        </div>

                        <div class="gs-standard-detail">
                            <label>Uses remaining</label>
                            <input v-model="discount.usesRemaining" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="discount-detail">
                            <label class="block-label">Amount</label>
                            <input v-model="discount.amount"
                                   class="amount"
                                   type="text" />

                            <input id="dollars"
                                   v-model="discount.discountType"
                                   type="radio"
                                   value="dollars" />
                            <label for="dollars">$</label>
                            <input id="percentage"
                                   v-model="discount.discountType"
                                   type="radio"
                                   value="percentage" />
                            <label for="percentage">%</label>
                        </div>

                        <div class="discount-detail frequency">
                            <label class="block-label">Frequency</label>
                            <input id="once"
                                   v-model="discount.frequency"
                                   type="radio"
                                   value="once" />
                            <label for="once">once</label>
                            <input id="recurring"
                                   v-model="discount.frequency"
                                   type="radio"
                                   value="recurring" />
                            <label for="recurring">recurring</label>
                        </div>

                        <div class="discount-detail">
                            <label class="block-label">Credit Rollover?</label>
                            <label class="explanation">
                                If a user's order is less than the discount amount,
                                should they receive a credit for the subsequent months?
                            </label>
                            <input v-model="discount.creditRollover"
                                   type="radio"
                                   value="true" />
                            <label for="true">true</label>
                            <input v-model="discount.creditRollover"
                                   type="radio"
                                   value="false" />
                            <label for="false">false</label>
                        </div>

                        <div class="discount-detail">
                            <label class="block-label">Minimum cart size ($)</label>
                            <input v-model="discount.minimumCartDollars"
                                   class="amount"
                                   type="text" />
                        </div>

                        <div class="discount-detail">
                            <input id="first-order-checkbox"
                                   v-model="discount.firstOrderOnly"
                                   type="checkbox" />
                            <label for="first-order-checkbox">First order only</label>
                        </div>

                        <div class="discount-detail">
                            <input id="referral-checkbox"
                                   v-model="discount.referralProgram"
                                   type="checkbox" />
                            <label for="referral-checkbox">Part of referral program</label>
                        </div>

                        <div class="discount-detail">
                            <label class="block-label">Referral reward ($)</label>
                            <input v-model="discount.referralRewardAmount"
                                   class="amount"
                                   type="text" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="discount-detail">
                            <label class="block-label">Label</label>
                            <input v-model="discount.label"
                                   class="amount"
                                   type="text" />
                        </div>
                        <div class="discount-detail">
                            <label class="block-label">Title</label>
                            <textarea v-model="discount.title" />
                        </div>
                        <div class="discount-detail">
                            <label class="block-label">Description</label>
                            <textarea v-model="discount.description" />
                        </div>
                        <div class="gs-standard-detail">
                            <label>Internal Notes</label>
                            <textarea v-model="discount.internalNotes" />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>

  import Datepicker from 'vuejs-datepicker'
  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import FormErrors from '@/components/forms/FormErrors'
  import EnableDisableButton from '@/components/forms/EnableDisableButton'
  import EventBus from '@/components/utilities/EventBus'

  export default {
    name: 'Discount',
    components: {
      Datepicker,
      LoadingSpinner,
      FormErrors,
      EnableDisableButton,
    },
    data() {
      return {
        primaryErrorMessage: '',
        errorMessages: [],
        discount: {},
        isLoadingDiscount: true,
        isUpdatingDiscount: false,
        imagePrimaryErrorMessage: '',
        imageErrorMessages: [],
        // Disabled until yesterday
        disabledDates:          { to: (d => new Date(d.setDate(d.getDate() - 1)))(new Date()) },
      }
    },
    computed: {
      correctedDiscount() {
        return Object.assign({}, this.discount, {
          startsAt: this.discount.startsAt ? this.$moment.tz(this.discount.startsAt, 'America/Los_Angeles').startOf('day').format() : null,
          expiresAt: this.discount.expiresAt ? this.$moment.tz(this.discount.expiresAt, 'America/Los_Angeles').startOf('day').format() : null,
        })
      },
    },
    mounted() {
      this.getDiscount()
    },
    methods: {
      getDiscount() {
        this.isLoadingDiscount = true
        this.axios.get('/discounts/' + this.$route.params.discountId)
          .then(response => {
            this.onDiscountRetrieved(response.data)
          })
      },
      updateDiscount() {
        this.isUpdatingDiscount = true
        this.axios.patch('/discounts/' + this.discount.id, {
          discount: this.correctedDiscount,
        }).then(response => this.onDiscountUpdated(response.data))
          .catch(error => this.onDiscountUpdatedFailure(error.response))
      },
      //////////// CALLBACKS ////////////
      onDiscountRetrieved(data) {
        this.discount = data.discount
        this.isLoadingDiscount = false
      },
      onDiscountUpdated(data) {
        this.isUpdatingDiscount = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully updated discount!',
          statusCode: 1,
        })

        this.$router.push({
          name: 'Discount',
          params: { discountId: this.$route.params.discountId },
        })
      },
      onDiscountUpdatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error updating discount'
          this.errorMessages = JSON.stringify(response)
        }

        this.isUpdatingDiscount = false
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

  .edit-discount {

    .gs-standard-form {

      > section {
        padding: 1em;

        > h2 {
          width: 100%;
          margin-bottom: 1em;
        }
      }

      .discount-details {

        .discount-detail {

          margin-bottom:      1em;

          .block-label {
            display:          block;
            margin-bottom:    0.25em;
          }

          .explanation {
            color: gray;
            font-size: 0.8em;
          }

          .amount {
            width:   50%;
          }

          &.frequency {

            > input[type="radio"]:first-of-type {
              margin-left:    0;
            }
          }

          > input[type="radio"] {
            margin-left:      0.75em;
            margin-right:     0.25em;
          }

          > input[type="checkbox"] {
            margin-right:     0.25em;
          }
        }

        label {
          margin-bottom:      0;
        }

        input {
          margin-top:         0;
        }

        textarea {
          height:             9em;
          width:              100%;
        }
      }
    }
  }

</style>
