<template>
    <div class="suggestion-override">
        <section class="gs-generic-header">
            <div class="content gs-standard-form">
                <span class="h1">Suggestion Override</span>

                <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                            :primary-message="primaryErrorMessage"
                            :error-messages="errorMessages" />
                <EnableDisableButton
                    v-if="suggestionOverride"
                    :object-to-watch="suggestionOverride"
                    :fields-to-observe="['sourceProductSku', 'targetProductSku', 'priority', 'block']"
                    :disable-button-computed="isDeletingSuggestionOverride"
                    class="delete-button"
                    button-text="Delete"
                    @buttonClick="deleteSuggestionOverride()" />
                <router-link class="gs-standard-button"
                             :to="{ name: 'EditSuggestionOverride', params: { suggestionOverrideId: $route.params.suggestionOverrideId } }">
                    Edit
                </router-link>
            </div>
        </section>

        <LoadingSpinner v-if="isLoadingSuggestionOverride"
                        is-black="true" />
        <div v-else
             class="gs-standard-content">
            <section class="row suggestion-override-details">
                <h3 class="gs-standard-sub-header">
                    General Info
                </h3>

                <div class="col-md-4">
                    <div class="gs-standard-detail">
                        <label>Source Product Sku Id:</label>
                        <span>{{ suggestionOverride.sourceProductSku.id }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Target Product Sku Id:</label>
                        <span>{{ suggestionOverride.targetProductSku.id }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Priority:</label>
                        <span>{{ suggestionOverride.priority }}</span>
                    </div>

                    <div class="gs-standard-detail">
                        <label>Block?:</label>
                        <span>{{ suggestionOverride.block }}</span>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import EventBus from '@/components/utilities/EventBus'
  import EnableDisableButton from '@/components/forms/EnableDisableButton'

  export default {
    name: 'SuggestionOverride',
    components: {
      LoadingSpinner,
      EnableDisableButton,
    },
    data() {
      return {
        suggestionOverride:            {},
        isLoadingSuggestionOverride:   true,
        isDeletingSuggestionOverride:  false,
        primaryErrorMessage:            '',
        errorMessages:                  [],
      }
    },
    computed: {
      ready() {
        const unreadyIndicators = _.keys(_.pickBy(this.suggestionOverride.readyIndicators, v => !v))
        if (unreadyIndicators.length === 0) {
          return 'Ready!'
        } else {
          return _.join(unreadyIndicators.map(i => `x ${i}`), ' | ')
        }
      },
      readyClass() {
        if (this.ready === 'Ready!') {
          return 'good'
        } else {
          return 'bad'
        }
      },
    },
    mounted() {
      this.getSuggestionOverride()
    },
    methods: {
      getSuggestionOverride() {
        this.isLoadingSuggestionOverride = true
        this.axios.get('/suggestion_overrides/' + this.$route.params.suggestionOverrideId)
          .then(response => { this.onSuggestionOverrideRetrieved(response.data) })
      },
      //////////// TEMPLATE ACTIONS ////////////
      deleteSuggestionOverride() {
        if (confirm('Are you sure you want to delete this override?')) {
          this.isDeletingSuggestionOverride = true
          this.axios.delete('/suggestion_overrides/' + this.$route.params.suggestionOverrideId)
            .then(response => {
              this.onSuggestionOverrideDeleted(response.data)
            })
            .catch(error => this.onSuggestionOverrideDeletedFailure(error.response))
        }
      },
      //////////// CALLBACKS ////////////
      onSuggestionOverrideRetrieved(data) {
        this.suggestionOverride = data.suggestionOverride
        this.isLoadingSuggestionOverride = false
      },
      onSuggestionOverrideDeleted(data) {
        this.isDeletingSuggestionOverride = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully deleted suggestion override!',
          statusCode: 1,
        })
        this.$router.push({ name: 'SuggestionOverride', params: { suggestionOverrideId: data.suggestionOverride.id } })
      },
      onSuggestionOverrideDeletedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error deleting suggestion override'
          this.errorMessages = JSON.stringify(response)
        }

        this.isDeletingSuggestionOverride = false
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

.suggestion-override {
  .gs-standard-button {
    display: inline-block;
    position: relative;
    margin: 0.5em;
    float: right;
  }

  .delete-button {
    background-color: #000000;
  }

  .gs-standard-content {

    .bad {
      color:                    #ff0000;
    }

    .good {
      color:                    #008000;
    }

    .suggestion-override-images {

      margin-bottom:            3em;

      .image-list {

        .image {

          text-align:           center;
          margin-bottom:        1em;

          > img {
            display:            block;
            min-height:         1.5em;
            width:              100%;
            margin-top:         0.5em;
          }
        }
      }
    }
  }
}

</style>
