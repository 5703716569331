<template>
    <div class="edit-product-sku">
        <section class="gs-generic-header">
            <h1>Edit Product Sku: {{ productSku.sku }}</h1>

            <router-link :to="{ name: 'ProductSku', params: { productSkuId: $route.params.productSkuId } }">
                <button class="gs-standard-button">
                    Go Back
                </button>
            </router-link>
        </section>

        <LoadingSpinner v-if="isLoadingProductSku"
                        is-black="true" />
        <div v-else
             class="content gs-standard-form">
            <LoadingSpinner v-if="isLoadingProduct"
                            is-black="true" />
            <section v-else
                     class="parent-product-section">
                <h3 class="row">
                    Parent Product
                </h3>

                <div class="row">
                    <div class="col-md-6">
                        <div class="gs-standard-detail">
                            <label>Name:</label>
                            <router-link :to="{ name: 'Product', params: { productId: product.id } }">
                                {{ product.name }}
                            </router-link>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Identifier:</label>
                            <span>{{ product.identifier }}</span>
                        </div>

                        <div class="gs-standard-detail">
                            <label>Brand:</label>
                            <span v-if="product.brand">{{ product.brand.name }}</span>
                        </div>
                    </div>

                    <div class="col-md-6 additional-properties">
                        <h5>Additional Properties:</h5>

                        <div v-if="productAdditionalPropertiesExist"
                             class="property-list">
                            <div v-for="(value, key) in product.additionalProperties"
                                 :key="key"
                                 class="property">
                                {{ key }}: <b>{{ value }}</b>
                            </div>
                        </div>
                        <div v-else
                             class="no-items">
                            No Additional Properties
                        </div>
                    </div>
                </div>
            </section>

            <div class="create-form gs-standard-form">
                <FormErrors v-if="primaryErrorMessage || errorMessages.length > 0"
                            :primary-message="primaryErrorMessage"
                            :error-messages="errorMessages" />

                <section class="product-sku-details gs-standard-form">
                    <div class="gs-generic-header row">
                        <h2>SKU Details</h2>

                        <EnableDisableButton
                            :object-to-watch="productSku"
                            :observe-entire-object="true"
                            :disable-button-computed="isUpdatingProductSku"
                            button-text="Update"
                            @buttonClick="updateProductSku" />
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <div class="gs-standard-detail">
                                <label>SKU</label>
                                <input v-model="productSku.sku" />
                            </div>
                            <div class="gs-standard-detail">
                                <label>UPC</label>
                                <input v-model="productSku.upc" />
                            </div>

                            <div class="gs-standard-detail">
                                <label>Sunset</label>
                                <toggle-button
                                    v-model="productSku.opsDetails.sunset"
                                    :value="productSku.opsDetails.sunset"
                                    color="#82C7EB" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="gs-standard-detail">
                                <label>Retail Price ($)</label>
                                <input v-model="productSku.retailPriceDollars" />
                            </div>
                            <div class="gs-standard-detail">
                                <label>Base Price ($)</label>
                                <input v-model="productSku.basePriceDollars" />
                            </div>
                            <div class="gs-standard-detail">
                                <label>Lease Override Multiplier</label>
                                <input v-model="productSku.leasePercentageOverride" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="gs-standard-detail">
                                <label>Weight (lbs)</label>
                                <input v-model="productSku.weightInPounds" />
                            </div>
                            <div class="gs-standard-detail">
                                <label>Length (in.)</label>
                                <input v-model="productSku.lengthInInches" />
                            </div>
                            <div class="gs-standard-detail">
                                <label>Height (in.)</label>
                                <input v-model="productSku.heightInInches" />
                            </div>
                            <div class="gs-standard-detail">
                                <label>Width (in.)</label>
                                <input v-model="productSku.widthInInches" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="gs-standard-detail">
                                <label>Internal Notes</label>
                                <textarea v-model="productSku.internalNotes" />
                            </div>

                            <div class="gs-toggle-container">
                                <label class="tag">Is Active?</label>
                                <toggle-button :value="productSku.isActive"
                                               color="#82C7EB"
                                               @change="productSku.isActive = !productSku.isActive" />
                            </div>
                        </div>
                    </div>
                </section>

                <section class="row warhouse-packets">
                    <h2>Warehouse Packets</h2>

                    <div class="col-md-6">
                        <div class="new-packet">
                            <h3>Create</h3>
                            <form class="gs-standard-form"
                                  @submit.prevent="createWarehousePacket">
                                <label>New Packet Sku:</label>
                                <input v-model="newPacketSku"
                                       type="text"
                                       name="packetSku" />

                                <button type="submit"
                                        class="gs-standard-button"
                                        :disabled="isCreatingWarehousePacket">
                                    <span>Create</span>
                                </button>
                            </form>
                        </div>

                        <v-client-table
                            v-if="productSku.warehousePackets"
                            :data="productSku.warehousePackets"
                            :columns="['packetSku', 'sunsetDate', 'action']"
                            :options="{filterable: false}">
                            <div slot="editName"
                                 slot-scope="data">
                                <input v-if="isEditingThisItem(data.row.id)"
                                       v-model="data.row['name']" />
                                <span v-else>{{ data.row['name'] }}</span>
                            </div>

                            <div slot="action"
                                 slot-scope="data"
                                 class="gs-standard-table-edit">
                                <button v-if="!data.row.sunsetDate"
                                        class="gs-standard-button"
                                        @click="sunsetWarhousePacket(data.row.id)">
                                    Mark as sunset
                                </button>
                            </div>
                        </v-client-table>
                    </div>
                </section>

                <section class="row variations">
                    <h2>Variations</h2>

                    <div class="col-md-6">
                        <ProductSkuVariationsManager
                            :product-sku-variations="productSkuVariations"
                            @addVariation="addProductSkuVariation"
                            @removeVariation="removeProductSkuVariation" />
                    </div>
                </section>

                <LoadingSpinner v-if="isLoadingProductSkuImages"
                                is-black="true" />
                <section v-else
                         class="product-images">
                    <h2>Product Images</h2>

                    <FormErrors v-if="imagePrimaryErrorMessage || imageErrorMessages.length > 0"
                                :primary-message="imagePrimaryErrorMessage"
                                :error-messages="imageErrorMessages" />

                    <ProductImageManager
                        :product-images="productSkuImages"
                        :is-saving-image="isAddingProductSkuImage"
                        :removing-image-index="removingProductSkuImageIndex"
                        @addProductImage="addProductSkuImage"
                        @removeProductImage="removeProductSkuImage" />
                </section>

                <section class="ops-details">
                    <h2>Ops Details</h2>

                    <vue-json-editor v-model="productSku.opsDetails"
                                     :expanded-on-start="true" />
                </section>
            </div>
        </div>
    </div>
</template>

<script>

  import LoadingSpinner from '@/components/utilities/LoadingSpinner'
  import FormErrors from '@/components/forms/FormErrors'
  import EnableDisableButton from '@/components/forms/EnableDisableButton'
  import ProductSkuVariationsManager from '@/components/resources/product-skus/ProductSkuVariationsManager'
  import ProductImageManager from '@/components/resources/products/ProductImageManager'
  import EventBus from '@/components/utilities/EventBus'
  import { arrayHelper } from '@petra-living/petra-vue-common'
  import apiHelper from '@/helpers/apiHelper'
  import VueJsonEditor from 'vue-json-editor'

  export default {
    name: 'ProductSku',
    components: {
      LoadingSpinner,
      FormErrors,
      EnableDisableButton,
      ProductSkuVariationsManager,
      ProductImageManager,
      VueJsonEditor,
    },
    data() {
      return {
        primaryErrorMessage: '',
        errorMessages: [],
        productSku: {},
        product: {
          brand: {},
        },
        products: [],
        productSkuVariations: [],
        productSkuImages: [],
        isLoadingProductSku: true,
        isLoadingProduct: true,
        isLoadingProductSkuImages: true,
        isUpdatingProductSku: false,
        isAddingProductSkuImage: false,
        removingProductSkuImageIndex: null,
        imagePrimaryErrorMessage: '',
        imageErrorMessages: [],
        newPacketSku: '',
        isCreatingWarehousePacket: false,
      }
    },
    computed: {
      productAdditionalPropertiesExist() {
        return this.product && !_.isEmpty(this.product.additionalProperties)
      },
    },
    mounted() {
      this.getProductSku()
      this.getProducts()
    },
    methods: {
      getProductSku() {
        this.isLoadingProductSku = true
        this.axios.get('/product_skus/' + this.$route.params.productSkuId)
          .then(response => { this.onProductSkuRetrieved(response.data) })
      },
      getProduct(productId) {
        this.isLoadingProduct = true
        this.axios.get('/products/' + productId)
          .then(response => { this.onProductRetrieved(response.data) })
      },
      getProducts() {
        this.axios.get('/products')
          .then(response => this.onProductsRetrieved(response.data))
      },
      getProductSkuVariations() {
        this.axios.get('/product_skus/' + this.productSku.id + '/product_sku_variations')
          .then(response => { this.onProductSkuVariationsRetrieved(response.data) })
      },
      getProductSkuImages() {
        this.isLoadingProductSkuImages = true
        this.axios.get('/product_skus/' + this.productSku.id + '/product_images')
          .then(response => { this.onProductSkuImagesRetrieved(response.data) })
      },
      //////////// TEMPLATE ACTIONS ////////////
      selectParentProduct(product) {
        this.product = product
      },
      removeParentProduct() {
        this.product = {}
        this.productSku.productId = null
      },
      addProductSkuVariation(variationType, value, sortOrder) {
        this.axios.post('/product_skus/' + this.productSku.id + '/product_sku_variations', {
          productSkuVariation: {
            variationType:  variationType,
            value:          value,
            sortOrder:      sortOrder,
          },
        }).then(response => this.onProductSkuVariationAdded(response.data))
          .catch(error => this.onProductSkuVariationAddedFailure(error.response))
      },
      removeProductSkuVariation(variation) {
        this.axios.delete('/product_skus/' + this.productSku.id + '/product_sku_variations/' + variation.id)
          .then(response => this.onProductSkuVariationRemoved(response.data))
      },
      addProductSkuImage(imageType, file) {
        this.imageErrorMessages = []
        this.imagePrimaryErrorMessage = ''
        this.isAddingProductSkuImage = true

        const imageObj = {
          imageType: imageType,
          file: file,
        }
        apiHelper.submitMultipartForm(
          imageObj, // params
          `/product_skus/${this.productSku.id}/product_images`, // endpoint
          this.onProductSkuImageAdded, // onSuccess
          this.onProductSkuImageAddedFailure, // onFailure
        )
      },
      removeProductSkuImage(index) {
        let productSkuImage = this.productSkuImages[index]
        this.removingProductSkuImageIndex = index
        this.axios.delete('/product_skus/' + this.productSku.id + '/product_images/' + productSkuImage.id)
          .then(_ => this.onProductSkuImageRemoved(index))
      },
      updateProductSku() {
        this.isUpdatingProductSku = true
        this.axios.patch('/product_skus/' + this.productSku.id, {
          productSku: this.productSku,
        }).then(response => this.onProductSkuUpdated(response.data))
          .catch(error => this.onProductSkuUpdatedFailure(error.response.data))
      },
      //////////// CALLBACKS ////////////
      onProductSkuRetrieved(data) {
        this.productSku = data.productSku
        this.isLoadingProductSku = false

        this.getProduct(this.productSku.productId)
        this.getProductSkuVariations()
        this.getProductSkuImages()
      },
      onProductRetrieved(data) {
        this.product = data.product
        this.isLoadingProduct = false
      },
      onProductsRetrieved(data) {
        this.products = data.products
      },
      onProductSkuVariationsRetrieved(data) {
        this.productSkuVariations = data.productSkuVariations
      },
      onProductSkuImagesRetrieved(data) {
        this.productSkuImages = data.productImages
        this.isLoadingProductSkuImages = false
      },
      onProductSkuImageRemoved(index) {
        this.removingProductIndex = null
        this.productSkuImages.splice(index, 1)
      },
      onProductSkuUpdated(data) {
        this.isUpdatingProductSku = false
        EventBus.$emit('globalAlert', {
          message: 'Successfully updated product SKU!',
          statusCode: 1,
        })

        this.$router.push({ name: 'ProductSku', params: { productSkuId: this.$route.params.productSkuId } })
      },
      onProductSkuUpdatedFailure(response) {
        if (response.data.error) {
          this.primaryErrorMessage = response.data.error
          this.errorMessages = response.data.messages
        } else {
          this.primaryErrorMessage = 'Unknown error updating product sku'
          this.errorMessages = JSON.stringify(response)
        }

        this.isUpdatingProductSku = false
      },
      onProductSkuImageAdded(response) {
        let productSkuImage = response.productSkuImage
        if (!_.includes(['OTHER_IMAGE', 'LIFESTYLE_IMAGE'], productSkuImage.imageType)) {
          arrayHelper.removeObjectByValue(this.productSkuImages, productSkuImage.imageType, 'imageType')
        }

        this.productSkuImages.push(productSkuImage)
        this.isAddingProductSkuImage = false
      },
      onProductSkuImageAddedFailure(error) {
        this.imagePrimaryErrorMessage = 'An error occurred and the image was not added.'
        this.imageErrorMessages = error.messages

        this.isAddingProductSkuImage = false
      },
      onProductSkuVariationAdded(data) {
        if (data.error) {
          EventBus.$emit('globalAlert', {
            message: data.messages[0],
            statusCode: 3,
          })
        } else {
          this.productSkuVariations.push(data.productSkuVariation)
        }
      },
      onProductSkuVariationAddedFailure(response) {
        EventBus.$emit('globalAlert', {
          message: response.data.messages[0],
          statusCode: 3,
        })
      },
      onProductSkuVariationRemoved(data) {
        arrayHelper.removeObjectByValue(this.productSkuVariations, data.productSkuVariation.id, 'id')
      },
      customFormatter(date) {
        return this.$moment(date).format('YYYY-MM-DD')
      },
      sunsetWarhousePacket(warehousePacketId) {
        this.$apollo.mutate({
          mutation: require('@/graphql/mutations/SunsetWarehousePacketSku.gql'),
          variables: {
            warehousePacketId,
            sunsetDate: this.$moment().format('MM-DD-YYYY'),
          },
        })
          .then((_data) => {
            const warehousePacket = _.find(this.productSku.warehousePackets, (p) => p.id === warehousePacketId)
            if (warehousePacket) {
              warehousePacket.sunsetDate = this.$moment().format('MM-DD-YYYY')
            }
          })
          .catch((error) => {
            alert(error)
          })
      },
      createWarehousePacket() {
        this.isCreatingWarehousePacket = true

        this.$apollo.mutate({
          mutation: require('@/graphql/mutations/AssociateWarehousePacketSku.gql'),
          variables: {
            productSkuId: this.productSku.id,
            packetSku: this.newPacketSku,
          },
        })
          .then((data) => {
            this.productSku.warehousePackets.push({
              id: _.get(data, 'associateWarehousePacketSku.id'),
              packetSku: this.newPacketSku,
              sunsetDate: null,
            })
            this.newPacketSku = ''
          })
          .catch((error) => {
            alert(error)
          })
          .finally(() => this.isCreatingWarehousePacket = false)
      },
    },
  }
</script>

<style lang="scss" scoped>

.edit-product-sku {
  .gs-standard-form {
    > section {
      padding:                    1em;

      > h2 {
        width:                    100%;
        margin-bottom:            1em;
      }
    }

    .parent-product-section {
      position:                   relative;
      margin-top:                 2em;
      padding:                    2em;
      border:                     1px solid lightgray;
      border-radius:              1em;
      background-color:           #f6f8f9;

      > a {
        float:                    right;
      }

      .edit-parent-product {
        position:                 relative;
        padding:                  0.5em 4em 0.5em 1em;
        display:                  inline-block;
        border:                   1px solid lightgray;
        border-radius:            0.5em;

        > i {
          position:               absolute;
          cursor:                 pointer;
          padding:                0.5em;
          right:                  0.5em;
          top:                    0.25em;
        }
      }

      .additional-properties .property-list .property {
         margin-top: 0.25em;
      }
    }

    .new-packet {
      background-color: #f6f8f9;
      padding: 2rem;
      border-radius: 10px;
      margin-bottom: 2rem;

      button {
        float: right;
      }

      input {
        margin-left: 1rem;
        border-radius: 5px;
        border: 1px solid;
      }
    }

    .product-sku-details {
      margin-top:                 2em;

      .gs-generic-header {
        margin-bottom:            1em;
      }

      .detail-column {

        margin-bottom:            2em;

        > h4 {
          margin-bottom:          1em;
          padding-bottom:         0.25em;
          border-bottom:          1px solid black;
          width:                  75%;
        }
      }

      textarea {
        height:                   10em;
      }
    }

    .product-sku-images {
      margin-bottom:              3em;

      .image-list {
        .image {
          text-align:             center;
          margin-bottom:          1em;

          > img {
            display:              block;
            min-height:           1.5em;
            width:                100%;
            margin-top:           0.5em;
          }
        }
      }
    }
  }
}

</style>
