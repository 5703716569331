var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-attribute gs-simple-crud"
  }, [_c('h1', [_vm._v("Product Attributes")]), _c('ApolloMutation', {
    attrs: {
      "mutation": require('@/graphql/mutations/CreateProductAttribute.gql'),
      "variables": _vm.newProductAttribute
    },
    on: {
      "done": _vm.onProductAttributeCreated
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var mutate = _ref.mutate,
            loading = _ref.loading,
            error = _ref.error;
        return [_c('SimpleCreateForm', {
          attrs: {
            "primary-error-message": _vm.primaryErrorMessage,
            "error-messages": _vm.errorMessages,
            "show-loading-state": loading,
            "new-model-instance": _vm.newProductAttribute
          },
          on: {
            "formSubmit": mutate
          },
          scopedSlots: _vm._u([{
            key: ":features",
            fn: function fn(slotProps) {
              return [_c('SimpleAddRemoveList', {
                attrs: {
                  "items": _vm.allowedFeatures,
                  "selected-items": slotProps.value,
                  "allow-duplicates": false,
                  "show-on-empty": true,
                  "item-type": "feature"
                },
                on: {
                  "itemAdded": _vm.addFeature,
                  "itemRemoved": _vm.removeFeature
                }
              })];
            }
          }, {
            key: ":allowedValues",
            fn: function fn(slotProps) {
              return [_c('SimpleCreateRemoveList', {
                attrs: {
                  "values": slotProps.value,
                  "allow-duplicates": true
                },
                on: {
                  "valueAdded": _vm.addAllowedValue,
                  "valueRemoved": _vm.removeAllowedValue
                }
              })];
            }
          }], null, true)
        }), error ? _c('p', [_vm._v(" An error occurred: " + _vm._s(error) + " ")]) : _vm._e()];
      }
    }])
  }), _c('ApolloQuery', {
    attrs: {
      "query": require('@/graphql/queries/ProductAttributes.gql')
    },
    on: {
      "result": _vm.onProductAttributesRetrieved
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var _ref2$result = _ref2.result,
            loading = _ref2$result.loading,
            error = _ref2$result.error,
            data = _ref2$result.data;
        return [loading ? _c('div', {
          staticClass: "loading apollo"
        }, [_vm._v(" Loading... ")]) : error ? _c('div', {
          staticClass: "error apollo"
        }, [_vm._v(" An error occurred: " + _vm._s(data.error) + " ")]) : data ? _c('div', {
          staticClass: "result apollo"
        }, [_c('section', {
          staticClass: "product-attribute-table gs-standard-table"
        }, [data.productAttributes.length > 0 ? _c('v-client-table', {
          attrs: {
            "data": data.productAttributes,
            "columns": _vm.columns,
            "options": _vm.options
          },
          scopedSlots: _vm._u([{
            key: "data",
            fn: function fn(data) {
              return _c('div', {}, [_c('button', {
                staticClass: "gs-standard-button"
              }, [_c('router-link', {
                attrs: {
                  "to": {
                    name: 'ProductAttributeInstances',
                    params: {
                      productAttributeId: data.row['id']
                    }
                  }
                }
              }, [_vm._v(" Data ")])], 1)]);
            }
          }, {
            key: "editName",
            fn: function fn(data) {
              return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.row['name'],
                  expression: "data.row['name']"
                }],
                domProps: {
                  "value": data.row['name']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(data.row, 'name', $event.target.value);
                  }
                }
              }) : _c('span', [_vm._v(_vm._s(data.row['name']))])]);
            }
          }, {
            key: "editDescription",
            fn: function fn(data) {
              return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('textarea', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.row['description'],
                  expression: "data.row['description']"
                }],
                domProps: {
                  "value": data.row['description']
                },
                on: {
                  "input": function input($event) {
                    if ($event.target.composing) { return; }

                    _vm.$set(data.row, 'description', $event.target.value);
                  }
                }
              }) : _c('span', [_vm._v(_vm._s(data.row['description']))])]);
            }
          }, {
            key: "editFeatures",
            fn: function fn(data) {
              return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('SimpleAddRemoveList', {
                attrs: {
                  "items": _vm.allowedFeatures,
                  "selected-items": data.row.features,
                  "allow-duplicates": false,
                  "show-on-empty": true,
                  "item-type": "feature"
                },
                on: {
                  "itemAdded": _vm.addFeature,
                  "itemRemoved": _vm.removeFeature
                }
              }) : _c('pre', [_vm._v(_vm._s(_vm._.join(_vm._.map(data.row['features'], function (v) {
                return "- " + v;
              }), "\n")))])], 1);
            }
          }, {
            key: "editAllowedValues",
            fn: function fn(data) {
              return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('SimpleCreateRemoveList', {
                attrs: {
                  "values": data.row.allowedValues,
                  "allow-duplicates": false
                },
                on: {
                  "valueAdded": _vm.addAllowedValue,
                  "valueRemoved": _vm.removeAllowedValue
                }
              }) : _c('pre', [_vm._v(_vm._s(_vm._.join(_vm._.map(data.row['allowedValues'], function (v) {
                return "- " + v;
              }), "\n")))])], 1);
            }
          }, {
            key: "editMultipleAllowed",
            fn: function fn(data) {
              return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.row['multipleAllowed'],
                  expression: "data.row['multipleAllowed']"
                }],
                attrs: {
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(data.row['multipleAllowed']) ? _vm._i(data.row['multipleAllowed'], null) > -1 : data.row['multipleAllowed']
                },
                on: {
                  "change": function change($event) {
                    var $$a = data.row['multipleAllowed'],
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;

                    if (Array.isArray($$a)) {
                      var $$v = null,
                          $$i = _vm._i($$a, $$v);

                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(data.row, 'multipleAllowed', $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(data.row, 'multipleAllowed', $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(data.row, 'multipleAllowed', $$c);
                    }
                  }
                }
              }) : _c('span', [_vm._v(_vm._s(data.row['multipleAllowed'] ? '✔' : '✘'))])]);
            }
          }, {
            key: "editInternal",
            fn: function fn(data) {
              return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: data.row['internal'],
                  expression: "data.row['internal']"
                }],
                attrs: {
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(data.row['internal']) ? _vm._i(data.row['internal'], null) > -1 : data.row['internal']
                },
                on: {
                  "change": function change($event) {
                    var $$a = data.row['internal'],
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false;

                    if (Array.isArray($$a)) {
                      var $$v = null,
                          $$i = _vm._i($$a, $$v);

                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(data.row, 'internal', $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(data.row, 'internal', $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(data.row, 'internal', $$c);
                    }
                  }
                }
              }) : _c('span', [_vm._v(_vm._s(data.row['internal'] ? '✔' : '✘'))])]);
            }
          }, {
            key: "edit",
            fn: function fn(data) {
              return _c('div', {
                staticClass: "gs-standard-table-edit"
              }, [_c('ApolloMutation', {
                attrs: {
                  "mutation": require('@/graphql/mutations/UpdateProductAttribute.gql'),
                  "variables": data.row
                },
                on: {
                  "done": _vm.onProductAttributeUpdated
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref3) {
                    var mutate = _ref3.mutate,
                        _loading = _ref3._loading,
                        updateError = _ref3.updateError;
                    return [_c('TableEditButton', {
                      attrs: {
                        "is-editing-this-item": _vm.isEditingThisItem(data.row.id),
                        "show-save-button": _vm.isEditingThisItem(data.row.id) && !_vm.isSavingThisItem(data.row.id)
                      },
                      on: {
                        "saveButtonClick": mutate,
                        "editButtonClick": function editButtonClick($event) {
                          _vm.currentEditProductAttributeId = data.row.id;
                        }
                      }
                    }), updateError ? _c('p', [_vm._v(" An error occurred: " + _vm._s(updateError) + " ")]) : _vm._e()];
                  }
                }], null, true)
              })], 1);
            }
          }, {
            key: "delete",
            fn: function fn(data) {
              return _c('div', {
                staticClass: "gs-standard-table-delete"
              }, [_c('DeleteIcon', {
                on: {
                  "delete": function _delete($event) {
                    return _vm.setCurrentDeleteProductAttribute(data.row);
                  }
                }
              })], 1);
            }
          }], null, true)
        }) : _c('div', {
          staticClass: "no-items"
        }, [_vm._v(" There are no product attributes to display ")])], 1), _c('ApolloMutation', {
          attrs: {
            "mutation": require('@/graphql/mutations/DeleteProductAttribute.gql'),
            "variables": _vm.currentDeletingProductAttribute
          },
          on: {
            "done": _vm.onProductAttributeDeleted
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var mutate = _ref4.mutate,
                  _loading = _ref4._loading,
                  deleteError = _ref4.deleteError;
              return [_vm.showConfirmDeleteModal ? _c('ConfirmModal', {
                attrs: {
                  "show-loading-state": _vm.isDeletingProductAttribute
                },
                on: {
                  "cancel": function cancel($event) {
                    _vm.showConfirmDeleteModal = false;
                  },
                  "confirm": mutate
                }
              }, [_c('span', {
                attrs: {
                  "slot": "body"
                },
                slot: "body"
              }, [_vm._v("Are you sure you want to remove "), _c('b', [_vm._v(_vm._s(_vm.currentDeletingProductAttribute.name))]), _vm._v("? This will also any delete data for this attribute!")])]) : _vm._e(), deleteError ? _c('p', [_vm._v(" An error occurred: " + _vm._s(deleteError) + " ")]) : _vm._e()];
            }
          }], null, true)
        })], 1) : _c('div', {
          staticClass: "no-result apollo"
        }, [_vm._v(" No result :( ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }