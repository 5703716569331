var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "product-material gs-simple-crud"
  }, [_c('h1', [_vm._v("Product Materials")]), _c('SimpleCreateForm', {
    attrs: {
      "primary-error-message": _vm.primaryErrorMessage,
      "error-messages": _vm.errorMessages,
      "show-loading-state": _vm.isCreatingProductMaterial,
      "new-model-instance": _vm.newProductMaterial
    },
    on: {
      "formSubmit": _vm.createProductMaterial
    }
  }), _vm.isLoadingProductMaterials ? _c('div', [_c('LoadingSpinner', {
    attrs: {
      "is-black": "true"
    }
  })], 1) : _c('div', {
    staticClass: "content"
  }, [_c('section', {
    staticClass: "product-material-table gs-standard-table"
  }, [_vm.productMaterials.length > 0 ? _c('v-client-table', {
    attrs: {
      "data": _vm.productMaterials,
      "columns": _vm.columns,
      "options": _vm.options
    },
    scopedSlots: _vm._u([{
      key: "editName",
      fn: function fn(data) {
        return _c('div', {}, [_vm.isEditingThisItem(data.row.id) ? _c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: data.row['name'],
            expression: "data.row['name']"
          }],
          domProps: {
            "value": data.row['name']
          },
          on: {
            "input": function input($event) {
              if ($event.target.composing) { return; }

              _vm.$set(data.row, 'name', $event.target.value);
            }
          }
        }) : _c('span', [_vm._v(_vm._s(data.row['name']))])]);
      }
    }, {
      key: "edit",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-edit"
        }, [_c('TableEditButton', {
          attrs: {
            "is-editing-this-item": _vm.isEditingThisItem(data.row.id),
            "show-loading-state": _vm.isEditingThisItem(data.row.id) && _vm.isSavingThisItem(data.row.id),
            "show-save-button": _vm.isEditingThisItem(data.row.id) && !_vm.isSavingThisItem(data.row.id)
          },
          on: {
            "saveButtonClick": function saveButtonClick($event) {
              return _vm.updateProductMaterial(data.row);
            },
            "editButtonClick": function editButtonClick($event) {
              _vm.currentEditProductMaterialId = data.row.id;
            }
          }
        })], 1);
      }
    }, {
      key: "delete",
      fn: function fn(data) {
        return _c('div', {
          staticClass: "gs-standard-table-delete"
        }, [_c('DeleteIcon', {
          on: {
            "delete": function _delete($event) {
              return _vm.setCurrentDeleteProductMaterial(data.row);
            }
          }
        })], 1);
      }
    }], null, false, 2514861876)
  }) : _c('div', {
    staticClass: "no-items"
  }, [_vm._v(" There are no Product Materials to display ")])], 1), _vm.showConfirmDeleteModal ? _c('ConfirmModal', {
    attrs: {
      "show-loading-state": _vm.isDeletingProductMaterial
    },
    on: {
      "cancel": function cancel($event) {
        _vm.showConfirmDeleteModal = false;
      },
      "confirm": function confirm($event) {
        return _vm.deleteProductMaterial();
      }
    }
  }, [_c('span', {
    attrs: {
      "slot": "body"
    },
    slot: "body"
  }, [_vm._v("Are you sure you want to remove "), _c('b', [_vm._v(_vm._s(_vm.currentDeletingProductMaterial.name))]), _vm._v("? This will also destroy any associations with this product material!")])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }