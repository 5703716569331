<template>
    <div class="product-sku-variations-manager">
        <div v-if="productSkuVariationsExist"
             class="variation-list">
            <div v-for="variation in productSkuVariations"
                 :key="variation.id"
                 class="variation">
                {{ variation.variationType }}: <b>{{ variation.value }}</b> <span v-if="variation.sortOrder"> ({{ variation.sortOrder }})</span>
                <i class="fa fa-close remove-variation"
                   @click="removeVariation(variation)" />
            </div>
        </div>
        <div v-else
             class="no-items">
            No Variations
        </div>

        <div class="new-variation">
            <SimpleArrayDropdown
                :items="productSkuVariationTypes"
                :items-to-exclude="productSkuVariationsToExclude"
                :selected-item="currentProductSkuVariation"
                prompt="Please select a variation (if applicable)"
                form-width="20em"
                @itemSelected="variationSelected" />
            <input v-model="currentVariationValue"
                   class="value"
                   placeholder="Value"
                   @keyup.enter="addVariation()" />
            <input v-model="currentVariationSortOrder"
                   type="number"
                   class="sort-order"
                   placeholder="Sort Order"
                   @keyup.enter="addVariation()" />
        </div>

        <button :disabled="isAddVariationDisabled"
                class="gs-standard-button"
                @click="addVariation()">
            Add
        </button>
    </div>
</template>

<script>

  import SimpleArrayDropdown from '@/components/forms/SimpleArrayDropdown'

  export default {
    name: 'ProductSkuVariationsManager',
    components: {
      SimpleArrayDropdown,
    },
    props: {
      productSkuVariations:{
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        productSkuVariationTypes:   [],
        currentVariationValue:      '',
        currentVariationSortOrder:  '',
        currentProductSkuVariation: '',
      }
    },
    computed: {
      isAddVariationDisabled() {
        return !this.currentVariationValue || !this.currentProductSkuVariation
      },
      productSkuVariationsExist() {
        return Object.keys(this.productSkuVariations).length > 0
      },
      productSkuVariationsToExclude() {
        return this.productSkuVariations.map(variation => { return variation.variationType })
      },
    },
    mounted() {
      this.getProductSkuVariationTypes()
    },
    methods: {
      //////////// CREATED/MOUNTED METHODS ////////////
      getProductSkuVariationTypes() {
        this.axios.get('/product_sku_variation_types')
          .then(response => this.onVariationTypesRetrieved(response.data))
      },
      //////////// TEMPLATE ACTIONS ////////////
      addVariation() {
        if (this.isAddVariationDisabled) return

        this.$emit('addVariation', this.currentProductSkuVariation, this.currentVariationValue, this.currentVariationSortOrder)

        this.currentVariationValue = ''
        this.currentProductSkuVariation = ''
        this.currentVariationSortOrder = ''
      },
      variationSelected(type) {
        this.currentProductSkuVariation = type
      },
      removeVariation(variation) {
        this.$emit('removeVariation', variation)
      },
      //////////// CALLBACKS ////////////
      onVariationTypesRetrieved(data) {
        this.productSkuVariationTypes = data.productSkuVariationTypes
      },
    //////////// INTERNAL METHODS ////////////

    },
  }
</script>

<style lang="scss" scoped>

.product-sku-variations-manager {

  .variation-list {
    margin:                   0 1em 1em 1em;

    .variation {
      padding:                0.5em;
      border:                 1px solid lightgray;
      border-radius:          0.5em;
      width:                  50%;
      margin-top:             0.5em;

      .remove-variation {
        float:                right;
        cursor:               pointer;
        padding:              0.25em;
      }
    }
  }

  .new-variation {

    > input {
      padding:                0.5em;
      display:                inline-block;

      &:first-of-type {
        margin-right:         0.5em;
      }
    }
  }

  .gs-standard-button {
    margin-top:               1em;
    display:                  inline-block;
  }
}

</style>
