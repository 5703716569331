<template>
    <b-navbar v-if="!isHidden"
              class="top-nav"
              :class="{ 'prod': isProduction }">
        <b-navbar-brand>
            <router-link :to="{ name: 'Home' }">
                Noodl
            </router-link>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse" />

        <b-collapse id="nav-collapse"
                    is-nav>
            <b-navbar-nav>
                <b-nav-item v-for="link in majorLinks"
                            :key="link.name">
                    <router-link :to="{ name: link.routeName }">
                        {{ link.name }}
                    </router-link>
                </b-nav-item>

                <b-nav-item-dropdown text="Settings"
                                     right>
                    <b-dropdown-item v-for="link in settingsLinks"
                                     :key="link.name">
                        <router-link :to="{ name: link.routeName }">
                            {{ link.name }}
                        </router-link>
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown text="Product Attributes"
                                     right>
                    <b-dropdown-item v-for="link in attributeLinks"
                                     :key="link.name">
                        <router-link :to="{ name: link.routeName }">
                            {{ link.name }}
                        </router-link>
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown text="New"
                                     right>
                    <b-dropdown-item>
                        <router-link :to="{ name: 'JourneyList' }">
                            Driver App
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <router-link :to="{ name: 'JourneyScheduling' }">
                            Scheduling App
                        </router-link>
                    </b-dropdown-item>
                </b-nav-item-dropdown>

                <b-nav-item-dropdown text="Tools"
                                     right>
                    <b-dropdown-item :href="`${apiRoot}/sidekiq`">
                        Sidekiq
                    </b-dropdown-item>
                    <b-dropdown-item :href="`${apiRoot}/flipper`">
                        Flipper
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>

            <!-- Right aligned -->
            <b-navbar-nav class="ml-auto">
                <b-nav-item-dropdown right>
                    <template #button-content>
                        Logged in as {{ currentUser.emailAddress }}
                    </template>
                    <b-dropdown-item>
                        <router-link :to="{ name: 'UserAccount' }">
                            Account
                        </router-link>
                    </b-dropdown-item>
                    <b-dropdown-item @click="logout">
                        Sign Out
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'Navbar',
    props: {
      isHidden: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        majorLinks: [
          { name: 'Product SKUs', routeName: 'ProductSkusList' },
          { name: 'Sets / Layouts', routeName: 'CuratedSetList' },
          { name: 'Suggestion Overrides', routeName: 'SuggestionOverrideList' },
          { name: 'Discounts', routeName: 'DiscountsList' },
          { name: 'Partnerships', routeName: "Partnerships" },
        ],
        settingsLinks: [
          { name: 'Banner', routeName: 'Banners' },
          { name: 'Blackout Dates', routeName: 'BlackoutDates' },
        ],
        attributeLinks: [
          { name: 'Best Sellers', routeName: 'BestSellers' },
          { name: 'Brands', routeName: 'Brands' },
          { name: 'Manufacturers', routeName: 'Manufacturers' },
          { name: 'Defined Attributes', routeName: 'ProductAttributes' },
          { name: 'Product Materials', routeName: 'ProductMaterials' },
          { name: 'Product Styles', routeName: 'ProductStyles' },
          { name: 'Product Categories', routeName: 'ProductCategories' },
          { name: 'Product Types', routeName: 'ProductTypes' },
          { name: 'Product Colors', routeName: 'ProductColors' },
          { name: 'Tags', routeName: 'Tags' },
        ],
        apiRoot: (process.env.VUE_APP_API_HOST || 'http://localhost:3000'),
      }
    },
    computed: {
      ...mapGetters({ currentUser: 'currentUser' }),
      isProduction() {
        return process.env.VUE_APP_NOODL_ENV === 'production'
      },
    },
    mounted() {
      // remove navbar-expand class from top-nav if viewport is smallish
      if (window.innerWidth < 1100) {
        $('.top-nav').removeClass('navbar-expand')
      }
    },
    methods: {
      logout() {
        this.$store.dispatch('logout')
        this.$router.replace(this.$route.query.redirect || '/')
      },
    },
  }
</script>

<style lang="scss">
.top-nav {
  background-color: $navbarYellow;

  .navbar-brand {
    font-weight: bold;
    color: $navbarDark;

    a {
      color: $navbarDark;
      text-decoration: none;
    }
  }

  .nav-item {
    margin-left: 0.5em;
  }

  .navbar-nav .nav-item a.dropdown-item:active {
    background-color: inherit;
  }

  .navbar-nav .nav-item a.dropdown-item a {
    width: 100%;
    display: block;
  }

  .navbar-nav .nav-item {
    a:link, a:visited, a:hover, a:active {
      color: $navbarDark;
      text-decoration: none;
    }
  }
}

.top-nav.prod {
  background-color: $navbarDark;

  .navbar-brand {
    color: white;

    a {
      color: white;
    }
  }

  .navbar-nav .nav-item {
    a:link, a:visited, a:hover, a:active {
      color: white;
    }

    a.dropdown-item, a.dropdown-item a {
      color: $navbarDark
    }
  }
}
</style>
