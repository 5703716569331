var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('button', {
    staticClass: "delete-icon gs-standard-button",
    on: {
      "click": function click($event) {
        return _vm.$emit('delete');
      }
    }
  }, [_vm._v(" Delete ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }