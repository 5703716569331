var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "modal"
    }
  }, [_c('div', {
    staticClass: "modal-mask"
  }, [_c('div', {
    staticClass: "modal-wrapper",
    on: {
      "click": _vm.closeModal
    }
  }, [_c('div', {
    staticClass: "modal-container"
  }, [_c('div', {
    staticClass: "body"
  }, [_c('div', {
    staticClass: "type-select-container"
  }, [_c('h3', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" " + _vm._s(_vm.categoryName) + " ")]), _c('h4', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Product Types ")]), _c('v-select', {
    attrs: {
      "value": _vm.selected,
      "label": "name",
      "options": _vm.allProductTypesWithAccent,
      "multiple": "",
      "placeholder": "Select Type"
    },
    on: {
      "input": _vm.setSelected
    }
  }), _c('button', {
    staticClass: "gs-standard-button",
    staticStyle: {
      "margin-top": "20px"
    },
    on: {
      "click": _vm.onAdd
    }
  }, [_vm._v(" Add ")]), _c('button', {
    staticClass: "gs-standard-button clear",
    on: {
      "click": function click($event) {
        return _vm.onClear();
      }
    }
  }, [_vm._v(" Clear Selection ")])], 1), _c('div', {
    staticClass: "gs-toggle-container",
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('label', {
    staticClass: "tag",
    staticStyle: {
      "margin-right": "20px"
    }
  }, [_vm._v("Show Accents: ")]), _c('toggle-button', {
    attrs: {
      "value": _vm.accent,
      "color": "#82C7EB"
    },
    on: {
      "change": function change($event) {
        _vm.accent = !_vm.accent;
      }
    }
  })], 1), _c('div', {
    staticClass: "type-container"
  }, [_c('div', {
    staticClass: "core-type"
  }, [_c('h4', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Core Types ")]), _c('draggable', {
    on: {
      "update": _vm.onUpdate
    },
    model: {
      value: _vm.currentCoreTypes,
      callback: function callback($$v) {
        _vm.currentCoreTypes = $$v;
      },
      expression: "currentCoreTypes"
    }
  }, [_c('transition-group', _vm._l(_vm.currentCoreTypes, function (element) {
    return _c('div', {
      key: element.id
    }, [_c('div', {
      staticClass: "product-type-boxes"
    }, [_c('div', {
      staticClass: "product-type-name"
    }, [_vm._v(" " + _vm._s(element.name) + " ")]), _c('button', {
      staticClass: "product-delete-button gs-standard-button",
      on: {
        "click": function click($event) {
          return _vm.onDelete('core', element.id);
        }
      }
    }, [_vm._v(" Delete ")])])]);
  }), 0)], 1)], 1), _c('div', {
    staticClass: "accent-type"
  }, [_c('h4', {
    staticStyle: {
      "margin-bottom": "10px"
    }
  }, [_vm._v(" Accent Types ")]), _c('draggable', {
    on: {
      "update": _vm.onUpdate
    },
    model: {
      value: _vm.currentAccentTypes,
      callback: function callback($$v) {
        _vm.currentAccentTypes = $$v;
      },
      expression: "currentAccentTypes"
    }
  }, [_c('transition-group', _vm._l(_vm.currentAccentTypes, function (element) {
    return _c('div', {
      key: element.id
    }, [_c('div', {
      staticClass: "product-type-boxes"
    }, [_c('div', {
      staticClass: "product-type-name"
    }, [_vm._v(" " + _vm._s(element.name) + " ")]), _c('button', {
      staticClass: "product-delete-button gs-standard-button",
      on: {
        "click": function click($event) {
          return _vm.onDelete('accent', element.id);
        }
      }
    }, [_vm._v(" Delete ")])])]);
  }), 0)], 1)], 1)])]), _c('div', {
    staticClass: "footer tablet-hide"
  }, [_vm._t("footer", function () {
    return [_c('button', {
      staticClass: "gs-standard-button cancel",
      attrs: {
        "disabled": _vm.showLoadingState
      },
      on: {
        "click": function click($event) {
          return _vm.onCancelClick();
        }
      }
    }, [_vm._v(" Cancel ")]), _c('button', {
      staticClass: "gs-standard-button confirm",
      attrs: {
        "disabled": _vm.showLoadingState
      },
      on: {
        "click": function click($event) {
          return _vm.onConfirm();
        }
      }
    }, [_vm.showLoadingState ? _c('LoadingSpinner', {
      attrs: {
        "max-height": "1"
      }
    }) : _vm.showSuccess ? _c('span', [_vm._v("Success")]) : _vm.showFailure ? _c('span', [_vm._v("Failed")]) : _c('span', [_vm._v("Confirm")])], 1)];
  })], 2)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }